import { jsPDF } from "jspdf";
import { Injectable } from '@angular/core';
import{
    Order,
    Order_Service,
    ServiceAddress_Service,
    User,
    User_Service
} from 'legalreflex-lib'

import '../../../assets/OpenSans-Medium-normal.js' 
import '../../../assets/OpenSans-Bold-bold.js'
import '../../../assets/OpenSans-Italic-italic.js'
@Injectable()
export class OrderPrint_Service {


    constructor(
        public serveService: ServiceAddress_Service
    ){}

    // public print(data: ReviewData, outputType: outputTypes = "dataurlnewwindow"){
    //     this.printDetails(data, outputType);
    // }

    public print(data: ReviewData, outputType: outputTypes = "dataurlnewwindow"){
        let doc = new jsPDF('p', 'in', [8.5,11], true);
        //[950, 1150] Original px Dimensions
        // Needs addition of ttf file for font addition
        // Conversion takes place through jspdf fontconverter, can just open up converter
        // in the jsPDF/fontconverter/fontconverter.html and drop in ttf file
        // or wrap up ttf file request result into binary string
        // https://github.com/parallax/jsPDF/blob/master/README.md#use-of-utf-8--ttf

        let defaultFontColor = "#0F1319";
        doc.setTextColor(defaultFontColor);

        let rowPosition = 0.50;
        let pageWidth = 8.5;
        let pageHeight = 11;
        let pageStart = 2.1;
        let startText = 0.4;
        let endText = 7.8;

        let leftColumnStartYPos = 0;
        let leftColumnHeaderXPos = 0.6;
        let leftLineSpacing = 0.65;
        let rightColumnHeaderXPos = 4.40;
        let rightLineSpacing = 4.45;
        let newLine = 0.185;
        //4 column grid
        let columnWidth = 2.125;
        let column1 = 1.115;
        let column2 = column1 + columnWidth;
        let column3 = column2 + columnWidth;

        doc.setLineWidth(0.0037);
        doc.setFont('OpenSans-Medium', 'normal');
        doc.setFillColor('#FFFFFF').rect(0, 0, pageWidth, pageHeight, "F");
        //short hand for formatting
        let quickcheck = (size:number = pageHeight - (newLine * 5)):void => {
            if(rowPosition >= size){
                let tempPosition = rowPosition;
                rowPosition = size - newLine;
                doc.setLineWidth(0.03);
                doc.setDrawColor('#022969');
                doc.rect(startText - 0.05, pageStart - newLine, endText + 0.05, tempPosition - pageStart + newLine);
                tempPosition += 0.0075;
                doc.setFillColor('#eeeeee').rect(startText - 0.07, tempPosition, endText + 0.09, 0.320, "F");
                tempPosition += newLine;
        
                doc.setFont('OpenSans-Italic', 'italic');
                let boxWidth = endText + 0.09 - (startText - 0.07);
                slt().text("For Internal Use", startText + 0.2, tempPosition);
                slt().text("DRV: " + (data.driverNumber ? data.driverNumber : ""), (startText + 0.2) + (boxWidth/4), tempPosition);
                slt().text("DSP: " + (data.dispatchNumber ? data.dispatchNumber : ""), (startText + 0.2) + 2 * (boxWidth/4), tempPosition);
                slt().text("BOL: " + (data.bolNumber ? data.bolNumber : ""), (startText + 0.2) + 3 * (boxWidth/4), tempPosition);
        
                doc.setFont('OpenSans-Medium', 'normal');
                // Footer 
                tempPosition = pageHeight - newLine;
                th().text(`© Copyright 2024, First Legal. All rights reserved. Private Investigator Licenses: CA PI: 24171 AZ PI: 1551710 NV PI-PS: 1452`, column1 + 0.555, tempPosition);

                doc.addPage();
                doc.setPage(doc.getNumberOfPages());
                rowPosition = 0.9;
                doc.setFillColor('#FFFFFF').rect(0, 0, pageWidth, pageHeight, "F");
                pageStart = 0.9;
            }
        }

        let miniText = (dataString: string, maxLength:number, xPos:number, fontSize:number = 7):void => {
            if(dataString.length > maxLength){
                let curLength: number = 0;
                while((curLength + maxLength) < (dataString.length - 1)){
                    fl(fontSize).text(dataString.slice(curLength, curLength + maxLength), xPos, rowPosition);
                    curLength += maxLength;
                    rowPosition += 0.1;
                }
                if(curLength <= dataString.length){
                    fl(fontSize).text(dataString.slice(curLength, dataString.length), xPos, rowPosition);
                    rowPosition += 0.1;
                }
            }else if(dataString.length > 0){
                fl(fontSize).text(dataString, xPos, rowPosition);
            }
        }

        let hyl = (size:number = 6.5, color:string = "#4f7ba0"): jsPDF => {
            quickcheck();
            return doc.setFontSize(size).setTextColor(color);
        }

        let bold = (size:number, color:string = "#0F1319"): jsPDF => {
            quickcheck();
            return doc.setFontSize(size).setTextColor(color);
        }

        let st = (size: number, color:string = "#022969"): jsPDF => {
            quickcheck();
            return doc.setFontSize(size).setTextColor(color);
        }

        let fl = (size:number = 8): jsPDF => {
            quickcheck();
            return doc.setFontSize(size).setTextColor(defaultFontColor);
        }

        let slt = (color:string = "#808080"): jsPDF => {
            quickcheck();
            return doc.setFontSize(8).setTextColor(color);
        }

        let th = (): jsPDF => { 
            quickcheck();
            return doc.setFontSize(6.5).setTextColor(defaultFontColor);
        }

        // Header
        hyl().text("FirstConnect Order Confirmation", startText, 0.37);
        let appurl = data.env;
        hyl().textWithLink("View In First Connect", 7.2, 0.37 ,{url: appurl});
        doc.setLineWidth(0.0037);
        doc.setDrawColor('#12426A');
        doc.line(7.19, 0.38, 8.11, 0.38);

        // Top Logo
        let logo = new Image();
        let logoSrc = "../../../assets/fln_logocorp.png";
        logo.src = logoSrc;
        // doc.setFillColor('#FFFFFF').rect(0.463, 1.38, 7.87, pageHeight - 1.38, "F");
        doc.addImage(logo, 'PNG', startText - 0.05, 0.65, 2.5, 0.6);

        // Submission blurb
        rowPosition = 0.77;
        doc.setFont('OpenSans-Bold', 'bold');
        st(11, '#000000').text("Order Date:", 5.8, rowPosition)
        rowPosition += 0.150;
        st(11, '#000000').text("Order ID:", 6, rowPosition)
        rowPosition -= 0.150;
        doc.setFont('OpenSans-Medium', 'normal');

        st(11, '#000000').text(" " + data.createdAt, 6.75, rowPosition);
        rowPosition += 0.150;
        st(11, '#000000').text(" " + data.orderNumber, 6.75, rowPosition);

        rowPosition += newLine * 4;
        th().text('Should you have any questions, please contact Client Care and Success at', column1 + (columnWidth/3), rowPosition);
        let curLen = th().getTextWidth("Should you have any questions, please contact Client Care and Success at ") + column1 + (columnWidth/3);
        
        hyl().textWithLink('877.350.8698', curLen, rowPosition, {url: "tel:877.350.8698"});
        curLen = curLen + th().getTextWidth('877.350.8698');
        th().text(', email ', curLen, rowPosition);
        curLen = curLen + th().getTextWidth(', email ');

        hyl().textWithLink(`clientcare@firstlegal.com`, curLen, rowPosition, {url:"mailto:clientcare@firstlegal.com"});
        curLen = curLen + th().getTextWidth('clientcare@firstlegal.com ');
        rowPosition += newLine * 2;

        leftColumnStartYPos = rowPosition;

        // First Legal Control Table
        doc.setFillColor('#eeeeee').rect(leftColumnHeaderXPos, rowPosition, 3.70, 0.278, "F");
        rowPosition += newLine;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("General Information", leftLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        doc.setFont('OpenSans-Medium', 'normal');
        fl().text("Name:", leftLineSpacing, rowPosition);
        fl().text(data.firmName || "", column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Ordered By:", leftLineSpacing, rowPosition);
        fl().text(data.callerName || "", column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Client Matter:", leftLineSpacing, rowPosition);
        fl().text(data.clientMatterNumber, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Claim Number:", leftLineSpacing, rowPosition);
        fl().text(data.claimNumber, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Insurance Company:", leftLineSpacing, rowPosition);
        fl().text(data.insuranceCompany, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Insurance Adjuster:", leftLineSpacing, rowPosition);
        fl().text(data.insuranceAdjuster, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        // First Legal Case Table
        doc.setFillColor('#eeeeee').rect(leftColumnHeaderXPos, rowPosition, 3.70, 0.278, "F");
        rowPosition += newLine;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Case Information", leftLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        fl().text("Case Name:", leftLineSpacing, rowPosition);
        miniText(data.caseName, 40, column1 + (columnWidth/3), 8)
        rowPosition += newLine;

        fl().text("Case Number:", leftLineSpacing, rowPosition);
        fl().text(data.caseNumber, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        // First Legal Pickup Table
        doc.setFillColor('#eeeeee').rect(leftColumnHeaderXPos, rowPosition, 3.70, 0.278, "F");
        rowPosition += newLine;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Pickup Details", leftLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        fl().text("Contact:", leftLineSpacing, rowPosition);
        fl().text(data.pickupContact, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Company:", leftLineSpacing, rowPosition);
        fl().text(data.pickupCompany ? data.pickupCompany.split(' - ')[0] : "", column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Address:", leftLineSpacing, rowPosition);
        miniText(data.pickupAddress[0], 40, column1 + (columnWidth/3), 8);
        // fl().text(data.pickupAddress[0], column1 + (columnWidth/3), rowPosition);
        if(data.pickupAddress[1]){
            rowPosition += (data.pickupAddress[0].length > 0 ? 0.10 : 0);
            fl().text(data.pickupAddress[1], column1 + (columnWidth/3), rowPosition);
        }
        rowPosition += newLine;
        
        fl().text("Phone:", leftLineSpacing, rowPosition);
        fl().text(data.pickupPhone, column1 + (columnWidth/3), rowPosition);
        rowPosition += newLine;

        fl().text("Pickup Date:", leftLineSpacing, rowPosition);
        fl().text(data.pickupDate, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.148;

        let leftColumn = rowPosition;
        rowPosition = leftColumnStartYPos;

        // Fulfillment Information
        doc.setFillColor('#eeeeee').rect(rightColumnHeaderXPos, rowPosition, 3.50, 0.278, "F");
        rowPosition += newLine;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Fulfillment Information", rightLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        fl().text("Service Line:", rightLineSpacing, rowPosition);
        fl().text(data.service, column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        fl().text("Service Category:", rightLineSpacing, rowPosition);
        fl().text(data.serviceCategory + (data.serviceCategoryDescription ? data.serviceCategoryDescription : ""), column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        fl().text("Service Type:", rightLineSpacing, rowPosition);
        fl().text(data.serviceType, column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        fl().text("Pieces:", rightLineSpacing, rowPosition);
        fl().text(data.pieces ? data.pieces : "", column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        fl().text("Weight:", rightLineSpacing, rowPosition);
        fl().text(data.weight ? data.weight : "", column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        // First Legal Deliver Table
        doc.setFillColor('#eeeeee').rect(rightColumnHeaderXPos, rowPosition, 3.50, 0.278, "F");
        rowPosition += newLine;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Delivery Details", rightLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;
        if(data.servee.length > 1){
            for(let cur = 0; cur < data.servee.length; cur++){
                fl().text("Servee", column3 + 0.555, rowPosition);
                fl().text("#" + (cur + 1) + ":", column3 + 0.575, rowPosition);
    
                fl().text("Contact:", column3 + 0.555, rowPosition);
                fl().text(data.servee[cur], column3 + (columnWidth/8), rowPosition);
                rowPosition += newLine;
    
                fl().text("Company:", column3 + 0.555, rowPosition);
                fl().text(data.serveCompany[cur], column3 + (columnWidth/8), rowPosition);
                rowPosition += newLine;
    
                fl().text("Address:", column3 + 0.555, rowPosition);
                miniText(data.serveAddress[cur], column3 + (columnWidth/8), rowPosition);
                rowPosition += newLine;
                
                fl().text("Phone:", column3 + 0.555, rowPosition);
                fl().text(data.servePhone[cur], column3 + (columnWidth/8), rowPosition);
                rowPosition += 0.278;
            }
        } else {
            fl().text("Contact:", rightLineSpacing, rowPosition);
            fl().text(data.servee[0] ? data.servee[0] : "", column3 + (columnWidth/8), rowPosition);
            rowPosition += newLine;

            fl().text("Company:", rightLineSpacing, rowPosition);
            fl().text(data.serveCompany[0], column3 + (columnWidth/8), rowPosition);
            rowPosition += newLine;

            fl().text("Address:", rightLineSpacing, rowPosition);
            miniText(data.serveAddress[0], 40, column3 + (columnWidth/8), 8);
            // fl().text(data.serveAddress[0], column3 + (columnWidth/8), rowPosition);
            if(data.serveAddress[1]){
                rowPosition += (data.serveAddress[0].length > 0 ? 0.10 : 0);
                fl().text(data.serveAddress[1], column3 + (columnWidth/8), rowPosition);
            }
            rowPosition += newLine;
            
            fl().text("Phone:", rightLineSpacing, rowPosition);
            fl().text(data.servePhone[0], column3 + (columnWidth/8), rowPosition);
            rowPosition += 0.278;
        }

        // First Legal Complettion Information Table
        doc.setFillColor('#eeeeee').rect(rightColumnHeaderXPos, rowPosition, 3.50, 0.278, "F");
        rowPosition += newLine; 
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Completion Information", rightLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        let completeDate: Date | null = null;
        if(data.deliveryDate){
            completeDate = new Date(data.deliveryDate);
            console.debug(completeDate);
            completeDate.toUTCString();
        }
        fl().text("Completion Date:", rightLineSpacing, rowPosition);
        fl().text(completeDate ? completeDate.toDateString() : "", column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        fl().text("Completion Time:", rightLineSpacing, rowPosition);
        fl().text(completeDate ? completeDate.toTimeString() : "", column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        fl().text("Completion Status:", rightLineSpacing, rowPosition);
        fl().text(data.orderStatus, column3 + (columnWidth/8), rowPosition);
        rowPosition += newLine;

        let rightColumn = rowPosition;

        rowPosition = (leftColumn > rightColumn ? leftColumn : rightColumn) + (0.278);


        // First Legal Special Instructions
        doc.setFillColor('#eeeeee').rect(leftColumnHeaderXPos, rowPosition, 7.30, 0.278, "F");
        rowPosition += newLine;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Special Instructions", leftLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        // fl().text(data.specialInstructions, leftLineSpacing, rowPosition)
        miniText(data.pickupSpecialInstructions, 130, leftLineSpacing, 7);
        if(data.pickupSpecialInstructions != '')
            rowPosition += newLine;
        miniText(data.deliverySpecialInstructions, 130, leftLineSpacing, 7);
        if(data.deliverySpecialInstructions != '')
            rowPosition += newLine;

        doc.setFillColor('#eeeeee').rect(leftColumnHeaderXPos, rowPosition, 7.30, 0.278, "F");
        rowPosition += newLine;

        // First Legal Documents
        doc.setFont('OpenSans-Bold', 'bold');
        bold(8).text("Document(s):", leftLineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;
        hyl(7).textWithLink("Click here to view the order's documents", leftLineSpacing, rowPosition, {url:appurl});
        rowPosition += newLine;

        data.attachedDocuments.map((att)=>{
            bold(8).text(att, leftLineSpacing, rowPosition);
            rowPosition += newLine;
            quickcheck((pageHeight - (newLine * 5)));
        })

        // Add footer if not on new page
        if(rowPosition != 0.09){
            let tempPosition = rowPosition;
            doc.setLineWidth(0.03);
            doc.setDrawColor('#022969');
            doc.rect(startText - 0.05, pageStart - newLine, endText + 0.05, tempPosition - pageStart + newLine);
            tempPosition += 0.0075;
            doc.setFillColor('#eeeeee').rect(startText - 0.07, tempPosition, endText + 0.09, 0.320, "F");
            tempPosition += newLine;
    
            doc.setFont('OpenSans-Italic', 'italic');
            let boxWidth = endText + 0.09 - (startText - 0.07);
            slt().text("For Internal Use", startText + 0.2, tempPosition);
            slt().text("DRV: " + (data.driverNumber ? data.driverNumber : ""), (startText + 0.2) + (boxWidth/4), tempPosition);
            slt().text("DSP: " + (data.dispatchNumber ? data.dispatchNumber : ""), (startText + 0.2) + 2 * (boxWidth/4), tempPosition);
            slt().text("BOL: " + (data.bolNumber ? data.bolNumber : ""), (startText + 0.2) + 3 * (boxWidth/4), tempPosition);
    
            // Footer 
            doc.setFont('OpenSans-Medium', 'normal');
            tempPosition = pageHeight - newLine;
            th().text(`© Copyright 2024, First Legal. All rights reserved. Private Investigator Licenses: CA PI: 24171 AZ PI: 1551710 NV PI-PS: 1452`, column1 + 0.555, tempPosition);
        }

        let filename = "FirstLegal-Order-" + data.service.replace(/[^\w\s]+/ig, '').replace(/\s+/g, '_') + '.pdf';
        doc.output(outputType as any, {filename: filename });
    }
}


export interface ReviewData {
    createdAt: string,
    updatedAt: string,
    orderNumber: string,
    service: string,
    serviceCategory: string,
    serviceType: string,
    serviceCategoryDescription?: string,
    clientMatterNumber: string,
    claimNumber: string,
    insuranceCompany: string,
    insuranceAdjuster: string,
    pickupContact: string,
    pickupCompany: string,
    pickupAddress: string[],
    pickupPhone: string,
    pickupDate: string,
    caseNumber: string,
    caseName: string,
    casePlaintiff?: string,
    caseDefendant?: string,
    documentDescription: string,
    attachedDocuments: string[],
    servee: string[],
    serveCompany: string[],
    serveAddress: string[],
    servePhone: string[],
    serveAttempts: string[][],
    serveAffidavits: string[][],
    pickupHeader: string,
    pickupContactLabel: string,
    deliveryHeader: string,
    deliveryContactLabel: string,
    deliveryCompanyLabel: string,
    deliveryDate: string,
    deliveryTime: string,
    deliverySpecialInstructions: string,
    pickupSpecialInstructions: string,
    orderId: string
    firmName?: string,
    callerName?: string,
    pieces?: string,
    weight?: string,
    driverNumber?: string,
    dispatchNumber?: string,
    bolNumber?: string,
    orderStatus: string,
    env: string
}

//may add more to this possibly if more fields are needed
export interface OrderPrintData{
    record: Order,
    review: ReviewData
}

export type outputTypes = "arraybuffer" | "blob" | "bloburi" | "datauristring" | "datauri" | "dataurlnewwindow" | "pdfobjectnewwindow" | "pdfjsnewwindow";
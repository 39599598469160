import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class InvoiceAdjustment extends DexResourceModel {

    @Field(String)
    public invoice_uuid: String = '';

    @Field(String)
    public order_uuid: String = '';

    @Field(String)
    public adjustmentType: string = "";

    @Field(String)
    public memo: string = "Miscellaneous";

    @Field(Number)
    public revenue: Number = 0.0;

    @Field(Number)
    public nonrevenue: Number = 0.0;

    @Field(Number)
    public tax: Number = 0.0;

    @Field(Number)
    public commissionable: Number = 0.0;

    @Field(Number)
    public lineTotal: Number = 0.0;

    @Field(Date)
    public postedAt: Date = new Date();

    @Field(String)
    public syncLocks: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}

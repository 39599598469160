import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientBranchVendorAccount extends DexResourceModel {

    @Field(String)
    public clientBranch_uuid: string = "";

    @Field(String)
    public vendor_uuid: string = "";

    @Field(Number)
    public vendorAccountNumber: number | null = null;

    @Field(Number)
    public vendorAccountId: number | null = null;

    public constructor(jsonData?: { [key: string]: any; }) {
        // Constructor calls empty super
        super(jsonData);

        // Map the data ourselves using the inherited function. If the parent
        // constructor maps it, typescript will overwrite our data with our
        // defaults afterwards.
        if (jsonData) {
            this.mapData(jsonData);
        }
    }
}

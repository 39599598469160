import {Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'error-reported-dialog',
  templateUrl: 'errorReportedDialog.html',
})
export class ErrorReportedDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {files:string, size:number}) {}
}

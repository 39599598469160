import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class InvoicePayment extends DexResourceModel {

    @Field(String)
    public invoice_uuid: String = '';

    @Field(String)
    public arBatch_uuid: String = '';

    @Field(String)
    public arBatchPayment_uuid: String = '';

    @Field(Number)
    public amountApplied: Number = 0;

    @Field(Date)
    public appliedAt: Date = new Date();

    @Field(String)
    public referenceNumber: String = '';

    @Field(String)
    public batchId: String = '';

    @Field(Date)
    public postedAt: Date = new Date();

    @Field(String)
    public checkNumber: String = '';

    @Field(String)
    public paymentMethod: String = '';

    @Field(Number)
    public batchAmountApplied: Number = 0;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}

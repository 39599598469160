import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceService } from '@smartsoftware/reflex-core';
import { InvoiceAdjustment } from '../models';

@Injectable()
export class InvoiceAdjustment_Service extends ResourceService<InvoiceAdjustment> {
    protected servicePath: string = '/InvoiceAdjustment';

    public ModelType = InvoiceAdjustment;

    public update(ia: InvoiceAdjustment) {
        return this.http.put<InvoiceAdjustment>(`${this.serviceUrl}${ia.uuid}`, ia);
    }

}

import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceService } from '@smartsoftware/reflex-core';
import { InvoiceAdjustmentType } from '../models';

@Injectable()
export class InvoiceAdjustmentType_Service extends ResourceService<InvoiceAdjustmentType> {
    protected servicePath: string = '/InvoiceAdjustmentType';

    public ModelType = InvoiceAdjustmentType;

}

import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors, NgForm } from '@angular/forms'
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ConfirmDeleteDialog, ReflexEnvironment, ResourceEditComponent, ResourceFormService } from '@smartsoftware/reflex-core';
import {  combineLatest, Observable, BehaviorSubject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import { MatTableDataSource } from '@angular/material/table';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { OrderPrint_Service, ReviewData } from '../orderPrint.service';
import { Title } from '@angular/platform-browser';
import { RequestAcclaimAccountDialog } from '../../../components/accountRequest/requestAcclaimAccount';
import { RequestTristarAccountDialog } from '../../../components/accountRequest/requestTristarAccount/requestTristarAccount';
import { OrderSubmitFailedDialog } from '../../../components/orderSubmitFailed/orderSubmitFailed';

import {
    UserData,
    CaseData,
    DocumentData,
    SubmitDocument,
    CourtData, 
    OrderServiceAddress, 
    CustomerAccountInfo, 
    OrderDataJSON,
    OrderData,
    ToServeData,
    AddDocumentData,
    UpdateOrderData,
    ServiceCategoryWithTypes,
    SuccessCounty,
    SuccessState
} from 'projects/legalreflex-lib/src';


import {
    Order,
    OrderStatus,
    Order_Service,
    FileAction_Service,
    AddressBook,
    AddressBookService,
    ServiceAddress,
    ServiceAddress_Service,
    UserPermission_Service,
    State, 
    WIP,
    ClientMatterEntry,
    ClientMatterEntry_Service,
    ClientMatterSet,
    ClientMatterSet_Service,
    CommonData_Service,
    SuccessCase,
    SuccessCase_Service,
    Service_Service,
    ServiceType,
    VendorAccount,
    VendorAccount_Service,
    LegalConnect_Service,
    ClientBranch_Service,
    Client_Service,
    Document,
    ToServe,
    ToServe_Service,
    Submission,
    Submission_Service,
    SuccessAccountSettings, 
    SuccessAccountRoleName,
    SuccessAccountSettingsService,
    ClientBranch,
    Dictionary_Service,
    FLCompany_Service,
    ServiceTypes,
    Services,
    ServiceTypeDocsRequired,
    Telemetry_Service,
    TelemetryEventType,
    SystemConfig_service,
    LookupConfiguration,
    Timezone,
    SuccessState_Service,
    SuccessCounty_Service,
    Service,
    SuccessCountry,
    SuccessCountry_Service,
} from 'legalreflex-lib';
import { InvalidDocumentDialog } from './dialog/invalidDocument';
import { DocumentNameChangedDialog } from './dialog/documentNameChanged';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FileSizeDialog } from './dialog/fileSize';

const PHONE_PATTERN = "^\\(?\\d{3}\\)?[\\s\-]?\\d{3}[\\s\-]?\\d{4}$";
const PHONE_EXT_PATTERN = "^[0-9]*$";
const ZIP_PATTERN = "^[0-9]*$";
const BAR_ID_PATTERN = "^[0-9]*$";

const DRAFT = "draft";
const SOURCE_VENDOR = "FirstConnect";

const RECORD_RETRIEVAL = "RECORDRETRIEVAL";
const DEPOSITIONS = "DEPOSITIONS";
const EDISCOVERY = "EDISCOVERY";
const DIGITAL = "DIGITAL";
const INVESTIGATIONS = "INVESTIGATIONS";
const CLIENT_BRANCH = "ClientBranch";

const enum VendorType {
    FIRSTCONNECT = "FirstConnect",
    ACCLAIM = "Acclaim",
    TRISTAR = "Tristar"
}

const REVIEW_TABLE_STYLE = "border-spacing:0;";
const LEFT_CELL_STYLE = "width:225px; vertical-align: top;";
const MIDDLE_CELL_STYLE = "width:50px;";
const RIGHT_CELL_STYLE = "width:225px; vertical-align: top;"
// const REVIEW_BODY_STYLE = "display:flex; justify-content:flex-start; flex-direction:column;"
// const REVIEW_SECTION_STYLE = "display:flex; flex-direction:row; padding-top:20px; width:300px;";
const REVIEW_HEADER_STYLE = "font-size:20px; padding-top:15px;";
const REVIEW_LABEL_STYLE = "color: #022969; font-weight:600; font-size:12px; font: Arial,sans-serif; margin-top: 15px;";
const REVIEW_DATA_STYLE = "font-size: 14px; font:Arial,sans-serif;";
const FONT_FAMILY = "Arial"; 

export enum TimeZoneOffset{
    Eastern='UTC-4', Central='UTC-5',Mountain='UTC-6', Pacific='UTC-7', Alaska='UTC-8', HawaiiAleutian='UTC-10'
}

const FEES  = {
    advanceFee: false,
    firstAppearanceFee: false
};

const FILES = {
    documents: null
};

const SERVICE = {
    service: '',
};

const CATEGORY = {
    category: '',
    type: '',
    claimNumber: '',
    insuranceCompany: '',
    insuranceAdjuster: '',
    clientMatterNumber: ''
};

const COURT = {
    courtName: '',
    courtContact: '', 
    courtStreet: '',
    courtSuite: '',
    courtCity: '',
    courtState: '',
    courtZip: '',
    courtPhone: '',
    courtPhoneExt: '',
    firstAppearanceFees: FEES.firstAppearanceFee,
}

const ACCOUNT = {
    corpId: '',
    accountName: '',
    customerNumber: '',
    companyNumber: '',
    accountCountry: '',
    accountState: '',
    accountZip: '',
    accountCity: '',
    accountStreet: '',
    accountRoom: ''
}

const REQUEST_NOTES = {
    specialInstructions: '',
    advanceFees: FEES.advanceFee
}

const { convertMinutesToTimeString, convertTimeStringToMinutes } = SuccessAccountSettings;

@Component({
    templateUrl: './create.html',
    styleUrls: ['./create.scss']
})
export class OrderCreate extends ResourceEditComponent<Order, Order_Service> implements OnInit, AfterViewInit {

    @ViewChild('stepper') private formStepper?: MatStepper;

    @Input()
    public settings: SuccessAccountSettings = new SuccessAccountSettings();

    // Blob Type | Extension
	public acceptedFileTypes: string[][] = [
        ['application/pdf','PDF'],
        ['application/vnd.openxmlformats-officedocument.wordprocessingml.document','DOCX'],
        ['application/msword','DOC']
    ];
    
    public files: File[] = [];
    public fileNames: Set<string> = new Set<string>();

    public userAddresses : AddressBook[] = [];
    public filteredUserPickupAddresses? : Observable<AddressBook[]>;
    public filteredUserAddresses? : Observable<AddressBook[]>;
    
    public courtAddresses : AddressBook[] = [];
    public filteredCourtPickupAddresses? : Observable<AddressBook[]>;
    public filteredCourtAddresses? : Observable<AddressBook[]>;
    public courtCounties : County[] = [];
    public filteredCourtCounties? : Observable<County[]>;
    public cases : SuccessCase[] = [];
    public filteredCases?: Observable<SuccessCase[]>;

    public selectedCase : SuccessCase | undefined;
    public selectedCourt : AddressBook | undefined;
    public selectedDeliveryCourt : AddressBook | undefined;
    public selectedDeliveryAddress: AddressBook | undefined;
    public selectedClientMatterNumber : string | undefined;
    public accountDefaultState: string | undefined;
    public selectedClientMatterSet: BehaviorSubject<ClientMatterSet | null>;
    public clientMatterLookupConfig: LookupConfiguration<ClientMatterEntry>;
    public filteredClientMatterEntries?: Observable<ClientMatterEntry[]>;

    public accountDataSource : MatTableDataSource<CustomerAccountInfo> = new MatTableDataSource();
    public accountColumnsToDisplay = ['name', 'state', 'city', 'street', 'accountNumber'];
    public accountNextClicked = false;

    public externalAccounts : CustomerAccountInfo[] = [];

    public clientMatterEntries: ClientMatterEntry[] = [];
    public clientMatterErrorMsg : BehaviorSubject<string> = new BehaviorSubject<string>('');

    public serviceAddresses : OrderServiceAddress[] = [];

    public filteredServiceState? : Observable<State[]>;
    public filteredDeliveryState? : Observable<State[]>;
    public filteredPickupState? : Observable<State[]>;
    public filteredCourtState?: Observable<State[]>;
    public filteredTimeZone?: Observable<Timezone[]>;
    public service : Service | undefined = undefined;
    // public services : Service[] = [];
    // DEV NOTE: have to declare categories as type 'any[]' because the ServiceCategoryWithTypes 
    // interface makes the typescript compiler very angry
    // public categories : ServiceCategoryWithTypes[] = [];
    public categories : any[] = [];
    public types : ServiceType[] = [];
    public groupedTypes : any[] = [];

    public courtProcess = ServiceTypes.COURT_PROCESS;
    public eFile = ServiceTypes.EFILE;
    public serviceOfProcess = ServiceTypes.SERVICE_OF_PROCESS;
    public research = ServiceTypes.RESEARCH;
    public eDiscovery = ServiceTypes.EDISCOVERY;
    public digital = ServiceTypes.DIGITAL;
    public investigations = ServiceTypes.INVESTIGATIONS;
    public scanning = ServiceTypes.SCANNING;
    public mailout = ServiceTypes.MAILOUT;

    public fees = FEES;

    public savePickupAddress = false;
    public saveDeliveryAddress = false;
    public saveEDiscoveryAddress = false;
    public pickupLookupOpen = false;
    public deliveryLookupOpen = false;
    public eDiscoveryLookupOpen = false;

    public WIP : boolean = WIP();

    public draftPickupAddress? : ServiceAddress;
    public draftCourtDeliveryAddress?: ServiceAddress;
    public draftDeliveryDetailAddress?: ServiceAddress;
    public draftDeliveryAddresses : ServiceAddress[] = [];
    public deletedDeliveryAddresses : OrderServiceAddress[] = [];
    public draftSubmission : Submission[] = [];
    public seedDocuments : Document[] = [];
    public deletedSeedDocuments: Document[] = [];

    public clonedOrder: boolean = false;

    public submitSuccess : boolean = false;

    public vendorAccounts : {[vendor:string] : VendorAccountDisplay[]} = {};
    public hasFirstConnectAccount = false;
    public hasAcclaimAccount = false;
    public hasTristarAccount = false;

    public orderCompleteHeader : string = "";
    public orderSubmissionError? : string;
    public orderControlNumber? : number;

    private _documentsRequired : ServiceTypeDocsRequired = ServiceTypeDocsRequired.Optional;
    private _pickupRequired : boolean = true;
    private _deliveryRequired : boolean = true;
    private _showDocumentsStep : boolean = true;

    private _cmvDataLoaded : boolean = false;

    // public signupFirstConnect: string;
    public signupAcclaim: string;
    public signupTristar: string;

    private SubmittedDocuments : Array<SubmitDocument | AddDocumentResponse> = [];
    private SubmitOrder? : {documents: SubmitDocument[], uuid: string, status: string, service: string};
    private failedDocumentUploads : number[] = [];

    public formDirty = false;
    public maintenance = false;
    public draftDisabled = true;

    public clientMatterMaxLength = 50;
    
    public counties: Array<SuccessCounty> = [];
    public selectableCounties$?: Observable<SuccessCounty[]>;
    public allStates: Array<SuccessState> = [];
    public selectableStates$?: Observable<SuccessState[]>;

    // workaround for getting the styles to be directly on the template 
    public reviewTableStyle = REVIEW_TABLE_STYLE;
    public reviewHeaderStyle = REVIEW_HEADER_STYLE;
    public leftCellStyle = LEFT_CELL_STYLE;
    public middleCellStyle = MIDDLE_CELL_STYLE;
    public rightCellStyle = RIGHT_CELL_STYLE; 
    // public reviewSectionStyle = REVIEW_SECTION_STYLE;
    // public reviewBodyStyle = REVIEW_BODY_STYLE;
    public reviewLabelStyle = REVIEW_LABEL_STYLE;
    public reviewDataStyle = REVIEW_DATA_STYLE;
    
    public showReviewRow = true;
    public review : ReviewData = {
        createdAt: "",
        updatedAt: "",
        orderNumber: "",
        service: "",
        serviceType: "",
        serviceCategory: "",
        serviceCategoryDescription: "",
        clientMatterNumber: "",
        claimNumber: "",
        insuranceCompany: "",
        insuranceAdjuster: "",
        pickupContact: "",
        pickupCompany: "",
        pickupAddress: [""],
        pickupPhone: "",
        pickupDate: "",
        caseNumber: "",
        caseName: "",
        documentDescription: "",
        attachedDocuments: [],
        servee: [""],
        serveCompany: [""],
        serveAddress: [""],
        servePhone: [""],
        serveAttempts: [],
        serveAffidavits: [],
        pickupHeader: "",
        pickupContactLabel: "",
        deliveryHeader: "",
        deliveryContactLabel: "",
        deliveryCompanyLabel: "",
        deliveryDate: "",
        deliveryTime: "",
        deliverySpecialInstructions: "",
        pickupSpecialInstructions: "",
        orderId: "",
        orderStatus: "",
        env: ""
    };

    public fileFormGroup: FormGroup = this.fb.group({
        documents:[null, [this.filesAndDocumentsValidator()]],
        description: ['', [this.documentDescriptionValidator()]]
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });


    // Used internally, not shown, might update later
    public accountFormGroup: FormGroup = this.fb.group({
        corpId: ['',[Validators.required]],
        accountName: ['', [Validators.required]],
        customerNumber: ['', []],
        companyNumber: ['', []],
        accountSystem: ['',[]],
        accountCountry: ['', []],
        accountState: ['', []],
        accountZip: ['', [Validators.pattern(ZIP_PATTERN)]],
        accountCity: ['', []],
        accountStreet: ['', []],
        accountRoom: ['', []],
        selectAccount: ['', []]
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public selectAccount = new FormControl('', [Validators.required, (group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]);
    
    public courtFormGroup: FormGroup = this.fb.group({
        courtCounty: ['', []],
        courtName: ['', [this.validateRequiredNoEmptySpaces()]],
        courtContact: ['', []],
        courtStreet: ['', [this.validateRequiredNoEmptySpaces()]],
        courtSuite: ['', []],
        courtCity: ['', [this.validateRequiredNoEmptySpaces()]],
        courtState: ['', [Validators.required]],
        courtZip: ['', [Validators.required, Validators.pattern(ZIP_PATTERN)]],
        courtPhone: ['', [Validators.pattern(PHONE_PATTERN)]],
        courtPhoneExt: ['', [Validators.pattern(PHONE_EXT_PATTERN)]],
    }, {
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public selectCourtControl = new FormControl('', []);
    public selectedCounty$ = new BehaviorSubject<string>('');
    public selectedState$ = new BehaviorSubject<string>('');

    //Ensures a field has at least 1 non-space character or sets a 'required' error on the control.
    private validateRequiredNoEmptySpaces(){
        return (control: AbstractControl): ValidationErrors | null => {
            if(!control.value.trim().length)
                return {required: {value: true}}
            return null;
        }
    }

    public caseFormGroup: FormGroup = this.fb.group({
        caseName: ['', [this.validateRequiredNoEmptySpaces()]], // hidden field for storing the case name
        caseNumber: ['', [this.validateRequiredNoEmptySpaces()]],
        // caseVendorId: [''], 
        // caseFrequentCallerId: [''], 
        // caseCorpId: [''],
        // caseSuccessAccountId: [''],
        // caseId: [''],
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}, this.caseFormGroupValidator()]
    });
    
    public selectCaseControl = new FormControl('', [this.validateRequiredNoEmptySpaces(), (group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]);

    private caseFormGroupValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if(!control.value.caseName.trim() || !control.value.caseNumber.trim()) 
                return {status: {value: "INVALID"}, required: {value: true}};
            return null;
        }
    } 
    

    // public selectClientMatterControl = new FormControl('', this.clientMatterValidator());
    private clientMatterValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if(this.hideClientMatter){
                if(this.eDiscoveryServiceSelectControl.value == null || this.eDiscoveryServiceSelectControl.value < 1){
                    return {serviceNeeded: {value: true}};
                }
            }else{
                if (control.value.trim().length == 0) {
                    return {required: {value: true}};
                }

                if (this.clientMatterEntries.length > 0) {
                    if (!this.clientMatterEntries.find(e => e.matter === control.value)) {
                        return {invalidMatter: {value: true}}
                    }
                }
            }

            return null;
        }
    }

    private timeZoneValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value.trim().length == 0) {
                return {required: {value: true}};
            }
            
            if (control.value.length > 0) {
                if (this.timeZoneOffset(control.value) == 'Missing Time Zone') {
                    return {invalid: {value: true}}
                }
            }
            return null;
        }
    }

    public toServeFormGroup: FormGroup = this.fb.group({
        serveEmail: ['',[]],
        serveAge: ['',[]],
        serveGender: ['',[]],
        serveEthnicity: ['',[]],
        serveWeight: ['',[]],
        serveHeight: ['',[]],
        serveHair: ['',[]],
        serveEyes: ['',[]],
        serveRelationship: ['',[]],
        servees: [null, []]
    }, {
        validators: [OrderCreate.serveesValidator, (group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    })

    public serviceAddressControlGroup: FormGroup = this.fb.group({
        serviceAddresses: [this.serviceAddresses, []]
    },{
        validators: [(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    })

    public serviceAddressFormGroup: FormGroup = this.fb.group({
        typeName: ['Business',[]],
        locationName: ['',[this.validateRequiredNoEmptySpaces()]],
        serviceContact: ['', []],
        serviceStreet: ['',[this.validateRequiredNoEmptySpaces()]],
        serviceSuite: ['', []],
        serviceCity: ['',[this.validateRequiredNoEmptySpaces()]],
        serviceState: ['',[]],
        serviceZip: ['',[Validators.pattern(ZIP_PATTERN)]],
        servicePhone: ['', [Validators.pattern(PHONE_PATTERN)]],
        servicePhoneExt: ['',[Validators.pattern(PHONE_EXT_PATTERN)]]
    }, {
        validators: [(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    })

    public serveeIndividualBusiness: boolean = false;

    public selectServiceCourt: FormControl = new FormControl('');
    public selectServiceAddress: FormControl = new FormControl('');

    public serviceFormGroup: FormGroup = this.fb.group({
        service: ['COURTPROCESS', [Validators.required]],
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public categoryFormGroup: FormGroup = this.fb.group({
        category: ['', [Validators.required]],
        type: new FormControl('', [Validators.required]),
        claimNumber: ['', []],
        insuranceCompany: ['', []],
        insuranceAdjuster: ['', []],
        clientMatterNumber: ['', [Validators.required]],
        additionalRequirements: ['', []],
        serviceCategoryDescription: new FormControl('', []),
    }, {
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public pristineCheck(group: AbstractControl): boolean
    {
        // console.log("PristineCheck: Redirect Internal:", this.redirect);
        if(this.categoryFormGroup)
        {
            const categoryControl = this.categoryFormGroup.get('category');
            if(categoryControl?.value == ServiceTypes.EFILE && this.typeName == ServiceTypes.EFILE_DIRECT_TYPE )
            {
                this.formDirty = false;
                return false;
            }
        }        
        return !group.pristine;
    }

    public selectClientMatterControl = new FormControl('', this.clientMatterValidator());
    public timeZones: Timezone[] = [];

    public hideClientMatter: boolean = false;
    public serviceOtherShow: boolean = false;
    public eDiscoverySendTrialInfo: boolean = false;
    public eDiscoveryServiceSelectControl = new FormControl('', Validators.required);
    public eDiscoveryDescriptionOfNeeds = new FormControl('');
    public serviceOtherControl = new FormControl('');
    public matterServiceOptions : any[] = [];

    public get serviceNameFormatted() : any {
        let service = Object.entries(Services).find((s)=>{
            return s[0] == this.serviceFormGroup.controls['service']?.value
        })
        return service? service[1] : this.serviceFormGroup.controls['service']?.value;
    }

    public get serviceName() : string {
        return this.serviceFormGroup.controls['service']?.value;
    }

    public get categoryName() : string {
        return this.categoryFormGroup.controls['category']?.value;
    }

    public get categoryDisplayName() : string {
        let categoryName = this.categoryFormGroup.controls['category']?.value;

        if(!categoryName) return '';

        let category = this.categories.find((c) => c.category.name == categoryName);

        if(!category) return '';

        return (category.category.displayName || '');
    }

    public get typeName() : string {
        let typeId = this.categoryFormGroup.controls['type']?.value;
        if(!typeId) return "";
        let serviceType = this.serviceType_byUuid(typeId);
        if(!serviceType) return "";

        return serviceType.name;
    }

    public get typeDisplayName() : string {
        let typeId = this.categoryFormGroup.controls['type']?.value;
        if(!typeId) return "";
        let serviceType = this.serviceType_byUuid(typeId);
        if(!serviceType) return "";

        return (serviceType.displayName || '');
    }

    public requestNotesFormGroup: FormGroup = this.fb.group({
        specialInstructions: ['',[]],
        advanceFees: [this.fees.advanceFee],
        alwaysDeliverCourtesyFeesBack:[''],
        hasNoContactHours:[''],
        noContactFromTime:[''],
        noContactToTime:[''],
        firstAppearanceFees: [this.fees.firstAppearanceFee, []]
    }, {
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });
    
    initForm() {
        //let settings = this.settings;
        //specialInstructions
        this.requestNotesFormGroup.get('advanceFees')?.setValue(this.settings.casePreferenceAdvanceFees);
        this.requestNotesFormGroup.get('alwaysDeliverCourtesyFeesBack')?.setValue(this.settings.casePreferenceAlwaysDeliverCourtesyFeesBack);
        this.requestNotesFormGroup.get('hasNoContactHours')?.setValue(this.settings.hasNoContactHours);
        this.requestNotesFormGroup.get('noContactFromTime')?.setValue(convertMinutesToTimeString(this.settings.noContactFromTime))
        this.requestNotesFormGroup.get('noContactToTime')?.setValue(convertMinutesToTimeString(this.settings.noContactToTime));   
    }

    // public selectedClientBranch: ClientBranch | null = null;
    public digitalDeliveryOnly: boolean = false;
    public digitalDeliveryFormGroup: FormGroup = this.fb.group({
        digitalCheckbox: [false, []]
    }, {
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    })

    public eDiscoveryInfoFormGroup: FormGroup = this.fb.group({
        relativity: [false, []],
        csDisco: [false, []],
        trialDirector: [false, []],
        caseCloud: [false, []],
        other: [false, []]
    }, {
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    })
    
    public eDiscoveryInfo: Array<any> = [
        {description: "Relativity", value: "relativity", checked: false, control: "relativity"},
        {description: "CS Disco", value: "csDisco", checked: false, control: "csDisco"},
        {description: "Trial Director", value: "trialDirector", checked: false, control: "trialDirector"},
        {description: "CaseCloud", value: "caseCloud", checked: false, control: "caseCloud"},
        {description: "Other", value: "other", checked: false, control: "other"}
    ];

    public eDiscoveryInfoOtherControl = new FormControl('');


    public digitalAddressesFormGroup: FormGroup = this.fb.group({
        recipientAddresses: [[], []],
        deadlineDate: ['', [Validators.required]],
        deadlineTime: ['', [Validators.required]],
        deadlineTimeZone: ['', [this.timeZoneValidator()]],
        addressState: [false, []]
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public recipientAddressFormGroup: FormGroup = this.fb.group({
        locationName: ['', [Validators.required]],
        locationAddress: ['', [Validators.required]],
        locationCity: ['', [Validators.required]],
        locationState: ['', [Validators.required]],
        locationZip: ['', [Validators.required]],
    },{
        validators:[(group)=>{
            if(this.pristineCheck(group)){
                this.formDirty = true;
            }
            if(group.valid)
                this.digitalAddressesFormGroup.updateValueAndValidity();
            return null;
        }]
    });

    public showRecipientAddressList: boolean = false;
    public selectDigitalCourt: FormControl = new FormControl('');
    public selectDigitalAddress: FormControl = new FormControl('');

    public eDiscoveryTrialFormGroup: FormGroup = this.fb.group({
        eDiscoveryCompany: ['', []],
        eDiscoveryContact: ['', []],
        eDiscoveryStreet: ['', [Validators.required]],
        eDiscoverySuite: ['', []],
        eDiscoveryCity: ['', [Validators.required]], 
        eDiscoveryState: ['', [Validators.required]], 
        eDiscoveryZip: ['', [Validators.required, Validators.pattern(ZIP_PATTERN)]],
        eDiscoveryEmail: ['', []],
        eDiscoveryPhone: ['', [Validators.pattern(PHONE_PATTERN)]],
        eDiscoveryPhoneExt: ['', [Validators.pattern(PHONE_EXT_PATTERN)]],

        trialDate: ['', []],
        trialTime: ['', []],
        trialTimeZone: ['', [this.timeZoneValidator()]],
        trialDuration: ['', []],
        courtName: ['', []],
        courtRoom: ['', []],
        saveAddress: [this.saveEDiscoveryAddress]
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public trialServicesFormGroup: FormGroup = this.fb.group({
        trialDate: ['', []],
        trialTime: ['', []],
        trialTimeZone: ['', [this.timeZoneValidator()]],
        trialDuration: ['', []],
        courtName: ['', []],
        courtRoom: ['', []],
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public selectEDiscoveryCourt: FormControl = new FormControl('');
    public selectEDiscoveryAddress: FormControl = new FormControl('');
    public eDiscoveryLookupSource: FormControl = new FormControl('addressbook');

    public skipTraceFormGroup: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        dateOfBirth: ['', []],
        ssn: ['', []],
        socialMediaLinks: [[], []],
        locationName: ['', []],
        locationAddress: ['', []],
        locationCity: ['', []],
        locationState: ['', []],
        locationZip: ['', []],
    });

    public skipTraceMediaFormGroup: FormGroup = this.fb.group({
        mediaName: ['', []],
        mediaLink: ['', []],
    })

    public lastKnownAddressesFormGroup: FormGroup = this.fb.group({
        locationName: ['', []],
        locationAddress: ['', []],
        locationCity: ['', []],
        locationState: ['', []],
        locationZip: ['', []],
    });

    public subrosaFormGroup: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        pointOfOrigination: ['', [Validators.required]],
        natureOfInjury: ['', [Validators.required]],
        
        homeName: ['', [Validators.required]],
        homeAddress: ['', [Validators.required]],
        homeCity: ['', [Validators.required]],
        homeState: ['', [Validators.required]],
        homeZip: ['', [Validators.required]],

        workName: ['', []],
        workAddress: ['', []],
        workCity: ['', []],
        workState: ['', []],
        workZip: ['', []],
    });

    public alternateInvestigationsFormGroup: FormGroup = this.fb.group({
        assignmentInstructions: ['', [Validators.required]],
        claimedInjuries: ['', []],
        informationOfClaimedInjuries: ['', []],
        locationName: ['', []],
        locationAddress: ['', []],
        locationCity: ['', []],
        locationState: ['', []],
        locationZip: ['', []],

        dateOfLoss: ['', [Validators.required]],
        timeOfLoss: ['', [Validators.required]],
        timeZoneOfLoss: ['', [this.timeZoneValidator()]]
    });

    public lastKnownAddresses: any[] = [];
    public workAddresses: any[] = [];
    public investigationsAddressSwitch: boolean = false;
    public selectInvestigationCourt: FormControl = new FormControl('');
    public selectInvestigationAddress: FormControl = new FormControl('');
    public investigationsSSN: boolean = true;

    public deliveryDetailFormGroup: FormGroup = this.fb.group({
        deliveryCompany: ['', [this.validateRequiredNoEmptySpaces()]],
        deliveryContact: ['', []],
        deliveryStreet: ['', [this.validateRequiredNoEmptySpaces()]],
        deliverySuite: ['', []],
        deliveryCity: ['', [this.validateRequiredNoEmptySpaces()]], 
        deliveryState: ['', [this.validateRequiredNoEmptySpaces()]], 
        deliveryZip: ['', [Validators.required, Validators.pattern(ZIP_PATTERN)]],
        deliveryPhone: ['', [Validators.pattern(PHONE_PATTERN)]],
        deliveryPhoneExt: ['', [Validators.pattern(PHONE_EXT_PATTERN)]],
        // returnService: ['', []],
        saveAddress: [this.saveDeliveryAddress]
    }, {
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public selectDeliveryCourtControl: FormControl = new FormControl('');
    public selectDeliveryAddressControl: FormControl = new FormControl('');

    public pickupDetailFormGroup: FormGroup = this.fb.group({
        pickupCompany: ['', [this.validateRequiredNoEmptySpaces()]],
        pickupContact: ['', []],
        pickupStreet: ['', [this.validateRequiredNoEmptySpaces()]],
        pickupSuite: ['', []],
        pickupCity: ['', [this.validateRequiredNoEmptySpaces()]], 
        pickupState: ['', [Validators.required]], 
        pickupZip: ['', [Validators.required, Validators.pattern(ZIP_PATTERN)]],
        pickupPhone: ['', [Validators.pattern(PHONE_PATTERN)]],
        pickupPhoneExt: ['', [Validators.pattern(PHONE_EXT_PATTERN)]],
        saveAddress: [this.savePickupAddress],
    },{
        validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
    });

    public pickupLookupSource: FormControl = new FormControl('addressbook');
    public selectPickupCourt: FormControl = new FormControl('');
    public selectPickupAddress: FormControl = new FormControl('');

    // set this after the user selects 
    public serviceDetailFormGroup: FormGroup = new FormGroup({});

    public fileRedirect = new Map<string, string>();

    constructor(
        protected auth: OidcSecurityService,
        protected fileUploadService: FileAction_Service,
        protected entityService: Order_Service,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService<Order>,
        protected fb: FormBuilder,
        protected userPermissionService: UserPermission_Service,
        protected addressBookService: AddressBookService,
        protected clientMatterEntryService : ClientMatterEntry_Service,
        protected clientMatterSetService : ClientMatterSet_Service,
        private successAccountSettingsService: SuccessAccountSettingsService,
        protected successCaseService: SuccessCase_Service,
        protected orderServiceService: Service_Service,
        protected vendorAccountService: VendorAccount_Service,
        protected breadCrumbService: BreadCrumbService,
        protected legalConnectService: LegalConnect_Service,
        public locationService: CommonData_Service,
        public OrderPrintService: OrderPrint_Service,
        protected clientBranchService: ClientBranch_Service,
        protected clientService: Client_Service,
        public dictionary: Dictionary_Service,
        public companyService: FLCompany_Service,
        public pageTitleService: Title,
        protected serviceAddressService: ServiceAddress_Service,
        protected toServeService: ToServe_Service,
        protected submissionService: Submission_Service,
        public telemetryService: Telemetry_Service,
        public systemConfigService: SystemConfig_service,
        public stateService: SuccessState_Service,
        public countyService: SuccessCounty_Service
    ) {
        super(entityService, route, dialog, resourceFormService);

        this.pageTitleService.setTitle("FirstConnect - New Order");

        // this.signupFirstConnect = environment.config['FirstConnectSignupUrl'];
        this.signupAcclaim = environment.config['AcclaimSignupUrl'];
        this.signupTristar = environment.config['TristarSignupUrl'];

        this.breadCrumbService.breadcrumbs.next([
            { label: 'Orders', url: '/order'},
            { label: 'Create New' }
        ]);

        this.breadCrumbService.actions.next([
        ]);

        this.selectedClientMatterSet = new BehaviorSubject<ClientMatterSet | null>(null);
        this.clientMatterLookupConfig = clientMatterEntryService.makeLookupConfig('');
    }

    static serveesValidator(form: FormGroup) {
        const serveesControl = form.get('servees');

        if(!serveesControl || !serveesControl.value) {
            serveesControl?.setErrors({required: true});
            return {required: true};
        }else{
            serveesControl?.setErrors(null);
            return null;
        }
    }

    private serviceAddressesValidator() : ValidatorFn { 
        return (c: AbstractControl) : { [key: string] : any } | null => {
            if (this.serviceAddressControlGroup.controls['serviceAddresses']?.value.length < 1){
                if(this.serviceAddressFormGroup.valid){
                    return null;
                }else{
                    return {required:true};
                }
            }else{
                return null;
            }
        }
    }

    private eDiscoveryValidator() : ValidatorFn {
        return (c: AbstractControl) : { [key: string] : any } | null => {
            if (!this.eDiscoveryServiceSelectControl.value){
                return {required:true};
            }
            
            if(this.eDiscoveryServiceSelectControl.value.length < 1){
                return {required:true};
            }

            return null;
        }
    }

    private digitalValidator() : ValidatorFn { 
        return (c: AbstractControl) : { [key: string] : any } | null => {
            let stateControl = this.digitalAddressesFormGroup.controls['addressState'];
            let adds = this.digitalAddressesFormGroup.controls['recipientAddresses'].value;
            let valid = true;
            Object.entries(this.recipientAddressFormGroup.controls).map((control)=>{
                if(!control[1].valid)
                    valid = false;
            })
            if (valid){
                stateControl.setValue(true)
                stateControl.updateValueAndValidity();
                return null;
            }else{
                stateControl.setValue(adds.length > 0 ? true : null);
                stateControl.updateValueAndValidity();
                return {required:true}
            }
        }
    }

    private stateValidator() : ValidatorFn {
        // return a ValidatorFn to get around the issue of locationService.states not being defined
        return (c: AbstractControl) : { [key: string] : any } | null => {
            // this case is caught by the 'required' validator, 
            // and causes some issues with addServiceAddress function
            // so this should exclude it from causing issues when that function is called.
            let state = this.locationService.states.find((s) => s.abbreviation == c.value);

            if(this.serviceAddresses && this.serviceAddresses.length) 
                return (state || c.value == '') ? null : {invalid: true};
            return state ? null : {invalid: true};
        }
    }

    private set documentsRequired(required: ServiceTypeDocsRequired) {
        this._documentsRequired = required;
    }

    private get documentsRequired() {
        return this._documentsRequired;
    }

    public set cmvDataLoaded(loaded: boolean) {
        this._cmvDataLoaded = loaded;
    }

    public get cmvDataLoaded() {
        return this._cmvDataLoaded;
    }

    public set showDocumentsStep(hide: boolean) {
        this._showDocumentsStep = hide;
    }

    public get showDocumentsStep() {
        return this._showDocumentsStep;
    }

    public set pickupRequired(hide: boolean) {
        this._pickupRequired = hide;
    }

    public get pickupRequired() {
        return this._pickupRequired;
    }

    public set deliveryRequired(hide: boolean) {
        this._deliveryRequired = hide;
    }

    public get deliveryRequired() {
        return this._deliveryRequired;
    }

    private fileFormGroupValidator(){
        return (c: AbstractControl) : { [key: string] : any } | null => {
            if(this.documentsRequired){
                if(!this.fileFormGroup.controls['documents'].valid || !this.fileFormGroup.controls['description'].valid){
                    return {required:true};
                }else{
                    return null;
                }
            }else{
                return null;
            }
        }
    }

    private documentDescriptionValidator() : ValidatorFn {
        return (c: AbstractControl) : { [key: string] : any } | null => {
            // console.log('docs', this.documentsRequired);
            if(this.documentsRequired != ServiceTypeDocsRequired.Required) return null;
            if(c.value.trim()) return null;
            return {required: true};
        }
    }

    private filesAndDocumentsValidator() : ValidatorFn {
        return (c: AbstractControl) : { [key: string] : any } | null => {

            // only serviceTypes 5, 12 and 76, require documents
            if(this.documentsRequired != ServiceTypeDocsRequired.Required) return null;

            // draft order check
            if(( this.entity?.orderStatus?.toLowerCase() == DRAFT) && this.seedDocuments?.length ) {
                return null;
            }

            // normal order check
            else if( this.files.length ) {
                return null;
            }

            return {required: true};
        }
    }

    private _caseFilter(value: string) : SuccessCase[] {
        const filterValue = value.toLowerCase();

        return this.cases.filter(successCase => successCase.name?.toLowerCase().includes(filterValue));
    }

    private _courtFilter(value: string): AddressBook[] {
        const filterValue = value.toLowerCase();

        return this.courtAddresses.filter(address => address.name.toLowerCase().includes(filterValue));
    }

    private _courtFilterArr(name: string, state: string, county: string): AddressBook[] {
        let allFiltered = this.courtAddresses;
        allFiltered = allFiltered.filter(address => address.name.toLowerCase().includes(name.toLowerCase()));
        allFiltered = allFiltered.filter(address => address.state.toLowerCase().includes(state.toLowerCase()));
        allFiltered = allFiltered.filter(address => address.county?.toLowerCase().includes(county.toLowerCase()));

        return allFiltered;
    }

    private _userAddressFilter(value: string): AddressBook[] {
        const filterValue = value.toLowerCase();

        return this.userAddresses.filter(address => address.name.toLowerCase().includes(filterValue));
    }
    
    private _stateFilter(value: string): State[] {
        const filterValue = value.toLowerCase();

        return this.locationService.states.filter(state => state.name.toLowerCase().includes(filterValue));
    }  

    private _timeZoneFilter(value: string): Timezone[] {
        const filterValue = value.toLowerCase();

        return this.timeZones.filter(timezone => timezone.label.toLowerCase().includes(filterValue));
    }

    private _countyFilter(value: string): County[] {
        const filterValue = value.toLowerCase();

        return this.courtCounties.filter(county => county.name.toLowerCase().includes(filterValue));
    }
    
    // overridden to make the modal display differently
    openConfirmDeleteDialog() {
        if(!this.entity) return new Observable<false>();
        return this.dialog
            .open(ConfirmDeleteDialog, {
                data: { entities: [{name: "Draft Order"}] }
            })
        .afterClosed();
    }

    public delete() {
        this.openConfirmDeleteDialog()
            .subscribe(response => {
                if(response.confirmed)
                    this.deleteWithConfirm();
            });
    }

    public deleteWithConfirm(): void {
        this.entity.deletedAt = new Date();
        this.entity.orderStatus = "Canceled";

        this.entityService
            .push(this.entity)
            .subscribe(
                (response) => {
                    this.formDirty = false;
                    this.router.navigate(['/order/' + this.entity.uuid]);  
                },
                (error) => {
                    this.openErrorDialog(error);
                }
            );
    }

    private seedInstructionsStep() {
        let submission : Submission | undefined;
        if(this.draftSubmission.length) 
            submission = this.draftSubmission[0];

        this.requestNotesFormGroup.get('specialInstructions')?.setValue((submission?.requestNotes ?? ''));
        this.requestNotesFormGroup.get('advanceFees')?.setValue(this.entity.advancedFees);
        this.requestNotesFormGroup.get('alwaysDeliverCourtesyFeesBack')?.setValue(this.entity.alwaysDeliverCourtesyFeesBack);
        this.requestNotesFormGroup.get('firstAppearanceFees')?.setValue(this.entity.firstAppearanceFees);
        this.requestNotesFormGroup.get('hasNoContactHours')?.setValue(this.entity.hasNoContactHours);
        // TODO: track down whether or not the no contact time is actually read from the order
        this.requestNotesFormGroup.get('noContactFromTime')?.setValue(convertMinutesToTimeString(this.settings.noContactFromTime));
        this.requestNotesFormGroup.get('noContactToTime')?.setValue(convertMinutesToTimeString(this.settings.noContactToTime));

        return this.requestNotesFormGroup.valid;
    } 

    private seedServiceAddresses(serveeList: ToServe[]) {
        if(!this.draftDeliveryAddresses.length) return false;
        let serveeData : ToServeData[] = serveeList.map((servee)=>{
            return {
                uuid: servee.uuid,
                firstName: servee.firstName,
                lastName: servee.lastName,
                email: servee.email,
                phone: servee.phone,
                age: servee.age,
                gender: servee.gender,
                ethnicity: servee.ethnicity,
                weight: servee.weight,
                height: servee.height,
                hair: servee.hair,
                eyes: servee.eyes,
                relationship: servee.relationship,
            }
        })
        let orderServiceAddresses : OrderServiceAddress[] = this.draftDeliveryAddresses.map(a => {
            let currentServe = serveeData.find((serveData)=> serveData.uuid == a.toServe_uuid)
            let orderServiceAddress : OrderServiceAddress = {
                uuid: a.uuid,
                name: (a.name ?? ''),
                address: (a.address ?? ''),
                city: (a.city ?? ''),
                state: (a.state ?? ''),
                zip: (a.zip ?? ''),
                attention: (a.attention ?? ''),
                room: (a.room ?? ''),
                phone: (a.phone ?? ''),
                phoneExt: (a.phoneExt ?? null),
                serve: (a.toServe_uuid ? currentServe : undefined)
            }

            return orderServiceAddress;
        }); 

        this.serviceAddresses = orderServiceAddresses;
        this.serviceAddressControlGroup.get('serviceAddresses')?.patchValue(this.serviceAddresses);

        return this.serviceAddressFormGroup.valid;
    }

    private seedEDiscovery() {
        // Seeds services requested
        let orderData = JSON.parse((this.entity.orderData || '{}'));
        let formGroup = this.eDiscoveryTrialFormGroup;
        if(orderData) return null;
        if(orderData.eDiscoveryServicesNeeded){
            let discoveryServices = orderData.eDiscoveryServicesNeeded
            let array : string[] = []
            Object.entries(discoveryServices).map((service)=>{
                if(service[1] == true){
                    array.push(service[0])
                    if(service[0] == 'other')
                        this.serviceOtherControl.setValue(discoveryServices.otherDescription[1])
                }
            })
            this.eDiscoveryServiceSelectControl.setValue(array)

        } else if(orderData.trialServicesServicesNeeded){
            let trialServices = orderData.trialServicesServicesNeeded
            let array : string[] = []
            Object.entries(trialServices).map((service)=>{
                if(service[1] == true){
                    array.push(service[0])
                    if(service[0] == 'other')
                        this.serviceOtherControl.setValue(trialServices.otherDescription[1])
                }
            })

            this.eDiscoveryServiceSelectControl.setValue(array)
        }
        if(orderData.eDiscoveryFields){
            if(orderData.eDiscoveryFields.descriptionOfNeeds){
                this.eDiscoveryDescriptionOfNeeds.setValue(orderData.eDiscoveryFields.descriptionOfNeeds)
            }
            if(orderData.eDiscoveryFields.trialServicesFields){
                let fields = orderData.eDiscoveryFields.trialServicesFields;
                formGroup.get('eDiscoveryCompany')?.setValue(fields.firmInfo.name);
                formGroup.get('eDiscoveryStreet')?.setValue(fields.firmInfo.address);
                formGroup.get('eDiscoveryPhone')?.setValue(fields.firmInfo.phone);

                formGroup.get('trialDate')?.setValue(fields.trialDate);
                formGroup.get('trialTime')?.setValue(fields.trialTime);

                // TODO FIX Drafting
                console.log("Seeding ediscovery:", fields)
                formGroup.get('trialTime')?.setValue(fields.trialTime);
                formGroup.get('trialTimeZone')?.setValue(fields.trialTimeZone.value);

                formGroup.get('trialDuration')?.setValue(fields.trialDuration);
                formGroup.get('courtName')?.setValue(fields.courtName);
                formGroup.get('courtRoomLocation')?.setValue(fields.courtRoomLocation);
            }
        }
        return this.categoryFormGroup.valid
    }

    private seedDigitalMailout(){
        
        if(!this.draftDeliveryAddresses.length) return false;

        let mailoutLocations : any[] = this.draftDeliveryAddresses.map(d => {
            let location : any = {
                uuid: d.uuid,
                locationName: (d.name ?? ''),
                locationAddress: (d.address ?? ''),
                locationCity: (d.city ?? ''),
                locationState: (d.state ?? ''),
                locationZip: (d.zip ?? ''),
            }
            return location;
        });

        this.digitalAddressesFormGroup.controls['recipientAddresses'].setValue(mailoutLocations);
        console.log("Seeding digital addresses:", mailoutLocations, "original:", this.draftDeliveryAddresses)

        let orderData = JSON.parse((this.entity.orderData || '{}'));
        let fields = orderData.digitalFields;

        this.digitalAddressesFormGroup.get('deadlineDate')?.setValue(fields.deadlineDate);
        this.digitalAddressesFormGroup.get('deadlineTime')?.setValue(fields.deadlineTime);
        this.digitalAddressesFormGroup.get('deadlineTimeZone')?.setValue(fields.deadlineTimeZone.name);
        console.log("Seeding Digitalstep:", fields)

        return this.recipientAddressFormGroup.valid;
    }

    private seedDigitalStep(){
        let orderData = JSON.parse((this.entity.orderData || '{}'));
        if(!orderData) return null;
        if(orderData.digitalServicesNeeded){
            let digitalServices = orderData.digitalServicesNeeded
            let array : string[] = []
            Object.entries(digitalServices).map((service)=>{
                if(service[1] == true){
                    array.push(service[0])
                    if(service[0] == 'other')
                        this.serviceOtherControl.setValue(digitalServices.otherDescription[1])
                }
            })
        }

        if(!this.draftDeliveryAddresses.length) return false;

        this.draftDeliveryDetailAddress = this.draftDeliveryAddresses[0];

        let selectedState = (this.draftDeliveryDetailAddress.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.recipientAddressFormGroup.get('locationName')?.setValue(this.draftDeliveryDetailAddress.name ?? ''); // *required
        this.recipientAddressFormGroup.get('locationAddress')?.setValue(this.draftDeliveryDetailAddress.address ?? ''); // *required
        this.recipientAddressFormGroup.get('locationCity')?.setValue(this.draftDeliveryDetailAddress.city ?? ''); // *required
        if(state)
            this.recipientAddressFormGroup.get('locationState')?.setValue(state.abbreviation); // *required
        this.recipientAddressFormGroup.get('locationZip')?.setValue(this.draftDeliveryDetailAddress.zip ?? ''); // *required

        let fields = orderData.digitalFields;

        this.digitalAddressesFormGroup.get('deadlineDate')?.setValue(fields.deadlineDate);
        this.digitalAddressesFormGroup.get('deadlineTime')?.setValue(fields.deadlineTime);
        this.digitalAddressesFormGroup.get('deadlineTimeZone')?.setValue(fields.deadlineTimeZone.name);

        return this.recipientAddressFormGroup.valid;

    }

    private seedEDiscoveryTrialStep(){
        if(!this.draftDeliveryAddresses.length) return false;

        this.draftDeliveryDetailAddress = this.draftDeliveryAddresses[0];

        let selectedState = (this.draftDeliveryDetailAddress.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );
        console.log("Draft delivery address:", this.draftDeliveryDetailAddress)
        this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany')?.setValue(this.draftDeliveryDetailAddress.name ?? ''); // *required
        this.eDiscoveryTrialFormGroup.get('eDiscoveryContact')?.setValue(this.draftDeliveryDetailAddress.attention ?? '');
        this.eDiscoveryTrialFormGroup.get('eDiscoveryStreet')?.setValue(this.draftDeliveryDetailAddress.address ?? ''); // *required
        this.eDiscoveryTrialFormGroup.get('eDiscoverySuite')?.setValue(this.draftDeliveryDetailAddress.room ?? '');
        this.eDiscoveryTrialFormGroup.get('eDiscoveryCity')?.setValue(this.draftDeliveryDetailAddress.city ?? ''); // *required
        if(state)
            this.eDiscoveryTrialFormGroup.get('eDiscoveryState')?.setValue(state.abbreviation); // *required
        this.eDiscoveryTrialFormGroup.get('eDiscoveryZip')?.setValue(this.draftDeliveryDetailAddress.zip ?? ''); // *required
        this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone')?.setValue(this.draftDeliveryDetailAddress.phone ?? ''); // *required
        this.eDiscoveryTrialFormGroup.get('eDiscoveryPhoneExt')?.setValue(this.draftDeliveryDetailAddress.phoneExt ?? '');
        this.requestNotesFormGroup.get('saveAddress')?.setValue(this.entity.firstAppearanceFees);

        let orderData = JSON.parse((this.entity.orderData || '{}'));
        let fields = orderData.eDiscoveryFields.trialServicesFields;

        if(this.categoryName == ServiceTypes.EDISCOVERY){
            this.eDiscoveryTrialFormGroup.get('trialDate')?.setValue(fields.trialDate);
            this.eDiscoveryTrialFormGroup.get('trialTime')?.setValue(fields.trialTime);
            this.eDiscoveryTrialFormGroup.get('trialTimeZone')?.setValue(fields.trialTimeZone.name);
            this.eDiscoveryTrialFormGroup.get('trialDuration')?.setValue(fields.trialDuration);
            this.eDiscoveryTrialFormGroup.get('courtName')?.setValue(fields.courtName);
            this.eDiscoveryTrialFormGroup.get('courtRoom')?.setValue(fields.courtRoomLocation);
        } else {
            this.trialServicesFormGroup.get('trialDate')?.setValue(fields.trialDate);
            this.trialServicesFormGroup.get('trialTime')?.setValue(fields.trialTime);
            this.trialServicesFormGroup.get('trialTimeZone')?.setValue(fields.trialTimeZone.name);
            this.trialServicesFormGroup.get('trialDuration')?.setValue(fields.trialDuration);
            this.trialServicesFormGroup.get('courtName')?.setValue(fields.courtName);
            this.trialServicesFormGroup.get('courtRoom')?.setValue(fields.courtRoomLocation);
        }
        console.log("Seeding ediscoverytrialstep:", fields)

        return this.eDiscoveryTrialFormGroup.valid;
    }

    private seedInvestigationsStep() {
         if(!this.entity || !this.entity.orderData) return false;
        let orderData = JSON.parse(this.entity.orderData)
        if(!orderData.investigationFields) return false;
        orderData = orderData.investigationFields;
        if(this.categoryName == ServiceTypes.SUB_ROSA){
            let homeAddress = this.draftDeliveryAddresses.find((draftAddress)=>{
                return draftAddress.name == orderData.homeAddress.locationName
            })
            if(homeAddress){
                this.subrosaFormGroup.get('homeName')?.setValue(homeAddress.name);
                this.subrosaFormGroup.get('homeAddress')?.setValue(homeAddress.address);
                this.subrosaFormGroup.get('homeCity')?.setValue(homeAddress.city);
                this.subrosaFormGroup.get('homeState')?.setValue(homeAddress.state);
                this.subrosaFormGroup.get('homeZip')?.setValue(homeAddress.zip);
            }
            let workAddress = this.draftDeliveryAddresses.find((draftAddress)=>{
                return draftAddress.name == orderData.workAddress.locationName
            })
            if(workAddress){
                this.subrosaFormGroup.get('workName')?.setValue(workAddress.name);
                this.subrosaFormGroup.get('workAddress')?.setValue(workAddress.address);
                this.subrosaFormGroup.get('workCity')?.setValue(workAddress.city);
                this.subrosaFormGroup.get('workState')?.setValue(workAddress.state);
                this.subrosaFormGroup.get('workZip')?.setValue(workAddress.zip);
            }
            this.subrosaFormGroup.get('name')?.setValue(orderData.name);
            this.subrosaFormGroup.get('pointOfOrigination')?.setValue(orderData.pointOfOrigination);
            this.subrosaFormGroup.get('natureOfInjury')?.setValue(orderData.natureOfInjury);
            return this.subrosaFormGroup.valid;

        } else if(this.categoryName == ServiceTypes.SKIPTRACE){
            let index = this.draftDeliveryAddresses.findIndex((draftAddress)=>{
                return draftAddress.name == orderData.workAddress.locationName
            })
            let workAddress :any;
            if(index != -1){
                workAddress = this.draftDeliveryAddresses.splice(index, 1);
                this.skipTraceFormGroup.get('locationName')?.setValue(workAddress[0].name);
                this.skipTraceFormGroup.get('locationAddress')?.setValue(workAddress[0].address);
                this.skipTraceFormGroup.get('locationCity')?.setValue(workAddress[0].city);
                this.skipTraceFormGroup.get('locationState')?.setValue(workAddress[0].state);
                this.skipTraceFormGroup.get('locationZip')?.setValue(workAddress[0].zip);
            }
            // drafiting runs multiple times
            if(this.skipTraceFormGroup.get('socialMediaLinks')?.value.length == 0){
                orderData.socialMediaLinks.map((socialLink:any)=>{
                    let media = {
                        mediaName:socialLink.mediaName, 
                        link:socialLink.link
                    }
                    let links = this.skipTraceFormGroup.get('socialMediaLinks')?.value;
                    links.push(media);
                    this.skipTraceFormGroup.get('socialMediaLinks')?.setValue(links);
                    this.skipTraceFormGroup.get('socialMediaLinks')?.updateValueAndValidity();
                })
            }

            if(this.lastKnownAddresses.length == 0){
                this.draftDeliveryAddresses.map((draftAddress)=>{
                    let address = {
                        locationName: draftAddress.name,
                        address: draftAddress.address,
                        city: draftAddress.city,
                        state: draftAddress.state,
                        zip: draftAddress.zip
                    }
                    this.lastKnownAddresses.push(address)
                })
            }
            if(index != -1){
                this.draftDeliveryAddresses.push(workAddress[0]);
            }
            this.skipTraceFormGroup.get('name')?.setValue(orderData.name);
            this.skipTraceFormGroup.get('dateOfBirth')?.setValue(orderData.DOB);
            this.skipTraceFormGroup.get('ssn')?.setValue(orderData.SSN);

        } else if(this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION){
            let locationAddress = this.draftDeliveryAddresses.find((draftAddress)=>{
                return draftAddress.name == orderData.location.locationName
            })
            if(locationAddress){
                this.alternateInvestigationsFormGroup.get('locationName')?.setValue(locationAddress.name);
                this.alternateInvestigationsFormGroup.get('locationAddress')?.setValue(locationAddress.address);
                this.alternateInvestigationsFormGroup.get('locationCity')?.setValue(locationAddress.city);
                this.alternateInvestigationsFormGroup.get('locationState')?.setValue(locationAddress.state);
                this.alternateInvestigationsFormGroup.get('locationZip')?.setValue(locationAddress.zip);
            }
            this.alternateInvestigationsFormGroup.get('assignmentInstructions')?.setValue(orderData.natureOfCase);

            if(this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE){
                this.alternateInvestigationsFormGroup.get('claimedInjuries')?.setValue(orderData.claimedInjuries);
                this.alternateInvestigationsFormGroup.get('informationOfClaimedInjuries')?.setValue(orderData.informationOfClaimedInjuries);
            }
            this.alternateInvestigationsFormGroup.get('dateOfLoss')?.setValue(orderData.dateOfLoss);
            this.alternateInvestigationsFormGroup.get('timeOfLoss')?.setValue(orderData.timeOfLoss);
            this.alternateInvestigationsFormGroup.get('timeZoneOfLoss')?.setValue(orderData.timeZoneOfLoss.offSet);
            return this.alternateInvestigationsFormGroup.valid;

        }
        return false;
    }

    private seedDeliveryDetailStep() {
        if(!this.draftDeliveryAddresses.length) return false;

        this.draftDeliveryDetailAddress = this.draftDeliveryAddresses[0];

        let selectedState = (this.draftDeliveryDetailAddress.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.deliveryDetailFormGroup.get('deliveryCompany')?.setValue(this.draftDeliveryDetailAddress.name ?? ''); // *required
        this.deliveryDetailFormGroup.get('deliveryContact')?.setValue(this.draftDeliveryDetailAddress.attention ?? '');
        this.deliveryDetailFormGroup.get('deliveryStreet')?.setValue(this.draftDeliveryDetailAddress.address ?? ''); // *required
        this.deliveryDetailFormGroup.get('deliverySuite')?.setValue(this.draftDeliveryDetailAddress.room ?? '');
        this.deliveryDetailFormGroup.get('deliveryCity')?.setValue(this.draftDeliveryDetailAddress.city ?? ''); // *required
        if(state)
            this.deliveryDetailFormGroup.get('deliveryState')?.setValue(state.abbreviation); // *required
        this.deliveryDetailFormGroup.get('deliveryZip')?.setValue(this.draftDeliveryDetailAddress.zip ?? ''); // *required
        this.deliveryDetailFormGroup.get('deliveryPhone')?.setValue(this.draftDeliveryDetailAddress.phone ?? ''); // *required
        this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.setValue(this.draftDeliveryDetailAddress.phoneExt ?? '');
        this.requestNotesFormGroup.get('saveAddress')?.setValue(this.entity.firstAppearanceFees);

        return this.deliveryDetailFormGroup.valid;
    }

    private seedDeliveryCourtStep() {
        if(!this.draftDeliveryAddresses.length && !this.draftCourtDeliveryAddress) return false;

        if(!this.draftCourtDeliveryAddress)
            this.draftCourtDeliveryAddress = this.draftDeliveryAddresses[0];

        let selectedState = (this.draftCourtDeliveryAddress.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.courtFormGroup.get('courtName')?.setValue(this.draftCourtDeliveryAddress.name ?? ''); // *required
        this.courtFormGroup.get('courtContact')?.setValue(this.draftCourtDeliveryAddress.attention ?? '');
        this.courtFormGroup.get('courtStreet')?.setValue(this.draftCourtDeliveryAddress.address ?? ''); // *required
        this.courtFormGroup.get('courtSuite')?.setValue(this.draftCourtDeliveryAddress.room ?? '');
        this.courtFormGroup.get('courtCity')?.setValue(this.draftCourtDeliveryAddress.city ?? ''); // *required
        if(state)
            this.courtFormGroup.get('courtState')?.setValue(state.abbreviation); // *required
        this.courtFormGroup.get('courtZip')?.setValue(this.draftCourtDeliveryAddress.zip ?? ''); // *required
        this.courtFormGroup.get('courtPhone')?.setValue(this.draftCourtDeliveryAddress.phone ?? ''); // *required
        this.courtFormGroup.get('courtPhoneExt')?.setValue(this.draftCourtDeliveryAddress.phoneExt ?? '');
        this.requestNotesFormGroup.get('firstAppearanceFees')?.setValue(this.entity.firstAppearanceFees);

        return this.courtFormGroup.valid;
    }

    private seedPickupStep() {
        if(!this.draftPickupAddress) return false;

        let selectedState = (this.draftPickupAddress.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.pickupDetailFormGroup.get('pickupCompany')?.setValue(this.draftPickupAddress.name ?? ''); // *required
        this.pickupDetailFormGroup.get('pickupContact')?.setValue(this.draftPickupAddress.attention ?? '');
        this.pickupDetailFormGroup.get('pickupStreet')?.setValue(this.draftPickupAddress.address ?? ''); // *required
        this.pickupDetailFormGroup.get('pickupSuite')?.setValue(this.draftPickupAddress.room ?? '');
        this.pickupDetailFormGroup.get('pickupCity')?.setValue(this.draftPickupAddress.city ?? ''); // *required
        if(state)
            this.pickupDetailFormGroup.get('pickupState')?.setValue(state.abbreviation); // *required
        this.pickupDetailFormGroup.get('pickupZip')?.setValue(this.draftPickupAddress.zip ?? ''); // *required
        this.pickupDetailFormGroup.get('pickupPhone')?.setValue(this.draftPickupAddress.phone ?? ''); // *required
        this.pickupDetailFormGroup.get('pickupPhoneExt')?.setValue(this.draftPickupAddress.phoneExt ?? '');

        return this.pickupDetailFormGroup.valid;
    }

    private seedCaseStep() {
        // DEV NOTE: this step might fail if the Case is marked deleted and the Order is not
        if(!this.entity.case_uuid) return false;

        let _case = this.cases.find(c => c.uuid == this.entity.case_uuid);
        if(!_case) return false;

        this.selectCaseControl.setValue(_case.uuid);
        this.onCaseSelect();
        return this.caseFormGroup.valid;
    }

    private seedDocumentStep() {
        this.fileFormGroup.get('description')?.setValue(this.entity.documentsDescription);
        
        return this.fileFormGroup.valid;
    }
    
    private seedCategoryStep() {
        // check entity
        if(!this.entity.filingServiceType_uuid || this.entity.filingServiceType_uuid == '') return false;
        
        // find the category and type based on the filingServiceType_uuid, 
        // we have to do it this way because the types array is not set until a category is selected 
        let type : ServiceType | undefined;
        let category : ServiceCategoryWithTypes | undefined = this.categories.find((c) => {
            let matchType = c.types.find((t : any) => t.uuid == this.entity.filingServiceType_uuid);

            // guard for matching type not found
            if(!matchType) return false;

            // DEV NOTE: have to make a new ServiceType from the matchType
            // because for some reason the types don't match and typescript is unhappy
            type = new ServiceType(matchType);
            return !!type;
        });

        if(!category) return false;
        // set the category form controls from the draft
        this.categoryFormGroup.get('category')?.setValue(category.category.name);
        this.onCategorySelect();

        // check type and category
        if(!type) return false;

        if(category.category.displayName == 'Other') {
            let orderData = JSON.parse(this.entity.orderData as string);
            this.categoryFormGroup.get('serviceCategoryDescription')?.setValue(orderData.serviceCategoryDescription);
        }

        this.categoryFormGroup.get('type')?.setValue(type.uuid);
        this.onTypeSelect();

        if(!this.entity.clientMatterNumber || this.entity.clientMatterNumber == '') return false;
        this.selectClientMatterControl.setValue(this.entity.clientMatterNumber);
        this.onClientMatterSelect();

        // set optional fields
        this.categoryFormGroup.get('claimNumber')?.setValue((this.entity.claimNumber ?? ''));
        this.categoryFormGroup.get('insuranceCompany')?.setValue((this.entity.insuranceCompany ?? ''));
        this.categoryFormGroup.get('insuranceAdjuster')?.setValue((this.entity.insuranceAdjuster ?? ''));

        // Read Order JSON data
        if(this.serviceName == Services.EDISCOVERY){
            let json = JSON.parse(this.entity.orderData || '{}')
            let arg: string[] = []
            if(json.trialServicesServicesNeeded){
                Object.entries(json.trialServicesServicesNeeded).map((entry)=>{
                    if(entry[1] == true) arg.push(entry[0])
                })
            }
            else if(json.eDiscoveryServicesNeeded){
                Object.entries(json.eDiscoveryServicesNeeded).map((entry)=>{
                    if(entry[1] == true) arg.push(entry[0])
                })
            }
            this.eDiscoveryServiceSelectControl.setValue(arg);
            this.seedEDiscovery();
            this.eDiscoveryServiceChange();
        } else if (this.serviceName == Services.DIGITAL){
            let json = JSON.parse(this.entity.orderData || '{}')
            let arg: string[] = []
            if(json.digitalOtherDescription){
                Object.entries(json.digitalOtherDescription).map((entry)=>{
                    if(entry[1] == true) arg.push(entry[0])
                })
            }
        }
        return this.categoryFormGroup.valid;
    }

    private async seedAccountStep() {
        // DEV NOTE: this step might fail if the loggedInUser is not the person who created the Order, because the accounts may not match. 
        // Client has expressed, that only the user that created the draft should be able to resume it.
        // To ensure that only the owner is able to resume the draft order, checks were added to hide the resume button on the detail page and in the afterLoad call.
        let orderData = JSON.parse((this.entity.orderData || '{}'));

        if(!orderData.customerInformation) return false;
        let info : CustomerAccountInfo = orderData.customerInformation;

        if(!(info.accountNumber || (info.companyNo && info.customerNo))) return false;

        let accountNumber = (info.accountNumber ?? (info.companyNo + '-' + info.customerNo)).trim();
        // match the account on record with external accounts
        let account = this.externalAccounts.find(
            (a) => { return (accountNumber == (a.accountNumber || '').trim());}
        );

        if(!account) return false;
        
        // set the selectedAccount 
        // this function also takes care of transitioning to the next step.
        await this.accountSelected(account);

        return this.selectAccount.valid;
    }

    private seedServiceStep() {
        if(!this.entity.filingServiceType_uuid) return
        this.loadServiceCategories()

        let search:any = this.types.find((t) => t.uuid == this.entity.filingServiceType_uuid)
        if(search != undefined){
            let type = search
            search = this.categories.find((c) => c.uuid == type.filingServiceCategory_uuid)
            if(search != undefined){
                let category = search
                search = this.orderServiceService.services.value.find((s) => s.service.uuid == category.filingService_uuid)
                if(search){
                    let service = search
                    this.serviceFormGroup.controls['service']?.setValue(service.service.name)
                    this.categories = service ? service.categories : [];
                }
            }
        }

        return this.serviceFormGroup.valid;
    }

    private async seedForm(clone: boolean) {
        let stepFailed = false;

        let serviceStep = this.seedServiceStep();
        // this.checkService();
        let accountStep = await this.seedAccountStep();
        let categoryStep = this.seedCategoryStep();
        let documentStep = true;
        if(!clone)
            documentStep = this.seedDocumentStep();
        let caseStep = this.seedCaseStep();
        let pickupStep = this.seedPickupStep();
        // There three possibilities for the delivery step based on the ServiceCategory and ServiceType
        let deliveryStep;
        if(this.serviceName == this.courtProcess){
            if(this.showCourt()) 
                deliveryStep = this.seedDeliveryCourtStep();
            else if(this.showDeliverTo()) 
                deliveryStep = this.seedDeliveryDetailStep();
            else    
                deliveryStep = true;
        }else if(this.serviceName == this.eDiscovery){
            deliveryStep = this.seedEDiscoveryTrialStep();
        }else if(this.serviceName == this.digital){
            if(this.categoryName == ServiceTypes.MAILOUT){
                deliveryStep = this.seedDigitalMailout();
            }else{
                deliveryStep = this.seedDigitalStep();
            }
        }else if(this.serviceName == this.investigations){
            deliveryStep = this.seedInvestigationsStep();
        }

        let instructionsStep = this.seedInstructionsStep();
        // this.prepareReview(); // this is now handled with onStepSelectionChange

        if(!serviceStep) stepFailed = true; 
        else if(!stepFailed)this.formStepper?.next();

        if(!accountStep) stepFailed = true;
        // this is handled in accountSelected, don't want to call next() twice on the form
        // else if(!stepFailed) this.formStepper?.next();

        if(!categoryStep) stepFailed = true;
        else if(!stepFailed) this.formStepper?.next();

        if(!documentStep) stepFailed = true;
        else if(!stepFailed && this.documentsRequired != ServiceTypeDocsRequired.None) this.formStepper?.next();

        if(!caseStep) stepFailed = true;
        else if(!stepFailed) this.formStepper?.next();

        if(!pickupStep) stepFailed = true;
        else if(!stepFailed && this.pickupRequired) this.formStepper?.next();
    
        if(!deliveryStep) stepFailed = true;
        else if(!stepFailed) this.formStepper?.next();

        if(!instructionsStep) stepFailed = true;
        else if(!stepFailed) this.formStepper?.next();

        // hack to make the order resume stay functional
        let caseFormStep = this.formStepper?.steps.find((s) => { return s.label == "Case"; });
        if(caseStep && caseFormStep && !caseFormStep.completed) 
            caseFormStep.completed = true;

        if(this.documentsRequired == ServiceTypeDocsRequired.Required && clone){
            let docStepForm = this.formStepper?.steps.find((s) => { return s.label == 'Documents'})
            docStepForm?.select();
        }
        this.draftDisabled = false;
    }

    private afterLoad() {
        this.draftDisabled = true;
        if(!this.isLoading) {
            this.serviceFormGroup.controls['service']?.enable();

            // seed resumed draft order.
            if(this.entity && this.entity?.orderStatus?.toLowerCase() == DRAFT && this.entity.successAccount_uuid == this.userPermissionService.loggedInUser?.uuid) { // TFADD
                // seedDocuments are Documents that were previously uploaded to the the Order as part of saving it as a Draft, 
                // we fetch them from the database and their data will be passed during the order Submit call for processing on the backend 
                // NOTE: they are not the actual files but for the purposes of form validation, they are essentially the same
                let documentsObservable = this.entityService.findDocumentsByOrderId(this.entity.uuid);
                let deliveryObservable = this.serviceAddressService.getDeliveryAddressesByOrderId(this.entity.uuid);
                let toServeObservable = this.toServeService.getDeliveryToServesByOrderId(this.entity.uuid);
                let submissionObservable = this.submissionService.getSubmissionByOrderId(this.entity.uuid);
                let pickupObservable : Observable<ServiceAddress> | undefined; 
                if(this.entity.pickup_ServiceAddress_uuid && this.entity.pickup_ServiceAddress_uuid != '')
                    pickupObservable = this.serviceAddressService.get(this.entity.pickup_ServiceAddress_uuid);

                let draftObservables : Array<Observable<any>> = [
                    documentsObservable,
                    deliveryObservable,
                    toServeObservable,
                    submissionObservable
                ];

                if(pickupObservable)
                    draftObservables.push(pickupObservable);

                this.breadCrumbService.actions.next([
                    { label: 'Delete', action: this.delete.bind(this), isPrimary: false, icon: 'delete'}
                ]);
                
                combineLatest(draftObservables).subscribe(
                    async (draftResponse: any[]) => {
                        this.seedDocuments = draftResponse[0];
                        this.fileNames = new Set<string>(this.seedDocuments.filter(d => d.fileName).map(d => d.fileName || ''));
                        // force the validator to run again on the docs list, to keep the seeding from getting stuck on this step.
                        this.fileFormGroup.controls['documents'].updateValueAndValidity();
                        this.draftDeliveryAddresses = draftResponse[1];
                        let draftServees = draftResponse[2];
                        this.draftSubmission = draftResponse[3];
                        if(pickupObservable)
                            this.draftPickupAddress = draftResponse[4];
                        this.seedServiceAddresses(draftServees);
                        await this.seedForm(false);
                    });
            } else if (this.entity && this.entity.successAccount_uuid == this.userPermissionService.loggedInUser?.uuid){
                // Clone order
                this.entity.orderStatus = null;
                this.fileFormGroup = this.fb.group({
                    documents:[null, []],
                    description: ['', []]
                },{
                    validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]
                });
                let deliveryObservable : Observable<ServiceAddress[]> | Observable<ServiceAddress> = this.serviceAddressService.getDeliveryAddressesByOrderId(this.entity.uuid);
                if(this.entity.delivery_ServiceAddress_uuid && this.entity.delivery_ServiceAddress_uuid != '')
                    deliveryObservable = this.serviceAddressService.get(this.entity.delivery_ServiceAddress_uuid);
                let toServeObservable = this.toServeService.getDeliveryToServesByOrderId(this.entity.uuid);
                let submissionObservable = this.submissionService.getSubmissionByOrderId(this.entity.uuid);
                
                let getUnassignedToServeObservable : Observable<ServiceAddress[]> | undefined;
                getUnassignedToServeObservable = this.serviceAddressService.ToServeDeliveryAddresses(this.entity.uuid);

                let pickupObservable : Observable<ServiceAddress> | undefined; 
                if(this.entity.pickup_ServiceAddress_uuid && this.entity.pickup_ServiceAddress_uuid != '')
                    pickupObservable = this.serviceAddressService.get(this.entity.pickup_ServiceAddress_uuid);

                let cloneObservables : Array<Observable<any>> = [
                    deliveryObservable,
                    toServeObservable,
                    submissionObservable
                ];

                if(pickupObservable)
                    cloneObservables.push(pickupObservable);

                if(getUnassignedToServeObservable)
                    cloneObservables.push(getUnassignedToServeObservable);

                combineLatest(cloneObservables).subscribe(
                    async (draftResponse: any[]) => {
                        if(!this.entity.delivery_ServiceAddress_uuid)
                            this.draftDeliveryAddresses = draftResponse[0];
                        else {
                            this.draftCourtDeliveryAddress = draftResponse[0];
                            this.draftDeliveryAddresses = [draftResponse[0]];
                        }
                        let draftServees = draftResponse[1];

                        this.draftSubmission = draftResponse[2];
                        if(pickupObservable)
                            this.draftPickupAddress = draftResponse[3];
                        if(draftResponse[4] && draftResponse[4].length > 0){
                            this.draftDeliveryAddresses = draftResponse[4];
                        }
                        if(this.draftCourtDeliveryAddress)
                            this.draftCourtDeliveryAddress.order_uuid = null;
                        this.draftDeliveryAddresses.map((address)=> address.order_uuid = null);
                        
                        this.seedServiceAddresses(draftServees);
                        draftServees.map((address:any)=> address.serviceAddress_uuid = null);

                        this.draftSubmission.map((submission)=> {
                            submission.order_uuid = null;
                            if(submission.requestNotes){
                                submission.requestNotes = submission.requestNotes?.split('{"ServeeNumber')[0];
                                submission.requestNotes = submission.requestNotes?.split('Digital Delivery')[0];
                                submission.requestNotes = submission.requestNotes?.split('Advance First Appearance Fees')[0];
                                submission.requestNotes = submission.requestNotes?.split('Deliver Courtesy Copy')[0];
                                submission.requestNotes = submission.requestNotes?.split('Advance Fees')[0];
                            }
                        });
                        this.clonedOrder = true;
                        await this.seedForm(true);

                        this.entity.uuid = '';
                        // force the validator to run again on the docs list, to keep the seeding from getting stuck on this step.
                        this.fileFormGroup = this.fb.group({
                            documents:[null, [this.filesAndDocumentsValidator()]],
                            description: ['', [this.documentDescriptionValidator()]]
                        },{validators:[(group)=>{if(this.pristineCheck(group)){this.formDirty = true}; return null}]});
                        this.fileFormGroup.controls['documents'].updateValueAndValidity();
                });
            } 
            // else if (this.entity.uuid) {
            //     this.router.navigate(['/order/' + this.entity.uuid]);
            //     TODO: Admin clone/draft, error reporting
            // }
        }
    }

    get hasNoContactHours() {
        return this.requestNotesFormGroup.get('hasNoContactHours');
    }

    ngOnInit() {

        super.ngOnInit();
        this.systemConfigService.getSysConfigListObservable().subscribe((res)=>{
            if(this.systemConfigService.maintenanceRedirectCheck()){  
                window.localStorage.removeItem("loggedInUserPermissionNodes");
                this.auth.logoff();
            };
        })

        // console.log(WIP());
        this.isLoading = true;
        this.serviceFormGroup.controls['service']?.disable();
        this.auth.isAuthenticated$.subscribe((authenticated) => {
            if(!authenticated.isAuthenticated) return;

            this.userPermissionService.userSync.subscribe((userSynced) => {
                if(!userSynced) return;


                (async () => { 
                    let user = this.userPermissionService.loggedInUser!.uuid;
        
                    this.successAccountSettingsService.getByAccountUUID(user)
                        .subscribe((records) => {
                            this.settings = records;
                
                            this.initForm();
                        });
                })();

                let userAddressRequest;
                if(this.userPermissionService.hasRole(SuccessAccountRoleName.client_firm_admin)
                    || this.userPermissionService.hasRole(SuccessAccountRoleName.fl_admin)) {
                    userAddressRequest = this.addressBookService.getByCorpId(this.userPermissionService.loggedInUser?.corpId);
                } else {
                    userAddressRequest = this.addressBookService.getByOwnerUUID(this.userPermissionService.loggedInUser?.uuid);
                }

                let requests = [
                    userAddressRequest,
                    this.addressBookService.getFirstLegalsAddressBook(),
                    this.addressBookService.getCourtsAddressBook(),
                    this.successCaseService.getByCorp(),
                    this.clientBranchService.getCustomerAccountsByCorpId(this.userPermissionService.loggedInUser!.corpId),
                    this.orderServiceService.dataSync,
                    this.vendorAccountService.getVendorAccountsByUsernameAndVendor({ 
                        username: this.userPermissionService.loggedInUser!.username 
                    }),
                    this.stateService.getAll(),
                ]

                let pageData = combineLatest(requests)
                .subscribe((response) => {
                    this.userAddresses = [...response[0], ...response[1]];
                    // don't inlcude "ClientBranch" AddressBooks these are not part of a users AddressBook
                    this.userAddresses = this.userAddresses.filter((a) => a.addressType != CLIENT_BRANCH);

                    this.courtAddresses = response[2];

                    this.cases = response[3];
                    this.cases.sort((a,b) => {
                        if(a.name == b.name) return 0
                        return (a.name || "").toUpperCase() < (b.name || "").toUpperCase() ? -1 : 1
                    })

                    // customer accounts
                    if(this.userPermissionService.loggedInUser && !this.entity){
                        let user = this.userPermissionService.loggedInUser;
                        let accountIndex = response[4].findIndex((account:any)=> account.companyNo == user.companyNumber && account.customerNo == user.customerNumber)
                        if(accountIndex != -1){
                            let account = response[4].splice(accountIndex, 1);
                            this.accountDataSource.data = account.concat(response[4]);
                            this.externalAccounts = account.concat(response[4]);
                            this.accountSelected(account[0], true);
                        }else {
                            this.accountDataSource.data = response[4];
                            this.externalAccounts = response[4];
                        }
                    }
                    else {
                        this.accountDataSource.data = response[4];
                        this.externalAccounts = response[4];
                    }

                    // service types
                    if(!response[5]) return;

                    // Refactored by jbethke 2021-08-10
                    // Sort vendor accounts by type
                    this.vendorAccounts = {};
                    response[6].forEach(
                        (vendor: VendorAccount) => {
                            if(!this.vendorAccounts[vendor.vendorName]) {
                                this.vendorAccounts[vendor.vendorName] = [];
                            }
                            let vendorAccountDisplay : VendorAccountDisplay = {
                                vendor_uuid: vendor.vendor_uuid,
                                successAccount_uuid: vendor.successAccount_uuid,
                                username: vendor.username,
                                email: vendor.email,
                                vendorName: vendor.vendorName,
                                display: (vendor.username || vendor.email),
                                createdAt: vendor.createdAt,
                                updatedAt: vendor.updatedAt,
                                deletedAt: vendor.deletedAt
                            }
                            this.vendorAccounts[vendor.vendorName].push(vendorAccountDisplay);
                        }
                    )

                    this.allStates = response[7];
                    this.allStates = this.allStates.filter(s => !s.isDeleted);
                    this.allStates.sort((s1, s2) => s1.stateName.localeCompare(s2.stateAbbreviation));

                    // initalize the user address filter observable
                    this.filteredUserPickupAddresses = this.selectPickupAddress.valueChanges.pipe(
                        startWith(''),
                        map(value => this._userAddressFilter(value || ''))
                    );

                    // initialize the court filter observable
                    this.filteredCourtPickupAddresses = this.selectPickupCourt.valueChanges.pipe(
                        startWith(''),
                        map(value => this._courtFilter(value || '')),
                    );

                    this.filteredPickupState = this.pickupDetailFormGroup.get('pickupState')?.valueChanges.pipe(
                        startWith(''),
                        map(value => this._stateFilter(value || ''))
                    );

                    this.pickupDetailFormGroup.controls['pickupState'].setValidators([Validators.required, this.stateValidator()]);
                    
                    this.filteredCases = this.selectCaseControl.valueChanges.pipe(
                        startWith(''),
                        map(value => this._caseFilter(value || '')),
                    );

                    this.timeZones = this.locationService.timezones;
                    this.timeZones.map((timeZone)=> timeZone.value = timeZone.value.replace('_',' '));
                    this.isLoading = false;
                    this.afterLoad();
                });
            })
        });

        this.filteredClientMatterEntries = this.selectClientMatterControl?.valueChanges.pipe(
            startWith(''),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((term: any, i: number) => {
                if (typeof term === 'string' && this.selectedClientMatterSet.getValue() !== null) {
                    return this.clientMatterLookupConfig.suggest(term, 50);
                }

                return of([]);
            })
        );

        this.filteredClientMatterEntries.subscribe((entries) => {
            this.clientMatterEntries = entries;
        });
        
        let request = [this.orderServiceService.dataSync]
        combineLatest(request).subscribe((response)=>{
            this.onServiceChanged();
        })
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    public navigateToVendorAccount(account: VendorAccountDisplay) {
        if (account.vendorName == VendorType.FIRSTCONNECT) {
            let url = environment.config['FirstConnectLoginUrl'];
            let redirect = url + '?username=' + account.username;

            this.telemetryService
                .announce
                (
                    TelemetryEventType.efileSSO, 
                    this.router.url, 
                    account.successAccount_uuid, 
                    this.prepareOrderDataJSON()
                )
                .subscribe();

            window.open(redirect);
        } else if (account.vendorName == VendorType.ACCLAIM) {
            let redirect = environment.config['AcclaimLoginUrl'];

            this.telemetryService
                .announce
                (
                    TelemetryEventType.depositionsSSO, 
                    this.router.url, 
                    account.successAccount_uuid, 
                    this.prepareOrderDataJSON()
                )
                .subscribe();

            window.open(redirect, "_blank");
        } else if (account.vendorName == VendorType.TRISTAR) {
            let redirect = environment.config['TristarLoginUrl'];
            
            this.telemetryService
                .announce
                (
                    TelemetryEventType.recordsSSO, 
                    this.router.url, 
                    account.successAccount_uuid, 
                    this.prepareOrderDataJSON()
                )
                .subscribe();

            window.open(redirect, "_blank");
        } else {
            // TODO: Visualize error for user
            console.log('ERROR: Invalid Vendor');
        }
    }

    public async saveErrorAsDraft() {
        // grab failed docs from submit document list
        let badDocuments : Array<any> = this.failedDocumentUploads.map((i) => this.SubmittedDocuments[i]);

        let orderId = (this.SubmitOrder?.uuid || this.entity.uuid);
        
        // remove the bad docs from the order
        let responses = await this.allSettled(badDocuments.map(
            async (document) => {
                
                let documentId = (document.id || document.uuid);
                let deleted = await this.entityService.deleteByOrderIdAndDocumentId(orderId, documentId).toPromise();
                // if(deleted.status != 200) throw new Error(`Order document delete failed ${document.name}`);
            }
        ));

        responses.map((response, index) => {
            if(response.status != 'processed') console.error('Could not delete document');
        });
        
        // grab the order so we can update it
        let order = (this.entity || await this.entityService.get(orderId).toPromise());
        order.orderStatus = OrderStatus.DRAFT;

        // update order status to be 'draft'
        let result = await this.entityService.push(order).toPromise();

        // go to draft detail
        this.formDirty = false;
        this.router.navigate(['/order/' + (this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid)], {queryParams:{ draft: true }});
    }

    public async retryDocumentUpload(draft: boolean) {
        // get a new list of failed submitted doc to retry.
        let retryDocuments : Array<any> = this.failedDocumentUploads.map((i) => this.SubmittedDocuments[i]);

        await this.uploadDocuments(retryDocuments, draft);
    }

    public orderFailed(message: string, draft: boolean) {
        let dialogRef: MatDialogRef<OrderSubmitFailedDialog>;
        dialogRef = this.dialog.open(OrderSubmitFailedDialog, {data: {
            failedDocuments: this.failedDocumentUploads,
            message: message
        }});

        // TODO: modify the retry logic so it isn't called/shown unless there are failed docs
        dialogRef.afterClosed().subscribe(async (response: string | boolean) => {
            if(!response) {
                this.isLoading = false;
            } else if(response == 'retry') {
                await this.retryDocumentUpload(draft);
            } else if(response == 'draft') {
                await this.saveErrorAsDraft();
            }
        })
    }

    public requestAcclaimAccount(){
        let dialogRef: MatDialogRef<RequestAcclaimAccountDialog>;
        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let userSub = userData;
            dialogRef = this.dialog.open(RequestAcclaimAccountDialog, {
                data: {
                    name:userSub.profile.firstName + ' ' + userSub.profile.lastName,
                    email:userSub.profile.email,
                    firm: userSub.profile.corpId
                }
            });
        })
    }

    public requestTristarAccount() {
        // window.open(this.signupTristar, "_blank");
        let dialogRef: MatDialogRef<RequestTristarAccountDialog>;
        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let userSub = userData;
            dialogRef = this.dialog.open(RequestTristarAccountDialog, {
                data: {
                    name:userSub.profile.firstName + ' ' + userSub.profile.lastName,
                    email:userSub.profile.email,
                    firm: userSub.profile.corpId
                }
            });
        })
    }

    public requestVendorAccount(vendorType : string) {
        if(vendorType == "Tristar") 
            this.requestTristarAccount();
        else if(vendorType == "Acclaim")
            this.requestAcclaimAccount();
        return;
    }

    public showDeliverTo() : boolean {
        const categoryControl = this.categoryFormGroup.get('category');

        if(!categoryControl) return false;

        return categoryControl.value == ServiceTypes.RESEARCH 
            || categoryControl.value == ServiceTypes.EDISCOVERY 
            || categoryControl.value == ServiceTypes.TRIALSERVICES
            || (categoryControl.value == ServiceTypes.MESSENGER && this.typeName != ServiceTypes.PDF_COURTESY_DELIVERY_TYPE);
    }

    public showCourt() : boolean {
        const categoryControl = this.categoryFormGroup.get('category');

        if(!categoryControl) return false;

        return (categoryControl.value == ServiceTypes.COURT_RUN || categoryControl.value == ServiceTypes.EFILE 
            || categoryControl.value == ServiceTypes.PDF_FAX_FILING || categoryControl.value == ServiceTypes.COURT_FILING 
            || categoryControl.value == ServiceTypes.PDF_COURTESY_DELIVERY 
            || (categoryControl.value == ServiceTypes.MESSENGER && this.typeName == ServiceTypes.PDF_COURTESY_DELIVERY_TYPE));
    }

    public isEfile() : boolean {
        const categoryControl = this.categoryFormGroup.get('category');
        
        if (!categoryControl) return false;

        return categoryControl.value == ServiceTypes.EFILE;
    }

    public isCourtService() : boolean {
        const categoryControl = this.categoryFormGroup.get('category');
        if (!categoryControl) {
            return false;
        }
        
        return categoryControl.value == ServiceTypes.PDF_FAX_FILING;
    }

    public skipProcessOrder() : boolean {
        if(!this.serviceName) return false;

        return (this.serviceName == ServiceTypes.EDISCOVERY 
            || this.serviceName == ServiceTypes.INVESTIGATIONS
            || this.serviceName == ServiceTypes.DIGITAL);
    }

    public formatBytes(bytes: number, decimals?: number) {
        if(bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }    

    public removeFile(index: number) {
        const file = this.files.splice(index,1);
        if (file.length !== 0) {
            const deletedFile = file[0];
            this.fileNames.delete(deletedFile.name);
        }

        this.fileFormGroup.patchValue({documents: this.files});

        // check the length of both seedDocuments and files, if both are empty 
        // and the documents are required set the error.
        if(!(this.files.length || this.seedDocuments.length) && (this.documentsRequired == ServiceTypeDocsRequired.Required)) 
            this.fileFormGroup.get('documents')?.setErrors({required: true});
    }

    public getUniqueFileName(fileName: string) {
        if (!this.fileNames.has(fileName)) {
            return fileName;
        }

        let increment = 1;
        const name = fileName.substring(0, fileName.lastIndexOf('.'));
        const suffix = fileName.substring(fileName.lastIndexOf('.'));
        while (this.fileNames.has(`${name}-${increment}${suffix}`))
        {
            increment++;
        }

        return `${name}-${increment}${suffix}`;
    }
    public restoreSeedDocument(index: number) {
        let restored = this.deletedSeedDocuments.splice(index, 1);
        if (restored.length !== 0) {
            let restoredFile = restored[0];
            if (restoredFile.fileName && this.fileNames.has(restoredFile.fileName)) {
                // the name of the file we're restoring conflicts with the name of a file that got uploaded
                // find the conflicting file and rename it
                let conflictingFile = this.files.find(f => f.name === restoredFile.fileName);
                if (conflictingFile) {
                    let indexToRemove = this.files.indexOf(conflictingFile);
                    let removed = this.files.splice(indexToRemove, 1)[0];
                    let newFileName = this.getUniqueFileName(restoredFile.fileName);
                    let changedFile: File = new File([removed], newFileName, {type: removed.type});

                    this.files.push(changedFile);
                    this.fileNames.add(newFileName);
                    this.dialog.open(DocumentNameChangedDialog, {
                        data: {changedFileNames: [restoredFile.fileName], newFileNames: [newFileName]}
                    });
                }
            }

            if (restoredFile.fileName) {
                this.fileNames.add(restoredFile.fileName);
            }
        }

        this.seedDocuments = [...this.seedDocuments, ...restored];

        // check is probably not needed, but for sanity
        if( this.seedDocuments.length )
            this.fileFormGroup.get('documents')?.setErrors(null);
    }

    public removeSeedDocument(index: number) {
        // put the deleted seed document into a separate list so it can be restored
        let deleted = this.seedDocuments.splice(index, 1);
        if (deleted.length !== 0) {
            let deletedFile = deleted[0];
            this.fileNames.delete(deletedFile.fileName || '');
        }

        this.deletedSeedDocuments = [...this.deletedSeedDocuments, ...deleted];

        // check the length of both seedDocuments and files, if both are empty 
        // and the documents are required set the error.
        if( !(this.files.length && this.seedDocuments.length) && (this.documentsRequired == ServiceTypeDocsRequired.Required))
            this.fileFormGroup.get('documents')?.setErrors({required: true});
    }

    // Accessing the values where a file is dropped.
    @ViewChild("fileUploader")
    fileDropRef: any;

    public onSelect(event: Event) {
        const element = event.target as HTMLInputElement;
        if(!element.files || element.files.length == 0) return;

        let error = false;
        let newFiles : File[] = []
        const files = element.files;
        let changedFileNames: Set<string> = new Set<string>();
        let newFileNames: string[] = [];
        let maxFileSize =  24576000;
        let currentSize = 0;
        this.files.map((file) => {
            currentSize += file.size;
        });

        Array.from(files).forEach((file: File) => {
            currentSize += file.size || 0;
        })
        if(currentSize > maxFileSize){
            error = true;
            newFiles = [];
            let excessFiles = Array.from(files).map((file) => {return file.name});
            this.dialog.open(FileSizeDialog, {
                data: {files: excessFiles.join(`', '`), size: currentSize}
            }).afterClosed().subscribe((res)=>{
                return;
            })
        } else {
            Array.from(files).forEach((file: File) => {
                let allowFile = false;
                this.acceptedFileTypes.map((type)=>{
                    if(file.type == type[0])
                        allowFile = true;
                })
                
                if (allowFile) {
                    let uniqueFileName = this.getUniqueFileName(file.name);
                    if (uniqueFileName !== file.name) {
                        changedFileNames.add(file.name);
                        newFileNames.push(uniqueFileName);

                        file = new File([file], uniqueFileName, {type: file.type});
                        this.fileNames.add(uniqueFileName);
                    }
                    this.fileNames.add(uniqueFileName);
                    newFiles.push(file);
                    let fileBlobURL = URL.createObjectURL(file);
                    console.log("fileURL:", fileBlobURL);
                    let encodedFileBlob = encodeURI(fileBlobURL);
                    console.log(encodedFileBlob);
                    this.fileRedirect.set(uniqueFileName, encodedFileBlob);
                } else if(!error) {
                    error = true;
                    newFiles = [];
                    let formats = this.acceptedFileTypes.map((type)=>{return "'" + type[1] + "'"});
                    formats[formats.length - 1] = 'or ' + formats[formats.length - 1];
                    this.dialog.open(InvalidDocumentDialog, {
                        data: {types: formats.join(', '), invalidType: file.type}
                    }).afterClosed().subscribe((result)=>{
                        if(result){
                            this.saveDraft();
                        }
                        return;
                    })
                }
            });
        }

        if (changedFileNames.size > 0) {
            this.dialog.open(DocumentNameChangedDialog, {
                data: {changedFileNames: [...changedFileNames], newFileNames}
            });
        }

        if(!error){
            this.files = this.files.concat(newFiles);
            this.fileFormGroup.patchValue({documents: files});
        }
        this.fileDropRef.nativeElement.value = '';
    }

    public filePreviewRedirect(fileName: string){
        window.open(this.fileRedirect.get(fileName));
    }

    public filesExist(): boolean { 
        if(this.documentsRequired == 'None' || this.documentsRequired == 'Optional')
            return true;
        return ( this.files && this.files.length > 0 ); 
    }

    // DEV NOTE: using the animationDone event because the selectionChange event fires before the selectedIndex is updated
    public onStepSelectionChange() {
        if(!this.formStepper) return;
        let length = this.formStepper.steps.length;
        // check for direct efile type and redirect if needed.
        this.checkCategory();
        // Users who click directly on the steps in the stepper rather than the next buttons,
        // will not call the prepareReview method resulting in a blank review step.
        // This logic will ensure the prepareReview is always called.
        if((length - 1) == this.formStepper.selectedIndex)
            this.prepareReview();
        this.checkSysStatus();
    }

    public test() {
        // console.log('TEST');
        // console.log('document', this.fileFormGroup.dirty);
        // console.log('files', this.files.length);
        // console.log('case', this.caseFormGroup.dirty);
        // console.log('select case', this.selectCaseControl.dirty);
        // console.log('selected index', this.formStepper?.selectedIndex);
        // console.log('length', this.formStepper?.steps.length);
        // this.formStepper.steps
        // this.failedDocumentUploads.push(1);
        // this.orderFailed('<Placeholder for error message>', false);
        // this.requestVendorAccount('Tristar');
        // this.prepareUpdateOrderData(true);
        // this.prepareOrderData(false);
        // this.typeName; 
        // let orderId = '6a4f0c12-a51d-4787-8b42-cbec841a7b36';  // ftorres1 draft with doc 
        // let orderId = '5a8e1db9-d07f-4896-ae67-21bebdb94f8d' // James Draft order
        // let orderId = '3391dde2-7ff7-41ca-938e-3b27c13477d1' // deleted case,normal order
        // let orderId = '2c5c7443-c076-4613-ada5-d1bdf7493a4c'; // normal order, no pickup
        // let orderId = '444d634e-2adb-4e12-9cbd-ade2fdb56189'; // normal order (COURT)
        // let orderId = '7D28EA38-7367-4FB2-B4DA-BD877D1A99FC'; // draft order (COURT)
        // let orderId = 'E98170EC-EEC6-4D2C-BA26-EF453E9CD3E4'; // draft order (MESSENGER)
        // let orderId = 'D5799EA8-F686-4B06-9BE5-0FAE15BD0206'; // draft order (SOP)
        // let orderId = '4316eac1-fb2e-40d5-852a-e4951cb499df'; // totally blank order
        // this.router.navigate(['/order/' + orderId], {queryParams:{finished: true}});
        // let orderId = '444d634e-2adb-4e12-9cbd-ade2fdb56189';  // test draft 
        // this.router.navigate(['/order/' + orderId], {queryParams:{draft: true}});
        // this.router.navigate(['/order/resume/' + orderId]); // for testing a normal order
        // console.log('service address form group', this.serviceAddressFormGroup);
        // console.log('INVALID', this.serviceAddressFormGroup.invalid);
        // console.log('errors', this.serviceAddressFormGroup.errors);

        // console.log('servees', this.servees);
        // console.log('serviceAddresses', this.serviceAddresses);
        // console.log('categories', this.categories);
        // console.log('TEST selectAccount', this.selectAccount);
        // console.log('TEST select Account errors', this.selectAccount.errors);
        // console.log('TEST select Account validators')
        // console.log('TEST addresses', this.userAddresses);
        // console.log('TEST addresses', this.courtAddresses);
        // console.log('TEST court state value', this.courtFormGroup.get('courtState')?.value);
        // console.log('TEST cases', this.cases);
        // console.log('TEST loggedin user', this.userPermissionService.loggedInUser);
        // console.log('TEST service', this.serviceFormGroup.get('service'))
        // console.log('TEST service', this.serviceFormGroup.get('service')?.value);
        
        // console.log('TEST serve group', this.toServeFormGroup);
        // console.log('TEST servee', this.toServeFormGroup.get('servees'))
        // console.log('TEST servee', this.toServeFormGroup.get('servees')?.value);

        // console.log('TEST form files', this.fileFormGroup.get('documents')?.value);
        // console.log('TEST files', this.files);
        // console.log('TEST file form control', this.formTestAGroup.get('testA'));
        // console.log('TEST file form control', this.fileFormGroup.get('documents'));

        // console.log('TEST court form valid', this.courtFormGroup.valid);
        // console.log('TEST court form errors', this.courtFormGroup.errors);
        // console.log('TEST court form', this.courtFormGroup);

        // console.log('TEST pickup form valid', this.pickupDetailFormGroup.valid);
        // console.log('TEST pickup form errors', this.pickupDetailFormGroup.errors);
        // console.log('TEST pickup form', this.pickupDetailFormGroup);

        // console.alog('TEST delivery form', this.deliveryDetailFormGroup);
    }


    private async getClientBranchFromAccount(selectedAccount: CustomerAccountInfo): Promise<ClientBranch | null> {
        if (typeof selectedAccount.companyNo != 'number' || !selectedAccount.customerNo || !selectedAccount.corpId) {
            return null;
        }

        if (Number.isNaN(parseInt(selectedAccount.customerNo))) {
            return null;
        }

        const results = await this.clientBranchService.findByCompanyNumberCustomerNumberAndCorpId(selectedAccount.companyNo, parseInt(selectedAccount.customerNo), selectedAccount.corpId)
            .pipe(take(1)).toPromise();

        return results.length === 0 ? null : results[0];
    }

    /**
     * Loads the client matter entries for the selected account
     * @param selectedAccount 
     */
    protected async loadClientMatterEntries_forAccount(selectedAccount: CustomerAccountInfo) {
        const successAccount_uuid = this.userPermissionService.loggedInUser?.uuid;

        if (!successAccount_uuid) {
            return;
        }

        const matterSet = await this.clientMatterSetService.getDefaultClientMatterSet(successAccount_uuid, selectedAccount.corpId || '', String(selectedAccount.companyNo), selectedAccount.customerNo || '')
            .pipe(take(1)).toPromise();
        
        if (matterSet) {
            const clientBranch = await this.getClientBranchFromAccount(selectedAccount);

            this.clientMatterErrorMsg.next(clientBranch?.errorMessage || matterSet.customMessage);
            this.selectedClientMatterSet.next(matterSet);
            this.clientMatterLookupConfig = this.clientMatterEntryService.makeLookupConfig(matterSet.uuid);
        }
    }

    // https://github.com/parallax/jsPDF/blob/master/src/jspdf.js 
    // http://raw.githack.com/MrRio/jsPDF/master/docs/index.html //RTM
    // public saveReviewToPDF() {
    //     const doc = new jsPDF('p', 'pt', 'a4');
    //     // doc.addFileToVFS("Barlow-Medium.tff", )
    //     // console.log('TEST FONT', doc.getFontList());
    //     doc.setFont("helvetica"); // doesn't seem to work with the 'html' function
    //     // doc.text("Hello World!", 10, 10);
    //     // doc.save("hw.pdf");

    //     let reviewHTML = (document.querySelector('#review-pdf') as HTMLElement);
    //     if(!reviewHTML) return;
    //     doc.html(reviewHTML, {
    //         margin: [20,40,20,40],
    //         callback: (doc) => doc.save("order-review.pdf")
    //     })
    // }

    // public saveReviewToPDF2(){
    //     this.OrderPrintService.print(this.review)
    // }

    public resetFileForm() {
        this.files = [];
        this.fileFormGroup.reset(FILES);
    }

    public resetServiceForm() {
        this.serviceFormGroup.reset(SERVICE);
    }

    public resetCategoryForm() {
        this.categories = [];
        this.types = [];
        this.groupedTypes = [];
        this.categoryFormGroup.reset(CATEGORY);
    }

    public resetAccountForm() {
        this.accountNextClicked = false;
        this.selectAccount.reset('');
        this.accountFormGroup.reset(ACCOUNT);
    }
    
    public resetServiceDetailForm() {
        this.serviceDetailFormGroup = new FormGroup({});
    }

    public resetCourtForm() {
        this.courtFormGroup.reset(COURT);
    }

    public resetToServeFormGroup() {
        let _toServe = {
            serveFirstName: '',
            serveLastName: '',
            serveEmail: '',
            servePhone: '',
            serveAge: '',
            serveGender: '',
            serveEthnicity: '',
            serveWeight: '',
            serveHeight: '',
            serveHair: '',
            serveEyes: '',
            serveRelationship: '',
            servees: this.serviceAddresses
        }
    }

    public resetCaseForm() {

        let _case = {
            caseName: '',
            caseNumber: '',
            // caseVendorId: '',
            // caseFrequentCallerId: '',
            // caseCorpId: '',
            // caseSuccessAccountId: '',
            // caseId: '',
        };

        this.selectCaseControl.reset('');
        this.caseFormGroup.reset(_case);
    }

    public resetDeliveryDetailForm() {
        this.saveDeliveryAddress = false;
        let delivery = {
            deliveryCompany: '',
            deliveryContact: '',
            deliveryStreet: '',
            deliverySuite: '',
            deliveryCity: '',
            deliveryState: '',
            deliveryZip: '',
            deliveryPhone: '',
            deliveryPhoneExt: '',
            saveAddresss: this.saveDeliveryAddress
        }
        this.deliveryDetailFormGroup.reset(delivery);
    }

    public resetPickupDetailForm() {
        this.savePickupAddress = false;
        let pickup = {
            pickupCompany: '',
            pickupContact: '',
            pickupStreet: '',
            pickupSuite: '',
            pickupCity: '',
            pickupState: '',
            pickupZip: '',
            pickupPhone: '',
            pickupPhoneExt: '',
            saveAddresss: this.savePickupAddress,
        }
        this.pickupDetailFormGroup.reset(pickup);
    }

    public resetServiceAddressForm() {
        this.serviceAddresses = [];
        let serviceAddress = {
            locationName: '',
            serviceContact: '',
            serviceStreet: '',
            serviceSuite: '',
            serviceCity: '',
            serviceState: '',
            serviceZip: '',
            servicePhone: '',
            servicePhoneExt: '',
            serviceAddresses: this.serviceAddresses
        }
        this.serviceAddressFormGroup.reset(serviceAddress);
    }

    public resetServiceAddressFormErrors(){
        this.serviceAddressFormGroup.controls['locationName']!.setErrors(null);
        this.serviceAddressFormGroup.controls['serviceStreet']!.setErrors(null);
        this.serviceAddressFormGroup.controls['serviceCity']!.setErrors(null);
        this.serviceAddressFormGroup.controls['serviceZip']!.setErrors(null);
        this.serviceAddressFormGroup.controls['serviceState']!.setErrors(null);
    }

    public resetRecipientAddressListFormErrors(){
        this.recipientAddressFormGroup.controls['locationName']!.setErrors(null);
        this.recipientAddressFormGroup.controls['locationAddress']!.setErrors(null);
        this.recipientAddressFormGroup.controls['locationCity']!.setErrors(null);
        this.recipientAddressFormGroup.controls['locationZip']!.setErrors(null);
        this.recipientAddressFormGroup.controls['locationState']!.setErrors(null);
    }

    public resetRequestNotesForm() {
        this.fees.advanceFee = false;
        this.requestNotesFormGroup.reset(REQUEST_NOTES);
    }

    public clearForm() {
        this.resetServiceForm();
        this.resetAccountForm();
        this.resetCategoryForm();
        this.resetCourtForm();
        this.resetDeliveryDetailForm();
        this.resetPickupDetailForm();
        this.resetServiceDetailForm();
        this.resetToServeFormGroup(); 
        this.resetServiceAddressForm();
        this.resetCaseForm();
        this.resetRequestNotesForm();
        this.resetFileForm();

        if(this.formStepper)
            this.formStepper.selectedIndex = 0;
    }

    public onServiceChanged() {
        const serviceControl = this.serviceFormGroup.get('service');
        if(!serviceControl || serviceControl.value == "") return;

        if(serviceControl.value != EDISCOVERY){
            this.matterServiceOptions = [];
            this.hideClientMatter = false;
        }

        let serviceWithCategories = this.orderServiceService.services.value.find((s) => s.service.name == serviceControl.value);
        this.service = serviceWithCategories?.service;
        // Modified by jbethke 2021-08-10 - Links moved, so just force de-selecting the service
        if(serviceControl.value == DEPOSITIONS || serviceControl.value == RECORD_RETRIEVAL) {
            serviceControl.setValue(ServiceTypes.COURT_PROCESS);
            return;
        }
    }

    public onTypeFocus(event: boolean) {
        console.log(' on type focus', event);
        // panel closed, do nothing
        if(!event) return;

        const categoryControl = this.categoryFormGroup.get('category');
        const typeControl = this.categoryFormGroup.get('type');

        if(!categoryControl || !typeControl || categoryControl.value == "") return;

        let category : ServiceCategoryWithTypes | undefined = this.categories.find((c) => c.category.name == categoryControl.value);
        // ensure a category is selected
        if(!category) return;
        
        // user has started filling in details beyond the serviceType selection step
        let dirtyForm : boolean = (!!this.files.length || this.fileFormGroup.dirty || this.caseFormGroup.dirty || this.selectCaseControl.dirty);
        // clear out the existing groupedTypes, so the correct select displays
        this.groupedTypes = [];
        // if the category has more than one subCategory build a groupedTypes array to use on the select instead
        if(category.subCategories && category.subCategories.length > 1) {
            let groups = category.subCategories!.map((sc) => {
                let types = category?.types
                    .filter((t) => { 
                        if(!dirtyForm) return (!t.backendUseOnly && t.filingServiceCategory_uuid == sc.uuid);

                        return (!t.backendUseOnly && t.filingServiceCategory_uuid == sc.uuid && t.name != ServiceTypes.EFILE_DIRECT_TYPE)
                    }) || [];

                return {
                    group: sc,
                    types: types
                };
            });

            this.groupedTypes = groups;
        }
        // have to map the types as new ServiceTypes because for some reason they don't match
        this.types = category.types
            .filter((t) => { 
                if(!dirtyForm) return !t.backendUseOnly;

                return (!t.backendUseOnly && t.name != ServiceTypes.EFILE_DIRECT_TYPE)
            })
            .map((t) => new ServiceType(t)); 
        
    }

    public onCourtServiceStateSelect(event: MatSelectChange) {
        let selectedValue = event.value;
        let state: SuccessState | undefined = this.allStates.find(s => s.stateAbbreviation === selectedValue);
        if (state) {
            this.selectableCounties$ = this.countyService.getByStateUuid(state.uuid);
        }

        this.selectedState$.next(selectedValue);
        this.selectedCounty$.next('');
    }

    public onCourtServiceCountySelect(event: MatSelectChange) {
        let value = event.value;
        this.selectedCounty$.next(value);
    }

    public onEFileCourtStateSelect() {
        const stateControl = this.courtFormGroup.get('courtState');
        const nameControl = this.courtFormGroup.get('courtName');

        if (nameControl) {
            nameControl.setValue('');
        }

        this.courtFormGroup.get('courtStreet')?.setValue('Electronic Filing');
        this.courtFormGroup.get('courtCity')?.setValue('eFiling');
        if (!stateControl || !stateControl.value) {
            stateControl?.setValue(this.accountDefaultState);
        }

        this.selectedState$.next(stateControl?.value);

        let state: SuccessState | undefined = this.allStates.find(s => s.stateAbbreviation === stateControl?.value);
        if (state) {
            this.selectableCounties$ = this.countyService.getByStateUuid(state.uuid, true);
        }        
    }

    public onEFileCourtCountySelect() {
        this.courtFormGroup.get('courtStreet')?.setValue('Electronic Filing');
        this.courtFormGroup.get('courtCity')?.setValue('eFiling');

        let selectedStateAbbr = this.selectedState$.value;

        let state: SuccessState | undefined = this.allStates.find(s => s.stateAbbreviation === selectedStateAbbr);
        if (state) {
            this.courtFormGroup.get('courtState')?.setValue(state.stateAbbreviation);
            this.courtFormGroup.get('courtZip')?.setValue(state.zipCodeDatatrac);
        }
    }

    public onCategorySelect() {
        const categoryControl = this.categoryFormGroup.get('category');
        const typeControl = this.categoryFormGroup.get('type');

        if(!categoryControl || !typeControl || categoryControl.value == "") return;

        let category : ServiceCategoryWithTypes | undefined = this.categories.find((c) => c.category.name == categoryControl.value);

        if(!category) return;

        if(category.category.displayName == 'Other') {
            this.categoryFormGroup.get('serviceCategoryDescription')?.setValidators(Validators.required);
        } else {
            this.categoryFormGroup.get('serviceCategoryDescription')?.clearValidators();
        }
        this.categoryFormGroup.get('serviceCategoryDescription')?.updateValueAndValidity();
        
        // grab the last type before we change this.types, so we can check the pickupAddressRequired flag for it
        let lastType = this.types.find((t) => {return t.uuid == typeControl.value});
        // the pickup detail form was populated previously with default data, so we need to reset it
        // DEV NOTE: lastType must be defined, and lastType.pickupAddressRequired must be false or we might get false positives
        if(lastType && lastType.pickupAddressRequired === false) {
            this.resetPickupDetailForm();
        } 
        // else the user may have already populated the pickup data, we should leave the form alone

        // clear out the old data from the type
        typeControl.reset('');

        // manually call onTypeFocus() to initialize the types or else the onTypeFocus doesn't trigger
        this.onTypeFocus(true);

        // let initialType = this.types.find(c => c.isDefault == true);
        // console.log(initialType) // TEST
        // if(initialType) {
        //     this.categoryFormGroup.get('type')?.patchValue(initialType.uuid);
        //     this.onTypeSelect();
        // }
        // EDiscovery swap out current trial requirement selections for type
        this.eDiscoveryServiceSelectControl.reset();
        this.matterServiceOptions = [];
        typeControl.enable();
    }

    public setPickupAddress() {
        // console.log('selected account', this.selectAccount.value)
        // console.log('accounts', this.externalAccounts);

        if(!this.selectAccount) return;

        let account = this.externalAccounts.find((a) => a.accountNumber == this.selectAccount.value);

        // might need to throw error
        if(!account) return;

        let selectedState = (account.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.pickupDetailFormGroup.get('pickupCompany')?.setValue((account.name ?? '').trim()); 
        this.pickupDetailFormGroup.get('pickupContact')?.setValue((account.attention ?? '').trim());
        this.pickupDetailFormGroup.get('pickupStreet')?.setValue((account.street ?? '').trim());
        this.pickupDetailFormGroup.get('pickupSuite')?.setValue((account.room ?? '').trim());
        this.pickupDetailFormGroup.get('pickupCity')?.setValue((account.city ?? '').trim());
        if(state)
            this.pickupDetailFormGroup.get('pickupState')?.setValue(state.abbreviation);
        this.pickupDetailFormGroup.get('pickupZip')?.setValue((account.zip ?? '').trim());
        this.pickupDetailFormGroup.get('pickupPhone')?.setValue((account.phone ?? '').trim());
        this.pickupDetailFormGroup.get('pickupPhoneExt')?.setValue((account.phoneExt ?? '').trim());

        if(this.serviceName == Services.DIGITAL || this.serviceName == Services.INVESTIGATIONS || this.serviceName == Services.EDISCOVERY){
            if(!account.attention)
                this.pickupDetailFormGroup.get('pickupContact')?.setValue((this.userPermissionService.loggedInUser?.fullName ?? '').trim());
            if(!account.phone){
                this.pickupDetailFormGroup.get('pickupPhone')?.setValue((this.settings.businessPhone || '').trim());
                this.pickupDetailFormGroup.get('pickupPhoneExt')?.setValue((this.settings.businessPhoneExtension || '').trim());
            }
        }
    }

    public setDeliveryAddress() {
        // console.log('selected account', this.selectAccount.value)
        // console.log('accounts', this.externalAccounts);

        if(!this.selectAccount) return;

        let account = this.externalAccounts.find((a) => a.accountNumber == this.selectAccount.value);
        // console.log('account', account);
        // might need to throw error
        if(!account) return;

        let selectedState = (account.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );
        // console.log('state', state);

        if(this.showDeliverTo()) {
            // this.deliveryDetailFormGroup
            this.deliveryDetailFormGroup.get('deliveryCompany')?.setValue((account.name ?? '').trim()); 
            this.deliveryDetailFormGroup.get('deliveryContact')?.setValue((account.attention ?? '').trim());
            this.deliveryDetailFormGroup.get('deliveryStreet')?.setValue((account.street ?? '').trim());
            this.deliveryDetailFormGroup.get('deliverySuite')?.setValue((account.room ?? '').trim());
            this.deliveryDetailFormGroup.get('deliveryCity')?.setValue((account.city ?? '').trim());
            if(state)
                this.deliveryDetailFormGroup.get('deliveryState')?.setValue(state.abbreviation);
            this.deliveryDetailFormGroup.get('deliveryZip')?.setValue((account.zip ?? '').trim());
            this.deliveryDetailFormGroup.get('deliveryPhone')?.setValue((account.phone ?? '').trim());
            this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.setValue((account.phoneExt ?? '').trim());
        } else if (this.showCourt()) {  
            this.courtFormGroup.get('courtName')?.setValue((account.name ?? '').trim());
            this.courtFormGroup.get('courtContact')?.setValue((account.attention ?? '').trim());
            this.courtFormGroup.get('courtStreet')?.setValue((account.street ?? '').trim());
            this.courtFormGroup.get('courtSuite')?.setValue((account.room ?? '').trim());
            this.courtFormGroup.get('courtCity')?.setValue((account.city ?? '').trim());
            if(state)
                this.courtFormGroup.get('courtState')?.setValue(state.abbreviation);
            this.courtFormGroup.get('courtZip')?.setValue((account.zip ?? '').trim());
            this.courtFormGroup.get('courtPhone')?.setValue((account.phone ?? '').trim());
            this.courtFormGroup.get('courtPhoneExt')?.setValue((account.phoneExt ?? '').trim());
            // this.courtFormGroup.get('firstAppearanceFees')?.setValue(this.entity.firstAppearanceFees);
        } else {
            this.serviceAddressFormGroup.get('locationName')?.setValue((account.name ?? '').trim());
            this.serviceAddressFormGroup.get('serviceContact')?.setValue((account.attention ?? '').trim());
            this.serviceAddressFormGroup.get('serviceStreet')?.setValue((account.street ?? '').trim());
            this.serviceAddressFormGroup.get('serviceSuite')?.setValue((account.room ?? '').trim());
            this.serviceAddressFormGroup.get('serviceCity')?.setValue((account.city ?? '').trim());
            if(state)
                this.serviceAddressFormGroup.get('serviceState')?.setValue(state.abbreviation);
            this.serviceAddressFormGroup.get('serviceZip')?.setValue((account.zip ?? '').trim());
            this.serviceAddressFormGroup.get('servicePhone')?.setValue((account.phone ?? '').trim());
            this.serviceAddressFormGroup.get('servicePhoneExt')?.setValue((account.phoneExt ?? '').trim());
        }
    }

    public onTypeSelect() {
        const serviceControl = this.serviceFormGroup.get('service');
        const categoryControl = this.categoryFormGroup.get('category');
        const requirementControl = this.categoryFormGroup.get('additionalRequirements')

        requirementControl?.setValidators([]);
        this.draftDisabled = false;
        // const typeControl = this.categoryFormGroup.get('type');
        let index = this.acceptedFileTypes.findIndex((type) => {return type[1] == 'JPEG'})
        if (index != -1) {this.acceptedFileTypes.splice(index, 1);}
        if(!serviceControl || !categoryControl) return;

        // set the documents required flag based on the service type 
        // Currently only these serviceTypes require documents for submission.
        //      COURT - PDF COURTESRY DELIVERY [5], 
        //      MESSENGER - PDF COURTESY DELIVERY [5], 
        //      COURT - FAX/PDF FILING [12], 
        //      EFILE - EFILE [76]
        // 
        // See https://firstlegal.atlassian.net/wiki/spaces/SCS/pages/1629716485/Service+Type+Chart
        let typeUUID = this.categoryFormGroup.controls['type'].value;
        if(typeUUID) {
            let serviceType = this.serviceType_byUuid(typeUUID);
            // console.log('serviceType', serviceType);
            if(serviceType) {
                this.documentsRequired = serviceType.documentsRequired;
                this.pickupRequired = serviceType.pickupAddressRequired;
                this.deliveryRequired = serviceType.deliveryAddressRequired;
                if(this.documentsRequired){
                    this.fileFormGroup.setValidators(this.fileFormGroupValidator());
                }
            }

            // because datatrac requires a pickup address, we need set the default one [SCS-737]
            if(!this.pickupRequired)
                this.setPickupAddress();

            // console.log('show pickup step', this.pickupRequired);
            this.showDocumentsStep = (this.documentsRequired != ServiceTypeDocsRequired.None);
        
            // becasuse we changed the documentsRequired flag, force the fields to call their validators again
            this.fileFormGroup.get('documents')?.updateValueAndValidity();
            this.fileFormGroup.get('description')?.updateValueAndValidity();    
        }

        if(serviceControl.value == ServiceTypes.COURT_PROCESS) {
            if(categoryControl.value == ServiceTypes.SERVICE_OF_PROCESS) {
                this.serviceDetailFormGroup = this.serviceAddressControlGroup;

                // initialize the court filter observable
                this.filteredCourtAddresses = this.selectServiceCourt.valueChanges.pipe(
                    startWith(''),
                    map(value => this._courtFilter(value || ''))
                );
                
                // initalize the user address filter observable
                this.filteredUserAddresses = this.selectServiceAddress.valueChanges.pipe(
                    startWith(''),
                    map(value => this._userAddressFilter(value || ''))
                );

                this.filteredServiceState = this.serviceAddressFormGroup.get('serviceState')?.valueChanges.pipe(
                    startWith(''),
                    map(value => this._stateFilter(value || ''))
                );
                this.serviceAddressFormGroup.statusChanges.subscribe((result)=>{this.serviceAddressControlGroup.updateValueAndValidity()});
                this.serviceAddressControlGroup.setValidators(this.serviceAddressesValidator());
                this.serviceAddressControlGroup.updateValueAndValidity();
                this.serviceAddressFormGroup.get('serviceState')?.setValidators(this.stateValidator());

            } else if (((categoryControl.value == ServiceTypes.MESSENGER && this.typeName != ServiceTypes.PDF_COURTESY_DELIVERY_TYPE) 
                    || categoryControl.value == ServiceTypes.RESEARCH )){
                
                this.serviceDetailFormGroup = this.deliveryDetailFormGroup;
                
                // initialize the court filter observable
                this.filteredCourtAddresses = this.selectDeliveryCourtControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._courtFilter(value || '')),
                );
                
                // initalize the user address filter observable
                this.filteredUserAddresses = this.selectDeliveryAddressControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._userAddressFilter(value || ''))
                );

                this.filteredDeliveryState = this.deliveryDetailFormGroup.get('deliveryState')?.valueChanges.pipe(
                    startWith(''),
                    map(value => this._stateFilter(value || ''))
                );
                
                this.deliveryDetailFormGroup.get('deliveryState')?.setValidators([Validators.required, this.stateValidator()]);
            } else {
                this.serviceDetailFormGroup = this.courtFormGroup;

                // initialize the court filter observable
                let arr = [this.selectCourtControl.valueChanges.pipe(startWith('')), this.selectedState$.pipe(startWith('')), this.selectedCounty$.pipe(startWith(''))];
                this.filteredCourtAddresses = combineLatest(arr).pipe(
                    map((values) =>this._courtFilterArr(values[0], values[1], values[2]))
                );

                this.selectedState$.next(this.accountDefaultState || '');
                let stateEntity = this.allStates.find(s => s.stateAbbreviation === this.accountDefaultState);
                if (categoryControl.value == ServiceTypes.EFILE) {
                    this.selectableStates$ = new BehaviorSubject(this.allStates.filter(s => s.isEFileAvailable))
                    if (stateEntity) {
                        if (stateEntity.isEFileAvailable) {
                            this.selectableCounties$ = this.countyService.getByStateUuid(stateEntity.uuid, true);
                        } else {
                            this.selectedState$.next('');
                        }
                    }
                }
                else {
                    this.selectableStates$ = new BehaviorSubject(this.allStates);
                    if (stateEntity) {
                        this.selectableCounties$ = this.countyService.getByStateUuid(stateEntity.uuid);
                    }
                }

                this.filteredCourtState = this.courtFormGroup.get('courtState')?.valueChanges.pipe(
                    startWith(''),
                    map(value => this._stateFilter(value || ''))
                );

                this.filteredCourtCounties = this.courtFormGroup.get('courtCounty')?.valueChanges.pipe(
                    startWith(''),
                    map(value => this._countyFilter(value || ''))
                );
                
                this.courtFormGroup.get('courtState')?.setValidators([Validators.required, this.stateValidator()]);
            }
        } else if(serviceControl.value == Services.EDISCOVERY){
            const requirementControl = this.categoryFormGroup.get('additionalRequirements')
            requirementControl?.setValidators([this.eDiscoveryValidator()]);
            requirementControl?.updateValueAndValidity();
            // initialize the court filter observable
            this.filteredCourtAddresses = this.selectEDiscoveryCourt.valueChanges.pipe(
                startWith(''),
                map(value => this._courtFilter(value || '')),
            );
            
            // initalize the user address filter observable
            this.filteredUserAddresses = this.selectEDiscoveryAddress.valueChanges.pipe(
                startWith(''),
                map(value => this._userAddressFilter(value || ''))
            );

            this.filteredDeliveryState = this.eDiscoveryTrialFormGroup.get('eDiscoveryState')?.valueChanges.pipe(
                startWith(''),
                map(value => this._stateFilter(value || ''))
            );


            if(categoryControl.value == ServiceTypes.EDISCOVERY) {
                this.filteredTimeZone = this.eDiscoveryTrialFormGroup.get('trialTimeZone')?.valueChanges.pipe(
                    startWith(''),
                    map(value => this._timeZoneFilter(value || ''))
                )
    
                this.eDiscoverySendTrialInfo = false;
                this.eDiscoveryTrialFormGroup.get('trialTimeZone')?.setValidators([]);
                this.eDiscoveryTrialFormGroup.get('trialTimeZone')?.updateValueAndValidity();
                this.serviceDetailFormGroup = this.eDiscoveryTrialFormGroup;
                if(this.typeName == 'NEW MATTER')
                    this.onHideClientMatter(true);
                else
                    this.onHideClientMatter(false);            
                this.matterServiceOptions = [
                    {key:"Litigation Hold Consultation", value:'litigationHoldConsultation'},
                    {key:"ESI Policy Consultation", value:'ESIPolicyConsultation'},
                    {key:"Data Collection and Forensics", value:'dataCollectionAndForensics'},
                    {key:"Early Data or Case Assessment", value:'earlyDataOrCaseAssessment'},
                    {key:"Data Processing and Hosting", value:'dataProcessingAndHosting'},
                    {key:"Document Review and Analysis", value:'documentReviewAndAnalysis'},
                    {key:"Data and Document Production", value:'dataAndDocumentProdduction'},
                    {key:"Trial Services", value:'trialServices'},
                    {key:"Other", value:'other'}
                ];
            } else if(categoryControl.value == ServiceTypes.TRIALSERVICES){
                this.filteredTimeZone = this.trialServicesFormGroup.get('trialTimeZone')?.valueChanges.pipe(
                    startWith(''),
                    map(value => this._timeZoneFilter(value || ''))
                )
    
                this.eDiscoverySendTrialInfo = true;
                this.trialServicesFormGroup.get('trialTimeZone')?.setValidators([this.timeZoneValidator()]);
                this.trialServicesFormGroup.get('trialTimeZone')?.updateValueAndValidity();
                this.serviceDetailFormGroup = this.trialServicesFormGroup;
                this.onHideClientMatter(false);
                this.matterServiceOptions = [
                    {key:"Pre-trial Consultation", value:'preTrialConsultation'},
                    {key:"Court Reporter", value:'courtReporter'},
                    {key:"Trial Technicians", value:'trialTechnicians'},
                    {key:"War Room Setup", value:'warRoomSetup'},
                    {key:"Trial Equipment Rental", value:'trialEquipmentRental'},
                    {key:"Trial Binders", value:'trialBinders'},
                    {key:"Demonstratives", value:'demonstratives'},
                    {key:"Audio/Video Clipping/Editing/Syncing", value:'audioOrVideo'},
                    {key:"3D Animations", value:'animations3D'},
                    {key:"Visual Presentations (PowerPoint, Posterboards, etc)", value:'visualPresentations'},
                    {key:"Review Platform (Trial Director, OnCue, Relativity, etc)", value:'reviewPlatform'},
                    {key:"Other", value:'other'}
                ];
            }
        } else if(serviceControl.value == Services.DIGITAL){
            const requirementControl = this.categoryFormGroup.get('additionalRequirements')
            requirementControl?.setValidators([]);
            requirementControl?.updateValueAndValidity();

            // initialize the court filter observable
            this.filteredCourtAddresses = this.selectDigitalCourt.valueChanges.pipe(
                startWith(''),
                map(value => this._courtFilter(value || '')),
            );
            
            // initalize the user address filter observable
            this.filteredUserAddresses = this.selectDigitalAddress.valueChanges.pipe(
                startWith(''),
                map(value => this._userAddressFilter(value || ''))
            );

            this.filteredDeliveryState = this.recipientAddressFormGroup.get('locationState')?.valueChanges.pipe(
                startWith(''),
                map(value => this._stateFilter(value || ''))
            );

            this.filteredTimeZone = this.digitalAddressesFormGroup.get('deadlineTimeZone')?.valueChanges.pipe(
                startWith(''),
                map(value => this._timeZoneFilter(value || ''))
            )

            this.digitalAddressesFormGroup.controls['recipientAddresses'].setValidators([]);
            let stateControl = this.digitalAddressesFormGroup.controls['addressState'];
            if(categoryControl.value == ServiceTypes.SCANNING){
                stateControl.setValidators([]);
                this.recipientAddressFormGroup.addValidators(this.digitalValidator());
                this.recipientAddressFormGroup.updateValueAndValidity();
                this.showDocumentsStep = false;
                if(this.typeName == 'INHOUSE'){
                    this.pickupRequired = true;
                }else if(this.typeName == 'ONSITE'){
                    this.pickupRequired = false;
                }
            } else if (categoryControl.value == ServiceTypes.MAILOUT){
                stateControl.setValidators([Validators.requiredTrue]);
                this.recipientAddressFormGroup.addValidators(this.digitalValidator());
                this.recipientAddressFormGroup.statusChanges.subscribe((result)=>{
                    this.serviceDetailFormGroup.updateValueAndValidity();
                }); 
                this.showDocumentsStep = true;
                this.showRecipientAddressList = true;
            } else {
                stateControl.setValidators([]);
                this.recipientAddressFormGroup.addValidators(this.digitalValidator());
                this.recipientAddressFormGroup.updateValueAndValidity();
            }
            if(this.digitalDeliveryOnly)
                this.serviceDetailFormGroup = this.digitalDeliveryFormGroup;
            else
                this.serviceDetailFormGroup = this.digitalAddressesFormGroup;

        } else if(serviceControl.value == Services.INVESTIGATIONS){
            // initialize the court filter observable
            this.filteredCourtAddresses = this.selectInvestigationCourt.valueChanges.pipe(
                startWith(''),
                map(value => this._courtFilter(value || '')),
            );
            
            // initalize the user address filter observable
            this.filteredUserAddresses = this.selectInvestigationAddress.valueChanges.pipe(
                startWith(''),
                map(value => this._userAddressFilter(value || ''))
            );

            this.filteredDeliveryState = this.recipientAddressFormGroup.get('locationState')?.valueChanges.pipe(
                startWith(''),
                map(value => this._stateFilter(value || ''))
            );

            this.filteredTimeZone = this.digitalAddressesFormGroup.get('deadlineTimeZone')?.valueChanges.pipe(
                startWith(''),
                map(value => this._timeZoneFilter(value || ''))
            )
            
            if(this.categoryName == ServiceTypes.SKIPTRACE){
                this.serviceDetailFormGroup = this.skipTraceFormGroup;
            }

            else if(this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION){
                let validators: ValidatorFn[] = [];
                if(this.categoryName != ServiceTypes.SUBJECT_INFORMATION){
                    validators = [Validators.required]
                }
                this.alternateInvestigationsFormGroup.get('locationName')?.setValidators(validators);
                this.alternateInvestigationsFormGroup.get('locationName')?.updateValueAndValidity();
                this.alternateInvestigationsFormGroup.get('locationAddress')?.setValidators(validators);
                this.alternateInvestigationsFormGroup.get('locationAddress')?.updateValueAndValidity();
                this.alternateInvestigationsFormGroup.get('locationCity')?.setValidators(validators);
                this.alternateInvestigationsFormGroup.get('locationCity')?.updateValueAndValidity();
                this.alternateInvestigationsFormGroup.get('locationState')?.setValidators(validators);
                this.alternateInvestigationsFormGroup.get('locationState')?.updateValueAndValidity();
                this.alternateInvestigationsFormGroup.get('locationZip')?.setValidators(validators);
                this.alternateInvestigationsFormGroup.get('locationZip')?.updateValueAndValidity();
                this.alternateInvestigationsFormGroup.updateValueAndValidity();
                this.serviceDetailFormGroup = this.alternateInvestigationsFormGroup;
            }

            else if(this.categoryName == ServiceTypes.SUB_ROSA){
                let ind = this.acceptedFileTypes.findIndex((type) => {return type[1] == 'JPEG'})
                if(ind == -1)
                    this.acceptedFileTypes.push(['image/jpeg','JPEG']);
                this.serviceDetailFormGroup = this.subrosaFormGroup;
            }
        }
    }

    public timeZoneOffset(timeZone:string) {
        switch(timeZone){
            case "America/Anchorage": return TimeZoneOffset.Alaska;
            case "America/Chicago": return TimeZoneOffset.Central;
            case "America/New York": return TimeZoneOffset.Eastern;
            case "Pacific/Honolulu": return TimeZoneOffset.HawaiiAleutian;
            case "America/Denver": return TimeZoneOffset.Mountain;
            case "America/Los Angeles": return TimeZoneOffset.Pacific;
            case TimeZoneOffset.Alaska: return "America/Anchorage";
            case TimeZoneOffset.Central: return "America/Chicago";
            case TimeZoneOffset.Eastern: return "America/New York";
            case TimeZoneOffset.HawaiiAleutian: return "Pacific/Honolulu";
            case TimeZoneOffset.Mountain: return "America/Denver";
            case TimeZoneOffset.Pacific: return "America/Los Angeles";
            default:
                return "Missing Time Zone";
        }
    }

    public serviceType_byUuid(uuid: string) : ServiceType | undefined {
        return this.types.find((t) => t.uuid == uuid);
    }

    public onCaseChange() {
        const selectCaseControl = this.selectCaseControl;
        this.caseFormGroup.get('caseName')?.setValue(selectCaseControl.value);

        // If a user selects a case from the auto complete list, then later edits the input field
        // we need to clear out the selectedCase value because it is no longer the selected case.
        // It should instead be treated like a new case, so we should also clear the caseNumber.
        if(selectCaseControl.value != this.selectedCase?.name) {
            this.selectedCase = undefined;
            this.caseFormGroup.get('caseNumber')?.reset('');
        }
    }

    public onCaseSelect() {
        const control = this.selectCaseControl;
        
        if(control && control.value) {
            this.selectedCase = this.cases.find((successCase) => successCase.id == control!.value);
            
            // if case not found, assume they are entering a new case and set the caseName control
            if(!this.selectedCase) {
                this.caseFormGroup.get('caseName')?.setValue(control.value);
                return;
            }

            // user selected an existing case set the caseName and caseNumber from the select case values
            control.setValue(this.selectedCase.name); // set the control so input display is updated
            // update form data that gets submitted
            this.caseFormGroup.get('caseName')?.setValue(this.selectedCase.name);
            this.caseFormGroup.get('caseNumber')?.setValue(this.selectedCase.caseNumber);
        }
    }

    public onClientMatterChange() {

        const selectClientMatterControl = this.selectClientMatterControl;
        this.categoryFormGroup.get('clientMatterNumber')?.setValue(selectClientMatterControl.value);

        this.selectedClientMatterNumber = selectClientMatterControl.value || undefined;
    }

    public onClientMatterSelect() {
        const control = this.selectClientMatterControl;
        
        if(control && control.value) {
            this.selectedClientMatterNumber = control!.value;
            this.categoryFormGroup.get('clientMatterNumber')?.setValue(this.selectedClientMatterNumber);
        }
    }


    public onDigitalCourtSelect() {
        const control = this.selectDigitalCourt;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.recipientAddressFormGroup.get('selectedAddress')?.setValue(control.value);
            let selectedAddress = this.courtAddresses.find((address) => address.id == control.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.recipientAddressFormGroup.get('locationName')?.setValue(selectedAddress.name);
            this.recipientAddressFormGroup.get('locationAddress')?.setValue(selectedAddress.streetAddress1);
            this.recipientAddressFormGroup.get('locationCity')?.setValue(selectedAddress.city);
            if(state)
                this.recipientAddressFormGroup.patchValue({'locationState': state.abbreviation});
            this.recipientAddressFormGroup.get('locationZip')?.setValue(selectedAddress.postalCode);
            this.recipientAddressFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public onDigitalAddressSelect() {
        const control = this.selectDigitalAddress;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.recipientAddressFormGroup.get('selectedAddress')?.setValue(control.value);
            let selectedAddress = this.userAddresses.find((address) => address.id == control!.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );

            this.recipientAddressFormGroup.get('locationName')?.setValue(selectedAddress.name);
            this.recipientAddressFormGroup.get('locationAddress')?.setValue(selectedAddress.streetAddress1);
            this.recipientAddressFormGroup.get('locationCity')?.setValue(selectedAddress.city);
            if(state)
                this.recipientAddressFormGroup.patchValue({'locationState': state.abbreviation});
            this.recipientAddressFormGroup.get('locationZip')?.setValue(selectedAddress.postalCode);
            this.recipientAddressFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public addRecipient() {
        let invalid = false;

        const locationNameControl = this.recipientAddressFormGroup.get('locationName')
        const locationAddressControl = this.recipientAddressFormGroup.get('locationAddress')
        const locationCityControl = this.recipientAddressFormGroup.get('locationCity')
        const locationStateControl = this.recipientAddressFormGroup.get('locationState')
        const locationZipControl = this.recipientAddressFormGroup.get('locationZip')

        if(!locationNameControl || !locationNameControl.value) {
            locationNameControl?.setErrors({required: true});
            invalid = true;
        }

        if(!locationAddressControl || !locationAddressControl.value) {
            locationAddressControl?.setErrors({required: true});
            invalid = true;
        }

        if(!locationCityControl || !locationCityControl.value) {
            locationCityControl?.setErrors({required: true});
            invalid = true;
        }
        
        if(!locationStateControl || !locationStateControl.value) {
            locationStateControl?.setErrors({required: true});
            invalid = true;
        }
        
        // extra check to make sure the state matches with one from the list
        if(!locationZipControl || locationZipControl.hasError('invalid')) {
            invalid = true;
        }

        // guard for bad values
        if(invalid) {
            return;
        }

        let recipient = {
            locationName: locationNameControl?.value,
            locationAddress: locationAddressControl?.value,
            locationCity: locationCityControl?.value,
            locationState: locationStateControl?.value,
            locationZip: locationZipControl?.value
        }

        let arg : any[] = this.digitalAddressesFormGroup.controls['recipientAddresses'].value;
        arg.push(recipient);
        this.digitalAddressesFormGroup.controls['recipientAddresses'].setValue(arg);
        locationNameControl?.reset();
        locationAddressControl?.reset();
        locationCityControl?.reset();
        locationStateControl?.reset();
        locationZipControl?.reset();
    }

    public removeRecipient(index:number) {
        let arg : any[] = this.digitalAddressesFormGroup.controls['recipientAddresses'].value;
        arg.splice(index,1);
        this.digitalAddressesFormGroup.controls['recipientAddresses'].setValue(arg);
        if(arg.length == 0){
            this.digitalAddressesFormGroup.controls['addressState'].setValue(null);
            Object.entries(this.recipientAddressFormGroup.controls).map((control)=>{
                control[1].setValue(null);
                control[1].updateValueAndValidity();
            })
            this.recipientAddressFormGroup.updateValueAndValidity();
            this.digitalAddressesFormGroup.updateValueAndValidity();
        }
    }

    // EDiscovery
    public onHideClientMatter(event:boolean) {
        if(event){
            this.hideClientMatter = true;
            let val = this.categoryFormGroup.get('clientMatterNumber')?.value
            this.categoryFormGroup.get('clientMatterNumber')?.setValue(val ? val : 'New Client Matter');
            this.selectClientMatterControl.setValue('New Client Matter');
        }else{
            this.hideClientMatter = false;
            let val = this.categoryFormGroup.get('clientMatterNumber')?.value
            if(val == 'New Client Matter') val = ''
            this.categoryFormGroup.get('clientMatterNumber')?.setValue(val ? val : '');
            this.selectClientMatterControl.setValue(val ? val : '');
        }
        this.categoryFormGroup.get('clientMatterNumber')?.updateValueAndValidity();
        this.selectClientMatterControl.updateValueAndValidity();
    }

    public eDiscoveryServiceChange(){
        this.categoryFormGroup.controls['additionalRequirements'].updateValueAndValidity();
        this.selectClientMatterControl.updateValueAndValidity();
        if(this.eDiscoveryServiceSelectControl.value && this.eDiscoveryServiceSelectControl.value.includes('other')){
            this.serviceOtherShow = true;
        }else{
            this.serviceOtherShow = false;
        }
    }


    public setEDiscoveryAddress() {
        if(!this.selectAccount) return;

        let account = this.externalAccounts.find((a) => a.accountNumber == this.selectAccount.value);
        // might need to throw error
        if(!account) return;

        let selectedState = (account.state || '').trim();
        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany')?.setValue((account.name ?? '').trim()); 
        this.eDiscoveryTrialFormGroup.get('eDiscoveryContact')?.setValue((account.attention ?? '').trim());
        this.eDiscoveryTrialFormGroup.get('eDiscoveryStreet')?.setValue((account.street ?? '').trim());
        this.eDiscoveryTrialFormGroup.get('eDiscoverySuite')?.setValue((account.room ?? '').trim());
        this.eDiscoveryTrialFormGroup.get('eDiscoveryCity')?.setValue((account.city ?? '').trim());
        if(state)
            this.eDiscoveryTrialFormGroup.get('eDiscoveryState')?.setValue(state.abbreviation);
        this.eDiscoveryTrialFormGroup.get('eDiscoveryZip')?.setValue((account.zip ?? '').trim());
        this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone')?.setValue((account.phone ?? '').trim());
        this.eDiscoveryTrialFormGroup.get('eDiscoveryPhoneExt')?.setValue((account.phoneExt ?? '').trim());
    }


    public onEDiscoveryCourtSelect() {
        const control = this.selectEDiscoveryCourt;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.eDiscoveryTrialFormGroup.get('selectedAddress')?.setValue(control.value);
            let selectedAddress = this.courtAddresses.find((address) => address.id == control.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany')?.setValue(selectedAddress.name);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryContact')?.setValue(selectedAddress.careOfLine);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryStreet')?.setValue(selectedAddress.streetAddress1);
            this.eDiscoveryTrialFormGroup.get('eDiscoverySuite')?.setValue(selectedAddress.streetAddress2);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryCity')?.setValue(selectedAddress.city);
            if(state)
                this.eDiscoveryTrialFormGroup.patchValue({'eDiscoveryState': state.abbreviation});
            this.eDiscoveryTrialFormGroup.get('eDiscoveryZip')?.setValue(selectedAddress.postalCode);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone')?.setValue((selectedAddress.primaryPhoneNumber || '').trim());
            this.eDiscoveryTrialFormGroup.get('eDiscoveryPhoneExt')?.setValue(selectedAddress.phoneExt);
            this.eDiscoveryTrialFormGroup.updateValueAndValidity();

            this.eDiscoveryLookupOpen = false;
        }

        control?.reset('');
    }

    public onEDiscoveryAddressSelect() {
        const control = this.selectEDiscoveryAddress;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.eDiscoveryTrialFormGroup.get('selectedAddress')?.setValue(control.value);
            let selectedAddress = this.userAddresses.find((address) => address.id == control!.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );

            this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany')?.setValue(selectedAddress.name);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryContact')?.setValue(selectedAddress.careOfLine);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryStreet')?.setValue(selectedAddress.streetAddress1);
            this.eDiscoveryTrialFormGroup.get('eDiscoverySuite')?.setValue(selectedAddress.streetAddress2);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryCity')?.setValue(selectedAddress.city);
            if(state)
                this.eDiscoveryTrialFormGroup.patchValue({'eDiscoveryState': state.abbreviation});
            this.eDiscoveryTrialFormGroup.get('eDiscoveryZip')?.setValue(selectedAddress.postalCode);
            this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone')?.setValue((selectedAddress.primaryPhoneNumber || '').trim());
            this.eDiscoveryTrialFormGroup.get('eDiscoveryPhoneExt')?.setValue(selectedAddress.phoneExt);
            this.eDiscoveryTrialFormGroup.updateValueAndValidity();

            this.eDiscoveryLookupOpen = false;
        }

        control?.reset('');
    }


    public onEDiscoveryTrialCourtSelect() {
        const control = this.selectEDiscoveryCourt;

        if(control && control.value) {
            // set the id of the selected address for use later 
            let selectedAddress = this.courtAddresses.find((address) => address.id == control.value);
            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            if(this.categoryName == ServiceTypes.EDISCOVERY){
                this.eDiscoveryTrialFormGroup.get('courtName')?.setValue(selectedAddress.name);
                this.eDiscoveryTrialFormGroup.get('courtLocation')?.setValue(selectedAddress.streetAddress1);
                this.eDiscoveryTrialFormGroup.updateValueAndValidity();
            } else {
                this.trialServicesFormGroup.get('courtName')?.setValue(selectedAddress.name);
                this.trialServicesFormGroup.get('courtLocation')?.setValue(selectedAddress.streetAddress1);
                this.trialServicesFormGroup.updateValueAndValidity();
            }
            this.eDiscoveryLookupOpen = false;
        }
        control?.reset('');
    }

    public onInvestigationsAddressSelect(type?: string) {
        const control = this.selectInvestigationAddress;
        let formGroup : any;

        if(this.categoryName == ServiceTypes.SUB_ROSA){
            formGroup = this.subrosaFormGroup;
        } else if(this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION){
            formGroup = this.alternateInvestigationsFormGroup;
        } else if(this.categoryName == ServiceTypes.SKIPTRACE){
            if(type == 'lastKnownAddress'){
                formGroup = this.lastKnownAddressesFormGroup;
            } else {
                formGroup = this.skipTraceFormGroup;
            }
        }

        if(control && control.value) {
            let selectedAddress = this.userAddresses.find((address) => address.id == control!.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            if(this.categoryName == ServiceTypes.SUB_ROSA){
                if(!type)
                    type = 'home';
                formGroup.get(type + 'Name')?.setValue(selectedAddress.name);
                formGroup.get(type + 'Address')?.setValue(selectedAddress.streetAddress1);
                formGroup.get(type + 'State')?.setValue(state?.abbreviation);
                formGroup.get(type + 'City')?.setValue(selectedAddress.city);
                formGroup.get(type + 'Zip')?.setValue(selectedAddress.postalCode);
            } else {
                formGroup.get('locationName')?.setValue(selectedAddress.name);
                formGroup.get('locationAddress')?.setValue(selectedAddress.streetAddress1);
                formGroup.get('locationState')?.setValue(state?.abbreviation);
                formGroup.get('locationCity')?.setValue(selectedAddress.city);
                formGroup.get('locationZip')?.setValue(selectedAddress.postalCode);
            }
            formGroup.updateValueAndValidity();
            this.pickupLookupOpen = false;
        }
        control?.reset('');
    }

    public onInvestigationsCourtSelect(type?: string) {
        const control = this.selectInvestigationCourt;
        let formGroup : any;
        if(this.categoryName == ServiceTypes.SUB_ROSA){
            formGroup = this.subrosaFormGroup;
        } else if(this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION){
            formGroup = this.alternateInvestigationsFormGroup;
        } else if(this.categoryName == ServiceTypes.SKIPTRACE){
            if(type == 'lastKnownAddress'){
                formGroup = this.lastKnownAddressesFormGroup;
            } else {
                formGroup = this.skipTraceFormGroup;
            }
            console.log(type)
        }

        if(control && control.value) {
            // set the id of the selected address for use later 
            let selectedAddress = this.courtAddresses.find((address) => address.id == control.value);
            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            if(this.categoryName == ServiceTypes.SUB_ROSA){
                if(!type)
                    type = 'home';
                formGroup.get(type + 'Name')?.setValue(selectedAddress.name);
                formGroup.get(type + 'Address')?.setValue(selectedAddress.streetAddress1);
                formGroup.get(type + 'State')?.setValue(selectedAddress.state);
                formGroup.get(type + 'City')?.setValue(selectedAddress.city);
                formGroup.get(type + 'Zip')?.setValue(selectedAddress.postalCode);
            } else {
                formGroup.get('locationName')?.setValue(selectedAddress.name);
                formGroup.get('locationAddress')?.setValue(selectedAddress.streetAddress1);
                formGroup.get('locationState')?.setValue(selectedAddress.state);
                formGroup.get('locationCity')?.setValue(selectedAddress.city);
                formGroup.get('locationZip')?.setValue(selectedAddress.postalCode);
            }

            formGroup.updateValueAndValidity();
            this.pickupLookupOpen = false;
        }
        control?.reset('');
    }

    public addLastKnownAddresses(){

        let form = this.lastKnownAddressesFormGroup;

        if(!form || !form.valid){
            return;
        }
        let location = {
            locationName: form.controls['locationName'].value,
            address: form.controls['locationAddress'].value,
            city: form.controls['locationCity'].value,
            state: form.controls['locationState'].value,
            zip: form.controls['locationZip'].value
        }
        form.controls['locationName'].reset('');
        form.controls['locationAddress'].reset('');
        form.controls['locationCity'].reset('');
        form.controls['locationState'].reset('');
        form.controls['locationZip'].reset('');
        this.lastKnownAddresses.push(location);
    }

    public removeLink(index:number){
        let links = this.skipTraceFormGroup.get('socialMediaLinks')?.value;
        links.splice(index, 1);
        this.skipTraceFormGroup.get('socialMediaLinks')?.setValue(links);
        this.skipTraceFormGroup.updateValueAndValidity();
    }

    public addLink(){
        if(this.skipTraceMediaFormGroup.get('mediaName')?.value == '' 
        || this.skipTraceMediaFormGroup.get('mediaLink')?.value == ''){
            return;
        }
        let name = this.skipTraceMediaFormGroup.get('mediaName')?.value;
        let link = this.skipTraceMediaFormGroup.get('mediaLink')?.value;

        this.skipTraceMediaFormGroup.get('mediaName')?.reset();
        this.skipTraceMediaFormGroup.get('mediaLink')?.reset();
        this.skipTraceMediaFormGroup.get('mediaName')?.setErrors([]);
        this.skipTraceMediaFormGroup.get('mediaLink')?.setErrors([]);

        let links = this.skipTraceFormGroup.get('socialMediaLinks')?.value;
        links.push({mediaName: name, link: link});
        this.skipTraceFormGroup.get('socialMediaLinks')?.setValue(links);
        this.skipTraceFormGroup.updateValueAndValidity();
    }

    public onServiceCourtSelect() {
        const control = this.selectServiceCourt;

        if(control && control.value) {

            let selectedAddress = this.courtAddresses.find((address) => address.id == control!.value);
            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset('');
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.serviceAddressFormGroup.get('locationName')?.setValue(selectedAddress.name);
            this.serviceAddressFormGroup.get('serviceContact')?.setValue(selectedAddress.careOfLine);
            this.serviceAddressFormGroup.get('serviceStreet')?.setValue(selectedAddress.streetAddress1);
            this.serviceAddressFormGroup.get('serviceSuite')?.setValue(selectedAddress.streetAddress2);
            this.serviceAddressFormGroup.get('serviceCity')?.setValue(selectedAddress.city);
            if(state) 
                this.serviceAddressFormGroup.patchValue({'serviceState': state.abbreviation});
            this.serviceAddressFormGroup.get('serviceZip')?.setValue(selectedAddress.postalCode);
            this.serviceAddressFormGroup.get('servicePhone')?.setValue((selectedAddress.primaryPhoneNumber || '').trim());
            this.serviceAddressFormGroup.get('servicePhoneExt')?.setValue(selectedAddress.phoneExt);
            this.serviceAddressFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public onServiceAddressSelect() {
        const control = this.selectServiceAddress;

        if(control && control.value) {

            let selectedAddress = this.userAddresses.find((address) => address.id == control!.value);
            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset('');
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.serviceAddressFormGroup.get('locationName')?.setValue(selectedAddress.name);
            this.serviceAddressFormGroup.get('serviceContact')?.setValue(selectedAddress.careOfLine);
            this.serviceAddressFormGroup.get('serviceStreet')?.setValue(selectedAddress.streetAddress1);
            this.serviceAddressFormGroup.get('serviceSuite')?.setValue(selectedAddress.streetAddress2);
            this.serviceAddressFormGroup.get('serviceCity')?.setValue(selectedAddress.city);
            if(state) 
                this.serviceAddressFormGroup.patchValue({'serviceState': state.abbreviation});
            this.serviceAddressFormGroup.get('serviceZip')?.setValue(selectedAddress.postalCode);
            this.serviceAddressFormGroup.get('servicePhone')?.setValue((selectedAddress.primaryPhoneNumber || '').trim());
            this.serviceAddressFormGroup.get('servicePhoneExt')?.setValue(selectedAddress.phoneExt);
            this.serviceAddressFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }


    public onPickupCourtSelect() {
        const control = this.selectPickupCourt;

        if(control && control.value) {

            // set the id of the selected address for use later 
            this.pickupDetailFormGroup.get('selectedAddress')?.setValue(control.value);
            let selectedAddress = this.courtAddresses.find((address) => address.id == control.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.pickupDetailFormGroup.get('pickupCompany')?.setValue(selectedAddress.name);
            this.pickupDetailFormGroup.get('pickupStreet')?.setValue(selectedAddress.streetAddress1);
            this.pickupDetailFormGroup.get('pickupSuite')?.setValue(selectedAddress.streetAddress2);
            this.pickupDetailFormGroup.get('pickupCity')?.setValue(selectedAddress.city);
            if(state)
                this.pickupDetailFormGroup.patchValue({'pickupState': state.abbreviation});
            this.pickupDetailFormGroup.get('pickupZip')?.setValue(selectedAddress.postalCode);
            this.pickupDetailFormGroup.get('pickupPhone')?.setValue((selectedAddress.primaryPhoneNumber || '').trim());
            this.pickupDetailFormGroup.updateValueAndValidity();

            // close the lookup pane
            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public onPickupAddressSelect() {
        const control = this.selectPickupAddress;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.pickupDetailFormGroup.get('selectedAddress')?.setValue(control.value);
            let selectedAddress = this.userAddresses.find((address) => address.id == control!.value);

            // make sure an address is found
            if(!selectedAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (selectedAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.pickupDetailFormGroup.get('pickupCompany')?.setValue(selectedAddress.name);
            this.pickupDetailFormGroup.get('pickupStreet')?.setValue(selectedAddress.streetAddress1);
            this.pickupDetailFormGroup.get('pickupSuite')?.setValue(selectedAddress.streetAddress2);
            this.pickupDetailFormGroup.get('pickupCity')?.setValue(selectedAddress.city);
            if(state)
                this.pickupDetailFormGroup.patchValue({'pickupState': state.abbreviation});
            this.pickupDetailFormGroup.get('pickupZip')?.setValue(selectedAddress.postalCode);
            this.pickupDetailFormGroup.get('pickupPhone')?.setValue((selectedAddress.primaryPhoneNumber || '').trim());
            this.pickupDetailFormGroup.updateValueAndValidity();

            // close the lookup pane
            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public onDeliveryCourtChange() {
        const selectDeliveryCourtControl = this.selectDeliveryCourtControl;
        this.deliveryDetailFormGroup.get('deliveryCompany')?.setValue(selectDeliveryCourtControl.value);

        // If a user selects a court from the auto complete list, then later edits the input field
        // we need to clear out the selectedDeliveryCourt value because it is no longer the selected court.
        // It should instead be treated like a new court, so we should also clear the other court fields.
        if(selectDeliveryCourtControl.value != this.selectedDeliveryCourt?.name) {
            this.selectedDeliveryCourt = undefined;
            // this.deliveryDetailFormGroup.get('deliveryContact')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryStreet')?.reset('');
            // this.deliveryDetailFormGroup.get('deliverySuite')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryCity')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryState')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryZip')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryPhone')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.reset('');
        }
    }

    public onDeliveryCourtSelect() {
        const control = this.selectDeliveryCourtControl;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.deliveryDetailFormGroup.get('selectedAddress')?.setValue(control.value);
            this.selectedDeliveryCourt = this.courtAddresses.find((address) => address.id == control.value);

            // make sure an address is found
            if(!this.selectedDeliveryCourt) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (this.selectedDeliveryCourt.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.deliveryDetailFormGroup.get('deliveryCompany')?.setValue(this.selectedDeliveryCourt.name);
            this.deliveryDetailFormGroup.get('deliveryContact')?.setValue(this.selectedDeliveryCourt.careOfLine);
            this.deliveryDetailFormGroup.get('deliveryStreet')?.setValue(this.selectedDeliveryCourt.streetAddress1);
            this.deliveryDetailFormGroup.get('deliverySuite')?.setValue(this.selectedDeliveryCourt.streetAddress2);
            this.deliveryDetailFormGroup.get('deliveryCity')?.setValue(this.selectedDeliveryCourt.city);
            if(state)
                this.deliveryDetailFormGroup.patchValue({'deliveryState': state.abbreviation});
            this.deliveryDetailFormGroup.get('deliveryZip')?.setValue(this.selectedDeliveryCourt.postalCode);
            this.deliveryDetailFormGroup.get('deliveryPhone')?.setValue((this.selectedDeliveryCourt.primaryPhoneNumber || '').trim());
            this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.setValue(this.selectedDeliveryCourt.phoneExt);
            this.deliveryDetailFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public onDeliveryAddressChange() {
        const selectDeliveryAddressControl = this.selectDeliveryAddressControl;
        this.deliveryDetailFormGroup.get('deliveryCompany')?.setValue(selectDeliveryAddressControl.value);

        // If a user selects an address from the auto complete list, then later edits the input field
        // we need to clear out the selectedDeliveryAddress value because it is no longer the selected address.
        // It should instead be treated like a new address, so we should also clear the other address fields.
        if(selectDeliveryAddressControl.value != this.selectedDeliveryCourt?.name) {
            this.selectedDeliveryCourt = undefined;
            // this.deliveryDetailFormGroup.get('deliveryContact')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryStreet')?.reset('');
            // this.deliveryDetailFormGroup.get('deliverySuite')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryCity')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryState')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryZip')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryPhone')?.reset('');
            // this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.reset('');
        }
    }

    public onDeliveryAddressSelect() {
        const control = this.selectDeliveryAddressControl;

        if(control && control.value) {
            // set the id of the selected address for use later 
            this.deliveryDetailFormGroup.get('selectedAddress')?.setValue(control.value);
            this.selectedDeliveryAddress = this.userAddresses.find((address) => address.id == control!.value);

            // make sure an address is found
            if(!this.selectedDeliveryAddress) {
                console.log('WARNING: could not find selected address');
                control.reset();
                return;
            }
            let selectedState = (this.selectedDeliveryAddress.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );

            this.deliveryDetailFormGroup.get('deliveryCompany')?.setValue(this.selectedDeliveryAddress.name);
            this.deliveryDetailFormGroup.get('deliveryContact')?.setValue(this.selectedDeliveryAddress.careOfLine);
            this.deliveryDetailFormGroup.get('deliveryStreet')?.setValue(this.selectedDeliveryAddress.streetAddress1);
            this.deliveryDetailFormGroup.get('deliverySuite')?.setValue(this.selectedDeliveryAddress.streetAddress2);
            this.deliveryDetailFormGroup.get('deliveryCity')?.setValue(this.selectedDeliveryAddress.city);
            if(state)
                this.deliveryDetailFormGroup.patchValue({'deliveryState': state.abbreviation});
            this.deliveryDetailFormGroup.get('deliveryZip')?.setValue(this.selectedDeliveryAddress.postalCode);
            this.deliveryDetailFormGroup.get('deliveryPhone')?.setValue((this.selectedDeliveryAddress.primaryPhoneNumber || '').trim());
            this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.setValue(this.selectedDeliveryAddress.phoneExt);
            this.deliveryDetailFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }

        control?.reset('');
    }

    public onCourtChange() {
        const selectCourtControl = this.selectCourtControl;
        this.courtFormGroup.get('courtName')?.setValue(selectCourtControl.value);

        // If a user selects a court from the auto complete list, then later edits the input field
        // we need to clear out the selectedCourt value because it is no longer the selected case.
        // It should instead be treated like a new court, so we should also clear the other court fields.
        if(selectCourtControl.value != this.selectedCourt?.name) {
            this.selectedCourt = undefined;
            this.courtFormGroup.get('courtCounty')?.reset('');
            this.courtFormGroup.get('courtContact')?.reset('');
            this.courtFormGroup.get('courtStreet')?.reset('');
            this.courtFormGroup.get('courtSuite')?.reset('');
            this.courtFormGroup.get('courtCity')?.reset('');
            this.courtFormGroup.get('courtState')?.reset('');
            this.courtFormGroup.get('courtZip')?.reset('');
            this.courtFormGroup.get('courtPhone')?.reset('');
            this.courtFormGroup.get('courtPhoneExt')?.reset('');
        }
    }

    public onCourtSelect() {
        const control = this.selectCourtControl;

        if(control && control.value) {

            this.selectedCourt = this.courtAddresses.find((address) => address.id == control!.value);

            // make sure an address is found
            if(!this.selectedCourt) {
                console.log('WARNING: could not find selected address');
                return;
            }

            let selectedState = (this.selectedCourt.state || '').trim();
            let state = this.locationService.states.find(
                (s) => (s.name == selectedState || s.abbreviation == selectedState)
            );
            this.selectCourtControl.setValue(this.selectedCourt.name)
            this.courtFormGroup.get('courtName')?.setValue(this.selectedCourt.name);
            this.courtFormGroup.get('courtContact')?.setValue(this.selectedCourt.careOfLine);
            this.courtFormGroup.get('courtStreet')?.setValue(this.selectedCourt.streetAddress1);
            this.courtFormGroup.get('courtSuite')?.setValue(this.selectedCourt.roomNumber);
            this.courtFormGroup.get('courtCity')?.setValue(this.selectedCourt.city);
            if(state)
                this.courtFormGroup.patchValue({'courtState': state.abbreviation});
            this.courtFormGroup.get('courtZip')?.setValue(this.selectedCourt.postalCode);
            this.courtFormGroup.get('courtPhone')?.setValue((this.selectedCourt.primaryPhoneNumber || '').trim());
            this.courtFormGroup.get('courtPhoneExt')?.setValue(this.selectedCourt.phoneExt);
            this.courtFormGroup.updateValueAndValidity();

            this.pickupLookupOpen = false;
        }
    }

    public disableAccount() : boolean {
        return (!this.selectAccount.valid || !this.cmvDataLoaded);
    }

    public async accountSelected(selectedAccount: CustomerAccountInfo, initial: boolean = false) {

        // make sure an address is found
        if(!selectedAccount) {
            console.warn('WARNING: could not find selected customer');
            return;
        }

        this.cmvDataLoaded = false;
        this.selectAccount.patchValue(selectedAccount.accountNumber);

        let selectedState = (selectedAccount.state || '').trim();
        this.accountDefaultState = selectedState;
        this.courtFormGroup.get('courtState')?.setValue(selectedState);
      //  this.onEFileCourtStateSelect();

        let state = this.locationService.states.find(
            (s) => (s.name == selectedState || s.abbreviation == selectedState)
        );

        this.accountFormGroup.get('corpId')?.setValue(selectedAccount.corpId);
        this.accountFormGroup.get('companyNumber')?.setValue(selectedAccount.companyNo);
        this.accountFormGroup.get('customerNumber')?.setValue(selectedAccount.customerNo);
        this.accountFormGroup.get('accountName')?.setValue(selectedAccount.name);
        this.accountFormGroup.get('accountStreet')?.setValue(selectedAccount.street);
        this.accountFormGroup.get('accountRoom')?.setValue(selectedAccount.room);
        this.accountFormGroup.get('accountCity')?.setValue(selectedAccount.city);
        if(state)
            this.accountFormGroup.get('accountState')?.setValue(state.abbreviation);
        this.accountFormGroup.get('accountZip')?.setValue(selectedAccount.zip);
        this.accountFormGroup.get('accountCountry')?.setValue(selectedAccount.country);

        // this.selectedClientBranch = await this.getClientBranchFromAccount(selectedAccount);

        const insuranceCompanyControl = this.categoryFormGroup.get('insuranceCompany');
        const insuranceAdjusterControl = this.categoryFormGroup.get('insuranceAdjuster');
        if (this.settings.insuranceInherited) {
            if (insuranceCompanyControl) {
                insuranceCompanyControl.setValue(selectedAccount.insuranceCompany || '');
            }
            if (insuranceAdjusterControl) {
                insuranceAdjusterControl.setValue(selectedAccount.insuranceAdjuster || '');
            }
        } else {
            if (insuranceCompanyControl) {
                insuranceCompanyControl.setValue(this.settings.insuranceCompany || '');
            }
            if (insuranceAdjusterControl) {
                insuranceAdjusterControl.setValue(this.settings.insuranceAdjuster || '');
            }
        }
        await this.loadClientMatterEntries_forAccount(selectedAccount);
        this.cmvDataLoaded = true;


        // clicking directly on the stepper 'step' doesn't trigger the loadServiceCategories so we call it here 
        // to make ensure they are loaded.
        this.loadServiceCategories();
        if(!this.disableAccount()){
            if(!initial) {
                this.formStepper?.next()
            }
            else{
                while(this.formStepper && this.formStepper.selectedIndex > 0){
                    this.formStepper?.previous()
                };
            }
        }
    }

    public checkService() {
        let control = this.serviceFormGroup.get('service');

        if(!control || !control.value || control.invalid) {
            control?.markAsTouched();
            control?.setErrors({'required': true});
        }

        this.loadServiceCategories();

        // If the user only has 1 account, auto-set that account and skip account step. 
        if(this.accountDataSource.data.length == 1) { this.accountSelected(this.accountDataSource.data[0]) }

        this.checkSysStatus();
    }

    public loadServiceCategories(serviceName?:string) {
        const serviceControl = this.serviceFormGroup.get('service');
        const categoryControl = this.categoryFormGroup.get('category');
        const typeControl = this.categoryFormGroup.get('type');

        // In case of draft order for non court-process
        if(serviceControl && this.entity && this.entity.filingServiceType_uuid){
            this.orderServiceService.services.value.map((service)=>{
                service.categories.map((category)=>{
                    category.types.map((type)=>{
                        if(type.uuid == this.entity.filingServiceType_uuid){
                            serviceName = service.service.name
                            serviceControl.setValue(service.service.name)
                        }
                    })
                })
            })
        } else if(!serviceControl || serviceControl.value == "") return;
            

        let service = Array
            .from(this.orderServiceService.services.value)
            .find((s : any) => serviceName ? s.service.name == serviceName : s.service.name == serviceControl.value);

        if((serviceName == DIGITAL || serviceControl.value == DIGITAL) && service) {
            service.categories.sort(function(a, b) {
                return a.category.sortOrder - b.category.sortOrder;
            });
        }
        this.categories = service ? service.categories : [];

        if(this.entity?.orderData) {
            let orderData = JSON.parse(this.entity.orderData as string);
            this.categoryFormGroup.get('serviceCategoryDescription')?.setValue(orderData.serviceCategoryDescription);
        }

        if(categoryControl?.value) {
            let category : ServiceCategoryWithTypes | undefined = this.categories.find((c) => c.category.name == categoryControl.value);
            if(category && category.category.displayName == 'Other') {
                this.categoryFormGroup.get('serviceCategoryDescription')?.setValidators(Validators.required);
            } else {
                this.categoryFormGroup.get('serviceCategoryDescription')?.clearValidators();
            }
        }

        // .map((c) => {
        //     let fixedService = {
        //         category : new ServiceCategory(c.category),
        //         types : c.types.map((t) => new ServiceType(t))
        //     };
            
        //     if(c.subCategories)
        //         fixedService.subCategories = c.subCategories.map((sc) => new ServiceCategory(sc));

        //     return fixedService;
        // }) : [];

        // make sure to clear out any previous selections
        if(categoryControl && categoryControl.value != '')
            categoryControl.reset('');

        if(typeControl && typeControl.value != '')
            typeControl.reset('');
    }

    public checkCategory() {
        const categoryControl = this.categoryFormGroup.get('category');
        const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
        const claimNumberControl = this.categoryFormGroup.get('claimNumber');

        if(this.categoryName == ServiceTypes.EFILE && this.typeName == ServiceTypes.EFILE_DIRECT_TYPE ) {
            this.formDirty = false;
            this.router.navigateByUrl('/order/direct-efile', {state: {
                clientMatterNumber: (clientMatterNumberControl?.value || ''),
                claimNumber: (claimNumberControl?.value || '')
            }});
        }

        // For Court Process - SOP Orders
        if(this.categoryName == ServiceTypes.SERVICE_OF_PROCESS){
            if((this.formStepper!.steps.length - 2) == this.formStepper!.selectedIndex){
                this.touchServiceAddressAndServees();
            }else if((this.formStepper!.steps.length - 4) <= this.formStepper!.selectedIndex 
            && (this.serviceAddresses.length > 0)){
                this.resetServiceAddressFormErrors();
            }
        }

        // For Digital - Mailout Orders
        if(this.categoryName == ServiceTypes.MAILOUT){
            let addresses = this.digitalAddressesFormGroup.controls['recipientAddresses'].value
            if((this.formStepper!.steps.length - 2) == this.formStepper!.selectedIndex){
                if(this.serviceAddressFormGroup.valid){
                    this.addRecipient();
                }
                this.resetRecipientAddressListFormErrors();
            }else if((this.formStepper!.steps.length - 4) <= this.formStepper!.selectedIndex 
            && (addresses && addresses.length > 0)){
                this.resetRecipientAddressListFormErrors();
            }
        }

        // For Investigations - Skiptrace orders
        if(this.categoryName == ServiceTypes.SKIPTRACE){
            Object.entries(this.lastKnownAddressesFormGroup.controls).map((entry)=>{
                entry[1].setErrors(null);
            })
            this.skipTraceMediaFormGroup.get("mediaName")?.setErrors(null);
            this.skipTraceMediaFormGroup.get("mediaLink")?.setErrors(null);
        }
        
        this.checkSysStatus();
    }

    public removeServiceAddresses(index: number) {

        // check the length of both seedDocuments and files, if both are empy set the error.
        this.deletedDeliveryAddresses = [...this.deletedDeliveryAddresses, ...this.serviceAddresses.slice(index,1)];
        //Errors where user can skip step if form/servee isn't validated
        this.serviceAddresses.splice(index,1);
        
        this.toServeFormGroup.patchValue({servees: this.serviceAddresses});

        if(this.serviceAddresses.length < 1){
            this.addServiceAddress();
        }

        this.serviceAddressControlGroup.get('serviceAddresses')?.patchValue(this.serviceAddresses);
        // this.serviceAddressFormGroup.patchValue({'serviceAddresses': this.serviceAddresses});
    }

    public touchServiceAddressAndServees() {
        const serviceAddressesControl = this.serviceAddressControlGroup.get('serviceAddresses');
        // const serviceAddressesControl = this.serviceAddressFormGroup.get('serviceAddresses');
        const serveesControl = this.toServeFormGroup.get('servees');

        serviceAddressesControl?.markAsTouched();
        serveesControl?.markAsTouched();

        this.serviceAddressFormGroup.updateValueAndValidity()
        if(this.serviceAddressFormGroup.valid){
            this.addServiceAddress();
        }
        this.resetServiceAddressFormErrors();

        // if(this.servees.length > 0 && this.serviceAddresses.length > 0)
        //     this.formStepper?.next();
        this.checkSysStatus();
    }

    public switchIndividualBusiness(state:boolean){
        this.serveeIndividualBusiness = state;
        if(!this.serveeIndividualBusiness){
            this.serviceAddressFormGroup.controls['typeName'].setValue('Business');
            this.toServeFormGroup.reset();
        }else{
            this.serviceAddressFormGroup.controls['typeName'].setValue('Individual');
            this.serviceAddressFormGroup.controls['serviceContact'].reset();
        }
    }

    public addServiceAddress() {
        const emailControl = this.toServeFormGroup.get('serveEmail');
        const ageControl = this.toServeFormGroup.get('serveAge');
        const genderControl = this.toServeFormGroup.get('serveGender');
        const ethnicityControl = this.toServeFormGroup.get('serveEthnicity');
        const weightControl = this.toServeFormGroup.get('serveWeight');
        const heightControl = this.toServeFormGroup.get('serveHeight');
        const hairControl = this.toServeFormGroup.get('serveHair');
        const eyesControl = this.toServeFormGroup.get('serveEyes');
        const relationshipControl = this.toServeFormGroup.get('serveRelationship');
        const serveesControl = this.toServeFormGroup.get('servees');


        // if(this.serviceAddresses.length > 0) return; // TFTEMP while we sort out the submit 
        const locationNameControl = this.serviceAddressFormGroup.get('locationName');
        const serviceContactControl = this.serviceAddressFormGroup.get('serviceContact');
        const serviceStreetControl = this.serviceAddressFormGroup.get('serviceStreet');
        const serviceSuiteControl = this.serviceAddressFormGroup.get('serviceSuite');
        const serviceCityControl = this.serviceAddressFormGroup.get('serviceCity');
        const serviceStateControl = this.serviceAddressFormGroup.get('serviceState');
        const serviceZipControl = this.serviceAddressFormGroup.get('serviceZip');
        const servicePhoneControl = this.serviceAddressFormGroup.get('servicePhone');
        const serviceExtControl = this.serviceAddressFormGroup.get('servicePhoneExt');
        // Seperate address control group
        const serviceAddressesControl = this.serviceAddressControlGroup.get('serviceAddresses');

        let invalid = false;

        if(!locationNameControl || !locationNameControl.value) {
            locationNameControl?.setErrors({add: true});
            invalid = true;
        }

        if(!serviceStreetControl || !serviceStreetControl.value) {
            serviceStreetControl?.setErrors({add: true});
            invalid = true;
        }

        if(!serviceCityControl || !serviceCityControl.value) {
            serviceCityControl?.setErrors({add: true});
            invalid = true;
        }
        
        if(!serviceStateControl || !serviceStateControl.value) {
            serviceStateControl?.setErrors({add: true});
            invalid = true;
        }
        
        // extra check to make sure the state matches with one from the list
        if(!serviceStateControl || serviceStateControl.hasError('invalid')) {
            invalid = true;
        }
        
        if(!serviceZipControl || !serviceZipControl.value) {
            serviceZipControl?.setErrors({add: true});
            invalid = true;
        }

        // extra check to make sure the phone number passes regex before adding service addresses to the list.
        if(!servicePhoneControl || servicePhoneControl.hasError('pattern')) {
            invalid = true;
        }

        // guard for bad values
        if(invalid || !serviceAddressesControl) {
            return;
        }

        // Add servee
        invalid = false;

        // guard for bad values
        if(invalid || !serveesControl) {
            console.log("Failed:", invalid, !serveesControl)
            return;
        }
        
        let servee : ToServeData = {
            firstName: locationNameControl?.value.split(' ')[0],
            lastName: (locationNameControl?.value.split(' ')[1]) ? (locationNameControl?.value.split(' ')[1]) : "",
            email: emailControl?.value,
            phone: servicePhoneControl?.value,
            age: ageControl?.value,
            gender: genderControl?.value,
            ethnicity: ethnicityControl?.value,
            weight: weightControl?.value,
            height: heightControl?.value,
            hair: hairControl?.value,
            eyes: eyesControl?.value,
            relationship: relationshipControl?.value,
        }

        let serviceAddress : OrderServiceAddress = {
            name: locationNameControl?.value,
            attention: serviceContactControl?.value,
            address: serviceStreetControl?.value,
            room: serviceSuiteControl?.value,
            city: serviceCityControl?.value,
            state: serviceStateControl?.value,
            zip: serviceZipControl?.value,
            phone: servicePhoneControl?.value,
            phoneExt: serviceExtControl?.value,
            serve: servee
        }

        this.serviceAddresses.push(serviceAddress);
        serviceAddressesControl?.setValue(this.serviceAddresses);

        locationNameControl!.reset('');
        serviceContactControl?.reset('');
        serviceStreetControl!.reset('');
        serviceSuiteControl?.reset('');
        serviceCityControl!.reset('');
        serviceStateControl!.reset('');
        serviceZipControl!.reset('');
        servicePhoneControl?.reset('');
        serviceExtControl?.reset('');

        locationNameControl!.setErrors(null)
        serviceStreetControl!.setErrors(null)
        serviceCityControl!.setErrors(null)
        serviceZipControl!.setErrors(null)

        emailControl?.reset('');
        ageControl?.reset('');
        genderControl?.reset('');
        ethnicityControl?.reset('');
        weightControl?.reset('');
        heightControl?.reset('');
        hairControl?.reset('');
        eyesControl?.reset('');
        relationshipControl?.reset('');
        console.log("New Entry:", servee, serviceAddress);
    }

    // public restoreServiceAddress(index: number) {
    //     this.serviceAddresses = [...this.serviceAddresses, ...this.deletedDeliveryAddresses.splice(index,1)];

    //     // check is probably not needed, but for sanity
    //     if( this.serviceAddresses.length )
    //         this.serviceAddressControlGroup.get('serviceAddresses')?.setErrors(null);
    //         // this.serviceAddressFormGroup.get('serviceAddresses')?.setErrors(null);
    // }

    // public removeServiceAddress(index: number) {
    //     this.deletedDeliveryAddresses = [...this.deletedDeliveryAddresses, ...this.serviceAddresses.splice(index,1)];

    //     this.serviceAddressControlGroup.get('serviceAddresses')?.patchValue(this.serviceAddresses);
    //     // this.serviceAddressFormGroup.patchValue({'serviceAddresses': this.serviceAddresses});
    // }

    private getDeliveryTime(serviceType: ServiceType) : Date {
        let date: Date = new Date();
        console.log('delivery date initial', date);
        // let utcHour = date.getUTCHours() || 24;
        let pacificHour = parseInt(date.toLocaleTimeString('en-US-u-hc-h24', {hour: '2-digit', hour12: false, timeZone: 'America/Los_Angeles'}));
        // let timezoneOffset = ((utcHour - pacificHour) + 24) % 24;
        // console.log('utcHour', utcHour);
        console.log('pacificHour', pacificHour);
        // console.log('timezoneOffset', timezoneOffset);
        // Keep track of how many days to adjust the time by.
        let days = serviceType.dayModifier;

        let hour: number; 
        if(serviceType.isRush) { 
            hour = date.getHours() + serviceType.hourModifier;
        } else {
            let unmoddedHour = serviceType.hourModifier; // + timezoneOffset was causing the times to be off
            hour = (unmoddedHour % 24);
            
            // non rush jobs need to check if the day should be incremented.
            if(unmoddedHour >= 24) days++;
        }
        console.log('hour', hour);
        console.log('day', days);
        
        // Set the minutes to the next 5-minute increment.
        let minute: number = (Math.ceil(date.getMinutes() / 5)) * 5;
    
        // Account for FILING SPECIAL and DELIVERY SPECIAL ServiceType.
        minute += serviceType.minuteModifier;
        hour += ((minute >= 60) ? 1 : 0);
        console.log('minute', minute);
        console.log('hour', hour);
        // If isRush: Add the current hour + hourModifer and adjust the day if needed.
        // If not isRush: If modifier > 0, check that the current time is earlier than the modifier, else adjust the day.
        if ( (serviceType.isRush && ((pacificHour + ((minute >= 60) ? 1 : 0) + serviceType.hourModifier) >= 24)) 
                || (!serviceType.isRush && (serviceType.hourModifier && (pacificHour >= serviceType.hourModifier))) ) {
            days++;
        }
        console.log('days', days);
        date.setDate(date.getDate() + days);
        date.setHours(hour, (minute % 60));
        console.log('date', date);
        return date;
    }    

    private reviewDigitalAddressFormatter(){
        let form = this.recipientAddressFormGroup;

        let name = form.controls['locationName'].value;
        let street = form.controls['locationAddress'].value;
        let city = form.controls['locationCity'].value;
        let state = form.controls['locationState'].value;
        let zip = form.controls['locationZip'].value;
        
        let deliveryAddress : OrderServiceAddress = {
            name: name,
            attention: '',
            address: street,
            room: '',
            city: city,
            state: state,
            zip: zip,
            phone: '',
            phoneExt: null
        }

        this.serviceAddresses = [deliveryAddress];
        return this.reviewAddressStringBuilder(street, undefined, city, state, zip);
    }

    private reviewDigitalMailoutAddressFormatter(index:number) {
        let control = this.digitalAddressesFormGroup.controls['recipientAddresses'];
        // out of bounds
        if(0 > index || index >= control.value.length) {
            console.log('Warning: index out of bounds');
            return "";
        }

        let street = control.value[index].locationAddress;
        let city = control.value[index].locationCity;
        let state = control.value[index].locationState;
        let zip = control.value[index].locationZip;
        
        return this.reviewAddressStringBuilder(street, '', city, state, zip);
    }

    private getDigitalCompany(index:number) {
        let control = this.digitalAddressesFormGroup.controls['recipientAddresses'];
        // out of bounds
        if(0 > index || index >= control.value.length) {
            console.log('Warning: index out of bounds');
            return "";
        }

        return control.value[index].locationName;
    }

    private getDigitalNames(index:number){
        let control = this.digitalAddressesFormGroup.controls['recipientAddresses'];
        // out of bounds
        if(0 > index || index >= control.value.length) {
            console.log('Warning: index out of bounds');
            return "";
        }

        return control.value[index].locationName;

    }

    private reviewServiceAddressFormatter(index:number) {
         // out of bounds
         if(0 > index || index >= this.serviceAddresses.length) {
            console.log('Warning: index out of bounds');
            return "";
        }

        let street = this.serviceAddresses[index].address;
        let suite = this.serviceAddresses[index].room;
        let city = this.serviceAddresses[index].city;
        let state = this.serviceAddresses[index].state;
        let zip = this.serviceAddresses[index].zip;
        
        return this.reviewAddressStringBuilder(street, suite, city, state, zip);
    }

    private getDeliveryAddressFromFormGroup() {
        const streetControl = this.deliveryDetailFormGroup.get('deliveryStreet');
        const suiteControl = this.deliveryDetailFormGroup.get('deliverySuite');
        const cityControl = this.deliveryDetailFormGroup.get('deliveryCity');
        const stateControl = this.deliveryDetailFormGroup.get('deliveryState');
        const zipControl = this.deliveryDetailFormGroup.get('deliveryZip');

        let address = (streetControl ? streetControl.value : "");
        let room = suiteControl?.value;
        let city = (cityControl ? cityControl.value : "");
        let state = (stateControl ? stateControl.value : "");
        let zip = (zipControl ? zipControl.value : "");
        return {address, room, city, state, zip};
    }

    private reviewPickupAddressFormatter() {
        const streetControl = this.pickupDetailFormGroup.get('pickupStreet');
        const suiteControl = this.pickupDetailFormGroup.get('pickupSuite');
        const cityControl = this.pickupDetailFormGroup.get('pickupCity');
        const stateControl = this.pickupDetailFormGroup.get('pickupState');
        const zipControl = this.pickupDetailFormGroup.get('pickupZip');

        let street = (streetControl ? streetControl.value : "");
        let suite = suiteControl?.value;
        let city = (cityControl ? cityControl.value : "");
        let state = (stateControl ? stateControl.value : "");
        let zip = (zipControl ? zipControl.value : "");

        return this.reviewAddressStringBuilder(street, suite, city, state, zip);
    }

    private reviewAddressStringBuilder(street: string, suite: string | undefined, city: string, state: string, zip: string) {
        let address = street 
            + (suite ? ", Suite " + suite + " " : " ") + "<br>"
            + city + ", " + state + " " + zip;
        return address;
    }

    public addressBuilderHTML(address: any) : string {
        if(!address)
            return '';
        let street = address.address || "";
        let hasStreet = !!address.address;
        let room = address.room || "";
        let hasRoom = !!address.room;
        let city = address.city || "";
        let hasCity = !!address.city;
        let state = address.state || "";
        let hasState = !!address.state;
        let zip = address.zip || "";
        let hasZip = !!address.zip;


        let formattedStreet = (hasStreet && hasRoom) ? (street.trim() + ", Suite " + room.trim()) : (hasStreet ? street.trim() : "");
        let formattedCityStateZip = (hasCity ? city.trim() : "") 
            + ((hasCity && hasState) ? (", " + state.trim()) : (hasState ? state.trim() : "")) 
            + ((hasState || hasCity) ? (" " + zip.trim()) : (hasZip ? zip.trim() : ""))
        
        let formattedAddress = formattedStreet + (formattedStreet || formattedCityStateZip ? "<br>" : "") + formattedCityStateZip;
        return formattedAddress;
    }

    private getServeeName (index: number) {
        // out of bounds
        if(0 > index || index >= this.serviceAddresses.length) {
            console.log('Warning: index out of bounds');
            return "";
        }
            
        let serveeName = this.serviceAddresses[index].serve?.firstName + " " + this.serviceAddresses[index].serve?.lastName;
        return serveeName;
    }

    private getServeCompany (index: number) {
        // out of bounds
        if(0 > index || index >= this.serviceAddresses.length) {
            console.log('Warning: index out of bounds');
            return "";
        }

        let serveCompany = this.serviceAddresses[index].name;
        return serveCompany;
    }

    private getServePhone (index:number) {
         // out of bounds
         if(0 > index || index >= this.serviceAddresses.length) {
            console.log('Warning: index out of bounds');
            return "";
        }

        let servePhone = (this.serviceAddresses[index].phone || "");
        return servePhone;
    }

    public getSOPAdditionalInfo(index: number){
        // out of bounds
        // let index = this.serviceAddresses.findIndex((address) => address.uuid == id)
        if(0 > index || index >= this.serviceAddresses.length) {
            console.log('Warning: index out of bounds');
            return ``;
        }
        let address = this.serviceAddresses[index];
        let servee: ServeeJSON = {
            serveeNumber: address.uuid ? address.uuid : ''
        }
        if(address.serve?.phone) servee.phone = address.serve?.phone;
        if(address.serve?.email) servee.email = address.serve?.email;
        if(address.serve?.age) servee.age = address.serve?.age;
        if(address.serve?.gender) servee.gender = address.serve?.gender;
        if(address.serve?.relationship) servee.relationship = address.serve?.relationship;
        if(address.serve?.ethnicity) servee.ethnicity = address.serve?.ethnicity;
        if(address.serve?.eyes) servee.eyes = address.serve?.eyes;
        if(address.serve?.hair) servee.hair = address.serve?.hair;
        if(address.serve?.height) servee.height = address.serve?.height;
        if(address.serve?.weight) servee.weight = address.serve?.weight;

        let requestNotes = ` ` + JSON.stringify(servee) + `,`;
        return requestNotes;
        // return (this.serviceAddresses[index].serve?.phone ? "Phone: " + this.serviceAddresses[index].serve?.phone : "") + (this.serviceAddresses[index].serve?.email ? " Email: " + this.serviceAddresses[index].serve?.email : "")
        // + (this.serviceAddresses[index].serve?.age ? " Age: " + this.serviceAddresses[index].serve?.age : "") + (this.serviceAddresses[index].serve?.gender ? " Gender: " + this.serviceAddresses[index].serve?.gender : "")
        // + (this.serviceAddresses[index].serve?.relationship ? " Relationship: " + this.serviceAddresses[index].serve?.relationship : "")  + (this.serviceAddresses[index].serve?.ethnicity ? " Ethnicity: " + this.serviceAddresses[index].serve?.ethnicity : "")
        // + (this.serviceAddresses[index].serve?.eyes ? " Eyes: " + this.serviceAddresses[index].serve?.eyes : "") + (this.serviceAddresses[index].serve?.hair ? " Hair: " + this.serviceAddresses[index].serve?.hair : "")
        // + (this.serviceAddresses[index].serve?.height ? " Height: " + this.serviceAddresses[index].serve?.height : "") + (this.serviceAddresses[index].serve?.weight ? " Weight: " + this.serviceAddresses[index].serve?.weight : "")
    }


    public prepareReview() {
        this.showReviewRow = true; // reset the flag to default  
        // const serviceCategoryControl = this.categoryFormGroup.get('category');
        const serviceTypeControl = this.categoryFormGroup.get('type');
        const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
        const claimNumberControl = this.categoryFormGroup.get('claimNumber');
        const insuranceCompanyControl = this.categoryFormGroup.get('insuranceCompany');
        const insuranceAdjusterControl = this.categoryFormGroup.get('insuranceAdjuster');

        const pickupCompanyControl = this.pickupDetailFormGroup.get('pickupCompany');
        const pickupPhoneControl = this.pickupDetailFormGroup.get('pickupPhone');

        const caseNumberControl = this.caseFormGroup.get('caseNumber');
        const caseNameControl = this.caseFormGroup.get('caseName');

        const documentDescriptionControl = this.fileFormGroup.get('description');

        const specialInstructionsControl = this.requestNotesFormGroup.get('specialInstructions');

        let serviceType : ServiceType | undefined;
        if(serviceTypeControl)
            serviceType = this.serviceType_byUuid(serviceTypeControl.value);

        if(!serviceType) {
            console.error('Error: service type not found');
            return;
        }

        let loc = "https://app." + ReflexEnvironment.config['resourcePrefix'] + ".firstlegal.com" +  `/order/` + (this.SubmitOrder?.uuid || this.entity?.uuid ? (this.SubmitOrder?.uuid ? this.SubmitOrder?.uuid: this.entity?.uuid): "");
        let now = new Date();
        let deliveryDateTime = this.getDeliveryTime(serviceType);
        console.log('deliveryDateTime', deliveryDateTime);
        let deliveryDate = deliveryDateTime.toLocaleDateString('en-US', {month: '2-digit', timeZone: 'America/Los_Angeles'}) + "/"
            + deliveryDateTime.toLocaleDateString('en-US', {day: '2-digit', timeZone: 'America/Los_Angeles'}) + "/"
            + deliveryDateTime.toLocaleTimeString('en-US', {year: 'numeric', timeZone: 'America/Los_Angeles'});
        let deliveryTime = deliveryDateTime.toLocaleTimeString('en-US-u-hc-h23', {hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'America/Los_Angeles'});
        // let deliveryDate = (deliveryDateTime.getMonth() + 1) + "/" + deliveryDateTime.getDate() + "/" + deliveryDateTime.getFullYear(); 
        // let deliveryTime = deliveryDateTime.getHours() + ":" + deliveryDateTime.getMinutes();
        let pickupDate = now.toLocaleDateString('en-US', {month: '2-digit', timeZone: 'America/Los_Angeles'}) + "/"
            + now.toLocaleDateString('en-US', {day: '2-digit', timeZone: 'America/Los_Angeles'}) + "/"
            + now.toLocaleTimeString('en-US', {year: 'numeric', timeZone: 'America/Los_Angeles'});

        // compiler wasn't smart enough to figure out the filter gets rid of nulls, so we have to cast the name
        let seedDocumentNames : string[] = this.seedDocuments.filter(d => d.fileName !== null).map(d => <string> d.fileName);

        let pickupHeader: string;
        let pickupContactLabel: string;
        let deliveryHeader : string;
        let deliveryContactLabel : string; 
        let deliveryCompanyLabel : string;
        let serveAddress : string[] = [];
        let serveCompany : string[] = [];
        let servee : string[] = [];
        let servePhone : string[] = [];
        let serviceLine = this.serviceFormGroup.controls['service']
        if(!serviceLine || serviceLine.value == 'COURTPROCESS'){
            if(this.showDeliverTo()) {
                pickupHeader = "Pickup From";
                pickupContactLabel = "Contact"
                serveAddress[0] = this.addressBuilderHTML(this.getDeliveryAddressFromFormGroup());
                serveCompany[0] = this.deliveryDetailFormGroup.get('deliveryCompany')?.value;
                servee[0] = (this.deliveryDetailFormGroup.get('deliveryContact')?.value || "Not Available");
                servePhone[0] = this.deliveryDetailFormGroup.get('deliveryPhone')?.value;
                deliveryHeader = "Deliver To";
                deliveryContactLabel = "Contact";
                deliveryCompanyLabel = "Company";
            } else if(this.showCourt()) {
                this.showReviewRow = false; // set flag to hide fields.
                pickupHeader = "Order Information";
                pickupContactLabel = "Ordered By"
                serveAddress[0] = ""; // not displayed 
                serveCompany[0] = this.courtFormGroup.get('courtName')?.value;
                servee[0] = "";
                servePhone[0] = "";
                deliveryHeader = "Court";
                deliveryContactLabel = "";
                deliveryCompanyLabel = "Court";
            } else {
                pickupHeader = "Pickup From";
                pickupContactLabel = "Contact";
                serveAddress = this.serviceAddresses.map((_, i) => {return this.getSOPAdditionalInfo(i)})
                // serveAddress = this.serviceAddresses.map((_, i) =>{return this.reviewServiceAddressFormatter(i);});
                serveCompany = this.serviceAddresses.map((_, i) =>{return this.getServeCompany(i);});
                servee = this.serviceAddresses.map((_, i) =>{return this.getServeeName(i);});
                servePhone = this.serviceAddresses.map((_, i) =>{return this.getServePhone(i);});
                deliveryHeader = "Served To";
                deliveryContactLabel = "Person to be Served";
                deliveryCompanyLabel = "Party to be Served";
            }
        } else if (serviceLine.value == Services.EDISCOVERY){
            // if(this.deliveryRequired){
            //     pickupHeader = "Pickup From";
            //     pickupContactLabel = "Contact"
            //     serveAddress[0] = this.reviewEDiscoveryAddressFormatter();
            //     serveCompany[0] = this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany')?.value;
            //     servee[0] = this.eDiscoveryTrialFormGroup.get('eDiscoveryContact')?.value;
            //     servePhone[0] = this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone')?.value;
            //     deliveryHeader = "Deliver To";
            //     deliveryContactLabel = "Contact";
            //     deliveryCompanyLabel = "Company";
            // }
            pickupHeader = "Pickup From";
            pickupContactLabel = "Contact"
            serveAddress = [];
            serveCompany = [];
            servee = [];
            servePhone = [];
            deliveryHeader = "Deliver To";
            deliveryContactLabel = "Contact";
            deliveryCompanyLabel = "Company";
        } else if (serviceLine.value == Services.DIGITAL){
            if(this.digitalDeliveryOnly){
                pickupHeader = "Pickup From";
                pickupContactLabel = "Contact"
                serveAddress = [];
                serveCompany = [];
                servee = [];
                servePhone = this.pickupDetailFormGroup.get('pickupPhone')?.value;
                deliveryHeader = "Deliver To";
                deliveryContactLabel = "Contact";
                deliveryCompanyLabel = "Company";
            } else if(this.categoryName == ServiceTypes.MAILOUT){
                pickupHeader = "Pickup From";
                pickupContactLabel = "Contact"
                serveAddress = this.digitalAddressesFormGroup.get('recipientAddresses')?.value.map((_:any, i:number) =>{return this.reviewDigitalMailoutAddressFormatter(i);});
                serveCompany = this.digitalAddressesFormGroup.get('recipientAddresses')?.value.map((_:any, i:number) =>{return this.getDigitalCompany(i);});
                servee = this.digitalAddressesFormGroup.get('recipientAddresses')?.value.map((_:any, i:number) =>{return this.getDigitalNames(i);});
                servePhone = this.pickupDetailFormGroup.get('pickupPhone')?.value;
                deliveryHeader = "Deliver To";
                deliveryContactLabel = "Contact";
                deliveryCompanyLabel = "Company";
            } else {
                pickupHeader = "Pickup From";
                pickupContactLabel = "Contact"
                serveAddress[0] = this.reviewDigitalAddressFormatter();
                serveCompany[0] = this.recipientAddressFormGroup.get('locationName')?.value;
                servee[0] = '';
                servePhone[0] = '';
                deliveryHeader = "Deliver To";
                deliveryContactLabel = "Contact";
                deliveryCompanyLabel = "Company";
            }
        } else {
            pickupHeader = "Pickup From";
            pickupContactLabel = "Contact";
            serveAddress = this.serviceAddresses.map((_, i) =>{return this.reviewServiceAddressFormatter(i);});
            serveCompany = this.serviceAddresses.map((_, i) =>{return this.getServeCompany(i);});
            servee = this.serviceAddresses.map((_, i) =>{return this.getServeeName(i);});
            servePhone = this.serviceAddresses.map((_, i) =>{return this.getServePhone(i);});
            deliveryHeader = "Served To";
            deliveryContactLabel = "Person to be Served";
            deliveryCompanyLabel = "Party to be Served";
        }
    
        let count = 0;
        let serveeInfo = this.serviceAddresses
        .map((address)=>{
            count++;
            if(address.serve && address.uuid){
                return this.getSOPAdditionalInfo(count)
            }
            return '';
        }).join(', ');

        this.review = {
            createdAt: new Date().toDateString(),
            updatedAt: new Date().toDateString(),
            orderNumber: "",
            service: this.serviceName,
            serviceCategory: this.categoryDisplayName,
            serviceType: this.typeDisplayName,
            serviceCategoryDescription: this.categoryFormGroup.get('serviceCategoryDescription')?.value,
            clientMatterNumber: clientMatterNumberControl?.value,
            claimNumber: claimNumberControl?.value,
            insuranceCompany: insuranceCompanyControl?.value,
            insuranceAdjuster: insuranceAdjusterControl?.value,
            pickupContact: this.userPermissionService.loggedInUser?.firstName + " " + this.userPermissionService.loggedInUser?.lastName, 
            pickupCompany: pickupCompanyControl?.value,
            pickupAddress: [this.reviewPickupAddressFormatter()],
            pickupPhone: pickupPhoneControl?.value,
            pickupDate: pickupDate,
            caseNumber: caseNumberControl?.value,
            caseName: caseNameControl?.value,
            documentDescription: documentDescriptionControl?.value,
            attachedDocuments: [...seedDocumentNames,...this.files.map(f => f.name)],
            servee: servee,
            serveCompany: serveCompany,
            serveAddress: serveAddress,
            serveAttempts: [],
            serveAffidavits: [],
            servePhone: servePhone,
            pickupHeader: pickupHeader,
            pickupContactLabel: pickupContactLabel,
            deliveryHeader: deliveryHeader,
            deliveryContactLabel: deliveryContactLabel,
            deliveryCompanyLabel: deliveryCompanyLabel,
            deliveryDate: deliveryDate,
            deliveryTime: deliveryTime, 
            deliverySpecialInstructions: "",
            pickupSpecialInstructions: specialInstructionsControl?.value + serveeInfo, 
            orderId: (this.SubmitOrder?.uuid || this.entity?.uuid ? (this.SubmitOrder?.uuid ? this.SubmitOrder?.uuid: this.entity?.uuid): ""),
            orderStatus: "Processing",
            env: loc
        };

        this.checkSysStatus();
    }

    private prepareUserData() : UserData {
        let userData : UserData = {
            customerId: 0,
            email: this.userPermissionService.loggedInUser?.email,
            id: this.userPermissionService.loggedInUser?.username,
            name: this.userPermissionService.loggedInUser?.firstName + " " + this.userPermissionService.loggedInUser?.lastName
        };

        return userData;
    }

    private prepareCaseData(draft: boolean) : CaseData | undefined{
        const caseNameControl = this.caseFormGroup.get('caseName');
        const caseNumberControl = this.caseFormGroup.get('caseNumber');
        const categoryControl = this.categoryFormGroup.get('category');

        // in the case of a draft order, where the user has not added the caseName and caseNumber, 
        // we want to send no data when submitting
        if(draft && !caseNameControl?.value && !caseNumberControl?.value) return;

        let caseData : CaseData = {
            name: caseNameControl?.value,
            caseNumber: caseNumberControl?.value,
            corpId: (this.selectedCase ? this.selectedCase.corpId : this.userPermissionService.loggedInUser!.corpId),
            successAccount_uuid: this.selectedCase ? this.selectedCase.successAccount_uuid : this.userPermissionService.loggedInUser!.uuid
        }

        // add optional fields if user selected a case, otherwise it's a new case and these field are not needed
        if(this.selectedCase) {
            caseData.uuid = this.selectedCase.uuid
            caseData.frequentCallerId = this.selectedCase.frequentCallerId
        }
        
        return caseData;
    }

    private prepareDocumentData() : DocumentData[] {
        let documents : DocumentData[] = this.files.map((file: File, index: number) => {
            let name  = file.name.split('.')[0];
            let extension = file.name.split('.').pop(); 

            let document : DocumentData = {
                // vendor_id: "", 
                name: name,
                fileName: file.name,
                extension: extension,
                sequenceIndex: index,
                // vendorDownloadURI: "",
                // siteUrl: "",
                // returnPath: ""
            }

            return document;
        });

        return documents;
    }

    private prepareCourtData() : CourtData {
        const courtNameControl = this.courtFormGroup.get('courtName');
        const courtStreetControl = this.courtFormGroup.get('courtStreet');
        const courtCityControl = this.courtFormGroup.get('courtCity');
        const courtStateControl = this.courtFormGroup.get('courtState');
        const courtZipControl = this.courtFormGroup.get('courtZip');
        
        let courtData : CourtData = {
            name: courtNameControl?.value,
            address: courtStreetControl?.value,
            city: courtCityControl?.value,
            state: courtStateControl?.value,
            zip: courtZipControl?.value
        };
        return courtData;
    }

    private prepareCourtServiceAddress() : OrderServiceAddress[] {
        const courtNameControl = this.courtFormGroup.get('courtName');
        const courtContactControl = this.courtFormGroup.get('courtContact');
        const courtStreetControl = this.courtFormGroup.get('courtStreet');
        const courtSuiteControl = this.courtFormGroup.get('courtSuite');
        const courtCityControl = this.courtFormGroup.get('courtCity');
        const courtStateControl = this.courtFormGroup.get('courtState');
        const courtZipControl = this.courtFormGroup.get('courtZip');
        const courtPhoneControl = this.courtFormGroup.get('courtPhone');
        const courtExtControl = this.courtFormGroup.get('courtPhoneExt');

        let courtServiceAddress : OrderServiceAddress = {
            name: courtNameControl?.value,
            attention: courtContactControl?.value,
            address: courtStreetControl?.value,
            room: courtSuiteControl?.value,
            city: courtCityControl?.value,
            state: courtStateControl?.value,
            zip: courtZipControl?.value,
            phone: courtPhoneControl?.value,
            phoneExt: courtExtControl?.value
        };

        return [courtServiceAddress];
    }
    
    private prepareAccountInfo() : CustomerAccountInfo {
        const corpIdControl = this.accountFormGroup.get('corpId');
        const companyNumberControl = this.accountFormGroup.get('companyNumber');
        const customerNumberControl = this.accountFormGroup.get('customerNumber');
        const accountNameControl = this.accountFormGroup.get('accountName');
        const accountStreetControl = this.accountFormGroup.get('accountStreet');
        const accountRoomControl = this.accountFormGroup.get('accountRoom');
        const accountCityControl = this.accountFormGroup.get('accountCity');
        const accountStateControl = this.accountFormGroup.get('accountState');
        const accountZipControl = this.accountFormGroup.get('accountZip');
        const accountCountryControl = this.accountFormGroup.get('accountCountry');
        const accountSystemControl = this.accountFormGroup.get('accountSystem');

        let accountInfo : CustomerAccountInfo = {
            system: accountSystemControl?.value,
            corpId: corpIdControl?.value,
            companyNo: companyNumberControl?.value,
            customerNo: customerNumberControl?.value,
            name: accountNameControl?.value,
            street: accountStreetControl?.value,
            room: accountRoomControl?.value,
            city: accountCityControl?.value,
            state: accountStateControl?.value,
            zip: accountZipControl?.value,
            country: accountCountryControl?.value,
            accountNumber: (companyNumberControl?.value + '-' + customerNumberControl?.value)
        };

        return accountInfo;
    }

    public prepareDigital(){
        let obj = {}
        let digitalFields = this.digitalAddressesFormGroup;
        let deliveryFields = this.recipientAddressFormGroup;
        Object.assign(obj, {
            deadlineDate: digitalFields.controls['deadlineDate'].value,
            deadlineTime: digitalFields.controls['deadlineTime'].value,
            deadlineTimeZone: {
                offSet:this.timeZoneOffset(digitalFields.controls['deadlineTimeZone'].value),
                name:digitalFields.controls['deadlineTimeZone'].value,
            }
        })
        if(this.typeName == 'INHOUSE'){
            let pickupInfo = this.pickupDetailFormGroup
            Object.assign(obj, {
                pickupInformation:{
                    locationName: pickupInfo.controls['pickupCompany'].value,
                    address: pickupInfo.controls['pickupStreet'].value,
                    city: pickupInfo.controls['pickupCity'].value,
                    state: pickupInfo.controls['pickupState'].value,
                    zip: pickupInfo.controls['pickupZip'].value
                },
                deliveryInformation:{
                    locationName: deliveryFields.controls['locationName'].value,
                    address: deliveryFields.controls['locationAddress'].value,
                    city: deliveryFields.controls['locationCity'].value,
                    state: deliveryFields.controls['locationState'].value,
                    zip: deliveryFields.controls['locationZip'].value
                }
            })
        } else if(this.typeName == 'ONSITE') {
            Object.assign(obj, {
                onSiteLocation:{
                    locationName: deliveryFields.controls['locationName'].value,
                    address: deliveryFields.controls['locationAddress'].value,
                    city: deliveryFields.controls['locationCity'].value,
                    state: deliveryFields.controls['locationState'].value,
                    zip: deliveryFields.controls['locationZip'].value
                }
            })
        } else if(this.categoryName == ServiceTypes.MAILOUT){
            Object.assign(obj, {recipientAddresses: digitalFields.controls['recipientAddresses'].value});
        } else if (this.categoryName == ServiceTypes.OTHER){
            Object.assign(obj, {
                otherDescription: this.serviceOtherControl.value,
                deliveryInformation:{
                    locationName: deliveryFields.controls['locationName'].value,
                    address: deliveryFields.controls['locationAddress'].value,
                    city: deliveryFields.controls['locationCity'].value,
                    state: deliveryFields.controls['locationState'].value,
                    zip: deliveryFields.controls['locationZip'].value
                }
            })
        } else {
            Object.assign(obj, {
                deliveryInformation:{
                    locationName: deliveryFields.controls['locationName'].value,
                    address: deliveryFields.controls['locationAddress'].value,
                    city: deliveryFields.controls['locationCity'].value,
                    state: deliveryFields.controls['locationState'].value,
                    zip: deliveryFields.controls['locationZip'].value
                }
            })
        }
        return obj;
    }

    public prepareEDiscovery(eDiscoveryOrTrial:boolean) {
        let obj = {};
        // eDiscovery - True, Trial - False
        if(eDiscoveryOrTrial == !this.eDiscoverySendTrialInfo){
            this.matterServiceOptions.map((selectedService)=>{
                let selected : any[]= this.eDiscoveryServiceSelectControl.value;
                let result = selected.find((option)=> option == selectedService.value)
                if(result){
                    Object.assign(obj,{[selectedService.value]: true})
                    if(result == 'other')
                        Object.assign(obj, {"otherDescription":this.serviceOtherControl.value ? this.serviceOtherControl.value : ''})
                }else{
                    Object.assign(obj,{[selectedService.value]: false})
                }
            })
        } else {
            return null;
        }
        return obj;
    }
    
    public prepareEDiscoveryFields() {
        // TODO: Remove sections as needed for ediscovery fields in SCS-954 
        let type = this.categoryFormGroup.get('category')?.value == ServiceTypes.EDISCOVERY ? true : false;
        let formGroup = type ? this.eDiscoveryTrialFormGroup : this.trialServicesFormGroup;
        let infoRequest = this.eDiscoveryInfoFormGroup;
        let obj = {
            descriptionOfNeeds: this.hideClientMatter ? this.eDiscoveryDescriptionOfNeeds.value : null,
            // Trial services are only for Trial service category orders
            trialServicesFields: !type ? {
                firmInfo:{
                    name:formGroup.get('eDiscoveryCompany')?.value,
                    address:formGroup.get('eDiscoveryStreet')?.value,
                    phone:formGroup.get('eDiscoveryPhone')?.value
                },
                trialDate:formGroup.get('trialDate')?.value,
                trialTime:formGroup.get('trialTime')?.value,
                trialTimeZone:{
                    offSet:this.timeZoneOffset(formGroup.get('trialTimeZone')?.value),
                    name:formGroup.get('trialTimeZone')?.value
                },
                trialDuration:formGroup.get('trialDuration')?.value,
                courtName:formGroup.get('courtName')?.value,
                courtRoomLocation:formGroup.get('courtRoom')?.value
            } : null,
            currentlyUse: {
                relativity: infoRequest.get('relativity')?.value ? true : false,
                CSdisco: infoRequest.get('csDisco')?.value ? true : false,
                trialDirector: infoRequest.get('trialDirector')?.value ? true : false,
                caseCloud: infoRequest.get('caseCloud')?.value ? true : false,
                other: infoRequest.get('other')?.value ? true : false,
                otherDescription: infoRequest.get('other')?.value ? this.eDiscoveryInfoOtherControl.value : ''
            }
        }
        return obj;
    }

    public prepareInvestigations() {
        let obj = {
            name: this.categoryName == ServiceTypes.SUB_ROSA || this.categoryName == ServiceTypes.SKIPTRACE ? 
                (this.categoryName == ServiceTypes.SUB_ROSA ?  this.subrosaFormGroup.get('name')?.value : this.skipTraceFormGroup.get('name')?.value)
                : null,  
            DOB: this.categoryName == ServiceTypes.SKIPTRACE ? this.skipTraceFormGroup.get('dateOfBirth')?.value : null,
            SSN: this.categoryName == ServiceTypes.SKIPTRACE ? this.skipTraceFormGroup.get('ssn')?.value : null,
            lastKnownAddresses: this.categoryName == ServiceTypes.SKIPTRACE ? this.lastKnownAddresses : null,
            homeAddress: this.categoryName == ServiceTypes.SUB_ROSA ? 
            {
                locationName: this.subrosaFormGroup.get('homeName')?.value,
                address: this.subrosaFormGroup.get('homeAddress')?.value,
                city: this.subrosaFormGroup.get('homeCity')?.value,
                state: this.subrosaFormGroup.get('homeState')?.value,
                zip: this.subrosaFormGroup.get('homeZip')?.value
            } : null,
            workAddress: this.categoryName == ServiceTypes.SUB_ROSA || this.categoryName == ServiceTypes.SKIPTRACE ? 
            (this.categoryName == ServiceTypes.SUB_ROSA ?  
                {
                    locationName: this.subrosaFormGroup.get('workName')?.value,
                    address: this.subrosaFormGroup.get('workAddress')?.value,
                    city: this.subrosaFormGroup.get('workCity')?.value,
                    state: this.subrosaFormGroup.get('workState')?.value,
                    zip: this.subrosaFormGroup.get('workZip')?.value
                } 
                :
                {
                    locationName: this.skipTraceFormGroup.get('locationName')?.value,
                    address: this.skipTraceFormGroup.get('locationAddress')?.value,
                    city: this.skipTraceFormGroup.get('locationCity')?.value,
                    state: this.skipTraceFormGroup.get('locationState')?.value,
                    zip: this.skipTraceFormGroup.get('locationZip')?.value
                }
            ) : null,
            socialMediaLinks: this.categoryName == ServiceTypes.SKIPTRACE ? this.skipTraceFormGroup.get('socialMediaLinks')?.value : null,
            pointOfOrigination: this.categoryName == ServiceTypes.SUB_ROSA ? this.subrosaFormGroup.get('pointOfOrigination')?.value : null,
            natureOfInjury: this.categoryName == ServiceTypes.SUB_ROSA ? this.subrosaFormGroup.get('natureOfInjury')?.value : null,
            natureOfCase: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION)
                ? this.alternateInvestigationsFormGroup.get('assignmentInstructions')?.value : null,
            claimedInjuries: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE)
                ? this.alternateInvestigationsFormGroup.get('claimedInjuries')?.value : null,
            informationOfClaimedInjuries: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE)
                ? this.alternateInvestigationsFormGroup.get('informationOfClaimedInjuries')?.value : null,
            location: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION)
            ? {
                locationName: this.alternateInvestigationsFormGroup.get('locationName')?.value,
                address: this.alternateInvestigationsFormGroup.get('locationAddress')?.value,
                city: this.alternateInvestigationsFormGroup.get('locationCity')?.value,
                state: this.alternateInvestigationsFormGroup.get('locationState')?.value,
                zip: this.alternateInvestigationsFormGroup.get('locationZip')?.value
            } : null,
            dateOfLoss: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION)
            ? this.alternateInvestigationsFormGroup.get('dateOfLoss')?.value : null,
            timeOfLoss: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION)
            ? this.alternateInvestigationsFormGroup.get('timeOfLoss')?.value : null,
            timeZoneOfLoss: (this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION) 
            ? 
            {
                offSet: this.timeZoneOffset(this.alternateInvestigationsFormGroup.get('timeZoneOfLoss')?.value),
                name: this.alternateInvestigationsFormGroup.get('timeZoneOfLoss')?.value
            } : null,
        }
        return obj;
    }

    private prepareOrderDataJSON() : OrderDataJSON {
        const categoryControl = this.categoryFormGroup.get('category');
        
        let accountInfo : CustomerAccountInfo = this.prepareAccountInfo();

        let category : ServiceCategoryWithTypes | undefined;
        
        if(categoryControl) 
            category = this.categories.find((c) => c.category.name == categoryControl.value);

        let orderDataJSON : OrderDataJSON = {
            isNewUserInfo: false,
            customerInformation: accountInfo, 
            // serviceCategoryDescription: category ? category.category.name : "", 
            serviceCategoryDescription: this.categoryFormGroup.get('serviceCategoryDescription')?.value, 
            // TODO: build logic to set these as needed
            depositionsServicesNeeded: null,
            depositionsFields: null,
            digitalFields: (this.serviceName == DIGITAL && !this.digitalDeliveryOnly) ? this.prepareDigital() : null,
            eDiscoveryServicesNeeded: this.serviceName == EDISCOVERY ? this.prepareEDiscovery(true) : null,
            trialServicesServicesNeeded: this.serviceName == EDISCOVERY ? this.prepareEDiscovery(false) : null,
            eDiscoveryFields: this.serviceName == EDISCOVERY ? this.prepareEDiscoveryFields() : null,
            investigationFields: this.serviceName == INVESTIGATIONS ? this.prepareInvestigations() : null,
            recordRetrievalFields: null
        };

        return orderDataJSON;
    }

    public switchDigital(state:boolean) {
        this.digitalDeliveryOnly = state;
        this.serviceDetailFormGroup = state ? this.digitalDeliveryFormGroup : this.digitalAddressesFormGroup;
        this.serviceDetailFormGroup.updateValueAndValidity();
    }

    public prepareDigitalMailoutAddresses() : OrderServiceAddress[] {
        const recipientAddresses = this.digitalAddressesFormGroup.get('recipientAddresses');
        if(recipientAddresses?.value.length > 0){
            let serviceAddresses : OrderServiceAddress[] = [];
            recipientAddresses?.value.map((address:any)=>{
                let newAddress : OrderServiceAddress = {
                    name: address.locationName,
                    address: address.locationAddress,
                    city: address.locationCity,
                    state: address.locationState,
                    zip: address.locationZip,
                };
                serviceAddresses.push(newAddress);
            })
            return serviceAddresses;
        } else {
            return [];
        }
    }

    public prepareInvestigationsAddress() : OrderServiceAddress[] {
        let form : FormGroup = this.fb.group({});
        let serviceArray : OrderServiceAddress[] = [];

        if(this.categoryName == ServiceTypes.SKIPTRACE){
            form = this.skipTraceFormGroup;
            this.lastKnownAddresses.map((address)=>{
                let location : OrderServiceAddress = {
                    name: address.locationName,
                    address: address.address,
                    city: address.city,
                    state: address.state,
                    zip: address.zip
                }
                serviceArray.push(location);
            })
            let serviceAddress : OrderServiceAddress = {
                name: form.get('locationName')?.value,
                address: form.get('locationAddress')?.value,
                city: form.get('locationCity')?.value,
                state: form.get('locationState')?.value,
                zip: form.get('locationZip')?.value,
            };
            if(serviceAddress.name && serviceAddress.address && serviceAddress.city 
                && serviceAddress.state && serviceAddress.zip)
                serviceArray.push(serviceAddress)

            return serviceArray;

        } else if(this.categoryName == ServiceTypes.SUB_ROSA){
            form = this.subrosaFormGroup;
            let serviceAddress : OrderServiceAddress = {
                name: form.get('workName')?.value,
                address: form.get('workAddress')?.value,
                city: form.get('workCity')?.value,
                state: form.get('workState')?.value,
                zip: form.get('workZip')?.value,
            };

            if(serviceAddress.name && serviceAddress.address && serviceAddress.city && serviceAddress.state && serviceAddress.zip)
                serviceArray.push(serviceAddress)
            
            serviceAddress = {
                name: form.get('homeName')?.value,
                address: form.get('homeAddress')?.value,
                city: form.get('homeCity')?.value,
                state: form.get('homeState')?.value,
                zip: form.get('homeZip')?.value,
            };
            if(serviceAddress.name && serviceAddress.address && serviceAddress.city && serviceAddress.state && serviceAddress.zip)
                serviceArray.push(serviceAddress)

            return serviceArray;
        } else if(this.categoryName == ServiceTypes.LIABILITY_RESPONSE || this.categoryName == ServiceTypes.INSURANCE_DEFENSE || this.categoryName == ServiceTypes.SUBJECT_INFORMATION){
            form = this.alternateInvestigationsFormGroup;
            let serviceAddress : OrderServiceAddress = {
                name: form.get('locationName')?.value,
                address: form.get('locationAddress')?.value,
                city: form.get('locationCity')?.value,
                state: form.get('locationState')?.value,
                zip: form.get('locationZip')?.value,
            };
            if(serviceAddress.name && serviceAddress.address && serviceAddress.city && serviceAddress.state && serviceAddress.zip)
                serviceArray.push(serviceAddress)

            return serviceArray;
        } else {
            return [];
        }
    }


    public prepareDigitalAddress() : OrderServiceAddress[] {

        const locationNameControl = this.recipientAddressFormGroup.get('locationName');
        const locationAddressControl = this.recipientAddressFormGroup.get('locationAddress');
        const locationCityControl = this.recipientAddressFormGroup.get('locationCity');
        const locationStateControl = this.recipientAddressFormGroup.get('locationState');
        const locationZipControl = this.recipientAddressFormGroup.get('locationZip');

        console.log("Control State:", !locationNameControl || !locationAddressControl || !locationCityControl 
            || !locationStateControl || !locationZipControl)
        if(!locationNameControl || !locationAddressControl || !locationCityControl 
                || !locationStateControl || !locationZipControl)
            return [];

        let serviceAddress : OrderServiceAddress = {
            name: locationNameControl?.value,
            address: locationAddressControl?.value,
            city: locationCityControl?.value,
            state: locationStateControl?.value,
            zip: locationZipControl?.value,
        };
        console.log("prepareDigitalAddress:", serviceAddress)
        return [serviceAddress];

    }

    public prepareEDiscoveryAddress() : OrderServiceAddress[] {
        const eDiscoveryCompanyControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany');
        const eDiscoveryStreetControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryStreet');
        const eDiscoveryCityControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryCity');
        const eDiscoveryStateControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryState');
        const eDiscoveryZipControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryZip');
        const eDiscoverySuiteControl = this.eDiscoveryTrialFormGroup.get('eDiscoverySuite'); 
        const eDiscoveryContactControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryContact');
        const eDiscoveryPhoneControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone');
        const eDiscoveryExtControl = this.eDiscoveryTrialFormGroup.get('eDiscoveryPhoneExt');

        if(!eDiscoveryCompanyControl || !eDiscoveryStreetControl || !eDiscoveryCityControl 
                || !eDiscoveryStateControl || !eDiscoveryZipControl)
            return [];

        let serviceAddress : OrderServiceAddress = {
            name: eDiscoveryCompanyControl?.value,
            address: eDiscoveryStreetControl?.value,
            city: eDiscoveryCityControl?.value,
            state: eDiscoveryStateControl?.value,
            zip: eDiscoveryZipControl?.value,
            room: eDiscoverySuiteControl?.value,
            attention: eDiscoveryContactControl?.value,
            phone: eDiscoveryPhoneControl?.value,
            phoneExt: eDiscoveryExtControl?.value
        };

        return [serviceAddress];
    }
    public saveNewEDiscoveryAddress(){
        let newOrderAddress = new AddressBook();
        newOrderAddress.name = this.eDiscoveryTrialFormGroup.get('eDiscoveryCompany')?.value; // *required
        newOrderAddress.isPrivate = true;
        newOrderAddress.streetAddress1 = this.eDiscoveryTrialFormGroup.get('eDiscoveryStreet')?.value; // *required
        newOrderAddress.roomNumber = this.eDiscoveryTrialFormGroup.get('eDiscoverySuite')?.value;
        newOrderAddress.city = this.eDiscoveryTrialFormGroup.get('eDiscoveryCity')?.value; // *required
        newOrderAddress.state = this.eDiscoveryTrialFormGroup.get('eDiscoveryState')?.value; // *required
        newOrderAddress.postalCode = this.eDiscoveryTrialFormGroup.get('eDiscoveryZip')?.value; // *required
        newOrderAddress.primaryPhoneNumber = this.eDiscoveryTrialFormGroup.get('eDiscoveryPhone')?.value; // *required
        newOrderAddress.phoneExt = this.eDiscoveryTrialFormGroup.get('eDiscoveryPhoneExt')?.value;

        newOrderAddress.corp_id = this.userPermissionService.loggedInUser!.corpId;
        newOrderAddress.owner_uuid = this.userPermissionService.loggedInUser!.uuid;
        this.addressBookService.push(newOrderAddress).subscribe((res)=>{return;});
    }

    public prepareDeliveryAddress() : OrderServiceAddress[] {
        const deliveryCompanyControl = this.deliveryDetailFormGroup.get('deliveryCompany');
        const deliveryStreetControl = this.deliveryDetailFormGroup.get('deliveryStreet');
        const deliveryCityControl = this.deliveryDetailFormGroup.get('deliveryCity');
        const deliveryStateControl = this.deliveryDetailFormGroup.get('deliveryState');
        const deliveryZipControl = this.deliveryDetailFormGroup.get('deliveryZip');
        const deliverySuiteControl = this.deliveryDetailFormGroup.get('deliverySuite'); 
        const deliveryContactControl = this.deliveryDetailFormGroup.get('deliveryContact');
        const deliveryPhoneControl = this.deliveryDetailFormGroup.get('deliveryPhone');
        const deliveryExtControl = this.deliveryDetailFormGroup.get('deliveryPhoneExt');

        if(!deliveryCompanyControl || !deliveryStreetControl || !deliveryCityControl 
                || !deliveryStateControl || !deliveryZipControl)
            return [];

        let serviceAddress : OrderServiceAddress = {
            name: deliveryCompanyControl?.value,
            address: deliveryStreetControl?.value,
            city: deliveryCityControl?.value,
            state: deliveryStateControl?.value,
            zip: deliveryZipControl?.value,
            room: deliverySuiteControl?.value,
            attention: deliveryContactControl?.value,
            phone: deliveryPhoneControl?.value,
            phoneExt: deliveryExtControl?.value
        };

        return [serviceAddress];
    }
    public saveNewDeliveryAddress(){
        let newOrderAddress = new AddressBook();
        newOrderAddress.name = this.deliveryDetailFormGroup.get('deliveryCompany')?.value; // *required
        newOrderAddress.isPrivate = true;
        newOrderAddress.streetAddress1 = this.deliveryDetailFormGroup.get('deliveryStreet')?.value; // *required
        newOrderAddress.roomNumber = this.deliveryDetailFormGroup.get('deliverySuite')?.value;
        newOrderAddress.city = this.deliveryDetailFormGroup.get('deliveryCity')?.value; // *required
        newOrderAddress.state = this.deliveryDetailFormGroup.get('deliveryState')?.value; // *required
        newOrderAddress.postalCode = this.deliveryDetailFormGroup.get('deliveryZip')?.value; // *required
        newOrderAddress.primaryPhoneNumber = this.deliveryDetailFormGroup.get('deliveryPhone')?.value; // *required
        newOrderAddress.phoneExt = this.deliveryDetailFormGroup.get('deliveryPhoneExt')?.value;

        newOrderAddress.corp_id = this.userPermissionService.loggedInUser!.corpId;
        newOrderAddress.owner_uuid = this.userPermissionService.loggedInUser!.uuid;
        this.addressBookService.push(newOrderAddress).subscribe((res)=>{return;});
    }
    public prepareAccountAddress(accountAddress:OrderServiceAddress) : OrderServiceAddress {

        if(!accountAddress.address){
            accountAddress.address = this.accountFormGroup.get('accountStreet')?.value;
            accountAddress.name = this.accountFormGroup.get('accountName')?.value;
            accountAddress.room = this.accountFormGroup.get('accountRoom')?.value;
            accountAddress.city = this.accountFormGroup.get('accountCity')?.value;
            accountAddress.state = this.accountFormGroup.get('accountState')?.value;
            accountAddress.zip = this.accountFormGroup.get('accountZip')?.value;
        }
        return accountAddress;
    }

    public preparePickupAddress() : OrderServiceAddress {
        const pickupCompanyControl = this.pickupDetailFormGroup.get('pickupCompany');
        const pickupStreetControl = this.pickupDetailFormGroup.get('pickupStreet');
        const pickupCityControl = this.pickupDetailFormGroup.get('pickupCity');
        const pickupStateControl = this.pickupDetailFormGroup.get('pickupState');
        const pickupZipControl = this.pickupDetailFormGroup.get('pickupZip');
        const pickupSuiteControl = this.pickupDetailFormGroup.get('pickupSuite'); 
        const pickupContactControl = this.pickupDetailFormGroup.get('pickupContact');
        const pickupPhoneControl = this.pickupDetailFormGroup.get('pickupPhone');
        const pickupExtControl = this.pickupDetailFormGroup.get('pickupPhoneExt');

        if(!pickupCompanyControl || !pickupStreetControl || !pickupCityControl 
                || !pickupStateControl || !pickupZipControl)
            throw new Error('Error: missing form control');

        let serviceAddress : OrderServiceAddress = {
            name: pickupCompanyControl?.value,
            address: pickupStreetControl?.value,
            city: pickupCityControl?.value,
            state: pickupStateControl?.value,
            zip: pickupZipControl?.value,
            room: pickupSuiteControl?.value,
            attention: pickupContactControl?.value,
            phone: pickupPhoneControl?.value,
            phoneExt: pickupExtControl?.value
        };

        return serviceAddress;
    }

    public saveNewPickupAddress(){
        let newOrderAddress = new AddressBook();
        newOrderAddress.isPrivate = true;
        newOrderAddress.name = this.pickupDetailFormGroup.get('pickupCompany')?.value;
        newOrderAddress.isDefault = false;
        newOrderAddress.streetAddress1 = this.pickupDetailFormGroup.get('pickupStreet')?.value;
        newOrderAddress.roomNumber = this.pickupDetailFormGroup.get('pickupSuite')?.value;
        newOrderAddress.city = this.pickupDetailFormGroup.get('pickupCity')?.value;
        newOrderAddress.state = this.pickupDetailFormGroup.get('pickupState')?.value;
        newOrderAddress.postalCode = this.pickupDetailFormGroup.get('pickupZip')?.value;
        newOrderAddress.primaryPhoneNumber = this.pickupDetailFormGroup.get('pickupPhone')?.value;
        newOrderAddress.phoneExt = this.pickupDetailFormGroup.get('pickupPhoneExt')?.value;

        newOrderAddress.corp_id = this.userPermissionService.loggedInUser!.corpId;
        newOrderAddress.owner_uuid = this.userPermissionService.loggedInUser!.uuid;
        this.addressBookService.push(newOrderAddress).subscribe((res)=>{return;});
    }

    private checkDeliveryDetailAddress (address : OrderServiceAddress) : boolean {
        // console.log('attention', (!!address.attention && (address.attention != this.draftDeliveryDetailAddress?.attention)), address.attention, this.draftDeliveryDetailAddress?.attention);
        // console.log('address', (!!address.address && (address.address != this.draftDeliveryDetailAddress?.address)), address.address, this.draftDeliveryDetailAddress?.address)
        // console.log('room', (!!address.room && (address.room != this.draftDeliveryDetailAddress?.room)), address.room, this.draftDeliveryDetailAddress?.room)
        // console.log('city', (!!address.city && (address.city != this.draftDeliveryDetailAddress?.city)), address.city, this.draftDeliveryDetailAddress?.city)
        // console.log('state', (!!address.state && (address.state != this.draftDeliveryDetailAddress?.state)), address.state, this.draftDeliveryDetailAddress?.state)
        // console.log('zip', (!!address.zip && (address.zip != this.draftDeliveryDetailAddress?.zip)), address.zip, this.draftDeliveryDetailAddress?.zip)
        // console.log('phone', (!!address.phone && (address.phone != this.draftDeliveryDetailAddress?.phone)), address.phone, this.draftDeliveryDetailAddress?.phone)
        // console.log('phoneExt', (!!address.phoneExt && (address.phoneExt != this.draftDeliveryDetailAddress?.phoneExt)), address.phoneExt, this.draftDeliveryDetailAddress?.phoneExt)
        // console.log('name', (!!address.name && (address.name != this.draftDeliveryDetailAddress?.name)), address.name, this.draftDeliveryDetailAddress?.name)


        return (address && ((!!address.attention && (address.attention != this.draftDeliveryDetailAddress?.attention))
            || (!!address.address && (address.address != this.draftDeliveryDetailAddress?.address))
            || (!!address.room && (address.room != this.draftDeliveryDetailAddress?.room))
            || (!!address.city && (address.city != this.draftDeliveryDetailAddress?.city))
            || (!!address.state && (address.state != this.draftDeliveryDetailAddress?.state))
            || (!!address.zip && (address.zip != this.draftDeliveryDetailAddress?.zip))
            || (!!address.phone && (address.phone != this.draftDeliveryDetailAddress?.phone)) 
            || (!!address.phoneExt && (address.phoneExt != this.draftDeliveryDetailAddress?.phoneExt)) 
            || (!!address.name && (address.name != this.draftDeliveryDetailAddress?.name)) ));
    }

    private checkCourtDeliveryAddress (address : OrderServiceAddress) : boolean {
        return (address && ((!!address.attention && (address.attention != this.draftCourtDeliveryAddress?.attention))
            || (!!address.address && (address.address != this.draftCourtDeliveryAddress?.address))
            || (!!address.room && (address.room != this.draftCourtDeliveryAddress?.room))
            || (!!address.city && (address.city != this.draftCourtDeliveryAddress?.city))
            || (!!address.state && (address.state != this.draftCourtDeliveryAddress?.state))
            || (!!address.zip && (address.zip != this.draftCourtDeliveryAddress?.zip))
            || (!!address.phone && (address.phone != this.draftCourtDeliveryAddress?.phone)) 
            || (!!address.phoneExt && (address.phoneExt != this.draftCourtDeliveryAddress?.phoneExt)) 
            || (!!address.name && (address.name != this.draftCourtDeliveryAddress?.name)) ));
    }

    private checkPickupAddress (address : OrderServiceAddress) : boolean {
        return (address && ((!!address.attention && (address.attention != this.draftPickupAddress?.attention))
            || (!!address.address && (address.address != this.draftPickupAddress?.address))
            || (!!address.room && (address.room != this.draftPickupAddress?.room))
            || (!!address.city && (address.city != this.draftPickupAddress?.city))
            || (!!address.state && (address.state != this.draftPickupAddress?.state))
            || (!!address.zip && (address.zip != this.draftPickupAddress?.zip))
            || (!!address.phone && (address.phone != this.draftPickupAddress?.phone)) 
            || (!!address.phoneExt && (address.phoneExt != this.draftPickupAddress?.phoneExt)) 
            || (!!address.name && (address.name != this.draftPickupAddress?.name)) ));
    }

    private prepareSpecialInstructions(draft:boolean) : string {
        const advanceFeeControl = this.requestNotesFormGroup.get('advanceFees');
        const deliverCourtesyFeesBackControl = this.requestNotesFormGroup.get('alwaysDeliverCourtesyFeesBack');
        const specialInstructionsControl = this.requestNotesFormGroup.get('specialInstructions');
        const firstAppearanceFeesControl = this.requestNotesFormGroup.get('firstAppearanceFees');
        const digitalDeliveryOnlyControl = this.digitalDeliveryFormGroup.get('digitalCheckbox');
        let specialInstructions = (specialInstructionsControl?.value || '');

        // console.log("Advance, Deliver, First", advanceFeeControl?.value, deliverCourtesyFeesBackControl?.value, firstAppearanceFeesControl?.value)
        if(!draft) {

            specialInstructions = specialInstructions + " " + (advanceFeeControl?.value ? "Advance Fees" : "");
            specialInstructions = specialInstructions + " " + (deliverCourtesyFeesBackControl?.value ? "Deliver Courtesy Copy" : "");
            specialInstructions = specialInstructions + " " + (firstAppearanceFeesControl?.value ? "Advance First Appearance Fees" : "");
            specialInstructions = specialInstructions + " " + (digitalDeliveryOnlyControl?.value ? "Digital Delivery" : "");

        }
        return specialInstructions;
    }

    public prepareUpdateOrderData(draft:boolean) : UpdateOrderData | undefined {
        const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
        const insuranceCompanyControl = this.categoryFormGroup.get('insuranceCompany');
        const insuranceAdjusterControl = this.categoryFormGroup.get('insuranceAdjuster');
        const serviceTypeControl = this.categoryFormGroup.get('type');

        const advanceFeeControl = this.requestNotesFormGroup.get('advanceFees');
        const firstAppearanceFeeControl = this.requestNotesFormGroup.get('firstAppearanceFees')

        const companyNumberControl = this.accountFormGroup.get('companyNumber');
        const customerNumberControl = this.accountFormGroup.get('customerNumber');

        const claimNumberControl = this.categoryFormGroup.get('claimNumber');

        const deliverCourtesyFeesBackControl = this.requestNotesFormGroup.get('alwaysDeliverCourtesyFeesBack');

        const hasNoContactHoursControl = this.requestNotesFormGroup.get('hasNoContactHours');
        const noContactFromTimeControl = this.requestNotesFormGroup.get('noContactFromTime');
        const noContactToTimeControl = this.requestNotesFormGroup.get('noContactToTime');

        const documentDescriptionControl = this.fileFormGroup.get('description');
        // const specialInstructionsControl = this.requestNotesFormGroup.get('specialInstructions');

        let noContactFromTime = this.prepareNoContactTime(noContactFromTimeControl?.value || '');
        let noContactToTime = this.prepareNoContactTime(noContactToTimeControl?.value || '');

        let specialInstructions = this.prepareSpecialInstructions(draft);

        let orderDataJSON : OrderDataJSON = this.prepareOrderDataJSON();
        let orderDataJSONString = JSON.stringify(orderDataJSON);
        let courtData : CourtData = this.prepareCourtData();
        let caseData : CaseData | undefined = this.prepareCaseData(draft);

        let pickupAddress : OrderServiceAddress;
        let deliveryAddresses : OrderServiceAddress[];

        try{
            pickupAddress = this.preparePickupAddress();    
        } catch(error: any) {
            console.error(error);
            return;
        }

        let orderData : UpdateOrderData = {};
        if(clientMatterNumberControl?.value && clientMatterNumberControl.value != this.entity?.clientMatterNumber) {
            orderData.clientMatterNumber = clientMatterNumberControl.value;
        }

        if(insuranceCompanyControl?.value && insuranceCompanyControl.value != this.entity?.insuranceCompany) {
            orderData.insuranceCompany = insuranceCompanyControl.value;
        }

        if(insuranceAdjusterControl?.value && insuranceAdjusterControl.value != this.entity?.insuranceAdjuster) {
            orderData.insuranceAdjuster = insuranceAdjusterControl.value;
        }

        if(advanceFeeControl?.value && advanceFeeControl.value != this.entity?.advancedFees) {
            orderData.advancedFees = advanceFeeControl.value;
        }

        if(firstAppearanceFeeControl?.value && firstAppearanceFeeControl.value != this.entity?.firstAppearanceFees) {
            orderData.firstAppearanceFees = firstAppearanceFeeControl.value;
        }

        if(companyNumberControl?.value && companyNumberControl.value != this.entity?.companyNumber) {
            orderData.companyNumber = companyNumberControl.value.toString();
        }

        if(customerNumberControl?.value && customerNumberControl.value != this.entity?.customerNumber) {
            orderData.customerNumber = customerNumberControl.value.toString();
        }

        if(claimNumberControl?.value && claimNumberControl.value != this.entity?.claimNumber) {
            orderData.claimNumber = claimNumberControl.value;
        }
        
        if(deliverCourtesyFeesBackControl?.value && deliverCourtesyFeesBackControl.value != this.entity?.alwaysDeliverCourtesyFeesBack) {
            orderData.alwaysDeliverCourtesyFeesBack = deliverCourtesyFeesBackControl.value;
        }

        if(hasNoContactHoursControl?.value && hasNoContactHoursControl.value != this.entity?.hasNoContactHours) {
            orderData.hasNoContactHours = hasNoContactHoursControl.value;
        }

        if(noContactFromTime && noContactFromTime != this.entity.noContactFromTime) {
            orderData.noContactFromTime = noContactFromTime;
        }

        if(noContactToTime && noContactToTime != this.entity.noContactToTime) {
            orderData.noContactToTime = noContactToTime;
        }

        if(serviceTypeControl?.value && serviceTypeControl.value != this.entity?.filingServiceType_uuid) {
            orderData.filingServiceType_uuid = serviceTypeControl.value;
        }

        if(documentDescriptionControl?.value && documentDescriptionControl.value != this.entity?.documentsDescription) {
            orderData.documentsDescription = documentDescriptionControl.value;
        }

        if(specialInstructions != this.draftSubmission[0].requestNotes) {
            orderData.requestNotes = specialInstructions;
        }

        if(orderDataJSON && orderDataJSONString != this.entity.orderData) {
            orderData.orderData = orderDataJSONString;
        }

        if(courtData && (courtData.address != this.entity.courtAddress 
                || courtData.name != this.entity.courtName 
                || courtData.city != this.entity.courtCity
                || courtData.state != this.entity.courtState
                || courtData.zip != this.entity.courtZip)) {
            orderData.court = courtData;
        }

        // if the uuid is not defined we have a new case, 
        // or if the uuid differs from the entity a different case was selected from the list
        // or if the caseNumber has changed we need to send that data, so the order caseNumber can be updated
        if(caseData && (!caseData.uuid 
                || caseData.uuid != this.entity.case_uuid 
                || caseData.caseNumber != this.entity.caseNumber)) {
            orderData.case = caseData;
        }

        if(this.checkPickupAddress(pickupAddress)) {
            orderData.pickupAddress = pickupAddress;
        }

        // if there are deleted items with a uuid, then a servee was removed, the list needs to be updated
        // or if there are servees with a uuid, then a new servee was added, the list needs to be updated 
        // if((this.deletedServees.findIndex((ds) => {return !!ds.uuid}) >= 0) 
        //         || (this.servees.findIndex((s) => {return !s.uuid}) >= 0)) {
        //     // console.log('setting to serve', this.servees);
        //     orderData.serves = this.servees;
        // }

        if(this.serviceName == this.courtProcess){
            if(this.showCourt()) {
                deliveryAddresses = this.prepareCourtServiceAddress();
                // console.log('court delivery', deliveryAddresses)
                // console.log('draft court delivery', this.draftCourtDeliveryAddress);
                if(this.checkCourtDeliveryAddress(deliveryAddresses[0])) {
                    // console.log('setting court delivery');
                    orderData.addresses = deliveryAddresses;
                }
            } else if(this.showDeliverTo()) {
                deliveryAddresses = this.prepareDeliveryAddress();
                // console.log('detail delivery', deliveryAddresses);
                // console.log('draft detail delivery', this.draftDeliveryDetailAddress);
                if(this.checkDeliveryDetailAddress(deliveryAddresses[0])) {
                    // console.log('setting delivery detail');
                    orderData.addresses = deliveryAddresses;
                }
            } else {
                // console.log('service addresses', this.serviceAddresses);
                // deliveryAddresses = this.serviceAddresses;
                // if there are deleted items with a uuid, then a servee was removed, the list needs to be updated
                // or if there are servees with a uuid, then a new servee was added, the list needs to be updated 
                if((this.deletedDeliveryAddresses.findIndex((ds) => {return !!ds.uuid}) >= 0) 
                        || (this.serviceAddresses.findIndex((s) => {return !s.uuid}) >= 0)) {
                    // console.log('setting service addresses', this.serviceAddresses);
                    orderData.addresses = this.serviceAddresses;
                }
            }
        } else if(this.serviceName == this.eDiscovery){
            deliveryAddresses = this.prepareEDiscoveryAddress();
            if(this.checkDeliveryDetailAddress(deliveryAddresses[0])) {
                orderData.addresses = deliveryAddresses;
            }

        } else if(this.serviceName == this.digital){
            if(!this.digitalDeliveryOnly){
                if(this.categoryName == ServiceTypes.MAILOUT){
                    deliveryAddresses = this.prepareDigitalMailoutAddresses();
                    orderData.addresses = deliveryAddresses;
                } else if (this.recipientAddressFormGroup.valid){
                    deliveryAddresses = this.prepareDigitalAddress();
                    orderData.addresses = deliveryAddresses;
                }
            }
        } else if(this.serviceName == this.investigations){
            deliveryAddresses = this.prepareInvestigationsAddress();
            orderData.addresses = deliveryAddresses;
        }

        return orderData;
    }

    private prepareNoContactTime(controlValue : string) {
        if(controlValue == '') return null;

        let hour="",min="",time=""; 

        hour = controlValue.slice(0,2)
        min = controlValue.slice(3,5)
        if(min == "0") min += "0";
            
        time = hour.concat(min);

        let value = parseInt(time);
        return (Number.isNaN(value) ? null : value); 
    }

    public prepareOrderData(draft: boolean) : OrderData | undefined {

        let documentCheck = (!draft && (this.documentsRequired == ServiceTypeDocsRequired.Required) && this.files.length == 0);
        // missing documents guard
        if ( documentCheck ) return;

        const fileDescriptionControl = this.fileFormGroup.get('description');

        let descriptionCheck = (!draft && (this.documentsRequired == ServiceTypeDocsRequired.Required) && (!fileDescriptionControl || fileDescriptionControl.value == ''));
        // missing description guard
        if ( descriptionCheck ) return;
        let userData : UserData = this.prepareUserData();
        let caseData : CaseData | undefined = this.prepareCaseData(draft);
        let documents : DocumentData[] = this.prepareDocumentData();
        let courtData : CourtData = this.prepareCourtData();
        let orderDataJSON : OrderDataJSON = this.prepareOrderDataJSON();
        let pickupAddress : OrderServiceAddress;

        try{
            pickupAddress = this.preparePickupAddress();    
        } catch(error: any) {
            console.error(error);
            return;
        }

        let deliveryAddresses : OrderServiceAddress[] = [];
        if(this.serviceName == this.courtProcess){
            if(this.showCourt()) {
                deliveryAddresses = this.prepareCourtServiceAddress();
            } else if(this.showDeliverTo()) {
                deliveryAddresses = this.prepareDeliveryAddress();
            } else {
                deliveryAddresses = this.serviceAddresses;
            }
        } else if(this.serviceName == this.eDiscovery){
            this.prepareAccountAddress(pickupAddress);
            deliveryAddresses = this.prepareEDiscoveryAddress();
        } else if(this.serviceName == this.digital){
            this.prepareAccountAddress(pickupAddress);
            if(!this.digitalDeliveryOnly){
                if(this.categoryName == ServiceTypes.MAILOUT){
                    deliveryAddresses = this.prepareDigitalMailoutAddresses();
                }
                else{
                    deliveryAddresses = this.prepareDigitalAddress();
                }
            }
        } else if(this.serviceName == this.investigations){
            this.prepareAccountAddress(pickupAddress);
            deliveryAddresses = this.prepareInvestigationsAddress();
        } else {
            deliveryAddresses = this.serviceAddresses;
        }
        const claimNumberControl = this.categoryFormGroup.get('claimNumber');
        const insuranceCompanyControl = this.categoryFormGroup.get('insuranceCompany');
        const insuranceAdjusterControl = this.categoryFormGroup.get('insuranceAdjuster');
        const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
        
        const serviceTypeControl = this.categoryFormGroup.get('type');
        // const specialInstructionsControl = this.requestNotesFormGroup.get('specialInstructions');

        const customerNumberControl = this.accountFormGroup.get('customerNumber');
        const companyNumberControl = this.accountFormGroup.get('companyNumber');

        const advanceFeeControl = this.requestNotesFormGroup.get('advanceFees');
        const deliverCourtesyFeesBackControl = this.requestNotesFormGroup.get('alwaysDeliverCourtesyFeesBack');

        const hasNoContactHoursControl = this.requestNotesFormGroup.get('hasNoContactHours');
        const noContactFromTimeControl = this.requestNotesFormGroup.get('noContactFromTime');
        const noContactToTimeControl = this.requestNotesFormGroup.get('noContactToTime');

        const firstAppearanceFeeControl = this.requestNotesFormGroup.get('firstAppearanceFees')

        let numFrom = this.prepareNoContactTime(noContactFromTimeControl?.value || ''); 
        let numTo = this.prepareNoContactTime(noContactToTimeControl?.value || '');

        let orderData : OrderData = {
            // bearer_Token: "", // we pass this in the request header 
            // authToken: "", // iManage specific
            // refresh_token?: "", // iManage specific
            // claimNumber: claimNumberControl?.value, // now optional for SharePoint
            clientMatterNumber: clientMatterNumberControl?.value,
            // insuranceCompany: "", // only included if the claim number is set
            // insuranceAdjuster: "", // only included if the claim number is set 
            user: userData,
            sourceVendor: SOURCE_VENDOR,
            // vendor_submission_id: "", // future proofing, used for third party lookup
            // case: caseData, // now optional for draft support and SharePoint
            // filingServiceType_uuid: serviceTypeControl?.value, // now optional for draft order support.
            documents: documents,
            requestNotes: this.prepareSpecialInstructions(draft), // specialInstructionsControl?.value,
            court: courtData,
            // contacts: [], // our contact is the user placing the order per SCS-232
            // attorneys: [],
            // plaintiffs: [], // not used for FCLP orders per SCS-235
            // defendants: [],
            advancedFees: advanceFeeControl?.value,

            alwaysDeliverCourtesyFeesBack: deliverCourtesyFeesBackControl?.value,
            hasNoContactHours: hasNoContactHoursControl?.value,
            noContactFromTime: numFrom,
            noContactToTime: numTo,

            firstAppearanceFees: firstAppearanceFeeControl?.value,
            customerNumber: customerNumberControl?.value,
            companyNumber: companyNumberControl?.value,
            addresses: deliveryAddresses,
            pickupAddress: pickupAddress,
            orderData: JSON.stringify(orderDataJSON),
            successAccount_uuid: this.userPermissionService.loggedInUser!.uuid, // users are looked up with bearer token in request header
            documentsDescription: (fileDescriptionControl?.value || ""),
            // vendor_key: '', // optional for unique identifier from third party systems (i.e. SharePoint)
            saveAsDraft: draft,
            orderId: '' // added to support updating existing draft orders, submitted orders cannot be updated.
        }

        // add optional case data if defined
        if(caseData) {
            orderData.case = caseData;
        }

        // add optional filingServiceType 
        if(!draft || (serviceTypeControl && serviceTypeControl.value)) {
            orderData.filingServiceType_uuid = serviceTypeControl?.value;
        }

        // add optional insurance data if defined
        if(claimNumberControl && claimNumberControl.value) {
            orderData.claimNumber = claimNumberControl.value;
            orderData.insuranceCompany = insuranceCompanyControl?.value;
            orderData.insuranceAdjuster = insuranceAdjusterControl?.value;
        }

        return orderData;
    }

    public allSettled<T = any>(promises: Array<Promise<T>>): Promise<Array<AllSettledResponse<T>>> {
        return Promise.all(promises.map(promise =>
            promise
                .then(value => ({ status: 'processed' as 'processed', value }))
                .catch(reason => ({ status: 'rejected' as 'rejected', reason }))
        ));
    }

    public getUploadFile(submitDocument: SubmitDocument | AddDocumentResponse) : File {
        if(!submitDocument.uploadUri) throw new Error(`Error: missing upload URI: ${submitDocument.fileName}`);
        
        let uploadFile : File | undefined = this.files.find((f) => f.name == submitDocument.fileName);

        if(!uploadFile) throw new Error(`Error: missing file: ${submitDocument.fileName}`);

        return uploadFile;
    }

    public nonDataTracUpdateOrder(draft: boolean){
        // console.log('has entity', this.entity);
        let orderData = (this.prepareUpdateOrderData(draft) || {});
        if(!draft){
            Object.assign(orderData, {orderStatus:OrderStatus.STUB})
        }
        // console.log('order update data', orderData);
        let documents : DocumentData[] = this.prepareDocumentData();
        // console.log('documents', documents);
        let hasAddDocuments = documents.length;
        // console.log('deleted', this.deletedSeedDocuments);
        let hasDeleteDocuments = this.deletedSeedDocuments.length;
        let updateComplete = false;
        let deleteDocumentComplete = !hasDeleteDocuments;
        let addDocumentsComplete = !hasAddDocuments;
        // check for updates to the order
        let updates = [this.entityService.updateDraftOrder(this.entity.uuid, orderData)];
        // check for new docs, then upload them
        if(hasAddDocuments) {
            let addDocuments : AddDocumentData = {
                documents: documents,
                sourceVendor: SOURCE_VENDOR
            };    
            updates.push(this.fileUploadService.addDocumentsToOrder(this.entity.uuid, addDocuments));
        }
        
        // check for deleted docs, then call the delete
        if(hasDeleteDocuments) {
            let deleteDocuments = {
                documents: this.deletedSeedDocuments.map((d) => d.uuid)
            }
            updates.push(this.fileUploadService.removeDocumentsFromOrder(this.entity.uuid, deleteDocuments));
        }

        combineLatest(updates)
            .subscribe(
                (response: any) => {
                    response[0].subscribe((updateOrderResponse: any) => {
                        console.log('update order response', updateOrderResponse);
                        updateComplete = true;
                        if(!updateOrderResponse.success) {
                            this.isLoading = false;
                            console.error('There was problem updating the order:', updateOrderResponse.result);
                        } else if(updateComplete && deleteDocumentComplete && addDocumentsComplete) {
                            console.log("Updated order entity:", this.entity)            
                            if(!draft) { 
                                this.submitSuccess = updateOrderResponse.success;
                                if(!this.submitSuccess) { 
                                    this.isLoading = false;
                                    this.orderProcessCallback(updateOrderResponse);
                                } else { 
                                    this.entity.orderStatus = OrderStatus.STUB;
                                    this.entityService.generateNonDTEmail(this.entity, this.serviceName, this.categoryName).subscribe((result)=>{
                                        this.isLoading = false;
                                        this.orderProcessCallback(updateOrderResponse);
                                    }, (err: any)=>{   
                                        this.isLoading = false;
                                        this.orderProcessCallback(updateOrderResponse);
                                    })  
                                    // this.formDirty = false;
                                    // this.router.navigate(['/order/' + this.entity.uuid], {queryParams:{ finished: true }});
                                }
                            } else {
                                this.isLoading = false;
                                this.formDirty = false;
                                this.router.navigate(['/order/' + this.entity.uuid], {queryParams:{ draft: true }});
                            }
                        }
                    },(err: any)=>{
                        this.isLoading = false;
                        this.openErrorDialog(err);
                    })
                        
                    if(hasDeleteDocuments) {
                        response[(hasAddDocuments ? 2 : 1)].subscribe((deleteDocumentsResponse: any) => {
                            console.log('delete docs response', deleteDocumentsResponse);
                            deleteDocumentComplete = true;
                            if(updateComplete && deleteDocumentComplete && addDocumentsComplete) {
                                if(!draft) { 
                                    this.submitSuccess = response.success;
                                    if(!this.submitSuccess) {
                                        this.isLoading = false;
                                        this.orderProcessCallback(deleteDocumentsResponse);
                                    } else { 
                                        this.formDirty = false;
                                        this.entity.orderStatus = OrderStatus.STUB;
                                        this.entityService.generateNonDTEmail(this.entity, this.serviceName, this.categoryName).subscribe((result)=>{
                                            this.isLoading = false;
                                            this.orderProcessCallback(deleteDocumentsResponse);
                                        }, (err: any)=>{   
                                            this.isLoading = false;
                                            this.orderProcessCallback(deleteDocumentsResponse);
                                        })  
                                    }
                                } else {
                                    this.formDirty = false;
                                    this.router.navigate(['/order/' + this.entity.uuid], {queryParams:{ draft: true }});
                                }
                            } 
                        });
                    }
                    
                    // if docs were added we need to send them to S3
                    if(hasAddDocuments) {
                        response[1].subscribe(async (addDocumentsResponse: any) =>  {
                            console.log('add docs response', addDocumentsResponse);
                            addDocumentsComplete = true;
                            if(!addDocumentsResponse.success) {
                                this.isLoading = false;
                                console.error('There was problem updating the order', addDocumentsResponse.result);
                            } else if (updateComplete && deleteDocumentComplete && addDocumentsComplete) {
                                this.SubmittedDocuments = addDocumentsResponse.result.documents;
                                await this.uploadDocuments(this.SubmittedDocuments, draft, false);
                            }
                        });
                    } 
                }, (err)=>{
                    this.isLoading = false;
                    this.openErrorDialog(err);
                }
            );
    }
    
    public updateOrder(draft: boolean) {
        // console.log('has entity', this.entity);
        let orderData = (this.prepareUpdateOrderData(draft) || {});
        // console.log('order update data', orderData);
        let documents : DocumentData[] = this.prepareDocumentData();
        // console.log('documents', documents);
        let hasAddDocuments = documents.length;
        // console.log('deleted', this.deletedSeedDocuments);
        let hasDeleteDocuments = this.deletedSeedDocuments.length;
        let updateComplete = false;
        let deleteDocumentComplete = !hasDeleteDocuments;
        let addDocumentsComplete = !hasAddDocuments;

        // check for updates to the order
        let updates = [this.entityService.updateDraftOrder(this.entity.uuid, orderData)];
        // check for new docs, then upload them
        if(hasAddDocuments) {
            let addDocuments : AddDocumentData = {
                documents: documents,
                sourceVendor: SOURCE_VENDOR
            };    
            updates.push(this.fileUploadService.addDocumentsToOrder(this.entity.uuid, addDocuments));
        }
        
        // check for deleted docs, then call the delete
        if(hasDeleteDocuments) {
            let deleteDocuments = {
                documents: this.deletedSeedDocuments.map((d) => d.uuid)
            }
            updates.push(this.fileUploadService.removeDocumentsFromOrder(this.entity.uuid, deleteDocuments));
        }

        combineLatest(updates)
            .subscribe(
                (response: any) => {
                    response[0].subscribe((updateOrderResponse: any) => {
                        console.log('update order response', updateOrderResponse);
                        updateComplete = true;
                        if(!updateOrderResponse.success) {
                            this.isLoading = false;
                            console.error('There was problem updating the order:', updateOrderResponse.result);
                        } else if(updateComplete && deleteDocumentComplete && addDocumentsComplete) {
                            if(!draft) { 
                                this.entityService.processOrder(this.entity.uuid).subscribe(
                                    (processResponse: any) => {this.orderProcessCallback(processResponse);}
                                );
                            } else {
                                this.formDirty = false;
                                this.router.navigate(['/order/' + this.entity.uuid], {queryParams:{ draft: true }});
                            }
                        }
                    })
                     
                    if(hasDeleteDocuments) {
                        response[(hasAddDocuments ? 2 : 1)].subscribe((deleteDocumentsResponse: any) => {
                            console.log('delete docs response', deleteDocumentsResponse);
                            deleteDocumentComplete = true;
                            if(updateComplete && deleteDocumentComplete && addDocumentsComplete) {
                                if(!draft) { 
                                    this.entityService.processOrder(this.entity.uuid).subscribe(
                                        (processResponse: any) => {this.orderProcessCallback(processResponse);}
                                    );
                                } else {
                                    this.formDirty = false;
                                    this.router.navigate(['/order/' + this.entity.uuid], {queryParams:{ draft: true }});
                                }
                            } 
                        });
                    }
                    
                    // if docs were added we need to send them to S3
                    if(hasAddDocuments) {
                        response[1].subscribe(async (addDocumentsResponse: any) =>  {
                            console.log('add docs response', addDocumentsResponse);
                            addDocumentsComplete = true;
                            if(!addDocumentsResponse.success) {
                                this.isLoading = false;
                                console.error('There was problem updating the order', addDocumentsResponse.result);
                            } else if (updateComplete && deleteDocumentComplete && addDocumentsComplete) {
                                this.SubmittedDocuments = addDocumentsResponse.result.documents;
                                await this.uploadDocuments(this.SubmittedDocuments, draft);
                            }
                        });
                    } 
                }
            );
    }
        
    public orderProcessCallback(response: any) {
        // console.log('process response', processResponse);
        // set local success flag
        this.isLoading = false;
        this.submitSuccess = response.success;
        if(!this.submitSuccess) {
            // handle errors
            this.orderCompleteHeader = "There was a problem processing your order";
            this.orderSubmissionError = response.result;
            this.orderFailed(response.result, false);

        } else { 
            this.orderCompleteHeader = "Your order has been processed successfully";
            this.orderControlNumber = response.result[0];

            // console.log('submit order', this.SubmitOrder)
            this.formDirty = false;
            this.router.navigate(['/order/' + (this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid)], {queryParams:{ finished: true }});
        }
    }

    public async uploadDocuments(documents: Array<SubmitDocument | AddDocumentResponse>, draft: boolean, datatrac: boolean = true) {
        // clear out the array of any old failures
        this.failedDocumentUploads = [];
        // console.log('------ CALL SETTLED ------');
        let responses = await this.allSettled(documents.map(
            async (document) => {
                let uploadFile : File;
                try {
                    
                    uploadFile = this.getUploadFile(document);
                } catch (error: any) {
                    console.error(error);
                    // DEV NOTE: if getting the file fails,then we need to probably request a new uploadURI
                    this.openErrorDialog(error);
                    return;
                }
                
                let upload = await this.fileUploadService.uploadOrderDocument(document.uploadUri!, uploadFile!);
                // console.log('upload', upload);
                if(upload.status != 200) {
                    this.isLoading = false;
                    throw new Error(`File Upload Failed ${uploadFile.name}`);
                }
            }
        ));

        responses.map((response, index) => {
            // console.log('response', response, index);
            if(response.status != 'processed') this.failedDocumentUploads.push(index);
        });
        
        // console.log('failed', this.failedDocumentUploads);
        if(this.failedDocumentUploads.length) {
            this.orderFailed('One or more documents failed to upload', draft);
            return;
        }

        // console.log('is draft', draft);
        // console.log('submit order', this.SubmitOrder)
        let orderId = (this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid);
        if(!draft && datatrac) {
            // console.log('------ CALL PROCESS ------');
            
            this.entityService.processOrder(orderId).subscribe(
                (processResponse: any) => {this.orderProcessCallback(processResponse);}
            );
        } else if(!draft && !datatrac){
            // console.log('------ DRAFT NAV ------');
            this.formDirty = false;
        } else {
            // console.log('------ DRAFT NAV ------');
            this.formDirty = false;
            this.router.navigate(['/order/' + orderId], {queryParams:{ draft: true }});
        }
    }

    // TODO: handle saving from a resumed Draft order
    public saveDraft() {
        // this.submitted = true;
        this.submitSuccess = false;
        this.orderCompleteHeader = "";
        this.isLoading = true;
        this.formDirty = false;
        if(this.maintenance){
            return;
        }

        if(this.skipProcessOrder()){
            this.nonDataTracSaveDraft();
            return;
        }else{
            const categoryControl = this.categoryFormGroup.get('category');
            const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
            const claimNumberControl = this.categoryFormGroup.get('claimNumber');
            // check the for "direct efile" and handle possible edge case by redirecting
            if(categoryControl?.value == ServiceTypes.EFILE && this.typeName == ServiceTypes.EFILE_DIRECT_TYPE ) {
                this.formDirty = false;
                this.router.navigateByUrl('/order/direct-efile', {state: {
                    clientMatterNumber: (clientMatterNumberControl?.value || ''),
                    claimNumber: (claimNumberControl?.value || '')
                }});
    
                return;
            }
    
            if(!this.entity || !this.entity.uuid) {
                // no entity, create a brand new draft order
                let orderData = this.prepareOrderData(true);
                
                // guard in case the orderData is undefined
                if(!orderData) {
                    this.isLoading = false;
                    return;
                }
    
                this.entityService.createOrder(orderData).subscribe(async (response: any) => {
    
                    if(!response.success) {
                        // TODO: error handling
                        this.isLoading = false;
                        let errorResult = '';
                        if(response.result != undefined) errorResult = response.result;
                        console.error('Error: order could not be submitted: ' + errorResult)
                        this.openErrorDialog('Error: order could not be submitted: ' + errorResult);
                        return;
                    }
                    
                    this.SubmitOrder = response.result.orders[0];
                    this.SubmittedDocuments = response.result.orders[0].documents;
    
                    if(this.SubmittedDocuments.length > 0) {
                        this.formDirty = false;
                        await this.uploadDocuments(this.SubmittedDocuments, true);
                    }
    
                    // draft with no documents
                    else if (this.SubmitOrder!.uuid) { 
                        this.formDirty = false;
                        this.router.navigate(['/order/' + this.SubmitOrder!.uuid], {queryParams:{ draft: true }});
                    }
                }, (error: any) => {
                    console.log('Error: order could not be submitted: ', error);
                    this.openErrorDialog('Error: order could not be submitted: ' + error);
                }
            );
            } else {
                this.formDirty = false;
                this.updateOrder(true);
            }    
    
        }
    }

    public nonDataTracSaveDraft() {
        // this.submitted = true;
        this.submitSuccess = false;
        this.orderCompleteHeader = "";
        this.isLoading = true;
        this.formDirty = false;
        if(this.maintenance){
            return;
        }

        const categoryControl = this.categoryFormGroup.get('category');
        const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
        const claimNumberControl = this.categoryFormGroup.get('claimNumber');
        // check the for "direct efile" and handle possible edge case by redirecting
        if(categoryControl?.value == ServiceTypes.EFILE && this.typeName == ServiceTypes.EFILE_DIRECT_TYPE ) {
            this.formDirty = false;
            this.router.navigateByUrl('/order/direct-efile', {state: {
                clientMatterNumber: (clientMatterNumberControl?.value || ''),
                claimNumber: (claimNumberControl?.value || '')
            }});

            return;
        }

        if(!this.entity || !this.entity.uuid) {
            // no entity, create a brand new draft order
            let orderData = this.prepareOrderData(true);

            // guard in case the orderData is undefined
            if(!orderData) {
                this.isLoading = false;
                return;
            }

            this.entityService.createOrder(orderData).subscribe(async (response: any) => {

                if(!response.success) {
                    // TODO: error handling
                    this.isLoading = false;
                    let errorResult = '';
                    if(response.result != undefined) errorResult = response.result;
                    if(response.result.message) errorResult = response.result.message;
                    console.error('Error: order could not be submitted: ' + errorResult)
                    this.openErrorDialog('Error: order could not be submitted: ' + errorResult);
                    return;
                }
                
                this.SubmitOrder = response.result.orders[0];
                this.SubmittedDocuments = response.result.orders[0].documents;

                if(this.SubmittedDocuments.length > 0) {
                    this.formDirty = false;
                    await this.uploadDocuments(this.SubmittedDocuments, true, false);
                }

                // draft with no documents
                else if (this.SubmitOrder!.uuid) { 
                    this.formDirty = false;
                    this.router.navigate(['/order/' + this.SubmitOrder!.uuid], {queryParams:{ draft: true }});
                }
            });
        } else {
            this.formDirty = false;
            this.nonDataTracUpdateOrder(true);
        }    

    }

    public nonDataTracSave() {
        // this.submitted = true;
        this.submitSuccess = false;
        this.orderCompleteHeader = "";
        this.isLoading = true;
        this.formDirty = false;

        if(this.maintenance){
            return;
        }

        if(!this.entity  || !this.entity.uuid) {
            // create a brand new order
            let orderData = this.prepareOrderData(false);

            // guard in case the orderData is undefined
            if(!orderData) {
                this.isLoading = false;
                return;
            }

            if(this.saveEDiscoveryAddress){
                this.saveNewEDiscoveryAddress();
            }

            this.entityService.createOrder(orderData).subscribe(async (response: any) => {
                if(!response.success) {
                    // TODO: error handling
                    this.isLoading = false;
                    let errorResult = '';
                    if(response.result != undefined) errorResult = response.result;
                    if(response.result.message) errorResult = response.result.message;
                    console.error('Error: order could not be submitted: ' + errorResult)
                    this.openErrorDialog('Error: order could not be submitted: ' + errorResult);
                    return;
                }
                
                // let orderId : string = response.result.order.id;
                // save for later use in case the uploads fail and need to be retried
                this.SubmitOrder = response.result.orders[0];
                // console.log('submit order set', this.SubmitOrder)
                this.SubmittedDocuments = response.result.orders[0].documents;
                
                if(this.SubmittedDocuments.length > 0) {
                    this.formDirty = false;
                    let submitResponse = await this.uploadDocuments(this.SubmittedDocuments, false, false);
                    this.entityService.get(this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid).subscribe((orderResponse)=>{
                        this.entityService.generateNonDTEmail(orderResponse, this.serviceName, this.categoryName).subscribe((noticeResult)=>{
                            this.orderProcessCallback(response);
                        }, (err: any)=>{   
                            this.orderProcessCallback(response);
                        }) 
                    })
                } else if(this.documentsRequired != ServiceTypeDocsRequired.Required) {
                    this.entityService.get(this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid).subscribe((orderResponse)=>{
                        this.entityService.generateNonDTEmail(orderResponse, this.serviceName, this.categoryName).subscribe((noticeResult)=>{
                            this.orderProcessCallback(response);
                        }, (err: any)=>{   
                            this.orderProcessCallback(response);
                        }) 
                    })
                } else { 
                    this.isLoading = false;
                    this.formDirty = false;
                    this.router.navigate(['/order/' + (this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid)], {queryParams:{ finished: true }});
                }
            },(error: any)=>{
                this.isLoading = false;
                console.error('Error: order could not be submitted: ' + error.message ? error.message : error)
                this.openErrorDialog('Error: order could not be submitted: ' + error.message ? error.message : error);
            },()=>{});
        }else{
            console.log("updating non datatrac")
            this.nonDataTracUpdateOrder(false);
        }
    }

    public save() {
        if(this.skipProcessOrder()){
            this.nonDataTracSave();
            return
        } else {
            // this.submitted = true;
            this.submitSuccess = false;
            this.orderCompleteHeader = "";
            this.isLoading = true;
            this.formDirty = false;

            if(this.maintenance){
                return;
            }

            const categoryControl = this.categoryFormGroup.get('category');
            const clientMatterNumberControl = this.categoryFormGroup.get('clientMatterNumber');
            const claimNumberControl = this.categoryFormGroup.get('claimNumber');
            // check the for "direct efile" and handle possible edge case by redirecting
            if(categoryControl?.value == ServiceTypes.EFILE && this.typeName == ServiceTypes.EFILE_DIRECT_TYPE ) {
                this.formDirty = false;
                this.router.navigateByUrl('/order/direct-efile', {state: {
                    clientMatterNumber: (clientMatterNumberControl?.value || ''),
                    claimNumber: (claimNumberControl?.value || '')
                }});

                return;
            }

            if(!this.entity  || !this.entity.uuid) {
                // create a brand new order
                let orderData = this.prepareOrderData(false);

                // guard in case the orderData is undefined
                if(!orderData) {
                    this.isLoading = false;
                    return;
                }

                if(this.savePickupAddress){
                    this.saveNewPickupAddress();
                }

                if(this.saveDeliveryAddress){
                    this.saveNewDeliveryAddress();
                }

                this.entityService.createOrder(orderData).subscribe(async (response: any) => {
                    if(!response.success) {
                        // TODO: error handling
                        this.isLoading = false;
                        let errorResult = '';
                        if(response.result != undefined) errorResult = response.result;
                        console.error('Error: order could not be submitted: ' + errorResult)
                        this.openErrorDialog('Error: order could not be submitted: ' + errorResult);
                        return;
                    }
                    
                    // let orderId : string = response.result.order.id;
                    // save for later use in case the uploads fail and need to be retried
                    this.SubmitOrder = response.result.orders[0];
                    // console.log('submit order set', this.SubmitOrder)
                    this.SubmittedDocuments = response.result.orders[0].documents;
                    
                    
                    if(this.SubmittedDocuments.length > 0) {
                        await this.uploadDocuments(this.SubmittedDocuments, false);
                    } else if(this.documentsRequired != ServiceTypeDocsRequired.Required) {
                        // console.log('------ CALL PROCESS ------');
                        // console.log('submit order', this.SubmitOrder)
                        this.entityService.processOrder(this.SubmitOrder!.uuid).subscribe((processResponse: any) => {
                            this.orderProcessCallback(processResponse);
                        },(processError: any)=>{
                            this.isLoading = false;
                            this.formDirty = false;
                            this.router.navigate(['/order/' + (this.SubmitOrder ? this.SubmitOrder.uuid : this.entity.uuid)], {queryParams:{ finished: true }});
                        });
                    }
                },(error: any)=>{
                    this.isLoading = false;
                    console.error('Error: order could not be submitted: ' + error)
                    this.openErrorDialog('Error: order could not be submitted: ' + error);
                },()=>{});
            } else {
                this.updateOrder(false);
            }
        }
    }
    
    public onCourtSearchClick() {
        (window.document.querySelector('.cdk-overlay-container') as HTMLElement).style.zIndex = 'auto'; 
    }

    public checkSysStatus() {
        this.systemConfigService.getSysConfigListObservable().subscribe( r => {
            if(this.systemConfigService.getMaintenanceMode('isInMaintenanceMode')) {
                this.maintenance = true;
                this.router.navigate(['/order'])
            }
            else
                console.log("systemConfig is not in maintainence mode.");
        });
    }

    public hyphenate(sentence: any, limit: number = 30): string[] {
        let hSentence: string[] = [];
        if(typeof sentence == "string") {
            let word: string = '';
            for(const i of sentence) {

                if( word.length == limit ){
                    if(i == ' '){
                        hSentence.push(word);
                        word = '';
                    }
                    else if(i == '-'){
                        hSentence.push(word + i)
                        word = '';
                    }
                    else if(word[word.length - 1] == ' '){
                        hSentence.push(word);
                        word = '' + i;
                    }
                    else
                    {
                        hSentence.push(word + '-');
                        word = '' + i;
                    }
                }
                else
                    word += i;
            }
            if(word != '')
                hSentence.push(word);
        }
        return hSentence;
    }

    // ngOnDestroy(): void {
    //     this.systemConfigService.sysConfigList = new BehaviorSubject(this.systemConfigService.sysConfigList$);
    // }
}

export type AllSettledResponse<T = any> = { status: 'processed'; value: T } | { status: 'rejected'; reason: any };

interface AddDocumentResponse {
    uuid: string,
    fileName: string,
    uploadUri: string
}

interface VendorAccountDisplay {
    vendor_uuid: string,
    successAccount_uuid: string,
    username: string | null,
    email: string | null,
    vendorName: string,
	display: string | null,
    createdAt: Date,
	updatedAt: Date,
	deletedAt: Date | null
}

interface County {
    state: string,
    name: string
}

interface ServeeJSON {
	serveeNumber: string,
	phone?: string,
	email?: string,
	age?: string,
	gender?: string,
	relationship?: string,
	ethnicity?: string,
	eyes?: string,
	hair?: string,
	height?: string,
	weight?: string
}




import { Injectable } from "@angular/core";
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientBranchSalesperson } from "../models";
import { HttpClient } from '@angular/common/http'

@Injectable()
export class ClientBranchSalesperson_service extends ResourceService<ClientBranchSalesperson> {

    protected servicePath: string = '/ClientBranchSalesperson';
    public ModelType = ClientBranchSalesperson;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

}



import { Injectable } from "@angular/core";
import { ResourceService } from '@smartsoftware/reflex-core';
import { FLCompanyEmployee } from "../models";
import { HttpClient } from '@angular/common/http'

@Injectable()
export class FLCompanyEmployee_service extends ResourceService<FLCompanyEmployee> {

    protected servicePath: string = '/FLCompanyEmployee';
    public ModelType = FLCompanyEmployee;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

}
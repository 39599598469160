<form #editForm="ngForm" (ngSubmit)="save()" [formGroup]="entityForm" *ngIf="entityForm !== undefined" (keydown.enter)="$event.preventDefault()">
    <div class="loading" *ngIf="isLoading">
        <div class="loading-text">Loading ...</div>
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    </div>
    <resource-list-grid *ngIf="!isLoading">
        <resource-list-pane>
            <mat-horizontal-stepper linear="true" #stepper id="stepper" *ngIf="!isLoading" (animationDone)="onStepSelectionChange()">

                <!-- Service Line Step -->
                <mat-step [stepControl]="serviceFormGroup" label="Service Line">
                    <form [formGroup]="serviceFormGroup">
                        
<!-- #region services -->

                        <div class="formActions">
                            <button mat-raised-button style="visibility: hidden;" (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="button" matStepperNext (click)="checkService()" [disabled]="!serviceFormGroup.valid">Next</button>
                        </div>

                        <div class="service-form">
                            <mat-radio-group aria-label="Select a Service" formControlName="service" (change)="onServiceChanged();" >
                                <mat-radio-button [class.checked]="serviceName == 'COURTPROCESS'" value="COURTPROCESS" class="service-button && limitedWidth" (click)="loadServiceCategories('COURTPROCESS')">
                                    <div class="serviceTitle">
                                        <img src="/assets/images/FLNlogo-CourtProcess.png" alt="Court and Process" />
                                    </div>
                                    
                                    <ul class="offerings">
                                        <li>Court Filings</li>
                                        <li>eFiling | Direct | Concierge</li>
                                        <li>Service of Process</li>
                                    </ul>
                                    
                                    <div class="accounts" *ngIf="false && vendorAccounts['FirstConnect']?.length">
                                        <p>Access Legacy FirstConnect</p>
                                        <ng-container *ngFor="let account of vendorAccounts['FirstConnect']" >
                                            <button mat-flat-button
                                                color="primary"
                                                (click)="navigateToVendorAccount(account)">
                                                    <mat-icon>open_in_new</mat-icon>
                                                    Login {{account.display ? 'as' : ''}} {{account.display}}
                                            </button>
                                            <br />
                                        </ng-container>
                                    </div>
                                </mat-radio-button>
                                
                                <mat-radio-button [class.checked]="serviceName == 'DEPOSITIONS'" value="DEPOSITIONS" class="service-button && limitedWidth" >
                                    <div class="serviceTitle">
                                        <img src="/assets/images/FLNlogo-Depositions.png" alt="Depositions" />
                                    </div>


                                    <ul class="offerings">
                                        <li>Court Reporting</li>
                                        <li>Depositions & Case Management</li>
                                        <li>Videographers</li>
                                    </ul>

                                    <div class="accounts" *ngIf="vendorAccounts['Acclaim']?.length">
                                        <p>Access Depositions Portal</p>
                                        <ng-container *ngFor="let account of vendorAccounts['Acclaim']" >
                                            <button mat-flat-button
                                                color="primary"
                                                (click)="navigateToVendorAccount(account)">
                                                    <mat-icon>open_in_new</mat-icon>
                                                    Login {{account.display ? 'as' : ''}} {{account.display}}
                                            </button>
                                            <br />
                                        </ng-container>
                                    </div>

                                    <div class="signup" *ngIf="!vendorAccounts['Acclaim']?.length">
                                        <p>You do not have an account with this portal.</p>
                                        <button mat-flat-button (click)="requestVendorAccount('Acclaim')"><mat-icon>open_in_new</mat-icon> Request Access</button>
                                    </div>
                                </mat-radio-button>

                                <mat-radio-button [class.checked]="serviceName == 'RECORDRETRIEVAL'" value="RECORDRETRIEVAL" class="service-button && limitedWidth">
                                    <div class="serviceTitle">
                                        <img src="/assets/images/FLNlogo-Records.png" alt="Records Retrieval" />
                                    </div>
                                    <ul class="offerings">
                                        <li>Subpoena & Authorization Services</li>
                                        <li>Out of State Subpoenas</li>
                                        <li>Deposition Officer Designation</li>
                                    </ul>

                                    <div class="accounts" *ngIf="vendorAccounts['Tristar']?.length">
                                        <p>Access Records Portal</p>
                                        <ng-container *ngFor="let account of vendorAccounts['Tristar']" >
                                            <button mat-flat-button
                                                color="primary"
                                                (click)="navigateToVendorAccount(account)">
                                                    <mat-icon>open_in_new</mat-icon>
                                                    Login {{account.display ? 'as' : ''}} {{account.display}}
                                            </button>
                                            <br />
                                        </ng-container>
                                    </div>

                                    <div class="signup" *ngIf="!vendorAccounts['Tristar']?.length">
                                        <p>You do not have an account with this portal.</p>
                                        <button mat-flat-button (click)="requestVendorAccount('Tristar')"><mat-icon>open_in_new</mat-icon> Request Access</button>
                                    </div>
                                </mat-radio-button>

                                <mat-radio-button [class.checked]="serviceName == 'EDISCOVERY'" value="EDISCOVERY" class="service-button && limitedWidth" (click)="loadServiceCategories('EDISCOVERY')">
                                    <div class="serviceTitle">
                                        <img src="/assets/images/FLNlogo-Discovery.png" alt="Discovery" />
                                    </div>
                                    <ul class="offerings">
                                        <li>Defensible Collection</li>
                                        <li>Data Processing & Hosting</li>
                                        <li>Document Production</li>
                                    </ul>
                                </mat-radio-button>
                                
                                <mat-radio-button [class.checked]="serviceName == 'INVESTIGATIONS'" value="INVESTIGATIONS" class="service-button && limitedWidth" (click)="loadServiceCategories('INVESTIGATIONS')">
                                    <div class="serviceTitle">
                                        <img src="/assets/images/FLNlogo-Investigations.png" alt="Investigations" />
                                    </div>

                                    <ul class="offerings">
                                        <li>Difficult Service of Process</li>
                                        <li>Surveillance & Sub Rosa</li>
                                        <li>Skip Trace</li>
                                    </ul>
                                </mat-radio-button>
                                
                                <mat-radio-button [class.checked]="serviceName == 'DIGITAL'" value="DIGITAL" class="service-button && limitedWidth" (click)="loadServiceCategories('DIGITAL')">
                                    <div class="serviceTitle">
                                        <img src="/assets/images/FLNlogo-Digital.png" alt="Digital" />
                                    </div>
                                    <ul class="offerings">
                                        <li>Litigation Copying & Scanning</li>
                                        <li>Trial Binders & Exhibit Boards</li>
                                        <li>Mail-Outs</li>
                                    </ul>
                                </mat-radio-button>
                            </mat-radio-group>
                            <div class="service-error" *ngIf="serviceFormGroup.controls['service'].touched 
                                    && serviceFormGroup.controls['service'].hasError('required')">
                                Service Line is required!
                            </div>
                        </div>  
<!-- #endregion services -->                                  
                        <div class="formActions">
                            <button mat-raised-button style="visibility: hidden;" (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="button" matStepperNext (click)="checkService()" [disabled]="!serviceFormGroup.valid">Next</button>
                        </div>
                    </form>
                </mat-step>

                <!-- Account Step -->
                <mat-step [stepControl]="selectAccount" label="Account">
                    <div class="customer-form">

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="accountNextClicked = true; checkSysStatus();" [disabled]="disableAccount()">Next</button>
                        </div>
                        
                        <table mat-table [dataSource]="accountDataSource" [matTooltip]="dictionary.tooltip.createOrder_selectAccountNumber">
                            
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
                            </ng-container>

                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef> State </th>
                                <td mat-cell *matCellDef="let element"> {{ element.state }} </td>
                            </ng-container>

                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef> City </th>
                                <td mat-cell *matCellDef="let element"> {{ element.city }} </td>
                            </ng-container>

                            <ng-container matColumnDef="street">
                                <th mat-header-cell *matHeaderCellDef> Street </th>
                                <td mat-cell *matCellDef="let element"> {{ element.street }} </td>
                            </ng-container>

                            <ng-container matColumnDef="accountNumber">
                                <th mat-header-cell *matHeaderCellDef> Account Number </th>
                                <td mat-cell *matCellDef="let element"> {{ element.accountNumber }} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="accountColumnsToDisplay"></tr>
                            <tr mat-row *matRowDef="let element; columns: accountColumnsToDisplay" 
                                (click)="accountSelected(element);" 
                                [ngClass]="{selected: element.accountNumber == selectAccount.value}">
                            </tr>
                        </table>
                    </div>
                    <mat-error *ngIf="accountNextClicked && selectAccount.hasError('required')">
                        Account is required
                    </mat-error>
                    <div class="formActions">
                        <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                        <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                        <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                        <button mat-raised-button color="primary" matStepperNext type="button" (click)="accountNextClicked = true; checkSysStatus();" [disabled]="disableAccount()">Next</button>
                    </div>
                </mat-step>

                <!-- Service Type Step -->
                <mat-step [stepControl]="categoryFormGroup" label="Service Type"> 
                    <form [formGroup]="categoryFormGroup">

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="button" matStepperNext (click)="checkCategory()" [disabled]="!categoryFormGroup.valid || !selectClientMatterControl.valid">Next</button>
                        </div>
                        
                        <div class="service-form">
                            <div class="row">
                                <div class="column" >
                                    <mat-form-field [matTooltip]="dictionary.tooltip.createOrder_selectServiceCategory">
                                        <mat-label>Service Category</mat-label>
                                        <mat-select 
                                        formControlName="category"
                                        (selectionChange)="onCategorySelect()">
                                            <mat-option *ngFor="let c of categories" value="{{c.category.name}}">
                                                {{ c.category.displayName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="column" *ngIf="types.length && !groupedTypes.length">
                                    <mat-form-field id="serviceType" >
                                        <mat-label>Service Type</mat-label>
                                        <mat-select 
                                            [matTooltip]="dictionary.tooltip.createOrder_selectServiceType"
                                            formControlName="type"
                                            (selectionChange)="onTypeSelect()"
                                            (openedChange)="onTypeFocus($event)">
                                            <mat-option *ngFor="let type of types" value="{{type.uuid}}" [matTooltip]="type.description" matTooltipPosition="right">
                                                {{ type.displayName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="serviceDescription">
                                        {{serviceType_byUuid(categoryFormGroup.controls['type'].value)?.description}}
                                    </div>
                                </div>

                                <div class="column" *ngIf="groupedTypes.length">
                                    <mat-form-field id="serviceType" >
                                        <mat-label>Service Type</mat-label>
                                        <mat-select 
                                            [matTooltip]="dictionary.tooltip.createOrder_selectServiceType"
                                            formControlName="type"
                                            (selectionChange)="onTypeSelect()"
                                            (openedChange)="onTypeFocus($event)">
                                            <mat-optgroup *ngFor="let group of groupedTypes" label="-----" >
                                                <mat-option *ngFor="let type of group.types" value="{{type.uuid}}" [matTooltip]="type.description" matTooltipPosition="right">
                                                    {{ type.displayName }}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="serviceDescription">
                                        {{ serviceType_byUuid(categoryFormGroup.controls['type'].value)?.description }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="categoryDisplayName == 'Other'">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Description for other" formControlName="serviceCategoryDescription" required>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field *ngIf="!hideClientMatter">
                                        <mat-label>Client Matter</mat-label>
                                        <input type="text"
                                            maxlength="{{clientMatterMaxLength}}" 
                                            [matTooltip]="dictionary.tooltip.createOrder_clientMatterNumber"
                                            placeholder="{{ clientMatterEntries.length == 0 ? 'Enter a new matter number' : 'Type to search'}}"
                                            aria-label="Client Matter"
                                            matInput
                                            [required]="!hideClientMatter"
                                            [formControl]="selectClientMatterControl"
                                            [matAutocomplete]="successClientMatter"
                                            (keyup)="onClientMatterChange()">
                                        <mat-autocomplete #successClientMatter="matAutocomplete" (optionSelected)="onClientMatterSelect();">
                                            <mat-option *ngFor="let entry of filteredClientMatterEntries | async" [value]="entry.matter">
                                                {{entry.matter}} | {{entry.name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint>{{ selectClientMatterControl.value.length }}/{{ clientMatterMaxLength }}</mat-hint>

                                        <mat-error *ngIf="selectClientMatterControl.hasError('invalidMatter')">
                                            {{ clientMatterErrorMsg.getValue() }}
                                        </mat-error>
                                        <mat-error *ngIf="selectClientMatterControl.hasError('required')">
                                            Client Matter is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row" *ngIf="serviceName == 'EDISCOVERY'">
                                <div class="column">
                                    <mat-form-field *ngIf="matterServiceOptions.length > 0 && typeName">
                                        <mat-select required placeholder="Services Needed" [formControl]="eDiscoveryServiceSelectControl" (selectionChange)="eDiscoveryServiceChange()" multiple>
                                            <mat-option color="primary" *ngFor="let matterService of matterServiceOptions" [value]=matterService.value>{{matterService.key}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="!eDiscoverySendTrialInfo && hideClientMatter">
                                        <input matInput placeholder="Description of Needs" [formControl]="eDiscoveryDescriptionOfNeeds">
                                    </mat-form-field>

                                    <mat-form-field *ngIf="serviceOtherShow">
                                        <input matInput placeholder="Other" [formControl]="serviceOtherControl"/>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field >
                                        <input matInput placeholder="Claim Number (Optional)" formControlName="claimNumber" [matTooltip]="dictionary.tooltip.createOrder_claimNumber" />
                                    </mat-form-field>
                                </div>

                                <div class="column" *ngIf="categoryFormGroup.controls['claimNumber'].value">
                                    <mat-form-field >
                                        <input matInput placeholder="Insurance Company (Optional)" formControlName="insuranceCompany" [matTooltip]="dictionary.tooltip.createOrder_insuranceCompany"/>
                                    </mat-form-field>

                                    <mat-form-field >
                                        <input matInput placeholder="Insurance Adjuster (Optional)" formControlName="insuranceAdjuster" [matTooltip]="dictionary.tooltip.createOrder_insuranceAdjuster"/>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>

                        <div class="service-error" *ngIf="categoryFormGroup.controls['category'].touched 
                                && categoryFormGroup.controls['category'].hasError('required')">
                            Service Category is required!
                        </div>
                        <div class="service-error" *ngIf="categoryFormGroup.controls['type'].touched 
                                && categoryFormGroup.controls['type'].hasError('required')">
                            Service Type is required!
                        </div>

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="button" matStepperNext (click)="checkCategory()" [disabled]="!categoryFormGroup.valid || !selectClientMatterControl.valid">Next</button>
                        </div>
                    </form>
                </mat-step>

                <!-- Documents Step -->
                <mat-step label="Documents" [stepControl]="fileFormGroup" *ngIf="showDocumentsStep">
                    <form [formGroup]="fileFormGroup" class="documents-form">
                        
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="button" matStepperNext (click)="checkSysStatus()" [disabled]="!fileFormGroup.valid || !filesExist()">Next</button>
                        </div>
                        <div class="row" *ngIf="!fileFormGroup.valid && seedDocuments.length == 0 && clonedOrder">
                            Please attach new documents for cloned orders
                        </div>
                        <br>
                        <div class="row">
                            <div class="column" >
                                <div class="drop-zone">
                                    <div class="instructions">
                                        <mat-icon>cloud_upload</mat-icon>
                                        <span>Accepted File Types:</span>
                                        <span *ngFor="let fileType of acceptedFileTypes; let i = index"> 
                                            {{ acceptedFileTypes.length > i + 1 ? fileType[1] + ',' :  ' and ' + fileType[1] }} 
                                        </span>
                                        <br><br>
                                        <span style="text-align: center; display:inherit">
                                            <i>Notes: Some special charaters may be removed<br>
                                            from uploaded file names to prevent processing issues.</i>
                                        </span>
                                        <br>
                                        <div class="drop-header">Drag & Drop Files Here</div>
                                        <p>or</p>
                                        <label for="fileDropRef">Browse for File</label>
                                        <input type="file" #fileUploader multiple (change)="onSelect($event)" (blur)="fileFormGroup.controls['documents'].markAsTouched()" id="fileDropRef">                        
                                    </div>
                
                                    <div class="file-error" *ngIf="fileFormGroup.controls['documents'].touched && fileFormGroup.controls['documents'].hasError('required') && !filesExist()">
                                        <mat-icon>warning</mat-icon>
                                        <span>At least one document is required!</span>
                                    </div>
                                </div>

                                <div class="files-list">
                                    <div class="single-file" *ngFor="let document of seedDocuments; let i = index">
                                        <div class="file-icon" style="width: 50px;">
                                            <mat-icon >article</mat-icon>
                                        </div>
                                        <div class="info">
                                            <h4 class="name">{{ document.fileName }}</h4>
                                            <p class="size">Uploaded: {{ document.updatedAtUTC | reflexTimeAgo }}</p>
                                        </div>
                                        <div class="delete" (click)="removeSeedDocument(i)">
                                            <mat-icon class="centered" color="primary">delete</mat-icon>
                                        </div>
                                    </div>
                                    <div class="single-file deleted" *ngFor="let document of deletedSeedDocuments; let i = index">
                                        <div class="file-icon" style="width: 50px;">
                                            <mat-icon >article</mat-icon>
                                        </div>
                                        <div class="info">
                                            <h4 class="name">{{ document.fileName }}</h4>
                                            <p class="warn">Will be removed from Order</p>
                                        </div>
                                        <div class="restore" (click)="restoreSeedDocument(i)">
                                            <mat-icon class="centered" color="primary">restore_from_trash</mat-icon>
                                        </div>
                                    </div>
                                    <div class="single-file" *ngFor="let file of files; let i = index">
                                        <div class="file-icon" style="width: 50px;">
                                            <mat-icon >article</mat-icon>
                                        </div>
                                        <div class="info">
                                            <h4 class="name">{{ file.name }}</h4>
                                            <p class="size">{{ formatBytes(file.size) }}</p>
                                        </div>
                                        <div class="delete" (click)="removeFile(i)">
                                            <mat-icon class="centered" color="primary">delete</mat-icon>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                            <div class="column" >
                                <mat-form-field>
                                    <textarea matInput placeholder="Document Description" maxlength=56 formControlName="description"></textarea>
                                    <mat-error *ngIf="fileFormGroup.controls['description'].hasError('required')">
                                        Description is required!
                                    </mat-error>
                                </mat-form-field>
                                Abbreviations are OK
                            </div>
                        </div>
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="button" matStepperNext (click)="checkSysStatus()" [disabled]="!fileFormGroup.valid || !filesExist()">Next</button>
                        </div>
                    </form>
                </mat-step>

                <!-- Case Step -->
                <mat-step [stepControl]="caseFormGroup" label="Case">
                    <form [formGroup]="caseFormGroup">

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!caseFormGroup.valid">Next</button>
                        </div>

                        <div class="case-form">
                            <div class="full-width row">
                                <mat-form-field>
                                    <mat-label>Case Name</mat-label>
                                    <input type="text"
                                        placeholder="Type to search or enter a new name"
                                        aria-label="Case"
                                        matInput
                                        required
                                        [formControl]="selectCaseControl"
                                        [matAutocomplete]="successCase"
                                        (keyup)="onCaseChange()">
                                    <mat-autocomplete #successCase="matAutocomplete" (optionSelected)="onCaseSelect();">
                                        <mat-option id="caseNameOption" *ngFor="let case of filteredCases | async" [value]="case.id">
                                            {{ case.name }} {{ case.caseNumber }} 
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="caseFormGroup.controls['caseName'].hasError('required')">
                                        Case Name is required!
                                    </mat-error>
                                </mat-form-field>
                                Short Titles are OK
                                
                                <mat-form-field>
                                    <input matInput placeholder="Case Number" required maxlength=50 formControlName="caseNumber">
                                    <mat-error *ngIf="caseFormGroup.controls['caseNumber'].hasError('required')">
                                        Case Number is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!caseFormGroup.valid">Next</button>
                        </div>
                    </form>
                </mat-step>
                
                <!-- Pickup Step -->
                <mat-step [stepControl]="pickupDetailFormGroup" label="Pickup" *ngIf="pickupRequired">
                    <form [formGroup]="pickupDetailFormGroup" *ngIf="serviceName != 'DIGITAL'">
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!pickupDetailFormGroup.valid">Next</button>
                        </div>

                        <div class="formActions" *ngIf="!pickupLookupOpen">
                            <button mat-raised-button color="primary" type="button" (click)="setPickupAddress()">Use My Address</button>
                            <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                        </div>
                        
                        <!-- <div class="formActions" >
                            <button mat-raised-button color="primary" type="button" (click)="setPickupAddress()">Use My Address</button>
                        </div> -->

                        <div class="lookup-form" *ngIf="pickupLookupOpen">
                            <mat-button-toggle-group 
                                name="pickupLookupSource"
                                [formControl]="pickupLookupSource"
                                aria-label="Where to lookup address from"
                                color="primary">
                                <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                <mat-label><mat-icon>search</mat-icon> Court</mat-label>
                                <input type="text"
                                    placeholder="Type to search by name"
                                    aria-label="Court"
                                    matInput
                                    [formControl]="selectPickupCourt"
                                    [matAutocomplete]="pickupCourtAddress"
                                    (click)="onCourtSearchClick()">
                                <mat-autocomplete #pickupCourtAddress="matAutocomplete" (optionSelected)="onPickupCourtSelect();">
                                    <mat-option *ngFor="let address of filteredCourtPickupAddresses | async" [value]="address.id">
                                        {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                        {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field  *ngIf="pickupLookupSource.value == 'addressbook'">
                                <mat-label><mat-icon>search</mat-icon> Address</mat-label>
                                <input type="text"
                                    placeholder="Type to search by name"
                                    aria-label="Address"
                                    matInput
                                    [formControl]="selectPickupAddress"
                                    [matAutocomplete]="pickupUserAddress">
                                <mat-autocomplete #pickupUserAddress="matAutocomplete" (optionSelected)="onPickupAddressSelect();">
                                    <mat-option *ngFor="let address of filteredUserPickupAddresses | async" [value]="address.id">
                                        {{ address.name }} 
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                        </div>
                        <div class="pickup-form" [class.lookupActive]="pickupLookupOpen">
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Company" required formControlName="pickupCompany">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupCompany'].hasError('required')">
                                            Company is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Contact (Optional)" formControlName="pickupContact">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Street" required formControlName="pickupStreet">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupStreet'].hasError('required')">
                                            Street address is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Suite (Optional)" formControlName="pickupSuite">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="City" required formControlName="pickupCity">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupCity'].hasError('required')">
                                            City is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <mat-label>State</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="State"
                                            required
                                            matInput
                                            formControlName="pickupState"
                                            [matAutocomplete]="selectPickupState">
                                        <mat-autocomplete #selectPickupState="matAutocomplete">
                                            <mat-option *ngFor="let state of filteredPickupState | async" [value]="state.abbreviation">
                                                {{ state.name }} ({{ state.abbreviation}}) 
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupState'].hasError('invalid')">
                                            Invalid State
                                        </mat-error>
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupState'].hasError('required')">
                                            Please select a State
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Zip" required formControlName="pickupZip">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupZip'].hasError('required')">
                                            Zip is required!
                                        </mat-error>
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupZip'].hasError('pattern')">
                                            Only numbers are allowed
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Phone (Optional)" formControlName="pickupPhone">
                                        <!-- <mat-error *ngIf="pickupDetailFormGroup.controls['pickupPhone'].hasError('required')">
                                            Phone is required!
                                        </mat-error> -->
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupPhone'].hasError('pattern')">
                                            Phone may only use allowed characters: 0-9,-,(,)
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Ext (Optional)" formControlName="pickupPhoneExt">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupPhoneExt'].hasError('pattern')">
                                            Phone Ext may only use allowed characters: 0-9
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                        </div>

                        <div class="pickup-form" >
                            <div class="row">
                                <div class="column">
                                    <mat-checkbox 
                                        formControlName="saveAddress"
                                        color="primary"
                                        (change)="savePickupAddress=$event.checked">
                                        Save address to my address book
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!pickupDetailFormGroup.valid">Next</button>
                        </div>
                    </form>
                    <form [formGroup]="pickupDetailFormGroup" *ngIf="serviceName == 'DIGITAL'">
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!pickupDetailFormGroup.valid">Next</button>
                        </div>

                        <div class="formActions" *ngIf="!pickupLookupOpen">
                            <button mat-raised-button color="primary" type="button" (click)="setPickupAddress()">Use My Address</button>
                            <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                        </div>
                        
                        <!-- <div class="formActions" >
                            <button mat-raised-button color="primary" type="button" (click)="setPickupAddress()">Use My Address</button>
                        </div> -->

                        <div class="lookup-form" *ngIf="pickupLookupOpen">
                            <mat-button-toggle-group 
                                name="pickupLookupSource"
                                [formControl]="pickupLookupSource"
                                aria-label="Where to lookup address from"
                                color="primary">
                                <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                <mat-label><mat-icon>search</mat-icon> Court</mat-label>
                                <input type="text"
                                    placeholder="Type to search by name"
                                    aria-label="Court"
                                    matInput
                                    [formControl]="selectPickupCourt"
                                    [matAutocomplete]="pickupCourtAddress"
                                    (click)="onCourtSearchClick()">
                                <mat-autocomplete #pickupCourtAddress="matAutocomplete" (optionSelected)="onPickupCourtSelect();">
                                    <mat-option *ngFor="let address of filteredCourtPickupAddresses | async" [value]="address.id">
                                        {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                        {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field  *ngIf="pickupLookupSource.value == 'addressbook'">
                                <mat-label><mat-icon>search</mat-icon> Address</mat-label>
                                <input type="text"
                                    placeholder="Type to search by name"
                                    aria-label="Address"
                                    matInput
                                    [formControl]="selectPickupAddress"
                                    [matAutocomplete]="pickupUserAddress">
                                <mat-autocomplete #pickupUserAddress="matAutocomplete" (optionSelected)="onPickupAddressSelect();">
                                    <mat-option *ngFor="let address of filteredUserPickupAddresses | async" [value]="address.id">
                                        {{ address.name }} 
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                        </div>
                        <div class="pickup-form" [class.lookupActive]="pickupLookupOpen">
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Company" required formControlName="pickupCompany">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupCompany'].hasError('required')">
                                            Company is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Street" required formControlName="pickupStreet">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupStreet'].hasError('required')">
                                            Street address is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="City" required formControlName="pickupCity">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupCity'].hasError('required')">
                                            City is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <mat-label>State</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="State"
                                            required
                                            matInput
                                            formControlName="pickupState"
                                            [matAutocomplete]="selectPickupState">
                                        <mat-autocomplete #selectPickupState="matAutocomplete">
                                            <mat-option *ngFor="let state of filteredPickupState | async" [value]="state.abbreviation">
                                                {{ state.name }} ({{ state.abbreviation}}) 
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupState'].hasError('invalid')">
                                            Invalid State
                                        </mat-error>
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupState'].hasError('required')">
                                            Please select a State
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <input matInput placeholder="Zip" required formControlName="pickupZip">
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupZip'].hasError('required')">
                                            Zip is required!
                                        </mat-error>
                                        <mat-error *ngIf="pickupDetailFormGroup.controls['pickupZip'].hasError('pattern')">
                                            Only numbers are allowed
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!pickupDetailFormGroup.valid">Next</button>
                        </div>
                    </form>
                </mat-step>

                <!-- Delivery Step -->
                <mat-step [stepControl]="serviceDetailFormGroup" label="Delivery" *ngIf="deliveryRequired">

                    <!-- Court and Proceess -->
                    <div *ngIf="serviceName == courtProcess">

                        <!-- Court Address Select -->
                        <div *ngIf="showCourt();">

                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!courtFormGroup.valid">Next</button>
                            </div>

                            <!-- <div class="formActions" >
                                <button mat-raised-button color="primary" type="button" (click)="setDeliveryAddress();">Use My Address</button>
                                <button mat-raised-button type="button" (click)="test();">Test</button>
                            </div> -->

                            <div class="formActions" *ngIf="!isEfile() && !pickupLookupOpen">
                                <button mat-raised-button color="primary" type="button" (click)="setDeliveryAddress();">Use My Address</button>
                                <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                            </div>

                            <form *ngIf="isEfile()" [formGroup]="courtFormGroup">
                                <h2>Court</h2>
                                <div class="state-county">
                                    <div>
                                        Choose a state and county for your electronic filing.
                                    </div>
                                    <mat-form-field>
                                        <mat-label>State</mat-label>
                                        <mat-select formControlName="courtState" (selectionChange)="onEFileCourtStateSelect()" [value]="this.accountDefaultState">
                                            <mat-option *ngFor="let s of selectableStates$ | async" [value]="s.stateAbbreviation">
                                                {{s.stateName}} ({{s.stateAbbreviation}})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                    <mat-form-field>
                                        <mat-label>County</mat-label>
                                        <mat-select 
                                        formControlName="courtName"
                                        (selectionChange)="onEFileCourtCountySelect()">
                                            <mat-option *ngFor="let c of selectableCounties$ | async" value="{{c.countyName}}">
                                                {{ c.countyName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                                <div class="formActions">
                                    <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                    <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                    <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                    <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!courtFormGroup.valid">Next</button>
                                </div>

                            </form>
                            <form *ngIf="!isEfile()" [formGroup]="courtFormGroup">
                                <h2>Court</h2>
                                <div *ngIf="isCourtService()" >
                                    <div class="row">
                                        <div class="column">
                                            Choose a state and county to filter the list of available courts. If the court you need is not listed, simply enter it in the Court field and provide the other required information.
                                        </div>
                                        <div class="column"></div>
                                    </div>
                                    <div class="state-county row">
                                        <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <mat-select (selectionChange)="onCourtServiceStateSelect($event)" [value]="this.accountDefaultState">
                                                <mat-option *ngFor="let s of selectableStates$ | async" [value]="s.stateAbbreviation">
                                                    {{s.stateName}} ({{s.stateAbbreviation}})
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field>
                                            <mat-label>County</mat-label>
                                            <mat-select 
                                            (selectionChange)="onCourtServiceCountySelect($event)">
                                                <mat-option *ngFor="let c of selectableCounties$ | async" value="{{c.countyName}}">
                                                    {{ c.countyName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="lookup-form" *ngIf="pickupLookupOpen">
    
                                    <mat-form-field class='courtFormField'>
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Select a Court"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectCourtControl"
                                            [matAutocomplete]="auto"
                                            (keyup)="onCourtChange();">
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
    
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <div class="deliver-form">
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field class='courtFormField'>
                                                <mat-label>Court (Optional)</mat-label>
                                                <input type="text"
                                                    placeholder="Select a Court"
                                                    aria-label="Court"
                                                    matInput
                                                    [formControl]="selectCourtControl"
                                                    [matAutocomplete]="auto"
                                                    (keyup)="onCourtChange();">
                                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCourtSelect();">
                                                    <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                        {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                        {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Contact (Optional)" formControlName="courtContact">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Street" required formControlName="courtStreet">
                                                <mat-error *ngIf="courtFormGroup.controls['courtStreet'].hasError('required')">
                                                    Street address is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Suite (Optional)" formControlName="courtSuite">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                        
                                            <mat-form-field>
                                                <input matInput placeholder="City" required formControlName="courtCity">
                                                <mat-error *ngIf="courtFormGroup.controls['courtCity'].hasError('required')">
                                                    City is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <mat-label>State</mat-label>
                                                <input type="text"
                                                    placeholder="Type to search by name"
                                                    aria-label="State"
                                                    required
                                                    matInput
                                                    formControlName="courtState"
                                                    [matAutocomplete]="selectCourtState">
                                                <mat-autocomplete #selectCourtState="matAutocomplete">
                                                    <mat-option *ngFor="let state of filteredCourtState | async" [value]="state.abbreviation">
                                                        {{ state.name }} ({{ state.abbreviation}}) 
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error *ngIf="courtFormGroup.controls['courtState'].hasError('invalid')">
                                                    Invalid State
                                                </mat-error>
                                                <mat-error *ngIf="courtFormGroup.controls['courtState'].hasError('required')">
                                                    Please select a State
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Zip" required formControlName="courtZip">
                                                <mat-error *ngIf="courtFormGroup.controls['courtZip'].hasError('required')">
                                                    Zip is required!
                                                </mat-error>
                                                <mat-error *ngIf="courtFormGroup.controls['courtZip'].hasError('pattern')">
                                                    Only numbers are allowed
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput maxlength="20" placeholder="Phone (Optional)" formControlName="courtPhone">
                                                <!-- <mat-error *ngIf="courtFormGroup.controls['courtPhone'].hasError('required')">
                                                    Phone is required!
                                                </mat-error> -->
                                                <mat-error *ngIf="courtFormGroup.controls['courtPhone'].hasError('pattern')">
                                                    Phone may only use allowed characters: 0-9,-,(,)
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Ext (Optional)" formControlName="courtPhoneExt">
                                                <mat-error *ngIf="courtFormGroup.controls['courtPhoneExt'].hasError('pattern')">
                                                    Phone Ext may only use allowed characters: 0-9
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="formActions">
                                    <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                    <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                    <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                    <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!courtFormGroup.valid">Next</button>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="showDeliverTo();">

                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!deliveryDetailFormGroup.valid">Next</button>
                            </div>

                            <!-- <div class="formActions" >
                                <button mat-raised-button color="primary" type="button" (click)="setDeliveryAddress()">Use My Address</button>
                                <button mat-raised-button type="button" (click)="test();">Test</button>
                            </div> -->

                            <div class="formActions" *ngIf="!pickupLookupOpen">
                                <button mat-raised-button color="primary" type="button" (click)="setDeliveryAddress();">Use My Address</button>
                                <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                            </div>

                            <form [formGroup]="deliveryDetailFormGroup">
                                
                                <h2>Deliver To</h2>
                                <div class="lookup-form" *ngIf="pickupLookupOpen">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>
    
                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectDeliveryCourtControl"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onDeliveryCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <!-- <h3>Select from Address Book</h3> -->
                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectDeliveryAddressControl"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onDeliveryAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
    
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <div class="deliver-form">
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Company" required formControlName="deliveryCompany">
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryCompany'].hasError('required')">
                                                    Company is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Contact" formControlName="deliveryContact">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Street" required formControlName="deliveryStreet">
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryStreet'].hasError('required')">
                                                    Street address is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Suite" formControlName="deliverySuite">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="City" required formControlName="deliveryCity">
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryCity'].hasError('required')">
                                                    City is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <mat-label>State</mat-label>
                                                <input type="text"
                                                    placeholder="Type to search by name"
                                                    aria-label="State"
                                                    required
                                                    matInput
                                                    formControlName="deliveryState"
                                                    [matAutocomplete]="selectDeliveryState">
                                                <mat-autocomplete #selectDeliveryState="matAutocomplete">
                                                    <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                        {{ state.name }} ({{ state.abbreviation}}) 
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryState'].hasError('invalid')">
                                                    Invalid State
                                                </mat-error>
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryState'].hasError('required')">
                                                    Please select a State
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Zip" required formControlName="deliveryZip">
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryZip'].hasError('required')">
                                                    Zip is required!
                                                </mat-error>
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryZip'].hasError('pattern')">
                                                    Only numbers are allowed
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Phone" formControlName="deliveryPhone">
                                                <!-- <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryPhone'].hasError('required')">
                                                    Phone is required!
                                                </mat-error> -->
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryPhone'].hasError('pattern')">
                                                    Phone may only use allowed characters: 0-9,-,(,)
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Ext" formControlName="deliveryPhoneExt">
                                                <mat-error *ngIf="deliveryDetailFormGroup.controls['deliveryPhoneExt'].hasError('pattern')">
                                                    Phone Ext may only use allowed characters: 0-9
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-checkbox 
                                                formControlName="saveAddress"
                                                color="primary"
                                                (change)="saveDeliveryAddress=$event.checked">
                                                Save address to my address book
                                            </mat-checkbox>
                                        </div>
                                    </div>

                                </div>

                                <div class="formActions">
                                    <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                    <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                    <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                    <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!deliveryDetailFormGroup.valid">Next</button>
                                </div>
                            </form>
                        </div>
                        <!-- Service of Process -->
                        <div *ngIf="categoryName == serviceOfProcess">

                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" [disabled]="!serviceAddressFormGroup.valid && serviceAddresses.length == 0">Next</button>
                            </div>

                            <h2>Who to Serve</h2>
                            <form [formGroup]="serviceAddressFormGroup">
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>
                                <div class="lookup-form" *ngIf="pickupLookupOpen">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectServiceCourt"
                                            [matAutocomplete]="serviceCourtAddress">
                                        <mat-autocomplete #serviceCourtAddress="matAutocomplete" (optionSelected)="onServiceCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    
                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectServiceAddress"
                                            [matAutocomplete]="serviceUserAddress">
                                        <mat-autocomplete #serviceUserAddress="matAutocomplete" (optionSelected)="onServiceAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
    
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>

                                <div class="servee-form">
                                    <div class="left-column" style="width:50%; margin-right:16%;">
                                        <mat-radio-group aria-label="Select an option" value=false style="background-color:primary;">
                                            <mat-radio-button style="margin-right: 10px;" color="primary" (click)="switchIndividualBusiness(false)" value=false>Business</mat-radio-button>
                                            <mat-radio-button style="margin-right: 10px;" color="primary" (click)="switchIndividualBusiness(true)" value=true>Individual</mat-radio-button>
                                        </mat-radio-group>

                                        <mat-form-field>
                                            <input required matInput [placeholder]="serviceAddressFormGroup.controls['typeName'].value" formControlName="locationName">
                                            <mat-error>
                                                {{serviceAddressFormGroup.controls['typeName'].value}} Name is required!
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field *ngIf="!serveeIndividualBusiness">
                                            <input matInput placeholder="Agent (Optional)" formControlName="serviceContact">
                                        </mat-form-field>
            
                                        <mat-form-field>
                                            <input required matInput placeholder="Street Address" formControlName="serviceStreet">
                                            <mat-error>
                                                Street Address is required!
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field>
                                            <input matInput placeholder="Suite (Optional)" formControlName="serviceSuite">
                                        </mat-form-field>

                                        <!-- Address -->
                                        <div class="servee-form">
                                            <mat-form-field style="width:33%">
                                                <input required matInput placeholder="City" formControlName="serviceCity">
                                                <mat-error>
                                                    City is required!
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field style="width:33%">
                                                <input type="text"
                                                    placeholder="State (Optional)"
                                                    aria-label="State"
                                                    matInput
                                                    formControlName="serviceState"
                                                    [matAutocomplete]="selectServiceState">
                                                <mat-autocomplete #selectServiceState="matAutocomplete">
                                                    <mat-option *ngFor="let state of filteredServiceState | async" [value]="state.abbreviation">
                                                        {{ state.name }} ({{ state.abbreviation}}) 
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error *ngIf="serviceAddressFormGroup.controls['serviceState'].hasError('invalid')">
                                                    Invalid State
                                                </mat-error>
                                                <mat-error *ngIf="serviceAddressFormGroup.controls['serviceState'].hasError('add')">
                                                    Please select a State
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field style="width:33%">
                                                <input required matInput placeholder="Zip" formControlName="serviceZip">
                                                <mat-error>
                                                    Zip code is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="servee-form">
                                            <mat-form-field style="width:33%">
                                                <input matInput placeholder="Phone (Optional)" formControlName="servicePhone">
                                                <mat-error>
                                                    Invalid Phone Format
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field style="width:33%">
                                                <input matInput placeholder="Ext (Optional)" formControlName="servicePhoneExt">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="right-column">
                                        <div class="help-info">
                                            Click 'Add Servee' to add the Servee to the list, then, if desired, fill in info for another Servee and click 'Add Servee' again to add additional Servees.
                                        </div>
                                        <div class="servee-list">
                                            <div *ngFor="let address of serviceAddresses; let i=index" class="single-contact">
                                                <div class="servee-icon" style="width: 50px;">
                                                    <mat-icon *ngIf="address.serve?.lastName != ''">person</mat-icon>
                                                    <mat-icon *ngIf="address.serve?.lastName == ''">domain</mat-icon>
                                                </div>
                                                <div class="info">
                                                    <h4 class="name">{{ serviceAddresses[i].name }}</h4>
                                                    <p class="detail" *ngIf="address.serve?.lastName != ''">{{address.serve?.email}} {{address.serve?.phone}}</p>
                                                    <p class="detail" *ngIf="address.serve?.lastName == ''">{{address.city}} {{address.state}} {{address.zip}}</p>
                                                </div>
                                                <div class="delete" (click)="removeServiceAddresses(i)">
                                                    <mat-icon class="centered" color="primary" >delete</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" mat-raised-button (click)="addServiceAddress();">Add Servee</button>
                                    </div>
                                </div>
                            </form>

                            <form [formGroup]="toServeFormGroup" *ngIf="serveeIndividualBusiness">
                                <div class="left-column" style="width:50%; margin-right:16%;">
                                    <h2>Additional Individual Information (Optional)</h2>

                                    <mat-form-field>
                                        <input matInput maxlength="255" placeholder="Email Address" formControlName="serveEmail">
                                    </mat-form-field>

                                    <div class="servee-form">
                                        <mat-form-field style="width:25%">
                                            <input matInput maxlength="3" placeholder="Age" formControlName="serveAge">
                                        </mat-form-field>

                                        <mat-form-field style="width:25%">
                                            <input matInput maxlength="50" placeholder="Gender" formControlName="serveGender">
                                        </mat-form-field>

                                        <mat-form-field style="width:50%">
                                            <input matInput maxlength="50" placeholder="Ethnicity" formControlName="serveEthnicity">
                                        </mat-form-field>
                                    </div>

                                    <div class="servee-form">
                                        <mat-form-field style="width:25%">
                                            <input matInput maxlength="5" placeholder="Height" formControlName="serveHeight">
                                        </mat-form-field>

                                        <mat-form-field style="width:25%">
                                            <input matInput maxlength="10" placeholder="Weight" formControlName="serveWeight">
                                        </mat-form-field>

                                        <mat-form-field style="width:50%">
                                            <input matInput maxlength="255" placeholder="Relationship" formControlName="serveRelationship">
                                        </mat-form-field>
                                    </div>

                                    <div class="servee-form">
                                        <mat-form-field style="width:25%">
                                            <input matInput maxlength="20" placeholder="Hair" formControlName="serveHair">
                                        </mat-form-field>

                                        <mat-form-field style="width:25%">
                                            <input matInput maxlength="20" placeholder="Eyes" formControlName="serveEyes">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>

                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" [disabled]="!serviceAddressFormGroup.valid && serviceAddresses.length == 0">Next</button>
                            </div>
                        </div>
                    </div>
                    <!-- EDiscovery -->
                    <div *ngIf="serviceName == eDiscovery">
                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!serviceDetailFormGroup.valid">Next</button>
                        </div>
                        
                        <form [formGroup]="eDiscoveryTrialFormGroup" *ngIf="categoryName == eDiscovery">
                            <div class="formActions" *ngIf="!eDiscoveryLookupOpen">
                                <button mat-raised-button color="primary" type="button" (click)="setEDiscoveryAddress();">Use My Address</button>
                                <button mat-raised-button color="primary" (click)="eDiscoveryLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                            </div>

                            <h2>Deliver To</h2>
                            <div class="lookup-form" *ngIf="eDiscoveryLookupOpen">
                                <mat-button-toggle-group 
                                    name="eDiscoveryLookupSource"
                                    [formControl]="eDiscoveryLookupSource"
                                    aria-label="Where to lookup address from"
                                    color="primary">
                                    <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                    <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                </mat-button-toggle-group>

                                <mat-form-field class='courtFormField' *ngIf="eDiscoveryLookupSource.value == 'court'">
                                    <mat-label>Court</mat-label>
                                    <input type="text"
                                        placeholder="Type to search by name"
                                        aria-label="Court (Optional)"
                                        matInput
                                        [formControl]="selectEDiscoveryCourt"
                                        [matAutocomplete]="courtAddress">
                                    <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onEDiscoveryCourtSelect();">
                                        <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                            {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                            {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <mat-form-field *ngIf="eDiscoveryLookupSource.value == 'addressbook'">
                                    <mat-label>Address</mat-label>
                                    <input type="text"
                                        placeholder="Type to search by name"
                                        aria-label="Address"
                                        matInput
                                        [formControl]="selectEDiscoveryAddress"
                                        [matAutocomplete]="userAddress">
                                    <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onEDiscoveryAddressSelect();">
                                        <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                            {{ address.name }} 
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <button mat-raised-button (click)="eDiscoveryLookupOpen = false;">Cancel Lookup</button>
                            </div>
                            <div class="deliver-form">
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Company" required formControlName="eDiscoveryCompany">
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryCompany'].hasError('required')">
                                                Company is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Contact (Optional)" formControlName="eDiscoveryContact">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Street" required formControlName="eDiscoveryStreet">
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryStreet'].hasError('required')">
                                                Street address is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Suite (Optional)" formControlName="eDiscoverySuite">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="City" required formControlName="eDiscoveryCity">
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryCity'].hasError('required')">
                                                City is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <input type="text"
                                                placeholder="Type to search by name"
                                                aria-label="State"
                                                required
                                                matInput
                                                formControlName="eDiscoveryState"
                                                [matAutocomplete]="selectEDiscoveryState">
                                            <mat-autocomplete #selectEDiscoveryState="matAutocomplete">
                                                <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                    {{ state.name }} ({{ state.abbreviation}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryState'].hasError('invalid')">
                                                Invalid State
                                            </mat-error>
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryState'].hasError('required')">
                                                Please select a State
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Zip" required formControlName="eDiscoveryZip">
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryZip'].hasError('required')">
                                                Zip is required!
                                            </mat-error>
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryZip'].hasError('pattern')">
                                                Only numbers are allowed
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Phone (Optional)" formControlName="eDiscoveryPhone">
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryPhone'].hasError('pattern')">
                                                Phone may only use allowed characters: 0-9,-,(,)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Ext (Optional)" formControlName="eDiscoveryPhoneExt">
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['eDiscoveryPhoneExt'].hasError('pattern')">
                                                Phone Ext may only use allowed characters: 0-9
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-checkbox 
                                            formControlName="saveAddress"
                                            color="primary"
                                            (change)="saveEDiscoveryAddress=$event.checked">
                                            Save address to my address book
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </form>


                        <form [formGroup]="eDiscoverySendTrialInfo ? trialServicesFormGroup : eDiscoveryTrialFormGroup">

                            <div class="formActions">
                                <button mat-raised-button color="primary" (click)="eDiscoveryLookupOpen = true;"><mat-icon>book</mat-icon> Lookup court</button>
                            </div>

                            <div class="lookup-form" *ngIf="eDiscoveryLookupOpen">
                                <mat-form-field class='courtFormField'>
                                    <mat-label>Court</mat-label>
                                    <input type="text"
                                        placeholder="Type to search by name"
                                        aria-label="Court"
                                        matInput
                                        [formControl]="selectEDiscoveryCourt"
                                        [matAutocomplete]="courtAddress">
                                    <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onEDiscoveryTrialCourtSelect();">
                                        <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                            {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                            {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button mat-raised-button (click)="eDiscoveryLookupOpen = false;">Cancel Lookup</button>
                            </div>
    
                            <div>
                                <h2>Trial Service Info</h2>

                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            required
                                            placeholder="Court"
                                            formControlName="courtName">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            required
                                            placeholder="Court Room"
                                            formControlName="courtRoom">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>Trial Date</mat-label>
                                            <input formControlName="trialDate" required matInput [matDatepicker]="trialDatePicker">
                                            <mat-datepicker-toggle matSuffix [for]="trialDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #trialDatePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            required
                                            type="time"
                                            class="time"
                                            placeholder="Trial Time"
                                            formControlName="trialTime">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input type="text"
                                                placeholder="Trial Time Zone"
                                                aria-label="Trial Time Zone"
                                                required
                                                matInput
                                                formControlName="trialTimeZone"
                                                [matAutocomplete]="selectTrialTimeZone">
                                            <mat-autocomplete #selectTrialTimeZone="matAutocomplete">
                                                <mat-option *ngFor="let timeZone of filteredTimeZone | async" [value]="timeZone.value">
                                                    {{ timeZone.label }} ({{ timeZone.value}} {{timeZone.time}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['trialTimeZone'].hasError('invalid')">
                                                Invalid Timezone
                                            </mat-error>
                                            <mat-error *ngIf="eDiscoveryTrialFormGroup.controls['trialTimeZone'].hasError('required')">
                                                Please select a timezone
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            placeholder="Trial Duration"
                                            formControlName="trialDuration">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div> 
                        </form>

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!serviceDetailFormGroup.valid">Next</button>
                        </div>
                    </div>

                    <!-- Digital -->
                    <div *ngIf="serviceName == 'DIGITAL'">
                        <div *ngIf="categoryName != 'MAILOUT'">

                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!serviceDetailFormGroup.valid">Next</button>
                            </div>

                            <form [formGroup]="digitalDeliveryFormGroup">
                                <mat-checkbox 
                                    formControlName="digitalCheckbox"
                                    color="primary"
                                    (change)="switchDigital($event.checked)">
                                    Digital Delivery (Skip Delivery Forms)
                                </mat-checkbox>
                            </form>

                            <form [formGroup]="recipientAddressFormGroup" *ngIf="!digitalDeliveryOnly">
                                <h2>Deliver to</h2>

                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectDigitalCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onDigitalCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectDigitalAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onDigitalAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <div class="deliver-form">
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Location" required formControlName="locationName">
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationName'].hasError('required')">
                                                    Location is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Street" required formControlName="locationAddress">
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationAddress'].hasError('required')">
                                                    Street address is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="City" required formControlName="locationCity">
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationCity'].hasError('required')">
                                                    City is required!
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <mat-label>State</mat-label>
                                                <input type="text"
                                                    placeholder="Type to search by name"
                                                    aria-label="State"
                                                    required
                                                    matInput
                                                    formControlName="locationState"
                                                    [matAutocomplete]="selectLocationState">
                                                <mat-autocomplete #selectLocationState="matAutocomplete">
                                                    <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                        {{ state.name }} ({{ state.abbreviation}}) 
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationState'].hasError('invalid')">
                                                    Invalid State
                                                </mat-error>
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationState'].hasError('required')">
                                                    Please select a State
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="column">
                                            <mat-form-field>
                                                <input matInput placeholder="Zip" required formControlName="locationZip">
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationZip'].hasError('required')">
                                                    Zip is required!
                                                </mat-error>
                                                <mat-error *ngIf="recipientAddressFormGroup.controls['locationZip'].hasError('pattern')">
                                                    Only numbers are allowed
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <form [formGroup]="digitalAddressesFormGroup" *ngIf="serviceName == 'DIGITAL' && !digitalDeliveryOnly">
                                <h2>Deadline Info</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>Date</mat-label>
                                            <input formControlName="deadlineDate" required matInput [matDatepicker]="deadlineDatePicker">
                                            <mat-datepicker-toggle matSuffix [for]="deadlineDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #deadlineDatePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            required
                                            type="time"
                                            class="time"
                                            placeholder="Time"
                                            formControlName="deadlineTime">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input type="text"
                                                placeholder="Time Zone"
                                                aria-label="Time Zone"
                                                required
                                                matInput
                                                formControlName="deadlineTimeZone"
                                                [matAutocomplete]="selectDeadlineTimeZone">
                                            <mat-autocomplete #selectDeadlineTimeZone="matAutocomplete">
                                                <mat-option *ngFor="let timeZone of filteredTimeZone | async" [value]="timeZone.value">
                                                    {{ timeZone.label }} ({{ timeZone.value}} {{timeZone.time}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="digitalAddressesFormGroup.controls['deadlineTimeZone'].hasError('invalid')">
                                                Invalid Timezone
                                            </mat-error>
                                            <mat-error *ngIf="digitalAddressesFormGroup.controls['deadlineTimeZone'].hasError('required')">
                                                Please select a timezone
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" (click)="checkSysStatus()" [disabled]="!serviceDetailFormGroup.valid">Next</button>
                            </div>
                        </div>

                        <div *ngIf="categoryName == 'MAILOUT'">
                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" [disabled]="!digitalDeliveryOnly && ((!serviceDetailFormGroup.valid || !recipientAddressFormGroup.valid) && digitalAddressesFormGroup.controls['recipientAddresses'].value.length == 0)">Next</button>
                            </div>

                            <form [formGroup]="digitalDeliveryFormGroup">
                                <mat-checkbox 
                                    formControlName="digitalCheckbox"
                                    color="primary"
                                    (change)="switchDigital($event.checked)">
                                    Digital Delivery (Skip Delivery Forms)
                                </mat-checkbox>
                            </form>
                            
                            <form [formGroup]="recipientAddressFormGroup" *ngIf="!digitalDeliveryOnly">
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <h2>Mail out to</h2>
                                <div class="lookup-form" *ngIf="pickupLookupOpen">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectDigitalCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onDigitalCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectDigitalAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onDigitalAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <div class="servee-form">
                                    <div class="left-column" style="width:50%; margin-right:16%;">
                                        <div class="deliver-form">
                                            <div class="row">
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Location" required formControlName="locationName">
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationName'].hasError('required')">
                                                            Location is required!
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Street" required formControlName="locationAddress">
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationAddress'].hasError('required')">
                                                            Street address is required!
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="City" required formControlName="locationCity">
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationCity'].hasError('required')">
                                                            City is required!
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <mat-label>State</mat-label>
                                                        <input type="text"
                                                            placeholder="Type to search by name"
                                                            aria-label="State"
                                                            required
                                                            matInput
                                                            formControlName="locationState"
                                                            [matAutocomplete]="selectLocationState">
                                                        <mat-autocomplete #selectLocationState="matAutocomplete">
                                                            <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                                {{ state.name }} ({{ state.abbreviation}}) 
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationState'].hasError('invalid')">
                                                            Invalid State
                                                        </mat-error>
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationState'].hasError('required')">
                                                            Please select a State
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Zip" required formControlName="locationZip">
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationZip'].hasError('required')">
                                                            Zip is required!
                                                        </mat-error>
                                                        <mat-error *ngIf="recipientAddressFormGroup.controls['locationZip'].hasError('pattern')">
                                                            Only numbers are allowed
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right-column">
                                        <h2>Document Recipients</h2>
                                        <div class="row">
                                            <div class="column">
                                                <button mat-raised-button color="primary" type="button" (click)="addRecipient()">Add Recipient</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="column">
                                                <div *ngFor="let recipient of digitalAddressesFormGroup.controls['recipientAddresses'].value; let i=index" class="single-contact">
                                                    <div class="servee-icon" style="width: 50px;">
                                                        <mat-icon>person</mat-icon>
                                                    </div>
                                                    <div class="info">
                                                        <h4 class="name">{{recipient.locationName}}</h4>
                                                        <p class="detail">{{recipient.locationAddress}} {{recipient.locationCity}}</p>
                                                        <p class="detail">{{recipient.locationState}}, {{recipient.locationZip}}</p>
                                                    </div>
                                                    <div class="delete" (click)="removeRecipient(i)">
                                                        <mat-icon class="centered" color="primary" >delete</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form [formGroup]="digitalAddressesFormGroup" *ngIf="serviceName == 'DIGITAL' && categoryFormGroup.controls['type'].value">
                                            <h2>Deadline Info</h2>
                                            <div class="row">
                                                <div class="column">
                                                    <mat-form-field>
                                                        <mat-label>Date</mat-label>
                                                        <input formControlName="deadlineDate" required matInput [matDatepicker]="deadlineDatePicker">
                                                        <mat-datepicker-toggle matSuffix [for]="deadlineDatePicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #deadlineDatePicker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput
                                                        required
                                                        type="time"
                                                        class="time"
                                                        placeholder="Time"
                                                        formControlName="deadlineTime">
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input type="text"
                                                            placeholder="Time Zone"
                                                            aria-label="Time Zone"
                                                            required
                                                            matInput
                                                            formControlName="deadlineTimeZone"
                                                            [matAutocomplete]="selectDeadlineTimeZone">
                                                        <mat-autocomplete #selectDeadlineTimeZone="matAutocomplete">
                                                            <mat-option *ngFor="let timeZone of filteredTimeZone | async" [value]="timeZone.value">
                                                                {{ timeZone.label }} ({{ timeZone.value}} {{timeZone.time}}) 
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-error *ngIf="digitalAddressesFormGroup.controls['deadlineTimeZone'].hasError('invalid')">
                                                            Invalid Timezone
                                                        </mat-error>
                                                        <mat-error *ngIf="digitalAddressesFormGroup.controls['deadlineTimeZone'].hasError('required')">
                                                            Please select a timezone
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </form>
        
                            <div class="formActions">
                                <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                                <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                                <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                                <button mat-raised-button color="primary" matStepperNext type="button" [disabled]="!digitalDeliveryOnly && ((!serviceDetailFormGroup.valid || !recipientAddressFormGroup.valid) && digitalAddressesFormGroup.controls['recipientAddresses'].value.length == 0)">Next</button>
                            </div>
                        </div>
                    </div>

                    <!-- Investigations -->
                    <div *ngIf="serviceName == 'INVESTIGATIONS'">

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" [disabled]="!serviceDetailFormGroup.valid">Next</button>
                        </div>

                        <div *ngIf="categoryName == 'SKIPTRACE'">
                            <form [formGroup]="skipTraceFormGroup">    
                                <h2>Skip Trace</h2>
                                <div class="servee-form">
                                    <div class="deliver-form">
                                        <div class="row">
                                            <div class="column">
                                                <mat-form-field>
                                                    <input matInput placeholder="Name" required formControlName="name">
                                                    <mat-error *ngIf="skipTraceFormGroup.controls['name'].hasError('required')">
                                                        Name is required!
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="column">
                                                <mat-form-field>
                                                    <mat-label>Date of Birth (Optional)</mat-label>
                                                    <input formControlName="dateOfBirth" matInput [matDatepicker]="dateOfBirthPicker">
                                                    <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="column">
                                                <mat-form-field>
                                                    <input [type]="investigationsSSN ? 'password' : 'text'" matInput placeholder="Social Security Number (Optional)" formControlName="ssn">
                                                </mat-form-field>
                                            </div>
                                            <button mat-raised-button (click)="investigationsSSN=!investigationsSSN" type="button">{{investigationsSSN ? 'Show' : 'Hide'}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = false;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen && !investigationsAddressSwitch">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectInvestigationCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectInvestigationAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>

                                <h2>Work Address (Optional)</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Location" formControlName="locationName">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Address" formControlName="locationAddress">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="City" formControlName="locationCity">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <input type="text"
                                                placeholder="Type to search by name"
                                                aria-label="State"
                                                matInput
                                                formControlName="locationState"
                                                [matAutocomplete]="selectLocationState">
                                            <mat-autocomplete #selectLocationState="matAutocomplete">
                                                <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                    {{ state.name }} ({{ state.abbreviation}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Zip" formControlName="locationZip">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="servee-form">
                                    <div class="left-column" style="width:50%; margin-right:16%">
                                        <form [formGroup]="skipTraceMediaFormGroup">
                                            <h2>Social Media Links (Optional)</h2>
                                            <div class="row">
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Social Media" formControlName="mediaName">
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Link" formControlName="mediaLink">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="right-column">
                                        <h2>Links</h2>
                                        <div class="row">
                                            <div class="column">
                                                <button mat-raised-button color="primary" type="button" (click)="addLink()">Add Link</button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="column">
                                                <div *ngFor="let mediaLink of skipTraceFormGroup.get('socialMediaLinks')?.value; let i=index" class="single-contact">
                                                    <div class="servee-icon" style="width: 50px;">
                                                        <mat-icon>book</mat-icon>
                                                    </div>
                                                    <div class="info">
                                                        <h4 class="name">{{mediaLink.mediaName}}</h4>
                                                        <p class="detail">{{mediaLink.link}}</p>
                                                    </div>
                                                    <div class="delete" (click)="removeLink(i)">
                                                        <mat-icon class="centered" color="primary" >delete</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="servee-form">
                                    <div class="left-column" style="width:50%; margin-right:16%">
                                        <div class="formActions" *ngIf="!pickupLookupOpen">
                                            <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                        </div>
        
                                        <div class="lookup-form" *ngIf="pickupLookupOpen && investigationsAddressSwitch">
                                            <mat-button-toggle-group 
                                                name="pickupLookupSource"
                                                [formControl]="pickupLookupSource"
                                                aria-label="Where to lookup address from"
                                                color="primary">
                                                <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                                <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                            </mat-button-toggle-group>
        
                                            <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                                <mat-label>Court</mat-label>
                                                <input type="text"
                                                    placeholder="Type to search by name"
                                                    aria-label="Court"
                                                    matInput
                                                    [formControl]="selectInvestigationCourt"
                                                    [matAutocomplete]="courtAddress">
                                                <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect('lastKnownAddress');">
                                                    <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                        {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                        {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
        
                                            <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                                <mat-label>Address</mat-label>
                                                <input type="text"
                                                    placeholder="Type to search by name"
                                                    aria-label="Address"
                                                    matInput
                                                    [formControl]="selectInvestigationAddress"
                                                    [matAutocomplete]="userAddress">
                                                <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect('lastKnownAddress');">
                                                    <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                        {{ address.name }} 
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
        
                                            <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                        </div>
                                        <form [formGroup]="lastKnownAddressesFormGroup">
                                            <h2>Last Known Addresses (Optional)</h2>
                                            <div class="row">
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Location" formControlName="locationName">
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Address" formControlName="locationAddress">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="City" formControlName="locationCity">
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <mat-label>State</mat-label>
                                                        <input type="text"
                                                            placeholder="Type to search by name"
                                                            aria-label="State"
                                                            matInput
                                                            formControlName="locationState"
                                                            [matAutocomplete]="selectLocationState">
                                                        <mat-autocomplete #selectLocationState="matAutocomplete">
                                                            <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                                {{ state.name }} ({{ state.abbreviation}}) 
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-error *ngIf="lastKnownAddressesFormGroup.controls['locationState'].hasError('invalid')">
                                                            Invalid State
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="column">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Zip" formControlName="locationZip">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="right-column">
                                        <h2>Addresses</h2>
                                        <div class="row">
                                            <div class="column">
                                                <button mat-raised-button color="primary" type="button" (click)="addLastKnownAddresses()">Add Address</button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="column">
                                                <div *ngFor="let address of lastKnownAddresses; let i=index" class="single-contact">
                                                    <div class="servee-icon" style="width: 50px;">
                                                        <mat-icon>person</mat-icon>
                                                    </div>
                                                    <div class="info">
                                                        <h4 class="name">{{address.locationName}}</h4>
                                                        <p class="detail">{{address.address}} {{address.city}}</p>
                                                        <p class="detail">{{address.state}}, {{address.zip}}</p>
                                                    </div>
                                                    <div class="delete" (click)="lastKnownAddresses.splice(i, 1)">
                                                        <mat-icon class="centered" color="primary" >delete</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="categoryName == 'INSURANCEDEFENSE'">
                            <form [formGroup]="alternateInvestigationsFormGroup">
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = false;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen && !investigationsAddressSwitch">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectInvestigationCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectInvestigationAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <h2>Insurance Defense</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Assignment of Instructions" required formControlName="assignmentInstructions">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['assignmentInstructions'].hasError('required')">
                                                Assignment is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Claimed Injuries" formControlName="claimedInjuries">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Incident Description" formControlName="informationOfClaimedInjuries">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Location" required formControlName="locationName">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationName'].hasError('required')">
                                                Location is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Address" required formControlName="locationAddress">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationAddress'].hasError('required')">
                                                Location address is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="City" required formControlName="locationCity">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationCity'].hasError('required')">
                                                City is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <input type="text"
                                                placeholder="Type to search by name"
                                                aria-label="State"
                                                required
                                                matInput
                                                formControlName="locationState"
                                                [matAutocomplete]="selectLocationState">
                                            <mat-autocomplete #selectLocationState="matAutocomplete">
                                                <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                    {{ state.name }} ({{ state.abbreviation}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationState'].hasError('invalid')">
                                                Invalid State
                                            </mat-error>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationState'].hasError('required')">
                                                Please select a State
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Zip" required formControlName="locationZip">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationZip'].hasError('required')">
                                                Zip is required!
                                            </mat-error>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationZip'].hasError('pattern')">
                                                Only numbers are allowed
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <h2>Time of Loss</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>Date</mat-label>
                                            <input formControlName="dateOfLoss" required matInput [matDatepicker]="dateOfLossPicker">
                                            <mat-datepicker-toggle matSuffix [for]="dateOfLossPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #dateOfLossPicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            required
                                            type="time"
                                            class="time"
                                            placeholder="Time"
                                            formControlName="timeOfLoss">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input type="text"
                                                placeholder="Time Zone"
                                                aria-label="Time Zone"
                                                required
                                                matInput
                                                formControlName="timeZoneOfLoss"
                                                [matAutocomplete]="selectTimeZoneOfLoss">
                                            <mat-autocomplete #selectTimeZoneOfLoss="matAutocomplete">
                                                <mat-option *ngFor="let timeZone of filteredTimeZone | async" [value]="timeZone.value">
                                                    {{ timeZone.label }} ({{ timeZone.value}} {{timeZone.time}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['timeZoneOfLoss'].hasError('invalid')">
                                                Invalid Timezone
                                            </mat-error>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['timeZoneOfLoss'].hasError('required')">
                                                Please select a timezone
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="categoryName == 'LIABILITYRESPONSE'">
                            <form [formGroup]="alternateInvestigationsFormGroup">
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen && investigationsAddressSwitch">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectInvestigationCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectInvestigationAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <h2>Liability Response</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Assignment of Instructions" required formControlName="assignmentInstructions">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['assignmentInstructions'].hasError('required')">
                                                Assignment is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Claimed Injuries" formControlName="claimedInjuries">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Incident Description" formControlName="informationOfClaimedInjuries">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                        <input matInput placeholder="Location" required formControlName="locationName">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationName'].hasError('required')">
                                                Location is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Address" required formControlName="locationAddress">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationAddress'].hasError('required')">
                                                Location address is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="City" required formControlName="locationCity">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationCity'].hasError('required')">
                                                City is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <input type="text"
                                                placeholder="Type to search by name"
                                                aria-label="State"
                                                required
                                                matInput
                                                formControlName="locationState"
                                                [matAutocomplete]="selectLocationState">
                                            <mat-autocomplete #selectLocationState="matAutocomplete">
                                                <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                    {{ state.name }} ({{ state.abbreviation}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationState'].hasError('invalid')">
                                                Invalid State
                                            </mat-error>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationState'].hasError('required')">
                                                Please select a State
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Zip" required formControlName="locationZip">
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationZip'].hasError('required')">
                                                Zip is required!
                                            </mat-error>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['locationZip'].hasError('pattern')">
                                                Only numbers are allowed
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <h2>Time of Loss</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>Date</mat-label>
                                            <input formControlName="dateOfLoss" required matInput [matDatepicker]="dateOfLossPicker">
                                            <mat-datepicker-toggle matSuffix [for]="dateOfLossPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #dateOfLossPicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput
                                            required
                                            type="time"
                                            class="time"
                                            placeholder="Time"
                                            formControlName="timeOfLoss">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input type="text"
                                                placeholder="Time Zone"
                                                aria-label="Time Zone"
                                                required
                                                matInput
                                                formControlName="timeZoneOfLoss"
                                                [matAutocomplete]="selectTimeZoneOfLoss">
                                            <mat-autocomplete #selectTimeZoneOfLoss="matAutocomplete">
                                                <mat-option *ngFor="let timeZone of filteredTimeZone | async" [value]="timeZone.value">
                                                    {{ timeZone.label }} ({{ timeZone.value}} {{timeZone.time}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['timeZoneOfLoss'].hasError('invalid')">
                                                Invalid Timezone
                                            </mat-error>
                                            <mat-error *ngIf="alternateInvestigationsFormGroup.controls['timeZoneOfLoss'].hasError('required')">
                                                Please select a timezone
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="categoryName == 'SUBJECTINFORMATION'">
                            <form [formGroup]="alternateInvestigationsFormGroup">
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen && investigationsAddressSwitch">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectInvestigationCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect();">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectInvestigationAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect();">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="categoryName == 'SUBROSA'">
                            <form [formGroup]="subrosaFormGroup">
                                <h2>Sub Rosa</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Name" required formControlName="name">
                                            <mat-error *ngIf="subrosaFormGroup.controls['name'].hasError('required')">
                                                Name is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Incident Location" required formControlName="pointOfOrigination">
                                            <mat-error *ngIf="subrosaFormGroup.controls['pointOfOrigination'].hasError('required')">
                                                Incident Location is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Nature of Injury" required formControlName="natureOfInjury">
                                            <mat-error *ngIf="subrosaFormGroup.controls['natureOfInjury'].hasError('required')">
                                                Nature of Injury is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = false;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen && !investigationsAddressSwitch">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectInvestigationCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect('home');">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectInvestigationAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect('home');">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <h2>Home Address</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Location" required formControlName="homeName">
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeName'].hasError('required')">
                                                Location is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Address" required formControlName="homeAddress">
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeAddress'].hasError('required')">
                                                Location address is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="City" required formControlName="homeCity">
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeCity'].hasError('required')">
                                                City is required!
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <input type="text"
                                                placeholder="Type to search by name"
                                                aria-label="State"
                                                required
                                                matInput
                                                formControlName="homeState"
                                                [matAutocomplete]="selectHomeState">
                                            <mat-autocomplete #selectHomeState="matAutocomplete">
                                                <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                    {{ state.name }} ({{ state.abbreviation}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeState'].hasError('invalid')">
                                                Invalid State
                                            </mat-error>
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeState'].hasError('required')">
                                                Please select a State
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Zip" required formControlName="homeZip">
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeZip'].hasError('required')">
                                                Zip is required!
                                            </mat-error>
                                            <mat-error *ngIf="subrosaFormGroup.controls['homeZip'].hasError('pattern')">
                                                Only numbers are allowed
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="formActions" *ngIf="!pickupLookupOpen">
                                    <button mat-raised-button color="primary" (click)="pickupLookupOpen = true; investigationsAddressSwitch = true;"><mat-icon>book</mat-icon> Lookup in Addressbook</button>
                                </div>

                                <div class="lookup-form" *ngIf="pickupLookupOpen && investigationsAddressSwitch">
                                    <mat-button-toggle-group 
                                        name="pickupLookupSource"
                                        [formControl]="pickupLookupSource"
                                        aria-label="Where to lookup address from"
                                        color="primary">
                                        <mat-button-toggle value="court">Court Addressbook</mat-button-toggle>
                                        <mat-button-toggle value="addressbook">My Addressbook</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <mat-form-field class='courtFormField' *ngIf="pickupLookupSource.value == 'court'">
                                        <mat-label>Court</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Court"
                                            matInput
                                            [formControl]="selectInvestigationCourt"
                                            [matAutocomplete]="courtAddress">
                                        <mat-autocomplete #courtAddress="matAutocomplete" (optionSelected)="onInvestigationsCourtSelect('work');">
                                            <mat-option *ngFor="let address of filteredCourtAddresses | async" [value]="address.id">
                                                {{ address.name }} | {{ address.streetAddress1? address.streetAddress1 + ', ' : '' }} {{ address.streetAddress2 != '' && address.streetAddress2 != null ? address.streetAddress2 + ', ' : '' }} 
                                                {{address.roomNumber ? 'Suite:' + address.roomNumber + ', ' : ''}} {{ address.city }}  {{ address.state }} {{ address.postalCode}} | County: {{address.county}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="pickupLookupSource.value == 'addressbook'">
                                        <mat-label>Address</mat-label>
                                        <input type="text"
                                            placeholder="Type to search by name"
                                            aria-label="Address"
                                            matInput
                                            [formControl]="selectInvestigationAddress"
                                            [matAutocomplete]="userAddress">
                                        <mat-autocomplete #userAddress="matAutocomplete" (optionSelected)="onInvestigationsAddressSelect('work');">
                                            <mat-option *ngFor="let address of filteredUserAddresses | async" [value]="address.id">
                                                {{ address.name }} 
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <button mat-raised-button (click)="pickupLookupOpen = false;">Cancel Lookup</button>
                                </div>
                                <h2>Work Address (Optional)</h2>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Location" formControlName="workName">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Address" formControlName="workAddress">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="City" formControlName="workCity">
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <mat-label>State</mat-label>
                                            <input type="text"
                                                placeholder="Type to search by name"
                                                aria-label="State"
                                                matInput
                                                formControlName="workState"
                                                [matAutocomplete]="selectWorkState">
                                            <mat-autocomplete #selectWorkState="matAutocomplete">
                                                <mat-option *ngFor="let state of filteredDeliveryState | async" [value]="state.abbreviation">
                                                    {{ state.name }} ({{ state.abbreviation}}) 
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="subrosaFormGroup.controls['workState'].hasError('invalid')">
                                                Invalid State
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="column">
                                        <mat-form-field>
                                            <input matInput placeholder="Zip" formControlName="workZip">
                                            <mat-error *ngIf="subrosaFormGroup.controls['workZip'].hasError('pattern')">
                                                Only numbers are allowed
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" [disabled]="!serviceDetailFormGroup.valid">Next</button>
                        </div>
                    </div>
                </mat-step>
                <!-- Special Instructions -->
                <mat-step [stepControl]="requestNotesFormGroup" label="Instructions">
                    
                    <div class="formActions">
                        <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                        <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                        <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                        <button mat-raised-button color="primary" matStepperNext type="button" (click)="prepareReview();" [disabled]="!requestNotesFormGroup.valid">Next</button>
                    </div>

                    <form [formGroup]="requestNotesFormGroup">
                        <div class="special-form row">
                            <div class="column">
                                <mat-form-field>
                                    <textarea matInput formControlName="specialInstructions" placeholder="Special Instructions (Optional)"></textarea>
                                </mat-form-field>
                        
                                <mat-checkbox formControlName="advanceFees" color="primary" *ngIf="service?.showAdvanceFeesCheckBox">
                                    Advance Fees
                                </mat-checkbox>

                                <mat-checkbox formControlName="alwaysDeliverCourtesyFeesBack" color="primary" *ngIf="service?.showAlwaysDeliverCourtesyFeesBackCheckBox">
                                    Deliver Courtesy Copy
                                </mat-checkbox>

                                <mat-checkbox formControlName="firstAppearanceFees" color="primary" *ngIf="service?.showFirstAppearanceFeesCheckBox">
                                    Advance First Appearance Fees
                                </mat-checkbox>
                                <form [formGroup]="eDiscoveryInfoFormGroup" *ngIf="matterServiceOptions.length > 0 && !eDiscoverySendTrialInfo">
                                    <div>Do you currently use or would you like information on any of the following:</div>
                                    <mat-checkbox 
                                        *ngFor="let info of eDiscoveryInfo; let i=index"
                                        style="display:block;"
                                        [value]="info.value" 
                                        [checked]="info.checked"
                                        [formControlName]="info.control"
                                        color="primary">
                                        {{info.description}}
                                    </mat-checkbox>
                                    <mat-form-field *ngIf="matterServiceOptions.length > 0 && eDiscoveryInfoFormGroup.controls['other'].value">
                                        <input matInput placeholder="Description for other" [formControl]="eDiscoveryInfoOtherControl">
                                    </mat-form-field>
                                </form>
                                <div *ngIf="serviceName == 'EDISCOVERY'">
                                    <div> Our Team will reach out within the hour to confirm details regarding your request</div>
                                    <br>
                                </div>
                            </div>
                        </div>


                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" matStepperNext type="button" (click)="prepareReview();" [disabled]="!requestNotesFormGroup.valid">Next</button>
                        </div>
                    </form>
                </mat-step>

                <!-- Review and Submit -->
                <mat-step label="Review"> 

                    <div class="formActions">
                        <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                        <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                        <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                        <button mat-raised-button color="primary" type="submit" (click)="fileFormGroup.controls['documents'].markAsTouched()" [disabled]="isLoading">Submit</button>
                    </div>

                        <div id="review-pdf" >
                            <table [style]="reviewTableStyle">
                                <tr><!-- Pick up and Service Section -->
                                    <td [style]="leftCellStyle">
                                        <div class="left-column">
                                            <div class="review-header" [style]="reviewHeaderStyle">{{ review.pickupHeader }}</div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">{{ review.pickupContactLabel }}</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.pickupContact }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">Company</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.pickupCompany }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Address</div>
                                                <div class="review-data" [style]="reviewDataStyle" [innerHTML]="review.pickupAddress"></div>
                                            </div>
                                        
                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Phone</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.pickupPhone }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">Pickup Date: </div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.pickupDate }}</div>
                                            </div>

                                            <div class="review-header" [style]="reviewHeaderStyle">Order Details</div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Case Number</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.caseNumber }}</div>
                                            </div>
                                            
                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Case Name</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.caseName }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Description of Documents</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.documentDescription }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td [style]="middleCellStyle">
                                        <div [style]="middleCellStyle"></div>
                                    </td>
                                    <td [style]="rightCellStyle">
                                        <div class="right-column">
                                            <div class="review-header" [style]="reviewHeaderStyle">Requested Service</div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Requested By</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.pickupContact }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Service</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.service }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Type</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.serviceType }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="review.service == 'Other'">
                                                <div class="review-label" [style]="reviewLabelStyle">Service Type Description</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.serviceCategoryDescription }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Client Matter Number</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.clientMatterNumber }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">Claim Number</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.claimNumber }}</div>
                                            </div>
                                            
                                            <div class="review-row" *ngIf="review.claimNumber">
                                                <div class="review-label" [style]="reviewLabelStyle">Insurance Company</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.insuranceCompany }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="review.claimNumber">
                                                <div class="review-label" [style]="reviewLabelStyle">Insurance Adjuster</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.insuranceAdjuster }}</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="review.attachedDocuments.length"><!-- Attached Documents Section -->
                                    <td colspan="3" style="word-wrap: anywhere;">
                                        <div class="review-header" [style]="reviewHeaderStyle">Attached Documents</div>

                                        <div class="review-row" *ngFor="let fName of review.attachedDocuments; let i = index">
                                            <!-- TODO: make link? doesn't make sense until they have been uploaded -->
                                            <div class="review-data" id="fileNameRedirect" (click)="filePreviewRedirect(fName)" [style]="reviewDataStyle">{{ fName }}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr><!-- Served To Section -->
                                    <td colspan="3">
                                        <div class="full-width" *ngFor="let address of review.serveAddress; let i = index">
                                            <div class="review-header" [style]="reviewHeaderStyle">{{ review.deliveryHeader }} {{ review.deliveryHeader == 'Served To' ? ' - Business/Individual #' + (i + 1) : ''}}</div>

                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">{{ review.deliveryContactLabel }}</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.servee[i] }}</div>
                                            </div>

                                            <div class="review-row">
                                                <div class="review-label" [style]="reviewLabelStyle">{{ review.deliveryCompanyLabel }}</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.serveCompany[i] }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">Address</div>
                                                <div class="review-data" [style]="reviewDataStyle" [innerHTML]="review.serveAddress[i]"></div>
                                            </div>

                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">Phone</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.servePhone[i] }}</div>
                                            </div>
                                            
                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">Delivery Date</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.deliveryDate }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="showReviewRow">
                                                <div class="review-label" [style]="reviewLabelStyle">Delivery Time</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{ review.deliveryTime }}</div>
                                            </div>

                                            <div class="review-row" *ngIf="review.deliveryHeader == 'Served To'">
                                                <div class="review-label" [style]="reviewLabelStyle">Additional Info</div>
                                                <div class="review-data" [style]="reviewDataStyle">{{getSOPAdditionalInfo(i)}}</div>
                                            </div>
                                        </div>
                                    </td>    
                                </tr>
                                <tr><!-- Special Instructions Section -->
                                    <td colspan="3">
                                        <div class="full-width">
                                            <div class="review-header" [style]="reviewHeaderStyle">Special Instructions</div>

                                            <div class="review-row">
                                                <div class="review-data" [style]="reviewDataStyle">
                                                    <div *ngIf="requestNotesFormGroup.controls['advanceFees'].value == true">Advance Fees</div>
                                                    <div *ngIf="requestNotesFormGroup.controls['alwaysDeliverCourtesyFeesBack'].value == true">Delivery Courtesy Copy</div>
                                                    <div *ngIf="requestNotesFormGroup.controls['firstAppearanceFees'].value == true">Advance First Appearance Fees</div>
                                                    <div>{{ requestNotesFormGroup.controls['specialInstructions'].value }}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="formActions">
                            <button mat-raised-button (click)="checkSysStatus()" matStepperPrevious>Back</button>
                            <a [routerLink]="['../']"><button mat-raised-button type="button">Cancel Order</button></a>
                            <button mat-raised-button (click)="saveDraft();" [disabled]="draftDisabled">Save Draft</button>
                            <button mat-raised-button color="primary" type="submit" (click)="fileFormGroup.controls['documents'].markAsTouched()" [disabled]="isLoading">Submit</button>
                        </div>
                </mat-step>

                <mat-step label="Complete" *ngIf="false" >
                    <h1>{{orderCompleteHeader}}</h1>

                    <div *ngIf="submitSuccess">
                        <span>Control Number: </span>
                        <span>{{ orderControlNumber }}</span>
                    </div>

                    <div *ngIf="!submitSuccess">
                        <span>Error: </span>
                        <span>{{ orderSubmissionError }}</span>
                    </div>

                    <div class="formActions">

                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </resource-list-pane>
        <resource-list-pane [collapsable]='true' [isCollapsed]="false" title="Order Summary">
            <div class="section">
                <label>Service</label>
                <div class="detail">
                    <label>Line</label>
                    <span>{{ serviceNameFormatted }}</span>
                </div>
                <div class="detail" *ngIf="categoryFormGroup.controls['category'].value">
                    <label>Category</label>
                    <span>{{ categoryDisplayName }}</span>
                </div>
                <div class="detail" *ngIf="categoryFormGroup.controls['type'].value">
                    <label>Type</label>
                    <span>{{ typeDisplayName }}</span>
                </div>
                <div class="detail" *ngIf="accountFormGroup.get('customerNumber')?.value">
                    <label>Account</label>
                    <span>{{ accountFormGroup.get('corpId')?.value }}-{{ accountFormGroup.get('customerNumber')?.value }}</span>
                </div>
            </div>

            <div class="section" *ngIf="categoryFormGroup.controls['claimNumber'].value">
                <label>Insurance Claim</label>
                <div class="detail">
                    <label>Claim Number</label>
                    <span>{{ categoryFormGroup.controls['claimNumber'].value }}</span>
                </div>
                <div class="detail">
                    <label>Insurance Company</label>
                    <span>{{ categoryFormGroup.controls['insuranceCompany'].value }}</span>
                </div>
                <div class="detail">
                    <label>Insurance Adjuster</label>
                    <span>{{ categoryFormGroup.controls['insuranceAdjuster'].value }}</span>
                </div>
            </div>            

            <div class="section files-list" *ngIf="files.length || seedDocuments.length">
                <label>Documents</label>
                <div class="single-file" *ngFor="let document of seedDocuments; let i = index">
                    <div class="file-icon" style="width: 50px;">
                        <mat-icon >article</mat-icon>
                    </div>
                    <div class="info">
                        <h4 class="name">{{ document.fileName }}</h4>
                        <p class="size">Uploaded: {{ document.updatedAtUTC | reflexTimeAgo }}</p>
                    </div>
                    <!-- <div class="delete" (click)="removeSeedDocument(i)">
                        <mat-icon class="centered" color="primary">delete</mat-icon>
                    </div> -->
                </div>
                <!-- <div class="single-file deleted" *ngFor="let document of deletedSeedDocuments; let i = index">
                    <div class="file-icon" style="width: 50px;">
                        <mat-icon >article</mat-icon>
                    </div>
                    <div class="info">
                        <h4 class="name">{{ document.fileName }}</h4>
                        <p class="warn">Will be removed from Order</p>
                    </div>
                    <div class="restore" (click)="restoreSeedDocument(i)">
                        <mat-icon class="centered" color="primary">restore_from_trash</mat-icon>
                    </div>
                </div> -->
                <div class="single-file" *ngFor="let file of files; let i = index">
                    <div class="file-icon" style="width: 50px;">
                        <mat-icon >article</mat-icon>
                    </div>
                    <div class="info">
                        <h4 class="name" id="fileNameRedirect" (click)="filePreviewRedirect(file.name)">{{ file.name }}</h4>
                        <p class="size">{{ formatBytes(file.size) }}</p>
                    </div>
                </div>
            </div>

            <div class="section" *ngIf="caseFormGroup.controls['caseNumber'].value">
                <label>Case</label>
                <div class="detail">
                    <label>Name</label>
                    <span>{{ caseFormGroup.controls['caseName']?.value }}</span>
                </div>
                <div class="detail">
                    <label>Number</label>
                    <span>{{ caseFormGroup.controls['caseNumber']?.value }}</span>
                </div>
                <div class="detail">
                    <label>Client Matter</label>
                    <span *ngFor="let i of hyphenate(this.categoryFormGroup.get('clientMatterNumber')?.value)">
                        <span>{{ i }}</span>
                    </span>
                </div>
            </div>     
            <div class="section" *ngIf="requestNotesFormGroup.controls['specialInstructions'].value">
                <label>Instructions</label>
                <div class="detail">
                    <span>{{ requestNotesFormGroup.controls['advanceFees'].value == true ? "Advance Fees" : "" }}</span>
                </div>
                <div class="detail">
                    <span>{{ requestNotesFormGroup.controls['alwaysDeliverCourtesyFeesBack'].value == true ? "Delivery Courtesy Copy" : "" }}</span>
                </div>
                <div class="detail">
                    <span>{{ requestNotesFormGroup.controls['specialInstructions'].value }}</span>
                </div>

            </div> 

        </resource-list-pane>
    </resource-list-grid>
</form>
<sc-dialog [dontLeave]="formDirty && !maintenance" [disableClose]="maintenance" #scdialog>
    <h2 mat-dialog-title><b>Unsaved changes</b></h2>
    <mat-dialog-content>
    <span *ngIf="maintenance">The site is currently undergoing maintenance, please logout and retry creating an order later.</span>
    <span *ngIf="!maintenance">You're about to leave the page without saving the latest changes.<br>Please confirm.</span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button *ngIf="!maintenance" mat-flat-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Return to Order</button>
        <button *ngIf="!maintenance" mat-button [mat-dialog-close]="true" (click)="saveDraft();">Save Draft</button>
        <button mat-button color="warn" [mat-dialog-close]="true">Cancel Order</button>
    </mat-dialog-actions>
</sc-dialog>    



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CanLeaveDirective } from './canLeaveDialog/canLeave.dialog';
import { DialogComponent } from './canLeaveDialog/dialog'
import { SiteFrameComponent } from './siteFrame/siteFrame';
import { UploaderComponent } from './uploader/uploader';
import { UserFlowComponent } from './userFlow/userFlow';
import { UploaderTableComponent } from './uploader/uploader-table/uploader-table';
import { UserControlComponent } from './userControl/userControl';
import { SelectVendorAccountDialog } from './selectVendorAccount/selectVendorAccount';
import { AestheticsModule } from '../aesthetics/aesthetics.module';
import { NavigationModule, ResourceModule } from '@smartsoftware/reflex-core';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { PageNoticeComponent } from './page-notice/page-notice';
import { RequestAcclaimAccountDialog } from './accountRequest/requestAcclaimAccount';
import { RequestTristarAccountDialog } from './accountRequest/requestTristarAccount/requestTristarAccount';
import { OrderSubmitFailedDialog } from './orderSubmitFailed/orderSubmitFailed';
import { BreadcrumbBarComponent } from './breadcrumb-bar/breadcrumb-bar';
import { BreadCrumbService } from './breadcrumb-bar/breadcrumb-service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';


@NgModule({
	declarations: [
		UserControlComponent,
		UploaderComponent,
		UploaderTableComponent,
		UserFlowComponent,
  		SiteFooterComponent,
    	InfoBannerComponent,
		RequestAcclaimAccountDialog,
		RequestTristarAccountDialog,
		OrderSubmitFailedDialog,
		BreadcrumbBarComponent,
		PageNoticeComponent,
		DialogComponent,
		CanLeaveDirective,
		SelectVendorAccountDialog,
	],
	imports: [
		CommonModule,
		RouterModule,
		AestheticsModule,
		NavigationModule,
		ResourceModule, 
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		MatBadgeModule,
		MatDialogModule,
	],
	exports: [
		UserControlComponent,
		UploaderComponent,
		UploaderTableComponent,
		UserFlowComponent,
		SiteFooterComponent,
		InfoBannerComponent,
		RequestAcclaimAccountDialog,
		RequestTristarAccountDialog,
		OrderSubmitFailedDialog,
		BreadcrumbBarComponent,
		PageNoticeComponent,
		DialogComponent,
		CanLeaveDirective,
	],
	providers: [
		BreadCrumbService
	]
})
export class ComponentsModule { }

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResourceViewComponent, ConfirmDeleteDialog, ReflexEnvironment } from '@smartsoftware/reflex-core';
import {
    SuccessCase,
    SuccessCase_Service,
    Order,
    OrderStatus,
    Document,
    Service,
    Service_Service,
    ServiceTypes,
    ServiceCategory,
    ServiceType,
    Order_Service,
    OrderDetail,
    OrderDetail_Service,
    ServiceAddress,
    ServiceAddress_Service,
    SuccessAccount,
    SuccessAccount_Service,
    UserPermission_Service,
    ToServe_Service,
    ToServe,
    Submission_Service,
    Submission,
    Vendor,
    Division,
    Vendor_Service,
    ServiceWithCategories,
    ServiceCategoryWithTypes,
    OrderService_Service,
    OrderService,
    OrderServiceAttempt,
    OrderServiceAffidavit,
    SystemConfig_service,
    OrderNote_service,
    OrderSpecialInstruction,
    OrderSpecialInstruction_Service
} from 'legalreflex-lib';
import { BreadCrumbService, BreadcrumbAction } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators';
import { Title } from '@angular/platform-browser';
import { OrderPrint_Service, ReviewData } from '../orderPrint.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    templateUrl: './view.html',
    styleUrls: ['./view.scss']
})
export class OrderView extends ResourceViewComponent<Order, Order_Service> implements OnInit, AfterViewInit {
    public orderNotes: OrderNoteListEntry[] = [];
    public sortedOrderNotes: MatTableDataSource<OrderNoteListEntry> = new MatTableDataSource(this.orderNotes);

    public orderDetail: BehaviorSubject<OrderDetail> = new BehaviorSubject<OrderDetail>(new OrderDetail());
    public pickupAddress: BehaviorSubject<ServiceAddress> = new BehaviorSubject<ServiceAddress>(new ServiceAddress());
    public deliveryAddress: BehaviorSubject<ServiceAddress> = new BehaviorSubject<ServiceAddress>(new ServiceAddress());
    public createdByUser: BehaviorSubject<SuccessAccount> = new BehaviorSubject<SuccessAccount>(new SuccessAccount());
    public service: BehaviorSubject<ServiceWithCategories> = new BehaviorSubject<ServiceWithCategories>({
        service: new Service(),
        categories: []
    });
    

    public deliveryAddress$: BehaviorSubject<ServiceAddress[]> = new BehaviorSubject<ServiceAddress[]>([])
    public courtAddresses$: BehaviorSubject<ServiceAddress[]> = new BehaviorSubject<ServiceAddress[]>([])
    public orderServiceArr: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([])
    public orderServiceCategory: BehaviorSubject<ServiceCategoryWithTypes> = new BehaviorSubject<ServiceCategoryWithTypes>({
        category: new ServiceCategory,
        types: []
    });

    public orderDocuments: BehaviorSubject<Document[]> = new BehaviorSubject<Document[]>([]);
    public case: BehaviorSubject<SuccessCase> = new BehaviorSubject<SuccessCase>(new SuccessCase());
    public vendor: BehaviorSubject<Vendor> = new BehaviorSubject<Vendor>(new Vendor());
    public recentlyFinished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public draftSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public specialInstructions: string = "";

    public orderNumber: string | undefined | null;

    public vendorMap = new Map();

    public orderService: BehaviorSubject<OrderService> | undefined = undefined;
    public orderServiceAttempts: BehaviorSubject<OrderServiceAttempt[]> = new BehaviorSubject<OrderServiceAttempt[]>([])
    public orderServiceAffidavits: BehaviorSubject<OrderServiceAffidavit[]> = new BehaviorSubject<OrderServiceAffidavit[]>([])
    public orderSpecialInstructions: BehaviorSubject<OrderSpecialInstruction> = new BehaviorSubject<OrderSpecialInstruction>(new OrderSpecialInstruction());

    public isDataSynced: boolean = false;
    public services: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([])
    public categories: ServiceCategory[] = [];
    public types: ServiceType[] = [];
    public servees$: BehaviorSubject<ToServe[]> = new BehaviorSubject<ToServe[]>([])
    public submission$: BehaviorSubject<Submission> = new BehaviorSubject<Submission>(new Submission());
    public category: ServiceCategory | undefined;
    public type: ServiceType | undefined;
    
    // TODO: Form validation
    public get formStatus(): boolean {
        return true;
    }

    constructor(
        protected entityService: Order_Service,
        protected orderDetailService: OrderDetail_Service,
        protected serviceService: Service_Service,
        protected serviceAddressService: ServiceAddress_Service,
        protected toServeService: ToServe_Service,
        protected orderServiceService: OrderService_Service,
        protected vendorService: Vendor_Service,
        protected SubmissionService: Submission_Service,
        protected successAccountService: SuccessAccount_Service,
        protected caseService: SuccessCase_Service,
        public OrderPrintService: OrderPrint_Service,
        protected auth: OidcSecurityService,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
		protected breadCrumbService: BreadCrumbService,
        protected pageTitleService: Title,
        protected userPermissionService: UserPermission_Service,
        public sysConfigService: SystemConfig_service,
        protected orderNoteService: OrderNote_service,
        protected orderSpecialInstructionsService: OrderSpecialInstruction_Service,
        private snackBar: MatSnackBar
    ) {
        super(entityService, route, dialog);
    }

    public review : ReviewData = {
        createdAt: "",
        updatedAt: "",
        orderNumber: "",
        service: "",
        serviceCategory: "",
        serviceType: "",
        serviceCategoryDescription: "",
        clientMatterNumber: "",
        claimNumber: "",
        insuranceCompany: "",
        insuranceAdjuster: "",
        pickupContact: "",
        pickupCompany: "",
        pickupAddress: [""],
        pickupPhone: "",
        pickupDate: "",
        caseNumber: "",
        caseName: "",
        documentDescription: "",
        attachedDocuments: [],
        servee: [""],
        serveCompany: [""],
        serveAddress: [""],
        serveAttempts: [],
        serveAffidavits: [],
        servePhone: [""],
        pickupHeader: "",
        pickupContactLabel: "",
        deliveryHeader: "",
        deliveryContactLabel: "",
        deliveryCompanyLabel: "",
        deliveryDate: "",
        deliveryTime: "",
        deliverySpecialInstructions: "",
        pickupSpecialInstructions: "",
        orderId: "",
        firmName: "",
        callerName: "",
        orderStatus: "",
        dispatchNumber: "",
        driverNumber: "",
        bolNumber: "",
        env: ""
    };

    private getDeliveryTime(serviceType: ServiceType) : Date {
        let date: Date = new Date((this.entity.orderDate || this.entity.updatedAt));
        // let utcHour = date.getUTCHours() || 24;
        let pacificHour = parseInt(date.toLocaleTimeString('en-US-u-hc-h24', {hour: '2-digit', hour12: false, timeZone: 'America/Los_Angeles'}));
        // let timezoneOffset = ((utcHour - pacificHour) + 24) % 24;
        if(!serviceType){
            serviceType = new ServiceType()
            serviceType.isRush = false;
            serviceType.dayModifier = 0;
            serviceType.hourModifier = 0;
            serviceType.minuteModifier = 0;
        }
        // Keep track of how many days to adjust the time by.
        let days = serviceType.dayModifier;

        let hour: number; 
        if(serviceType.isRush) { 
            hour = date.getHours() + serviceType.hourModifier;
        } else {
            let unmoddedHour = serviceType.hourModifier; // + timezoneOffset; was causing times to be off
            hour = (unmoddedHour % 24);
            
            // non rush jobs need to check if the day should be incremented.
            if(unmoddedHour >= 24) days++;
        }

        // Set the minutes to the next 5-minute increment.
        let minute: number = (Math.ceil(date.getMinutes() / 5)) * 5;
    
        // Account for FILING SPECIAL and DELIVERY SPECIAL ServiceType.
        minute += serviceType.minuteModifier;
        hour += ((minute >= 60) ? 1 : 0);
    
        // If isRush: Add the current hour + hourModifer and adjust the day if needed.
        // If not isRush: If modifier > 0, check that the current time is earlier than the modifier, else adjust the day.
        if ( (serviceType.isRush && ((pacificHour + ((minute >= 60) ? 1 : 0) + serviceType.hourModifier) >= 24)) 
                || (!serviceType.isRush && (serviceType.hourModifier && (pacificHour >= serviceType.hourModifier))) ) {
            days++;
        }
    
        date.setDate(date.getDate() + days);
        date.setHours(hour, (minute % 60));
    
        return date;
    }

    public saveReviewToPDF(): void{
        //this.setReviewData();
        this.OrderPrintService.print(this.review)
    }

    public getSOPAdditionalInfo(entity:ToServe){
        return (entity.phone ? "Phone: " + entity.phone + ";" : "") + (entity.email ? "Email: " + entity.email + ";" : "")
        + (entity.age ? "Age: " + entity.age + ";" : "") + (entity.gender ? "Gender: " + entity.gender + ";" : "")
        + (entity.relationship ? "Relationship: " + entity.relationship + ";" : "")  + (entity.ethnicity ? "Ethnicity: " + entity.ethnicity + ";" : "")
        + (entity.eyes ? "Eyes: " + entity.eyes + ";" : "") + (entity.hair ? "Hair: " + entity.hair + ";" : "")
        + (entity.height ? "Height: " + entity.height + ";" : "") + (entity.weight ? "Weight: " + entity.weight + ";" : "")
    }

    public getServeeServiceAddress(entity: ToServe){
        return this.deliveryAddress$.value.find((address)=> address.toServe_uuid == entity.uuid)
    }

    // function for stringifying ServiceAddresses for the review
    private addressBuilder(address: ServiceAddress) : Array<string> {
        if(!address) return [""];

        let street = address.address || "";
        let hasStreet = !!address.address;
        let room = address.room || "";
        let hasRoom = !!address.room;
        let city = address.city || "";
        let hasCity = !!address.city;
        let state = address.state || "";
        let hasState = !!address.state;
        let zip = address.zip || "";
        let hasZip = !!address.zip;

        let formattedStreet = (hasStreet && hasRoom) ? (street.trim() + ", Suite " + room.trim()) : (hasStreet ? street.trim() : "");
        let formattedCityStateZip = (hasCity ? city.trim() : "") 
            + ((hasCity && hasState) ? (", " + state.trim()) : (hasState ? state.trim() : "")) 
            + ((hasState || hasCity) ? (" " + zip.trim()) : (hasZip ? zip.trim() : ""))

        let formattedAddress = [
            formattedStreet,
            formattedCityStateZip
        ];
        
        return formattedAddress;
    }

    public addressBuilderHTML(address?: ServiceAddress) : string {
        if(!address) return "";

        let street = address.address || "";
        let hasStreet = !!address.address;
        let room = address.room || "";
        let hasRoom = !!address.room;
        let city = address.city || "";
        let hasCity = !!address.city;
        let state = address.state || "";
        let hasState = !!address.state;
        let zip = address.zip || "";
        let hasZip = !!address.zip;


        let formattedStreet = (hasStreet && hasRoom) ? (street.trim() + ", Suite " + room.trim()) : (hasStreet ? street.trim() : "");
        let formattedCityStateZip = (hasCity ? city.trim() : "") 
            + ((hasCity && hasState) ? (", " + state.trim()) : (hasState ? state.trim() : "")) 
            + ((hasState || hasCity) ? (" " + zip.trim()) : (hasZip ? zip.trim() : ""))
        
        let formattedAddress = formattedStreet + (formattedStreet || formattedCityStateZip ? "<br>" : "") + formattedCityStateZip;
        return formattedAddress;
    }

    public phoneBuilder(address: ServiceAddress) : string {
        if(!address.phoneExt && !address.phone) return "";

        if(!address.phoneExt) return address.phone || "";

        return address.phone + " ext. " + address.phoneExt;
    }

    public setReviewData(): void {
        console.log('setReviewData');
        let pickupHeader: string;
        let pickupContactLabel: string;
        let deliveryHeader : string;
        let deliveryContactLabel : string; 
        let deliveryCompanyLabel : string;
        let serveCompany: string;

        if(this.showDeliverTo){
            pickupHeader = "Pickup From";
            pickupContactLabel = "Contact"
            deliveryHeader = "Deliver To";
            deliveryContactLabel = "Contact";
            deliveryCompanyLabel = "Company";
            serveCompany = (this.deliveryAddress.value.name)?this.deliveryAddress.value.name:""
        }

        else if(this.showCourt) {
            pickupHeader = "Order Information";
            pickupContactLabel = "Ordered By"
            deliveryHeader = "Court";
            deliveryContactLabel = "";
            deliveryCompanyLabel = "Court";
        }

        else {
            pickupHeader = "Pickup From";
            pickupContactLabel = "Contact"
            deliveryHeader = "Served To";
            deliveryContactLabel = "Person to be Served";
            deliveryCompanyLabel = "Party to be Served";
        }
        // console.log("Submission Value:", this.submission$.value, "Instructions:", this.specialInstructions)
        // if(this.submission$.value && this.submission$.value.requestNotes?.indexOf("{ServeeNumber") != -1){
        //     let currentSubmission = this.submission$.value
        //     let serveesStart = currentSubmission.requestNotes?.indexOf("{ServeeNumber");
        //     let reqIndex = currentSubmission.requestNotes?.indexOf("{ServeeNumber: '" + this.deliveryAddress?.value.uuid + "';");
        //     let baseRequestNotes = currentSubmission.requestNotes?.substring(0, (serveesStart != 0 ? serveesStart : undefined)).trim().replace("\"", "'").replace("\\", "/").replace("“", "'").replace("”", "'").replace(/[^ -~]+/g, '');
        //     if(reqIndex != -1) {
        //         let servee = currentSubmission.requestNotes?.substring(reqIndex ? reqIndex : currentSubmission.requestNotes.length);
        //         let endIndex = servee?.indexOf("}");
        //         let str = "{ServeeNumber: '" + this.deliveryAddress?.value.uuid + "';"
        //         servee = servee?.substring(str.length, endIndex ? endIndex : 0);
        //         if(servee != ""){
        //             this.specialInstructions = baseRequestNotes + (servee ? servee : "");
        //         }
        //         console.log("MAILINGLOGIC - Current Submission:", currentSubmission.requestNotes, "Servee:", servee, "End result:", this.specialInstructions);
        //     }
        // }
        
        let contact = this.createdByUser.value.firstName + " " + this.createdByUser.value.lastName;
        let servee = [""];
        let servePhone = [""];
        let deliveryNames = [""];
        let deliveryAddresses = [""];

        if(this.showServedTo){
            servee.pop();
            servePhone.pop();
            this.servees$.value.map((s)=>{
                console.debug("Current servee:", s);
                servee.push(s.firstName + " " + s.lastName);
                servePhone.push(s.phone)
            })
            deliveryAddresses.pop();
            deliveryNames.pop();
            this.deliveryAddress$.value.map((d)=>{
                console.debug("current address:", d);
                deliveryNames.push(d.name ? d.name : "");
                // TODO: maybe add the rest of the address?
                if(this.deliveryAddress$.value.length == 1)
                    deliveryAddresses = this.addressBuilder(d)
                else
                    deliveryAddresses.push((d.address ? d.address : "") + (d.city ? (', ' + d.city) : ""));

                if(servePhone.length == 0 && d.phone)
                    servePhone.push((d.phone))
            })

            if(!this.servees$.value || !this.servees$.value.length) {
                servee.push('attention');
            }
            
            if(!this.deliveryAddress$.value || !this.deliveryAddress$.value.length) {
                servePhone[0] = this.deliveryAddress.value.phone ? this.deliveryAddress.value.phone : "";
                deliveryNames[0] = (this.deliveryAddress.value.name || "");
                deliveryAddresses = this.addressBuilder(this.deliveryAddress.value);
            }
        } else if (this.showDeliverTo) {
            // TODO: delivery name is used for both Servee and the deliveryNames, not sure if this is the intent
            servee[0] = this.deliveryAddress.value.name ? this.deliveryAddress.value.name : "";
            servePhone[0] = this.deliveryAddress.value.phone ? this.deliveryAddress.value.phone : "";
            deliveryNames[0] = (this.deliveryAddress.value.name || "");
            deliveryAddresses = this.addressBuilder(this.deliveryAddress.value);
        } else if (this.showCourt) {
            servee[0] = this.deliveryAddress.value.attention ? this.deliveryAddress.value.attention : "";
            servePhone[0] = this.deliveryAddress.value.phone ? this.deliveryAddress.value.phone : "";
            deliveryNames[0] = (this.deliveryAddress.value.name || "");
            deliveryAddresses = this.addressBuilder(this.deliveryAddress.value);
        }

        let files: string[] = [];
        let companyPickup = this.pickupAddress.value.name;
        for(let i=0; i<this.orderDocuments.value.length; i++){
            
            files.push(this.orderDocuments.value[i].fileName!)
        }
        let loc = "https://app." + ReflexEnvironment.config['resourcePrefix'] + ".firstlegal.com" +  `/order/` + this.entity.uuid;
        console.log(this.entity.jobDate, this.entity.dueDate)
        let pDate : string = ''
        let deliverDate : string = ''
        if(this.entity.jobDate)
            pDate = new Date(this.entity.jobDate).toDateString();
        // pDate =  formatDate(pDate!,'yyyy-MM-dd','en-US');
        if(this.entity.dueDate)
            deliverDate = new Date(this.entity.dueDate)?.toDateString();
        // if(this.entity.dueDate){
          // deliverDate = formatDate(deliverDate!,'yyyy-MM-dd','en-US')
        // }
        console.debug("Service Info:", this.service.value.service.displayName)
        let orderData = JSON.parse(this.entity.orderData as string);
        let showDelivery = ((this.deliveryAddress$.value.length > 0 && this.showServedTo) || (this.deliveryAddress.value && !this.showServedTo));
        this.review = {
            createdAt: (this.entity.createdAtUTC ? this.entity.createdAtUTC.toDateString() : ""),
            updatedAt: (this.entity.updatedAtUTC ? this.entity.updatedAtUTC.toDateString() : ""),
            orderNumber: (this.entity.orderNumber || ""),
            service: (this.service.value.service.displayName || ""),
            serviceCategory: (this.orderServiceCategory.value.category.displayName || ""),
            serviceType: (this.type?.displayName || ""),
            serviceCategoryDescription: orderData? (orderData.serviceCategoryDescription || "") : "",
            clientMatterNumber: (this.entity.clientMatterNumber)? this.entity.clientMatterNumber : "",
            claimNumber: (this.entity.claimNumber)? this.entity.claimNumber : "",
            insuranceCompany: (this.entity.insuranceCompany)? this.entity.insuranceCompany : "",
            insuranceAdjuster: (this.entity.insuranceAdjuster)? this.entity.insuranceAdjuster : "",
            pickupContact: (contact)? contact:"",
            pickupCompany: companyPickup!,
            pickupAddress: this.addressBuilder(this.pickupAddress.value),
            pickupPhone: (this.pickupAddress.value.phone)? this.pickupAddress.value.phone: "",
            pickupDate: pDate!,
            caseNumber: (this.case?.value.caseNumber || this.entity.caseNumber || ""),
            caseName: (this.case?.value.name || this.entity.caseName || ""),
            documentDescription: (this.entity.documentsDescription)? this.entity.documentsDescription : "",
            attachedDocuments: files, 
            servee: (servee.length > 0) ? servee : [""],
            serveCompany: deliveryNames,
            serveAddress: deliveryAddresses, 
            // this.deliveryAddress.value ? this.deliveryAddress.value.state + ', ' + this.deliveryAddress.value.zip: ""],
            servePhone: (servePhone.length > 0) ? servePhone : [""],
            serveAttempts: this.orderServiceAttempts.value.map((attempt)=> {return [attempt.attemptedAt? attempt.attemptedAt.toLocaleString('en-US', {timeZone: 'America/Los_Angeles'}) : "", attempt.status]}),
            serveAffidavits: this.orderServiceAffidavits.value.map((affidavit)=> {return [affidavit.title || '', affidavit.linkTo || '']}),
            pickupHeader: pickupHeader, 
            pickupContactLabel: pickupContactLabel,
            deliveryHeader: deliveryHeader,
            deliveryContactLabel: deliveryContactLabel,
            deliveryCompanyLabel: deliveryCompanyLabel,
            deliveryDate: (deliverDate)?deliverDate:"",
            deliveryTime: this.getDeliveryTime(this.type!).toDateString(),
            // deliverySpecialInstructions: this.specialInstructions,
            deliverySpecialInstructions: this.deliverySpecialInstructionBuilder(),
            pickupSpecialInstructions: this.pickupSpecialInstructionBuilder(),
            orderId: this.entity.uuid,
            firmName: this.createdByUser.value.corpId,
            callerName: this.createdByUser.value.fullName,
            orderStatus: this.entity.orderStatus || "",
            dispatchNumber: this.review.dispatchNumber ? this.review.dispatchNumber.toString() : "",
            driverNumber: this.review.driverNumber ? this.review.driverNumber.toString() : "",
            bolNumber: this.review.bolNumber ? this.review.bolNumber.toString() : "",
            pieces: this.review.pieces ? this.review.pieces.toString() : "",
            weight: this.review.weight ? this.review.weight.toString() : "",
            env: loc
        }
        this.OrderPrintService.print(this.review)
    }

    public vendorName: string = "";
    public set_VendorName(entity : Order){

        this.vendorName = this.vendorMap.get(entity.vendor_uuid)
    }

    public restoreOrder() {
        this.entity.deletedAt = null;
        this.entity.orderStatus = OrderStatus.DRAFT; // "Draft"
        this.entityService
            .push(this.entity)
            .subscribe(
                (response) => {
                    this.updateCrumbActions();
                },
                (error) => {
                    error.message = error.message + ' ' + error.error.internalError;
                    this.openErrorDialog(error);
                }
            )
    }

    // overridden to make the modal display differently
    openConfirmDeleteDialog() {
        if(!this.entity) return new Observable<false>();
        return this.dialog
            .open(ConfirmDeleteDialog, {
                data: { entities: [{name: "Draft Order"}] }
            })
        .afterClosed();
    }

    public delete() {
        this.openConfirmDeleteDialog()
            .subscribe(response => {
                if(response.confirmed)
                    this.deleteWithConfirm();
            });
    }

    public deleteWithConfirm(): void {
        this.entity.deletedAt = new Date();
        this.entity.orderStatus = OrderStatus.CANCELED; // "Canceled";

        // console.log('entity', this.entity);
        this.entityService
            .push(this.entity)
            .subscribe(
                (response) => {
                    this.updateCrumbActions();  
                },
                (error) => {
                    error.message = error.message + ' ' + error.error.internalError;
                    this.openErrorDialog(error);
                }
            );
    }


    public set_OrderNumber(entity: Order) {
        this.set_VendorName(entity)
        console.log("Entity info:", entity.orderNumber, entity.vendorId, this.vendorName)

        if(entity.orderStatus == OrderStatus.DRAFT || entity.orderStatus == OrderStatus.CANCELED) {
            entity.orderNumber = OrderStatus.DRAFT;
        } else {
            if(this.vendorName == Division.DATATRAC){
                if(!entity.companyNumber || !entity.vendorId){
                    this.entityService.get(this.entity.uuid).subscribe((res)=>{
                        if(res.companyNumber && res.vendorId){
                            this.entity.orderNumber = res.companyNumber + '-' + res.vendorId;
                        }else{
                            this.entity.orderNumber = "Number Invalid: " + (res.companyNumber ? "Company: " + res.companyNumber + " Missing Control": '') + (res.vendorId ? "Control: " + res.vendorId + " Missing Company": '');
                        }
                        this.breadCrumbService.breadcrumbs.next([
                            { label: 'Orders', url: '/order'},
                            { label: 'Order ' + this.entity.orderNumber }
                        ]);

                    })
                }else{
                    this.entity.orderNumber = entity.companyNumber + '-' + entity.vendorId;
                    this.breadCrumbService.breadcrumbs.next([
                        { label: 'Orders', url: '/order'},
                        { label: 'Order ' + this.entity.orderNumber }
                    ]);
                }
            }
            else if(this.vendorName == Division.ACCLAIM){
                this.entity.orderNumber = entity.vendorId;
            }
            else if(this.vendorName == Division.EDISCOVERY){
                if(this.entity.clientMatterNumber == 'New Client Matter'){
                    this.entity.orderNumber = 'Pending New Client Matter, Order ID:' + this.entity.orderId;
                } 
                else if(!entity.companyNumber || !entity.vendorId){
                    this.entity.orderNumber = 'eDiscovery' + ' Order ID:' + this.entity.orderId;
                }
            }
            // Digital
            else if(this.vendorName == Division.TRISTAR_FDS){
                if(!entity.companyNumber || !entity.vendorId){
                    this.entity.orderNumber = 'Digital' + ' Order ID:' + this.entity.orderId;
                }else{
                    this.entity.orderNumber = entity.companyNumber + '-' + entity.vendorId;
                    this.breadCrumbService.breadcrumbs.next([
                        { label: 'Orders', url: '/order'},
                        { label: 'Order ' + this.entity.orderNumber }
                    ]);
                }
            }
            // Investigations
            else if(this.vendorName == Division.TRISTAR_FLI || this.vendorName == Division.TRISTAR_FLI_CG){
                if(!entity.orderNumber || !entity.vendorId){
                    this.entity.orderNumber = 'Investigations' + ' Order ID:' + this.entity.orderId;
                }else{
                    this.entity.orderNumber = entity.vendorId;
                    this.breadCrumbService.breadcrumbs.next([
                        { label: 'Orders', url: '/order'},
                        { label: 'Order ' + this.entity.orderNumber }
                    ]);
                }
            }
            // Tristar
            else{
                this.entity.orderNumber = entity.vendorId + '-' + entity.vendorKeyPart;
            }        
        }
    }

    public fetch_Servees(entity: Order){
        if(!entity || (entity && !entity.uuid)){
            this.servees$.next([]);
            return;
        }

        this
            .toServeService
            .getDeliveryToServesByOrderId(entity.uuid)
            .subscribe(
                (items) => {
                    this.servees$.next(items);
                    if(this.deliveryAddress$.value){
                        let sort: ServiceAddress[] = [];
                        this.servees$.value.map((servee)=>{
                            let add = this.deliveryAddress$.value.find((address)=> address.toServe_uuid == servee.uuid)
                            if(add) {sort.push(add)};
                        })
                        this.deliveryAddress$.next(sort);
                    }
                    this.fetch_ServiceAddresses(this.entity)
                }
            )
    }

    public fetch_ServiceAddresses(entity: Order){
        if(!entity || (entity && !entity.uuid)){
            this.deliveryAddress$.next([]);
            return;
        }
        if(this.servees$.value.length > 0){
            this
                .serviceAddressService
                .ToServeDeliveryAddresses(this.entity.uuid)
                .subscribe(
                    (items) => {
                        this.deliveryAddress$.next(items);
                        if(this.deliveryAddress$.value){
                            let sort: ServiceAddress[] = [];
                            this.servees$.value.map((servee)=>{
                                let add = this.deliveryAddress$.value.find((address)=> address.toServe_uuid == servee.uuid);
                                if(add) {sort.push(add)};
                            })
                            this.deliveryAddress$.next(sort);
                        }
                    }
                )
        }else{
            this
                .serviceAddressService
                .list({
                    reloadFresh: true,
                    where: {
                        filters: {
                            order_uuid: {
                                value: entity.uuid, 
                                operator: "="
                            },
                        }
                    }
                })
                .subscribe(
                    (items) => {
                        this.deliveryAddress$.next(items);

                        // TEMP FIX to set the delivery Address data for SOP orders
                        if(this.deliveryAddress$.value.length == 0 && this.deliveryAddress.value) {
                            this.deliveryAddress$.next([this.deliveryAddress.value]);
                        }
                    }
                )
        }
    }
    
    public fetch_courtAddresses(entity: Order) {
        if(!entity || !entity.uuid || entity.status !== 'draft'){
            this.courtAddresses$.next([]);
            return;
        }
        
        this.serviceAddressService.list({
            reloadFresh: true,
            where: {
                filters: {
                    order_uuid: {
                        value: entity.uuid, 
                        operator: "="
                    }           
                }
            }
        }).subscribe(
            (items) => {
                this.courtAddresses$.next(items);
            }
        )
    }

    public test(){
        this.fetch_Servees(this.entity);
        // console.log(this.deliveryAddress$.value, this.servees$.value)
        this.orderService = new BehaviorSubject<OrderService>(new OrderService())
        // console.log(!this.orderService)
        // console.log(this.orderServiceCategory.value.category.name)
        // console.log(this.orderServiceCategory.value.category.name == ServiceTypes.SERVICE_OF_PROCESS)
        
        if(!this.orderService) return false;
        let servName = this.orderServiceCategory.value.category.name;

        return (servName == ServiceTypes.SERVICE_OF_PROCESS);

    }

    public fetch_Submissions(entity: Order){
    if(!entity || (entity && !entity.uuid)){
        return;
    }
    
    this
        .SubmissionService
        .list({
            reloadFresh: true,
            where: {
                includeDeleted:true,
                filters: {
                    order_uuid: {
                        value: entity.uuid,
                        operator: "="
                    },
                }
            }
        })
        .subscribe(
            (items) => {
                this.submission$.next(items[0]);
            if(this.submission$.value) this.specialInstructions = this.submission$.value.requestNotes!
            }
        )
    }

    public fetch_OrderService(entity: Order){
        if(!entity || (entity && !entity.uuid)){
            return;
        }

        this
            .orderServiceService
            .list({
                reloadFresh: true,
                where: {
                    includeDeleted:true,
                    filters: {
                        order_uuid: {
                            value: entity.uuid,
                            operator: "="
                        },
                            
                    }
                }
            })
            .subscribe(
                (items) => {
                    if(items[0]){
                        this.orderService = new BehaviorSubject<OrderService>(new OrderService());
                        this.orderService.next(items[0])
                        this.orderServiceService.getAttemptsAndAffidavits(this.orderService.value.uuid).subscribe((response)=>{
                            this.orderServiceAttempts.next(response.attempts)
                            this.orderServiceAffidavits.next(response.affidavits)
                        })
                    }
                }
            )
    }

    public fetch_OrderSpecialInstructions(entity: Order){
        // console.log('FETCH ORDER SPECIAL', entity.uuid);
        if(!entity || (entity && !entity.uuid)) 
            return;

        this.orderSpecialInstructionsService
            .search({
                    where: {
                        includeDeleted:false,
                        filters: {
                            order_uuid: entity.uuid
                        }
                    }
                }
            )
            .subscribe(
                (response: any) => {
                    
                    // console.log('ORDER SPECIAL INSTRUCTIONS RESPONSE', response);
                    let specialInstructions = response.results[0];
                    if(specialInstructions?.uuid)
                        this.orderSpecialInstructions.next(specialInstructions)
                    console.log('ORDER SPECIAL INSTRUCTIONS', this.orderSpecialInstructions.value);
                    
                }
            );
    }

    public deliverySpecialInstructionBuilder() {
        if(!this.orderSpecialInstructions.value.deliveryInstruction && !this.orderSpecialInstructions.value.deliveryAdditionalInstruction) return "";
        
        let deliverySpecialInstructions = "Deliver Special Instructions: " 
            + (this.orderSpecialInstructions.value.deliveryInstruction 
                ? this.orderSpecialInstructions.value.deliveryInstruction + "; " : "")
            + (this.orderSpecialInstructions.value.deliveryAdditionalInstruction || "");
        
        return deliverySpecialInstructions;
    }

    public pickupSpecialInstructionBuilder() {
        if(!this.orderSpecialInstructions.value.pickupInstruction && !this.orderSpecialInstructions.value.pickupAdditionalInstruction) return "";
        
        let deliverySpecialInstructions = "Pickup Special Instructions: " 
            + (this.orderSpecialInstructions.value.pickupInstruction 
                ? this.orderSpecialInstructions.value.pickupInstruction + "; " : "")
            + (this.orderSpecialInstructions.value.pickupAdditionalInstruction || "");
        
        return deliverySpecialInstructions;
    }

    fetch_RelatedEntities(entity: Order){
        this.fetch_Servees(entity);
        this.fetch_Submissions(entity);
        this.fetch_OrderService(this.entity);
        this.set_OrderNumber(entity);
        this.fetch_OrderSpecialInstructions(entity);
    }

    updateCrumbActions() {
        let crumbActions : BreadcrumbAction[] = [
            // { label: 'Export PDF', action:  this.setReviewData.bind(this), isPrimary: false, icon: 'description', tooltip: 'Prepare a PDF with information from this order.' }
        ];
        
        if(this.entity.orderStatus != OrderStatus.DRAFT && this.entity.orderStatus != OrderStatus.CANCELED) {
            // only show the "Export PDF" button for non Draft orders per SCS-587
            if(this.entity.successAccount_uuid == this.userPermissionService.loggedInUser?.uuid)
                crumbActions.push({ label: 'Clone Order', routerLink: ['/order/clone', this.entity.id], icon:'content_copy', tooltip: 'Clone this order.'});
            crumbActions.push({ label: 'Export PDF', action:  this.setReviewData.bind(this), isPrimary: false, icon: 'description', tooltip: 'Prepare a PDF with information from this order.' });
        }
        
        // check to allow only person who created the draft order to see buttons for resuming/canceling it 
        if(this.entity.orderStatus == OrderStatus.DRAFT && this.entity.successAccount_uuid == this.userPermissionService.loggedInUser?.uuid) {
            crumbActions.push({ label: 'Resume Order', routerLink: ['/order/resume', this.entity.id], icon: 'resume', tooltip: 'Resume this draft order.' });
            crumbActions.push({ label: 'Cancel Order', action: this.delete.bind(this), icon: 'delete', tooltip: 'Cancel this draft order.' });
            
        } else if (this.entity.orderStatus == "Canceled" && this.entity.successAccount_uuid == this.userPermissionService.loggedInUser?.uuid) {
            crumbActions.push({ label: 'Restore Order', action: this.restoreOrder.bind(this), icon: 'restore_from_trash', tooltip: 'Resume this draft order.' });
        } else {
            // crumbActions.push({ label: 'Clone Order', routerLink: ['/order/clone', this.entity.id], isPrimary: true, icon: 'library_add', tooltip: 'Start a new order with the same information.' });
        }
        this.breadCrumbService.actions.next(crumbActions);
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfigService.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.pageTitleService.setTitle("FirstConnect - Order - Loading");
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Orders', url: '/order'},
            { label: 'Loading' }
        ]);

        this.breadCrumbService.actions.next([]);

        // Only show the "thank you" banner if the order was just finished
        this.recentlyFinished.next(false);
        this.draftSaved.next(false);
        this.route.queryParams
            .pipe(
                filter(params => params.finished)
            )
            .subscribe(
                () => this.recentlyFinished.next(true)
            );

        this.route.queryParams
            .pipe(
                filter(params => params.draft)
            )
            .subscribe(
                () => this.draftSaved.next(true)
            )
                filter
        this.entityUpdated.pipe(filter(entity => {
            return (!!entity)
        }))
            .subscribe(
                () => {
                    this.updateCrumbActions();

                    if(this.entity.orderId) {
                        this.orderDetailService.get(this.entity.uuid)
                            .subscribe((n) => n ? this.orderDetail.next(n) : n);
                    }
                    
                    this
                        .vendorService
                        .list({
                            reloadFresh: true
                        })
                        .subscribe((records) => {
    
                        for(let i=0; i< records.length; i++){
                            //builds map of vendor.uuid to vendor.name
                            this.vendorMap.set(records[i].uuid,records[i].name)
                        }
                        });
                    


                    if(this.entity.delivery_ServiceAddress_uuid) {
                        let temp = this.serviceAddressService
                            .get(this.entity.delivery_ServiceAddress_uuid)
                            temp.subscribe( ls => {
                                this.deliveryAddress.next(ls);
                            })
                    }

                    this.fetch_courtAddresses(this.entity);

                    if(this.entity.pickup_ServiceAddress_uuid) {
                        let temp =  this.serviceAddressService
                            .get(this.entity.pickup_ServiceAddress_uuid)
                            temp.subscribe( ls => {
                                this.pickupAddress.next(ls);
                                this.isDataSynced = true;  
                            })
                    }   

                    if(this.entity.successAccount_uuid) {
                        this.successAccountService
                            .get(this.entity.successAccount_uuid)
                            .subscribe((n) => n ? this.createdByUser.next(n) : n);
                    }

                    if(this.entity.case_uuid) {
                        this.caseService
                            .get(this.entity.case_uuid)
                            .subscribe((n) => n ? this.case.next(n) : n);
                    }
                    console.log('entity vendor', this.entity.vendor_uuid);
                    if(this.entity.vendor_uuid) {
                        this.vendorService
                            .get(this.entity.vendor_uuid) 
                            .subscribe((v) => {
                                console.log('vendor', v);
                                return v ? this.vendor.next(v) : v
                            });
                    }

                    this.entityService
                        .findDocumentsByOrderId(this.entity.uuid)
                        .subscribe((n) => n ? this.orderDocuments.next(n) : n);

                    this.serviceService.dataSync.subscribe((dataSynced) => {
                        if(!dataSynced) return;

                        // console.log(this.serviceService.services.value);
                        if(this.entity.companyNumber && this.entity.orderNumber){
                            let comVal = Number.parseInt(this.entity.orderNumber.split('-')[0])
                            let conVal = this.entity.orderNumber.split('-')[1]
                            let obs = [
                                this.entityService.getDatatracInfo(comVal, conVal),
                                this.entityService.getDriverInfo(comVal, conVal)
                            ]
                            combineLatest(obs).subscribe(([datatrac,drivers])=>{
                                console.debug("Datatrac order info:", datatrac, drivers);
                                if(drivers.result && drivers.result[0]){
                                    if(drivers.result[0]){
                                        this.review.dispatchNumber = drivers.result[0].dispatcher_id;
                                    }
                                    if(datatrac){
                                        this.review.driverNumber = datatrac.driver1;
                                        this.review.bolNumber = datatrac.bol_number;
                                        this.review.pieces = datatrac.number_of_pieces;
                                        this.review.weight = datatrac.weight;
                                    }
                                }
                            })
                        }
                        //let type : ServiceType | undefined;
                        let category : ServiceCategoryWithTypes | undefined
                        let service : ServiceWithCategories | undefined = this.serviceService.services.value.find((s) => {
                            category = s.categories.find((c) => {
                                this.type = c.types.find((t) => t.uuid == this.entity.filingServiceType_uuid);

                                return !!this.type;
                            });
                            this.category = category?.category;

                            return !!category;
                        });

                        if(service) this.service.next(service);
                        if(category) this.orderServiceCategory.next(category);
                        if(category) this.fetch_OrderService(this.entity);

                    })

                    this.fetch_RelatedEntities(this.entity);
                    this.fetchNotes(this.entity);
                    
                    this.pageTitleService.setTitle("FirstConnect - Order - " + this.entity.orderNumber);

                    this.breadCrumbService.breadcrumbs.next([
                        { label: 'Orders', url: '/order'},
                        { label: 'Order ' + (this.entity.orderNumber ? this.entity.orderNumber : "Loading") }
                    ]);
                    
                }
            )
    }


    ngAfterViewInit() {
        super.ngAfterViewInit();

    }

    public exportPDF(): void {
        //this.setReviewData();
        //this.saveReviewToPDF()
    }
    /*
    the services that get an address displayed are:
    MESSANGER SERVICE
    RESEARCH
    --
    Services that get a served to section:
    SERVICE OF PROCESS / SOP

    EVERYTHING ELSE IS JUST COURT + SPECIAL INSTRUCTIONS

    */

    public get showServedTo() : boolean {
        if(!this.orderServiceCategory) return false;
        let servName = this.orderServiceCategory.value.category.name;
        return (servName == ServiceTypes.SERVICE_OF_PROCESS);

    }

    public get showDeliverTo() : boolean {
        if(!this.service) return false;
        let service = this.service.value;
        let servName = this.orderServiceCategory.value.category.name;

        return (servName == ServiceTypes.RESEARCH 
                || (servName == ServiceTypes.MESSENGER && this.entity.serviceType != "PDF COURTESY DELIVERY"));

    }

    public get showCourt() : boolean {
        if(!this.service) return false;
        let service = this.service.value;
        let servName = this.orderServiceCategory.value.category.name;

        return (servName == ServiceTypes.COURT_RUN || servName == ServiceTypes.EFILE || servName == ServiceTypes.PDF_FAX_FILING 
            || servName == ServiceTypes.COURT_FILING || servName == ServiceTypes.PDF_COURTESY_DELIVERY
            || (servName == ServiceTypes.MESSENGER && this.entity.serviceType == "PDF COURTESY DELIVERY"));

    }

    public get serviceCategoryDescription() : string {
        let orderData = JSON.parse(this.entity.orderData as string);
        return orderData.serviceCategoryDescription;
    }

    public fetchNotes(entity: Order){
        if(!entity || (entity && !entity.uuid))
            return;
        this.orderNoteService.list({
            reloadFresh: true,
            where: {
                includeDeleted:false,
                filters: {
                    visibleToCustomers:{
                        value: true,
                        operator: "="
                    },
                    order_uuid: {
                        value: entity.uuid,
                        operator: "="
                    },
                }
            }
        }).subscribe((items) => {
            this.orderNotes = items.map((orderNote)=>{
                return {
                    date: this.getDate(orderNote.createdAt),
                    time: this.getTime(orderNote.createdAt),
                    note: orderNote.note,
                    createdAt: orderNote.createdAt,
                }
            });

            this.orderNotes.sort((a, b)=>{
                return -(a.createdAt.getTime()-b.createdAt.getTime());
            });
            this.sortedOrderNotes.data = this.orderNotes;
        })
    }

    public getDate(date: Date){
        let dateString = date.toLocaleString();
        return dateString.slice(0,dateString.indexOf(','));
    }

    public getTime(date: Date){
        let dateString = date.toLocaleString();
        return dateString.slice(dateString.indexOf(',')+1);
    }
}

interface OrderNoteListEntry {
    date: string,
    time: string,
    note: string,
    createdAt: Date
}
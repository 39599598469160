<resource-list-grid>
	<resource-list-pane>
		<div class="loading" *ngIf="loading">
            <div class="loading-text">Loading Orders</div>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>

		<div [hidden]="loading">
			<mat-paginator
				(page)="pageChange($event)"
				[pageSizeOptions]="dataSource.pageSizeOptions"
				[pageIndex]="dataSource.pageIndex"
				[pageSize]="dataSource.pageSize"
				showFirstLastButtons >
			</mat-paginator>

			<mat-table multiTemplateDataRows [dataSource]="dataSource" matSort id="orderTable" (matSortChange)="sortData($event)">

				<ng-container cdkColumnDef="expand">
					<mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<mat-icon>{{entity == expandedEntity ? 'expand_less' : 'expand_more'}}</mat-icon>
						<button mat-icon-button [routerLink]="['/order', entity.uuid]" aria-label="View full order details">
							<mat-icon>list_alt</mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="division">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Service</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<div *ngIf="entity.division">
							<img src={{getDivisionSrc(entity.division)}} style="max-height:20px; margin-top:7px; margin-right:5px;" alt="Service Icon" />
						</div>
						<div>
							<span>{{entity.divisionExtended ? entity.divisionExtended : setDivisionInfo(entity)}}</span>
						</div>
						<div *ngIf="entity.divisionExtended == ' '">
							{{entity.division}} {{entity.serviceType ? ": " + entity.serviceType : ''}}
						</div>
						</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="requestedBy">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Requested By</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.requestedBy}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="orderNumber">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Order Number</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.orderNumber ? entity.orderNumber : 'Missing Number, ID: ' + entity.orderId}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="orderDate">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Order Date</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.orderDate ? (getDateUTC(1, entity) | date : 'MM/dd/YYYY h:mm a') : "Missing Date, Created: " + (getDateUTC(4, entity) | date : 'MM/dd/YYYY h:mm a') }}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="clientMatterNumber">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Reference</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.clientMatterNumber}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="caseNumber">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Case Number</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.caseNumber}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="displayStatus">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Status</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.displayStatus ? entity.displayStatus : "Missing Display Status, Order Status: " + entity.orderStatus}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="expandedDetail">
					<mat-cell *cdkCellDef="let entity">
						<div class="entity-detail" 
							[@detailExpand]="entity == expandedEntity ? 'expanded' : 'collapsed'">
							<mat-tab-group [animationDuration]="100">
								<mat-tab label="Overview">

									<div class="entity-detail-row">
										<div class="entity-detail-column">
											<div class="entity-additional case-name">
												<label>Case Name</label>
												<span>{{entity.caseName}}&nbsp;</span>
											</div>
											<div class="entity-additional claim-number">
												<label>Claim Number</label>
												<span>{{entity.claimNumber}}&nbsp;</span>
											</div>
											<div class="entity-additional service-type">
												<label>Service Type</label>
												<span>{{entity.serviceType}}&nbsp;</span>
											</div>
											<div class="entity-additional subject">
												<label>Subject</label>
												<span>{{entity.subject}}&nbsp;</span>
											</div>

										</div>

										<div class="entity-detail-column">
											<div class="entity-additional delivery-location">
												<label>Delivery Location</label>
												<span>{{entity.deliveryLocation}}&nbsp;</span>
											</div>
											<div class="entity-additional delivery-address">
												<label>Delivery Address</label>
												<span>{{entity.deliveryAddress}}&nbsp;</span>
											</div>
										</div>

										<div class="entity-detail-column">
											<div class="entity-additional order-date">
												<label>Order Date</label>
												<span>{{entity.orderDate ? (getDateUTC(1, entity) | date : 'MM/dd/YYYY h:mm a') : "Missing Date, Created: " + (getDateUTC(4, entity) | date : 'MM/dd/YYYY h:mm a') }}&nbsp;</span>
											</div>
											<div class="entity-additional job-date">
												<label>Job Date</label>
												<span>{{getDateUTC(2, entity) | date : 'MM/dd/YYYY h:mm a'}}&nbsp;</span>
											</div>
											<div class="entity-additional due-date">
												<label>Due Date</label>
												<span>{{getDateUTC(3, entity) | date : 'MM/dd/YYYY h:mm a'}}&nbsp;</span>
											</div>
										</div>

										<div class="entity-detail-column">
											<div class="entity-additional" *ngIf="allowSSO(entity)">
												<button mat-button color="primary" (click)="SSORedirect()"><u>Connect to the Records Portal</u></button>
											</div>
										</div>
									</div>
								</mat-tab>
								<mat-tab>
									<ng-template mat-tab-label>
										Documents
										<span class="chip-count">
											{{(collectedDocuments.get(entity) || []).length}}
										</span>
									</ng-template>
										<lib-order-documents *ngIf="collectedDocuments.has(entity)" 
											[showUploadControls]="false"
											[cc]="false"
											[order]="entity" 
											[documents]="collectedDocuments.get(entity) || []"
											[allowBatchDownloading]="true">
										</lib-order-documents>
								</mat-tab>
							</mat-tab-group>
						</div>
					</mat-cell>
				</ng-container>

				<mat-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
				<mat-row *cdkRowDef="let entity; columns: columnsToDisplay"
					class="entity-row"
					[class.expanded]="expandedEntity === entity"
					(click)="expandEntity(entity)">
				</mat-row>
				<mat-row *cdkRowDef="let entity; columns: ['expandedDetail']" class="detail-row" [class.expanded]="entity == expandedEntity"></mat-row>
			</mat-table>

			<mat-paginator
				(page)="pageChange($event)"
				[pageSizeOptions]="dataSource.pageSizeOptions"
				[pageIndex]="dataSource.pageIndex"
				[pageSize]="dataSource.pageSize"
				showFirstLastButtons >
			</mat-paginator>
		</div>

	</resource-list-pane>
	<resource-list-pane id="filterPaneOrder" #filter="resourceListPane" [isCollapsed]='filterCollapsed' title="Filters" [collapsable]='true' class="entity-filters" >

		<form *ngIf="filterFormGroup" (ngSubmit)="search()" [formGroup]="filterFormGroup">
			
			<mat-form-field class="filter">
				<mat-label>Division</mat-label>
			
				<div class="checkboxOptions">
					<mat-checkbox 
						*ngFor="let division of orderDivisions; let i=index"
						[value]="division.value" 
						[checked]="division.checked"
						[formControlName]="division.control"
						color="primary"
						#divisionCheckbox>
						{{division.description}}
					</mat-checkbox>
				</div>
				<input hidden=true matInput>
				<mat-hint>(select 1 or more)</mat-hint>
			</mat-form-field>
			<mat-form-field class="filter">
				<mat-label>Requested By</mat-label>
				<input matInput type="text" 
					formControlName="requestedBy"
					placeholder="Requested By">
			</mat-form-field>
			<mat-form-field class="filter">
				<mat-label>Order Number</mat-label>
				<input matInput type="text"
					formControlName="orderNumber"
					placeholder="Order Number">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Start Order Date</mat-label>
				<input formControlName="startOrderDate" matInput [matDatepicker]="startOrderDatePicker">
				<mat-datepicker-toggle matSuffix [for]="startOrderDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #startOrderDatePicker></mat-datepicker>
				<mat-error>{{getErrors('startOrderDate')}}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>End Order Date</mat-label>
				<input formControlName="endOrderDate" matInput [matDatepicker]="endOrderDatePicker">
				<mat-datepicker-toggle matSuffix [for]="endOrderDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #endOrderDatePicker></mat-datepicker>
			</mat-form-field>
			<mat-form-field class="filter">
				<mat-label>Reference</mat-label>
				<input matInput type="text" 
					formControlName="clientMatterNumber"
					placeholder="Reference">
			</mat-form-field>
			<mat-form-field class="filter">
				<mat-label>Case Number</mat-label>
				<input matInput type="text"
					formControlName="caseNumber"
					placeholder="Case Number">
			</mat-form-field>
			<mat-form-field class="filter">
				<mat-label>Status</mat-label>
				<input matInput type="text" 
					formControlName="displayStatus"
					placeholder="Status">
			</mat-form-field>

			<mat-form-field class="filter">
				<div class="checkboxOptions">
					<mat-checkbox 
						[checked]="includeDrafts.checked"
						[formControlName]="includeDrafts.control"
						color="primary"
						#includeDraftCheckbox>
						{{includeDrafts.description}}	
					</mat-checkbox>
				</div>
				<input hidden=true matInput>
			</mat-form-field>

			<div class="filterActions">
				<button mat-flat-button type="button" (click)="clearFilters();">
					Clear
				</button>
				<button mat-raised-button color="primary" type="submit">
					Search
				</button>
			</div>

		</form>
	</resource-list-pane>
</resource-list-grid>


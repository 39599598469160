import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Component, HostListener, Injectable, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import { 
	SuccessAccount_Service, 
	UserPermission_Service, 
	User_Service, 
	SystemConfig_service, 
	Telemetry_Service, 
	TelemetryEventType, 
	SuccessAccountPermissionNodeName
} from 'legalreflex-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators'
import { Router } from '@angular/router';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import jwtDecode, { JwtPayload } from "jwt-decode";
import sign from "jwt-encode";
import { ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';

let { legalreflexUrl} = environment.config;

@Component({
	selector: 'site-frame',
	templateUrl: './siteFrame.html',
	styleUrls: ['./siteFrame.scss'],
	animations: [
		trigger('expand', [
            state('collapsed', style({width: '0px', "flex-basis": '95px', "min-width": '95px'})),
            state('expanded', style({width: '*', "flex-basis": '250px', "min-width": '250px'})),
            transition('expanded <=> collapsed', [
				group([
					query('@sideExpand', animateChild()),
					animate('450ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
				]),
			]),
        ]),
        trigger('sideExpand', [
            state('collapsed', style({opacity:0, width: '0px', "flex-grow": 0.5})),
            state('expanded', style({opacity:1, width: '*', "flex-grow": 1})),
            transition('expanded <=> collapsed', animate('450ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
	]
})
export class SiteFrameComponent {

	public isScrolledDown: boolean = false;
	public copyRightYear : number = (new Date()).getFullYear();
	public expanded: boolean = true;
	constructor(
		public auth: OidcSecurityService,
		public successAccountService: SuccessAccount_Service,
		public userPermissionService: UserPermission_Service,
		public userService: User_Service,
		public httpActivity: HttpActivityInterceptor,
		public systemConfig: SystemConfig_service,
		public telemetryService: Telemetry_Service,
		public router: Router
	) {

	}

	get can_manage_users(): Observable<boolean> {
		return this.successAccountService.user.pipe(
			map(user => {
				// hopefully the roles have loaded, have no control when they get loaded, or when
				// they will be available. they should be observable.
				// guess user's are supposed to subscribe to isDataSynced?

				return this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_firm_users)
					|| this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_all_users);
					// || this.userPermissionService.hasRole(SuccessAccountRoleName.client_firm_admin);
					//|| this.userPermissionService.hasRole(SuccessAccountRoleName.fl_admin);
			})
		);
	}

	get can_access_admin(): Observable<boolean> {
		return this.successAccountService.user.pipe(
			map(user => {
				return this.userPermissionService.canDo(SuccessAccountPermissionNodeName.access_legalreflex_admin_site);
				// || this.userPermissionService.hasRole(SuccessAccountRoleName.fl_admin);
			})
		);
	}

	get can_access_clientMatter(): Observable<boolean> {
		return this.successAccountService.user.pipe(
			map(user => {
				return this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_clients);
				// || this.userPermissionService.hasRole(SuccessAccountRoleName.fl_admin)
				// || this.userPermissionService.hasRole(SuccessAccountRoleName.client_firm_admin);
			})
		)
	}

	public openFreshDesk() {
		window.open('https://support.beta.firstlegal.com', 'fresh')?.focus();
	}

	public openFeedback(){
		window.open('https://www.firstlegal.com/first-connect-feedback', 'feedback')?.focus();
	}

	public openAdmin() {
		window.open(legalreflexUrl, 'reflex')?.focus();
	}

	ngOnInit(): void
	{
		this.systemConfig.getSysConfigListObservable().subscribe();
		this.userService.user.subscribe(user =>
			{
				if(user.uuid)
				{
					this.telemetryService
						.announce
						(
							TelemetryEventType.RouteChange,
							user.uuid,
						)
						.subscribe();
				}
			}
		);
		
		if(this.systemConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };	
	}

	// Issue with cdk-scroll-block ignoring scroll
	@HostListener('window:scroll', ['$event'])
    public onWindowScroll(event: any) {
		this.isScrolledDown = (window.scrollY > 5);
		let element = window.document.getElementsByTagName('html')[0]
		if(element.classList.contains('cdk-global-scrollblock'))
			this.isScrolledDown = true;
        window.document.body.style.marginTop = this.isScrolledDown ? element.style.top + 'px' : "0px";
    }

}

// TODO: Move this to Reflex-Common framework
@Injectable({
	providedIn: 'root'
})
export class HttpActivityInterceptor implements HttpInterceptor {
	public isActive : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public activeRequests : BehaviorSubject<number> = new BehaviorSubject<number>(0);

	protected push() {
		let next = this.activeRequests.value + 1;
		this.activeRequests.next(next);
	}
	protected pop() {
		let next = this.activeRequests.value > 0 ? this.activeRequests.value-1 : 0;
		this.activeRequests.next(next);
	}

	constructor(
		public auth: OidcSecurityService
	) {
		this.activeRequests.subscribe(
			(count) => {
				this.isActive.next(count > 0);
			}
		)
	}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.push();
		const bearerToken = this.generateBearerToken();
		const isFlapiUrl = req.url.startsWith(environment.config['flapiUrl']);
		const isOpenAPIUrl = req.url.startsWith(environment.config['openapiUrl']);
		const isSCApiUrl = req.url.startsWith(environment.config['apiUrl']);
        const idToken = this.auth.getIdToken();
        
		if(isFlapiUrl || isOpenAPIUrl) {
			if(!bearerToken)
				throw new Error('Failed to generate bearer token');
			req = req.clone({
				setHeaders: { Authorization: bearerToken }
			});
		} else if (isSCApiUrl && idToken) {
			// Use JWT for all calls to the SuccessConnect api to avoid a request to the oidc server
			req = req.clone({
                setHeaders: { Authorization: `Bearer ${idToken}`}
            })
        }

		//console.log("Starting" + this.activeRequests.value);
        return next
			.handle(req)
			.pipe(
	        	finalize(() => {
					//console.log("Done" + this.activeRequests.value);
					this.pop();
				})
			);
    }

    public generateBearerToken(): string {
        let idToken = this.auth.getIdToken();
		if(idToken) {
			let decoded = jwtDecode(idToken) as JwtPayload;
			if(decoded && decoded?.sub) {
				let secret = ENV.config.openapiJWTSecret;
				let encode = sign(decoded, secret);
				return 'bearer ' + encode;
			} else {
				return '';
			}
		} else {
			return '';
		}
    }
}

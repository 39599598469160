<reflex-navigation [class.scrolled]="isScrolledDown">
    <reflex-navigation-contents>
        <a id="site-logo" routerLink="/">
            <img src="/assets/images/FirstConnect-Header-Logo.svg" alt="First Legal" />
			<div id="site-logo-name" >
				<div class="companyName">First<span>Connect</span></div>
				<div class="applicationName" *ngIf="false" >First Connect Portal</div>
			</div>
        </a>
        <div class="span"></div>

        <user-control></user-control>
		<user-flow></user-flow>
    </reflex-navigation-contents>
	<info-banner></info-banner>
</reflex-navigation>

<div id="site-body">
	<div [@expand]="expanded == true ? 'expanded' : 'collapsed'" id="site-navigation" *ngIf="auth.isAuthenticated() === true" [class.navigation-collapsed]="!expanded">
		<mat-list style="padding-top:0; padding-bottom:4px;" >
			<mat-list-item id="chev" style="left: -10px; max-height:25px; justify-items: end; display: grid;">
				<a mat-icon-button (click)="expanded = !expanded">
					<mat-icon *ngIf="expanded">chevron_left</mat-icon>
					<mat-icon *ngIf="!expanded">chevron_right</mat-icon>
				</a>
			</mat-list-item>
		</mat-list>
		
		<mat-list >
			<mat-list-item>
				<button matTooltip="Dashboard" mat-flat-button [routerLink]="['/']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Dashboard</span>
					<mat-icon>home</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button matTooltip="New Order" mat-flat-button [routerLink]="['/order/new']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">New Order</span>
					<mat-icon>library_add</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button matTooltip="Direct eFile" mat-flat-button [routerLink]="['/order/direct-efile']" [state]="{clientMatterNumber: '', claimNumber: ''}">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Direct eFile</span>
					<mat-icon fontSet="material-symbols-outlined">export_notes</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item *ngIf="recordsRetrievalAccounts.length > 0">
				<button matTooltip="Records Retrieval" mat-flat-button (click)="SSORedirect(vendorAccountService.vendorName.RecordsRetrieval)">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Records Retrieval</span>
					<mat-icon>party_mode</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item *ngIf="depositionsAccounts.length > 0">
				<button matTooltip="Depositions" mat-flat-button (click)="SSORedirect(vendorAccountService.vendorName.Depositions)">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Depositions</span>
					<mat-icon>gavel</mat-icon>
				</button>
			</mat-list-item>
            <mat-list-item>
				<button matTooltip="Order History" mat-flat-button [routerLink]="['/order']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Order History</span>
					<mat-icon>library_books</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button matTooltip="Billing History" mat-flat-button [routerLink]="['/invoice']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Billing History</span>
					<mat-icon>payments</mat-icon>
				</button>
			</mat-list-item>
		</mat-list>
		<div class="nav-header">{{expanded ? 'Manage' : ''}}</div>
		<mat-list>
			<mat-list-item *ngIf="can_manage_users | async">
				<button matTooltip="Users" mat-flat-button [routerLink]="['/users']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Users</span>
					<mat-icon>group</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item [routerLink]="['/case']">
				<button matTooltip="Case Manager" mat-flat-button>
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Case Manager</span>
					<mat-icon>topic</mat-icon>
				</button>
			</mat-list-item>
			<!--
			<mat-list-item [routerLink]="['/document']">
				<button matTooltip="Document Manager" mat-flat-button>
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Document Manager</span>
					<mat-icon>feed</mat-icon>
				</button>
			</mat-list-item>
			-->
			<mat-list-item *ngIf="false">
				<button mat-flat-button>
					<span>Filing/Job Activity</span>
					<mat-icon>task</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item [routerLink]="['/notifications']">
				<button matTooltip="Notifications" mat-flat-button>
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Notifications</span>
					<mat-icon>notifications</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button matTooltip="Address Book" mat-flat-button [routerLink]="['/address-book']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Address Book</span>
					<mat-icon>book_3</mat-icon>
				</button>
			</mat-list-item>
			<!--
			<mat-list-item>
				<button matTooltip="Client Matters" mat-flat-button *ngIf="can_access_clientMatter | async" [routerLink]="['/clientMatter']">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Client Matters</span>
					<mat-icon>folder</mat-icon>
				</button>
			</mat-list-item>
			-->
		</mat-list>
		<div *ngIf="false" class="nav-header">Initiate</div>
		<mat-list *ngIf="false">
			<mat-list-item>
				<button mat-flat-button>
					<span>New Case</span>
					<mat-icon>create_new_folder</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button mat-flat-button>
					<span>Court Filing</span>
					<mat-icon>drive_file_move_outline</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button mat-flat-button>
					<span>Service of Process</span>
					<mat-icon>file_copy</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button mat-flat-button>
					<span>Investigation</span>
					<mat-icon>find_in_page</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button mat-flat-button>
					<span>Messenger Service</span>
					<mat-icon>switch_account</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button mat-flat-button>
					<span>Deposition Service</span>
					<mat-icon>gavel</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button mat-flat-button>
					<span>Records Retrieval</span>
					<mat-icon>file_download</mat-icon>
				</button>
			</mat-list-item>
		</mat-list>
		<div class="nav-header">{{expanded ? 'Support' : ''}}</div>
		<mat-list>
			<mat-list-item>
				<button matTooltip="Support" mat-flat-button (click)="openFreshDesk()">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Support</span>
					<mat-icon>contact_support</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button matTooltip="Feedback" mat-flat-button (click)="openFeedback()">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Feedback</span>
					<mat-icon>rate_review</mat-icon>
				</button>
			</mat-list-item>
			<mat-list-item>
				<button matTooltip="Staff Admin" mat-flat-button *ngIf="can_access_admin | async" (click)="openAdmin()">
					<span [@sideExpand]="expanded == true ? 'expanded' : 'collapsed'">Staff Admin</span>
					<mat-icon>display_settings</mat-icon>
				</button>
			</mat-list-item>
		</mat-list>
	</div>
	<div id="application-frame">
		<breadcrumb-bar *ngIf="auth.isAuthenticated() === true"></breadcrumb-bar>
		<router-outlet></router-outlet>
	</div>
</div>

<site-footer></site-footer>

<mat-progress-bar [mode]="(httpActivity.isActive | async) ? 'indeterminate' : 'determinate'" id="httpActivityBar" [value]="httpActivity.activeRequests | async"></mat-progress-bar>
<reflex-download anchorLocation="top-right"></reflex-download>

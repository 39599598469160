import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VendorAccount } from 'projects/legalreflex-lib/src';

@Component({
  selector: 'selectVendorAccount-dialog',
  templateUrl: 'selectVendorAccount.html',
  styleUrls: ['./selectVendorAccount.scss']

})
export class SelectVendorAccountDialog implements OnInit {
  public isImpersonating: boolean = false;
  public impersonates: string | null = '';

  constructor(
    private dialogRef : MatDialogRef<SelectVendorAccountDialog>,

    @Inject(MAT_DIALOG_DATA) 
    public data: {
      vendor: string,
      accounts: Array<VendorAccount>
    }
  ) {   
  }

  ngOnInit(): void {
    this.impersonates = window.sessionStorage.getItem(`Impersonate`);
		if(this.impersonates) {
			this.isImpersonating = true;
		} else {
      if(this.data.accounts.length == 1)
        this.select(this.data.accounts[0]);
    }
  }

  select(result:VendorAccount){
    this.dialogRef.close(result)
  }

  close(){
    this.dialogRef.close()
  }
}

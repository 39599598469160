import { Component, Input, HostBinding } from '@angular/core'
import { Router } from '@angular/router';
import { ReflexDownloaderService, ReflexDownloadFile, ReflexDownloadGroup } from '../../services/reflexDownloader.service'
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'reflex-download',
    templateUrl: './reflex-download.component.html',
    styleUrls: ['./reflex-download.component.scss']
})
export class ReflexDownloadComponent {

    @Input()
    anchorLocation: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' = 'top-right';

    @Input()
    chunkSize: string = '5mb';

    @Input()
    maxChunks: number = 6;

    @HostBinding('style.top')
    top = '1vh';
    @HostBinding('style.left')
    left = 'calc(100% - 11vw)';

    @HostBinding('class')
    class = 'collapsed hidden';


    public message: string = '';
    public expanded: boolean = false;
    public fadingOut: boolean = false;
    public hidden: boolean = true;
    public inactiveTimeout: NodeJS.Timeout | null = null;

    private getClass() {
        let cssClass = this.expanded ? 'expanded' : 'collapsed';
        if (this.fadingOut) {
            cssClass += ' fade-out';
        }
        if (this.hidden) {
            cssClass += ' hidden';
        }

        return cssClass;
    }
    constructor(
        protected _router: Router,
        protected _reflexDownloaderService: ReflexDownloaderService
    ) {
        this._reflexDownloaderService.displayDetails.subscribe((value) => {
            this.expanded = value;
            this.class = this.getClass();
            this.alignAnchor();
        })
    }

    ngOnInit() {
        this.alignAnchor();
        this._reflexDownloaderService.chunkSize = this.chunkSize;
        this._reflexDownloaderService.concurrentChunks = this.maxChunks;
    }

    private alignAnchor() {
        let leftAnchor = this.expanded ? '21vw' : '11vw';
        let topAnchor = this.expanded ? '22vh' : '5vh';
        switch(this.anchorLocation) {
            case 'top-left':
                this.top = '1vh',
                this.left =  '1vw'
                break;
            case 'bottom-right':
                this.top = 'calc(100% - ' + topAnchor + ')';
                this.left =  'calc(100% - ' + leftAnchor + ')';
                break;
            case 'bottom-left':
                this.top = 'calc(100% - ' + topAnchor + ')';
                this.left =  '1vw';
                break;
            default:
                this.top = '1vh',
                this.left =  'calc(100% - ' + leftAnchor + ')'
        }
    }

    /**
    * Requests a download for a group of multiple files with option to compress them
    * @param {ReflexFileGroup} fileGroup Group of files to be downloaded
    */
    public downloadGroup(event: any, group: ReflexDownloadGroup) {
        event.stopPropagation();
        this._reflexDownloaderService.saveFileGroup(group);
    }

    public downloadFile(event: any, file: ReflexDownloadFile) {
        event.stopPropagation();
        this._reflexDownloaderService.saveFile(file);
    }

    public cancelFile(event: any, group: ReflexDownloadGroup, fileIndex: number) {
        event.stopPropagation();
        this._reflexDownloaderService.cancelFile(group, fileIndex);
    }


    public downloaderGroups() {
        return this._reflexDownloaderService.fileGroups;
    }

    public downloadMessage() {
        let prog = this.downloadProgress();
        if(prog === null) {
            return "No downloads";
        } else {
            if(prog >= 100) {
                if (this.inactiveTimeout === null) {
                    this.inactiveTimeout = setTimeout(() => {
                        this.fadingOut = true;
                        this.class = this.getClass();
                        setTimeout(() => {
                            this.hidden = true;
                            this.class = this.getClass();
                        }, 900);
                    }, 10000);
                }
                return "Downloads Ready";
            } else {
                this.hidden = false;
                if (this.inactiveTimeout) {
                    clearTimeout(this.inactiveTimeout);
                    this.fadingOut = false;
                    this.inactiveTimeout = null;
                }
                this.class = this.getClass();
                return "Downloading";
            }
        }
    }

    public downloadProgress() {
        return this._reflexDownloaderService.totalDownloadProgress();
    }

    public compressionProgress(group : ReflexDownloadGroup) {
        return group.compressionProgress.value;
    }

    public fileProgress(file: ReflexDownloadFile) {
        return file.downloadProgress.value;
    }

    public expand() {
        this._reflexDownloaderService.toggleDetails();
    }
}


import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class InvoiceLineItem extends DexResourceModel {

    @Field(String)
    public invoice_uuid: String | null = null;
    @Field(String)
    public order_uuid: String | null = null;
    @Field(String)
    public itemLabel: String = "";
    @Field(Number)
    public quantity: Number | null = null;
    @Field(Number)
    public rate: Number | null = null;
    @Field(Number)
    public revenue: Number | null = null;
    @Field(Number)
    public nonrevenue: Number | null = null;
    @Field(Number)
    public commissionable: Number | null = null;
    @Field(Number)
    public jobTotal: Number | null = null;
    @Field(Number)
    public lineTotal: Number | null = null;
    @Field(String)
    public syncLocks: String | null = null;
    @Field(Number)
    public tax: Number | null = null;


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}

import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ToServe extends DexResourceModel {
   
    @Field(String)
	public firstName: string = "";
    @Field(String)
	public lastName: string = "";
    @Field(String)
	public email: string = "";
    @Field(String)
	public phone: string = "";
    @Field(String)
	public age: string = "";
    @Field(String)
	public gender: string = "";
    @Field(String)
	public ethnicity: string = "";
    @Field(String)
	public weight: string = "";
    @Field(String)
	public height: string = "";
    @Field(String)
	public hair: string = "";
    @Field(String)
	public eyes: string = "";
    @Field(String)
	public relationship: string = "";
    @Field(Date)
    public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;
    
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}

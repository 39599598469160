import {Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'document-download-failed-dialog',
  templateUrl: 'documentDownloadFailed.html',
})
export class DocumentDownloadFailedDialog {
  public files: any;
  public error: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {files: any, error: any}) {
    this.files = data.files;
    this.error = data.error;
  }

  public getFileName(file: any) {
    return file.displayName? file.displayName : file.renameFileName;
  }
}

import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceListOptions, ResourceService } from '@smartsoftware/reflex-core';
import { Invoice } from '../models/invoice.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Page, PageParams, Pager } from '../paged.datasource';
import { InvoicePayment } from '../models';

@Injectable()
export class InvoicePayment_Service extends ResourceService<InvoicePayment> {
    protected servicePath: string = '/InvoicePayment';

    public ModelType = InvoicePayment;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getByInvoiceUuid(invoice_uuid: string) {
        return this.http.get<InvoicePayment[]>(`${this.serviceUrl}getByInvoiceUuid?invoice_uuid=${invoice_uuid}`).pipe(map(arr => arr.map(ip => new InvoicePayment(ip))));
    }
}

<h1 mat-dialog-title>Download Failed</h1>
<div style="display:inline-block" mat-dialog-content>
    The following documents failed to download.
    <div *ngFor="let file of files; index as i" class="file-progess">
        <div class="download-header" *ngIf="file.downloadProgress.value > 100">
            <h2><mat-icon color="warn">error</mat-icon>{{getFileName(file)}}</h2>
            <p>{{error[i]}}</p>
        </div>
    </div>
    <br>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close autofocus>Close</button>
</div>

import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class OrderServiceAffidavit extends DexResourceModel {

    @Field(String)
    public orderService_uuid: string = "";
    
    @Field(String)
    public vendor_id: string | null = null;

    @Field(String)
    public title: string | null = null;

    @Field(String)
    public linkTo: string | null = null;
    
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}

<div *ngIf="isImpersonating">
  <h1 mat-dialog-title>Impersonate mode</h1>
  <p>{{data.vendor}} SSO portal has been disabled.</p>
  <p>You are impersonating user {{impersonates}}.</p>
  <div mat-dialog-actions>
      <button mat-button mat-dialog-close autofocus>Close</button>
  </div>
</div>

<div *ngIf="!isImpersonating">
  <h1 mat-dialog-title>You have multiple {{data.vendor}} accounts</h1>
  <div mat-dialog-content>
    <label>Please select one of the {{data.vendor}} accounts to login: </label>
  
    <ng-container *ngFor="let account of data.accounts">
      <br/>
      <button mat-raised-button color="primary" (click)="select(account)">
        <mat-icon>open_in_new</mat-icon>
        {{account.username? account.username : account.email}}
      </button>
    </ng-container>
  </div>
</div>
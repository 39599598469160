


import { DexResourceModel } from "../abstracts/dexResource.model";
import { Field } from '@smartsoftware/reflex-core';

export class ClientBranchSalesperson extends DexResourceModel {

    @Field(String)
    public client_uuid: string = "";

    @Field(String)
    public clientBranch_uuid: string = "";

    @Field(String)
    public employee_uuid: string = "";

    @Field(Number)
    public commissionPercent: Number | null = null;

    @Field(Date)
    public startDate: Date | null = null;

    @Field(Date)
    public endDate: Date | null = null;


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}



import { DexResourceModel } from "../abstracts/dexResource.model";
import { Field } from '@smartsoftware/reflex-core';

export class FLCompanyEmployee extends DexResourceModel {

    @Field(String)
    public successAccount_uuid: String | null = null;

    @Field(String)
    public company_uuid: String = "";

    @Field(Boolean)
    public isActive: Boolean = true;

    @Field(Number)
    public datatracUserId: Number | null = null;

    @Field(String)
    public flnUsername: String | null = null;

    @Field(String)
    public emailAddress: String | null = null;

    @Field(String)
    public firstName: String | null = null;

    @Field(String)
    public lastName: String | null = null;

    @Field(String)
    public phoneNumber: String | null = null;

    @Field(String)
    public title: String | null = null;

    @Field(String)
    public salesCode: String | null = null;


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { OrderServiceAffidavit } from '../models/orderServiceAffidavit.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class OrderServiceAffidavit_Service extends ResourceService<OrderServiceAffidavit> {
    protected servicePath: string = '/OrderServiceAffidavit';

    public ModelType = OrderServiceAffidavit;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getServeManagerDocuments(url: string, affidavit?: OrderServiceAffidavit) {
        return this
            .http
            .post(
                this.serviceUrl + 'getServeManagerDocuments',
                {
                    url:url
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        Object.assign(response, {uuid:affidavit?.uuid, fileName:affidavit?.title})
                        return response;
                    }
                )
            )
    }

    public getCaseOrderAffidavits(caseId: string): Observable<Array<OrderServiceAffidavit>> {
        let orderResponse: OrderServiceAffidavit[] = []
        return this
            .http
            .post(
                this.serviceUrl + 'getCaseOrderAffidavits',
                {
                    caseId:caseId
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        orderResponse = response.body.map((affidavit: OrderServiceAffidavit)=> new OrderServiceAffidavit(affidavit));
                        return orderResponse;
                    }
                )
            )
    }
}

import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Attorney extends DexResourceModel {
    @Field(String)
    public clientBranch_uuid: string = "";

    @Field(String)
    public adressBook_uuid: string = "";

    @Field(Number)
    public barId: Number = 0;

    @Field(String)
    public successAccount_uuid: string | null = null;

    @Field(String)
    public emailAddress: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceListOptions, ResourceService } from '@smartsoftware/reflex-core';
import { Invoice } from '../models/invoice.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Page, PageParams, Pager } from '../paged.datasource';

@Injectable()
export class Invoice_Service extends ResourceService<Invoice> implements Pager<Invoice> {
  protected servicePath: string = '/Invoice';

  public ModelType = Invoice;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  page(params: PageParams): Observable<Page<Invoice>> {
    return this.filterListInvoice(params);
  }

  public filterListInvoice(pageParams: PageParams) {
    if (!pageParams.sortColumn)
      pageParams.sortColumn = 'invoiceNumber';

    if (!pageParams.sortOrder)
      pageParams.sortOrder = 'desc';

    let params = new HttpParams().append('params', btoa(JSON.stringify(pageParams)));

    return this.http.get<Page<Invoice>>(`${this.serviceUrl}`, {
      params
    }).pipe(map(page => {
      page.items = page.items.map(invoice => new Invoice(invoice));
      return page;
    }));
  }

  public totalCount() {
    return this
      .http
      .get(
        this.serviceUrl + 'totalCount',
        {
          observe: 'response'
        }
      )
      .pipe(
        map(
          (response: HttpResponse<any>) => {
            return response.body;
          }
        )
      );
  }
}

@Injectable()
export class AdminInvoice_Service extends ResourceService<Invoice> implements Pager<Invoice> {
  protected servicePath: string = '/Invoice';

  public ModelType = Invoice;

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  page(params: PageParams): Observable<Page<Invoice>> {
    return this.history(params);
  }

  public history(pageParams: PageParams) {
    let params = new HttpParams().append('params', btoa(JSON.stringify(pageParams)));
    return this.http.get<Page<Invoice>>(`${this.serviceUrl}`, {
      params
    }).pipe(map(page => {
      page.items = page.items.map(invoice => new Invoice(invoice));
      return page;
    }));
  }

  public printBilling(params: any) : Observable<BillingResults> {
    return this
    .http
    .post(
      this.serviceUrl + 'printBilling',
      {
        userEmail: params.userEmail,
        customerNo: params.customerNo,
        controlNo: params.controlNo ? params.controlNo : null,
        clientMatter: params.clientMatter ? params.clientMatter : null
      },
      {
        observe: 'response'
      }
    )
    .pipe(
      map(
        (response: HttpResponse<any>) => {
          return response.body.result as BillingResults;
        }
      )
    );
  }

  public sendBillingEmail(params: any) : Observable<any> {
    return this
    .http
    .post(
      this.serviceUrl + 'sendBillingReportEmail',
      {
        byClientMatter: params.byClientMatter,
        orders: params.orders,
        pdfOutput: params.pdfOutput,
        controlNumbers: params.byClientMatter ? null : params.controlNumbers,
        userEmail: params.userEmail
      },
      {
        observe: 'response'
      }
    )
    .pipe(
      map(
        (response: HttpResponse<any>) => {
          return response.body;
        }
      )
    );
  }
}

export interface BillingResults {
  orders: any[], // Cops Orders
  pickups: any[], // FirstLegal Order Pickups
  rates: any[] // Cops Rate Buckets
}